import React from 'react'
import { IListItem, IListProperty } from '../../models'
import StyledList from './styles'

export interface IProps {
  items: IListItem[]
  properties: IListProperty[]
  className?: string
}

export const List: React.FC<IProps> = ({ items, properties, className, children }) => {
  const header = () => {
    return properties.map((prop: IListProperty) => (
      <p key={ prop.key } className={ prop.className }>{ prop.label }</p>
    ))
  }

  const rows = () => {
    return items.map(item => (
      <li key={ item.key } className={ `item ${ item.className ?? '' }` }>
        {
          properties.map(prop => (
            <div
              key={ `item-prop-${ prop.key }` }
              className={ prop.className ? prop.className : '' }
            >
              { item[prop.key] }
            </div>
          ))
        }
      </li>
    ))
  }

  return (
    <StyledList className={ `list__content ${ className ?? '' }` }>
      <li className="list__header">{ header() }</li>
      <div className="scrollable-rows">
        { rows() }
        { children }
      </div>
    </StyledList>
  )
}
