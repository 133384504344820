import { Button, Checkbox, Field, Input } from '@valudio/ui'
import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { ModalNew as Modal } from '../../../../components'
import { SessionContext } from '../../../../context'
import { IModal, ITeacherObject } from '../../../../models'
import Styled from './styles'

interface IProps extends IModal {
  onCreate: (teacher: ITeacherObject) => void
}

const ModalNew: React.FC<IProps> = ({ setTableUpdated = () => undefined, title, isHidden, onClose, onCreate }) => {
  const { formatMessage } = useIntl()
  const { openModal, setOpenModal, loading } = useContext(SessionContext)
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [userName, setUserName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [isAdmin, setIsAdmin] = useState(false)

  const handleClose = () => {
    handleCleanup()
    if (onClose && typeof onClose === 'function') onClose()
  }

  const handleCleanup = () => {
    setFirstName('')
    setLastName('')
    setUserName('')
    setEmail('')
    setPassword('')
    setIsAdmin(false)
  }

  const handleSubmit = () => {
    const newTeacher: ITeacherObject = {
      id: 0,
      firstName,
      lastName,
      userName,
      email,
      password,
      isAdmin
    }
    onCreate(newTeacher)
    handleCleanup()
  }

  return (
    <Modal
      isHidden={ isHidden }
      title={title}
      setTableUpdated={setTableUpdated}
      onClose={ handleClose }
    >
      <Styled>
        <Field label={ formatMessage({ id: 'labelFirstName'}) }>
          <Input
            className='input'
            onChange={setFirstName}
            initialValue={firstName}
            placeholder={(formatMessage({ id: 'placeholderFirstName'}))}
            type='text'
          />
        </Field>
        <Field label={ formatMessage({ id: 'labelLastName'}) }>
          <Input
            className='input'
            onChange={setLastName}
            initialValue={lastName}
            placeholder={ formatMessage({ id: 'placeholderLastName'}) }
            type='text'
          />
        </Field>
        <Field label={ formatMessage({ id: 'userName'}) }>
          <Input
            className='input'
            onChange={setUserName}
            initialValue={userName}
            placeholder={formatMessage({ id: 'placeholderUserName'})}
            type='text'
          />
        </Field>
        <Field label={ formatMessage({ id: 'email'}) }>
          <Input
            className='input'
            onChange={setEmail}
            initialValue={email}
            placeholder={formatMessage({ id: 'placeholderYourEmail'})}
            type='email'
          />
        </Field>
        <Field label={formatMessage({ id: 'labelPassword'})}>
          <Input
            className='input'
            onChange={setPassword}
            initialValue={password}
            placeholder={formatMessage({ id: 'placeholderPassword'})}
            type='password'
          />
        </Field>
        <div className="checkbox-container">
          <Checkbox
            value={ isAdmin }
            onChange={ setIsAdmin }
          />
          <span>{ formatMessage({ id: 'admin' }) }</span>
        </div>
        <div className='content-buttons'>
          <Button type='tertiary' onClick={() => setOpenModal(!openModal)} isDisabled={ loading }>
            {formatMessage({ id: 'cancel' })}
          </Button>
          <Button
            style={{ marginLeft: '0.5rem' }}
            isDisabled={
              !firstName || !lastName || !userName || !email || !password || loading
            }
            onClick={ handleSubmit }
            isLoading={ loading }
          >
            {formatMessage({ id: 'save' })}
          </Button>
        </div>
      </Styled>
    </Modal>
  )
}

export default ModalNew
