export interface ICommentRowItem {
  id: number
  label: string
  comments: string
  isEditDisabled: boolean
}
// TODO: Review unused models
export interface IStudentGeneralComments {
  idStudent: string
  commentsStudents: IGeneralComment[]
}

export interface IGeneralComment {
  comments: string
  commentsToList: IAdditionalExam[]
  commentType: GeneralCommentType
}

export interface IAdditionalExam {
  rowId: number
  typeListAditionalExams: AdditionalExamsType
  subjectName: string
  date: string
}

export enum GeneralCommentType {
  FinalDecision,
  FinalDecisionJustification,
  CertificateCompetenceJustification,
  AdditionalExams,
  AbsentDaysJustified,
  AbsentDaysUnJustified,
  CertificateCompetenceDecision
}

export enum AdditionalExamsType {
  Exam,
  Homework
}

export const initialGeneralComment: IGeneralComment = {
  comments: '',
  commentsToList: [],
  commentType: GeneralCommentType.FinalDecision
}

export const initialAdditionalExamDraft: IAdditionalExam = {
  rowId: 0,
  typeListAditionalExams: AdditionalExamsType.Exam,
  subjectName: '',
  date: ''
}
