import styled from 'styled-components'

export default styled.article`
  height: 100%;

  .sample-text-value {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
  }

  .add-button {
    button {
      width: 230px;
    }
  }
`
