import { Dispatch, ReactNode, SetStateAction } from 'react'
import { ISection, ISubSection, Year } from '.'

export interface IClass {
  id: number
  code?: string
  name?: string
  idSection?: number | null
  idSubSection?: number | null
  students: any[]
  teachersLeaders: any[]
  year?: Year
  disableIndicationReport: boolean
}

export interface IClassResponse {
  id: number
  code?: string
  name?: string
  idSection?: number | null
  section: string
  idSubSection?: number | null
  subSection: string
  students: any[]
  teachersLeaders: any[]
  year?: Year
  disableIndicationReport: boolean | null
}

export interface IClassRow {
  id: number
  code: string
  name: string
  section?: ISection
  subSection?: ISubSection
  students: any[]
  teachersLeaders: any[]
  year?: Year
  disableIndicationReport: boolean | null
}

export interface IClassEdit {
  id: number
  code?: string
  section?: ISection
  subSection?: ISubSection
  name?: string
  students: number[]
  teachersLeaders: number[]
  year?: Year
  disableIndicationReport: boolean
}

export interface IModalTabEdit {
  openModalTabEdit: boolean
  classEdit: IClassEdit
}

export interface IClassObject {
  id: number
  name: string
  code: string
  section: string
  subSection: string
  year: Year
}

export interface IObjectValue {
  id?: number
  value?: string
  name?: string
  type?: string
  selected?: boolean
  delete?: ReactNode
}

export interface ITabContentProps {
  itemKey: string
  title: string
  nameInput: string
  codeInput: string
  yearInput: Year
  idSectionInput: number
  idSubSectionInput: number
  titleModal?: string
  items?: any
  loading?: boolean
  itemsArray: IObjectValue[]
  setItemsArray: Dispatch<SetStateAction<IObjectValue[]>>
  setTableUpdated: React.Dispatch<React.SetStateAction<boolean>>
}

export interface ITypeItem {
  key: string
  name: string
  items?: any[]
  type: string
  loading?: boolean
}

export interface IModalTabProps {
  title: string
  itemsForTab: ITypeItem[]
  setTableUpdated: Dispatch<SetStateAction<boolean>>
  isHidden?: boolean
  onClose?: () => void
}

export interface IModalTabEditProps {
  title: string
  itemsForTab: any[]
  setTableUpdated: Dispatch<SetStateAction<boolean>>
  name: string
  onNameChange: Dispatch<SetStateAction<string>>
  code: string
  onCodeChange: Dispatch<SetStateAction<string>>
  year: Year
  onYearChange: Dispatch<SetStateAction<Year>>
  idSection: number
  onIdSectionChange: Dispatch<SetStateAction<number>>
  idSubSection: number
  onIdSubSectionChange: Dispatch<SetStateAction<number>>
  isHidden?: boolean
  onClose?: () => void
}

export interface ITableTabsProps {
  title: string
  titleModal?: string
  items?: any
}

export interface ITypeItemTable {
  key: string
  name: string
  items?: any[]
  group?: any[]
  type: string
  loading: boolean
}

export interface ITabItemProps {
  itemKey: string
  titleModal?: string
  title: string
  items?: any[]
  type: string
  loading?: boolean
  name: string
  code: string
  year: Year
  idSection: number
  idSubSection: number
  itemsArray: any[]
  setItemsArray: any
  itemsEdit?: any[]
  setTableUpdated: React.Dispatch<React.SetStateAction<boolean>>
}

export enum ClassTab {
  Students = 'students',
  Teachers = 'teachers'
}

export const initialClassDraft: IClassEdit = {
  id: 0,
  code: '',
  name: '',
  students: [],
  teachersLeaders: [],
  year: null,
  disableIndicationReport: true
}
