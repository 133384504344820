import { IBaseProps } from '@valudio/ui'
import React, { RefObject } from 'react'
import StyledSection from './styles'

interface IProps extends IBaseProps {
  parentRef: RefObject<HTMLDivElement>
}

const Dropdown: React.FC<IProps> = ({ isHidden, children, className, parentRef }) => {
  if (isHidden || !parentRef.current) return null

  const selectContainer = parentRef.current.getBoundingClientRect()
  const dropdownPosition = {
    top: selectContainer.top + selectContainer.height + 8,
    left: selectContainer.left
  }

  return <StyledSection className={ className || '' } style={ dropdownPosition } >{ children }</StyledSection>
}

export default Dropdown
