export interface ITeacherObject {
  id: number
  firstName: string
  lastName: string
  userName: string
  email?: string
  password?: string
  active?: boolean
  isAdmin?: boolean
}

export interface ITeacherRow {
  id: number
  firstName: string
  lastName: string
  userName: string
  email: string
  isAdmin?: boolean
}

export interface ITeacherEditObject {
  id: number
  firstName: string
  lastName: string
  userName: string
  email: string
  isAdmin: boolean
}

export const initialDraftTeacher: ITeacherEditObject = {
  id: 0,
  firstName: '',
  lastName: '',
  userName: '',
  email: '',
  isAdmin: false
}
