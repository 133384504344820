import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: column;
  min-width: 80vw;
  width: 100%;
  max-width: 1024px;
  height: 85vh;
  overflow-y: hidden;

  form {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: hidden;

    .form-row {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 1.5em;

      &:last-of-type {
        margin-bottom: 0;
      }

      .field {
        flex: 1;
        margin-left: 1em;
        margin-right: 1em;

        &.select {
          /* z-index: 1000; */

          &.year {
            /* width: 80px;
            flex-basis: 80px; */
            flex: 1;
          }

          .select-container {
            width: 100%;
            box-sizing: border-box;

            > section {
              z-index: 2000;
            }
          } 
        }

        .label {
          text-align: left;
        }

        input {
          border-radius: 4px;
          max-width: 100%;
        }
      }

      > .label {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: cenmter;

        .checkbox {
          margin-right: 8px;
        }
      }
    }

    .tabs {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 20px;
      overflow-y: hidden;

      nav {
        width: 100%;
        margin-top: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        div {
          cursor: pointer;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 12px 0;
          box-sizing: border-box;
          border-bottom: 4px solid transparent;
          color: ${({ theme }) => theme.colors.typography.medium };
          font-size: 16px;

          &.active {
            border-bottom-color: ${({ theme }) => theme.colors.primary.medium };
            color: ${({ theme }) => theme.colors.primary.medium };
          }
        }
      }

      .tab-content {
        flex: 1;
        padding: 1em 1em 0;
        box-sizing: border-box;
        background-color: ${({ theme }) => theme.colors.grey.light };
        overflow: hidden;
      }
    }

    .actions {
      width: 100%;
      padding: 1em;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      background-color: ${({ theme }) => theme.colors.grey.light };
    }
    
  }

  .content-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 1em;
    padding-right: 1em;
  }

  /* label {
    text-align: left;
    color: #b7b7b7;
    font-size: 14px;
  }

  label::after {
    content: '*';
    color: #008bf8;
    margin-left: 0.4rem;
  }

  label.error::after {
    display: none;
  } */
`
