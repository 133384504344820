import styled from 'styled-components'

export default styled.div`
  position: relative;

  button {
    i {
      font-size: 22px;
      line-height: 24px;
    }
  }

  .options {
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 2000;
    border-radius: 4px;
    overflow: hidden;
    width: max-content;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.2);
    background-color: ${ ({ theme }) => theme.colors.white };
    padding: 0;
  }
`
