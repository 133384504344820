import { IStudentGrade, IStudentGradeLine, initialStudentGradeLine } from '.'

// Responses from API
export interface ISubjectGradeResponse {
  id: number
  code: string
  name: string
  students: ISubjectGradeStudentResponse[]
  competencies: ISubjectGradeCompetencyResponse[]
}

export interface ISubjectGradeStudentResponse {
  id: number
  firstName: string
  lastName: string
  className: string
  classYear: string
  grade: IStudentGrade[]
  totalFirstSemester: number | null
  totalSecondSemester: number | null
  total: number | null
}

export interface ISubjectGradeCompetencyResponse {
  id: number
  name: string
  students: ISubjectGradeStudentResponse[]
}

// Models for UI
export interface ISubjectGradeDetail {
  id: number
  code: string
  name: string
  students: ISubjectGradeStudent[]
  competencies: ISubjectGradeCompetency[]
}

export interface ISubjectGradeStudent {
  id: number
  firstName: string
  lastName: string
  className: string
  classYear: string
  grade: IStudentGradeLine
  totalFirstSemester: number | null
  totalSecondSemester: number | null
  total: number | null
}

export interface ISubjectGradeCompetency {
  id: number
  name: string
  students: ISubjectGradeStudent[]
}

export interface ISubjectGradeCalculation {
  idSubject: number
  idCompetency: number | null
  idStudent: number
  lines: IStudentGrade[]
}

export interface ISubjectGradeTotals {
  totalFirstSemester: number | null
  totalSecondSemester: number | null
  total: number | null
}

export const initialSubjectGradeDetail: ISubjectGradeDetail = {
  id: 0,
  code: '',
  name: '',
  students: [],
  competencies: []
}

export const initialSubjectGradeStudent: ISubjectGradeStudent = {
  id: 0,
  firstName: '',
  lastName:  '',
  className: '',
  classYear: '',
  grade: initialStudentGradeLine,
  totalFirstSemester: null,
  totalSecondSemester: null,
  total: null
}
