import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: #FFFFFF;
  border-radius: 4px;
  margin: 0 0 8px;
  padding: 0 8px 0 18px;
  min-height: 40px;
  box-sizing: border-box;
  margin: 0 0 8px;

  &:hover {
    box-shadow: 0 2px 6px 0 rgba(66,74,97,0.12);

    span {
      color: ${ ({ theme }) => theme.colors.typography.darkest };
    }
  }

  span {
    flex: 1;
    text-align: left;
    font-size: 14px;
    color: ${ ({ theme }) => theme.colors.typography.dark };
  }

  button.circular {
    width: 30px;
    height: 30px;
  }
`
