import { IOption } from '@valudio/ui'
import axios, { AxiosResponse } from 'axios'
import { useCallback, useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { prefix } from '../config'
import { SessionContext } from '../context'
import { initialDraftSubjectParent, ISubjectParent } from '../models'
import { useAuth } from '.'

export const useSubjectParents = () => {
  const { formatMessage } = useIntl()
  const { setAlert, setOpenModalConfirm } = useContext(SessionContext)
  const { logOut } = useAuth()
  const [subjectParents, setSubjectParents] = useState<ISubjectParent[]>([])
  const [subjectParentOptions, setSubjectParentOptions] = useState<IOption[]>([])
  const [editSubjectParent, setEditSubjectParent] = useState<ISubjectParent>(initialDraftSubjectParent)
  const [isFetching, setIsFetching] = useState(false)
  const [isDraftFecthing, setIsDraftFecthing] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const bearerToken = localStorage.getItem(`${ prefix }:token`)
  const bearer = 'Bearer ' + bearerToken

  const fetchSubjectParents = async () => {
    setIsFetching(true)

    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/SubjectParent/GetAll`, {
      method: 'GET',
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json-patch+json'
      }
    }).then((response: AxiosResponse) => {
      const subjectParents: ISubjectParent[] = response.data
      const options = subjectParents.map(sp => ({ id: sp.id.toString(), label: `${ sp.code } - ${ sp.name }` }))
      setSubjectParents(subjectParents)
      setSubjectParentOptions(options)
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Subjects error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ?? formatMessage({ id: 'genericErrorListFetch' })
        })
      }
    }).finally(() => {
      setIsFetching(false)
    })
  }

  const searchSubjectParents = useCallback(async (query: string) => {
    setIsFetching(true)

    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/SubjectParent/Filter?search=${ query }`, {
      method: 'GET',
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json'
      }
    }).then((response: AxiosResponse) => {
      setSubjectParents(response.data)
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Subjects error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ?? formatMessage({ id: 'genericErrorListFetch' })
        })
      }
    }).finally(() => {
      setIsFetching(false)
    })
  }, [bearer, formatMessage, logOut, setAlert])

  const getSubjectParentById = async (id: number) => {
    setIsDraftFecthing(true)

    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/SubjectParent/GetOne/${ id }`, {
      method: 'GET',
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json'
      }
    }).then((response: AxiosResponse<ISubjectParent>) => {
      setEditSubjectParent(response.data)
      setIsModalVisible(true)
      setIsDraftFecthing(false)
    }).catch(async error => {
      if (error && error.response && error.response.status === 401) {
        await logOut()
      }
      if (process.env.NODE_ENV === 'development') {
        console.log('Subject fetch error: ', error)
      }
      // Change literal
      setAlert({
        type: 'error',
        message: error.Message ?? formatMessage({ id: 'genericErrorListFetch' })
      })
      setIsModalVisible(false)
      setIsDraftFecthing(false)
    })
  }

  const createSubjectParent = async (newSubjectParent: ISubjectParent) => {
    setIsFetching(true)

    const body = {
      code: newSubjectParent.code,
      name: newSubjectParent.name,
      sorting: newSubjectParent.sorting
    }
    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/SubjectParent/Create`,
      JSON.stringify(body),
      {
        headers: {
          accept: '*/*',
          Authorization: 'Bearer ' + bearerToken,
          'Content-Type': 'application/json-patch+json'
        }
      }
    ).then(async () => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'created' })
      })
      setIsModalVisible(false)
      await fetchSubjectParents()
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Subject fetch error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ??
          `${ formatMessage({ id: 'genericErrorCreate' }) } ${ formatMessage({ id: 'subject' }) }`
        })
      }
      setIsFetching(false)
    })
  }

  const updateSubjectParent = async (updatedSubjectParent: ISubjectParent) => {
    setIsFetching(true)

    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/SubjectParent/Update`,
      JSON.stringify(updatedSubjectParent),
      {
        headers: {
          accept: '*/*',
          Authorization: bearer,
          'Content-Type': 'application/json'
        }
      }
    ).then(async () => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'updated' })
      })
      setIsModalVisible(false)
      await fetchSubjectParents()
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Subject update error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ??
          `${ formatMessage({ id: 'genericErrorUpdate' }) } ${ formatMessage({ id: 'subject' }) }`
        })
      }
      setIsFetching(false)
    })
  }

  const deleteSubjectParent = async (id: number) => {
    setIsFetching(true)

    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/SubjectParent/Delete?id=${ id }`, null, {
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json'
      }
    }).then(async () => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'deleted' })
      })
      setOpenModalConfirm({ openModalConfirm: false, id: 0 })
      await fetchSubjectParents()
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Subject delete error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ??
            `${ formatMessage({ id: 'genericErrorDelete' }) } ${ formatMessage({ id: 'subject' }) }`
        })
      }
    })
  }

  return {
    subjectParents,
    subjectParentOptions,
    isDraftFecthing,
    setIsDraftFecthing,
    editSubjectParent,
    setEditSubjectParent,
    isFetching,
    setIsFetching,
    isModalVisible,
    setIsModalVisible,
    fetchSubjectParents,
    searchSubjectParents,
    getSubjectParentById,
    createSubjectParent,
    updateSubjectParent,
    deleteSubjectParent
  }
}
