import { Button, Icon } from '@valudio/ui'
import React from 'react'
import StyledDiv from './styles'

interface IProps {
  name: string
  onDelete: () => void
}

const Row: React.FC<IProps> = ({ name, onDelete }) => {
  return (
    <StyledDiv>
      <span>
        { name }
      </span>
      <Button
        isCircular
        onClick={ onDelete }
      >
        <Icon className='icon' icon='delete' />
      </Button>
    </StyledDiv>
  )
}

export default Row
