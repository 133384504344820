import { ITableColumn, ITableItem } from '@valudio/ui'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { CustomTable } from '../../../components'
import { useSections } from '../../../hooks'
import StyledArticle from './styles'

const Sections: React.FC = () => {
  const { formatMessage } = useIntl()
  const { sections, fetchSections, isFetching } = useSections()
  const [isTableUpdated, setIsTableUpdated] = useState(false)

  const sectionColumns: ITableColumn[] = [
    {
      key: 'id',
      label: '',
      style: {
        display: 'none'
      }
    },
    {
      key: 'name',
      label: formatMessage({ id: 'name' }),
      style: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    }
  ]

  const sectionItems: ITableItem[] = sections.map(section => ({
    ...section
  }))

  // const handleSearch = async (query: string) => {
  //   if (query) await searchSections(query)
  //   else await fetchSections()
  // }

  // const debounceSearch = debounce(handleSearch, 400)

  useEffect(() => {
    if (!sections.length) fetchSections()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledArticle>
      <CustomTable
        title={ formatMessage({ id: 'subjectParents' }) }
        columns={ sectionColumns }
        items={ sectionItems }
        loading={ isFetching }
        setValueSearch={ (value: string) => console.log(value) }
        tableUpdated={ isTableUpdated }
        setTableUpdated={ setIsTableUpdated }
      />
    </StyledArticle>
  )
}

export default Sections
