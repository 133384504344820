import styled from 'styled-components'

export default styled.div`
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  /* .container-form {
    margin-top: 1rem;
    margin-left: 1em;
    margin-right: 1em;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  } */

  .container-form > .input {
    flex-grow: 1;
    min-width: 40%;
  }

  .frame {
    overflow-x: visible !important;
    overflow-y: visible !important;
  }

  @media (max-width: 767px) {
    .frame {
      height: 100%;
      width: 80%;
    }
  }

   @media (max-width: 992px) {
    .content-buttons {
      margin-bottom: 2rem;
    }
  }

  @media (min-width: 768px) and (max-width: 1124px) {
    .frame {
      /* height: 50%; */
      width: 50%;
    }
  }

  @media (min-width: 1125px) {
    .frame {
      height: 420px;
      width: 40%;
    }
  }

  @media (min-width: 1024px) and (orientation: portrait) { 
    .frame {
      height: 425px;
      width: 40%;
    }
   }

  .content-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h3 {
      margin: 0;
    }
  }

  .content-header > button {
    margin: 0;
  }

  label {
    text-align: left;
    color: #b7b7b7;
    font-size: 14px;
  }

  label::after {
    content: '*';
    color: #008bf8;
    margin-left: 0.4rem;
  }

  label.error::after {
    display: none;
  }

  input {
    max-width: 100%;
    margin-bottom: 1.5rem;
    font-size: 14px;
  }

  .content-buttons {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: auto;
  }

  .select {
    width: 100%;
  }
`
