import { Button, Icon } from '@valudio/ui'
import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { isClickingOutside } from '../../../../helpers'
import { IMenuOption } from '../../../../models'
import { Option } from './Option'
import DivStyled from './styles'

interface IProps {
  options: IMenuOption[]
  isDisabled: boolean
  isHidden: boolean
  isLoading: boolean
  style?: CSSProperties
}

export const ReportGenerationOptions: React.FC<IProps> = ({ options, isDisabled, isHidden, isLoading, style }) => {
  const ref = useRef<HTMLDivElement>(null)
  const listRef = useRef<HTMLUListElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => setIsOpen(current => !current)

  const handleDocumentClick =  (event: MouseEvent) => {
    if (isClickingOutside(ref, listRef, event)) setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  const menuOptions = isOpen
    ? (
      <ul className="options" ref={ listRef }>
        { options.map(o => <Option key={ `menu-option-${ o.label }` } option={ o } onClick={ toggleOpen } />) }
      </ul>
    ) : null
  if (isHidden) return null
  return (
    <DivStyled ref={ ref } style={ style }>
      <Button
        onClick={ toggleOpen }
        type="tertiary"
        isCircular
        isDisabled={ isDisabled }
        isLoading={ isLoading }
      >
        <Icon icon="points"/>
      </Button>
      { menuOptions }
    </DivStyled>
  )
}

