import styled from 'styled-components'

export default styled.article`
  display: flex;
  flex-direction: column;
  width: 380px;
  height: auto;
  overflow-y: hidden;

  > header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 1em;
    padding-right: 1em;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 16px;

    button:last-of-type {
      margin-top: 20px;
    }

    input {
      width: 100%;
      border-radius: 4px;
    }
  }
`
