import styled from 'styled-components'

export default styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  .table-header {
    padding: 32px;
    box-sizing: border-box;
    z-index: 3;

    h2 {
      margin-top: 0;
    }
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: center;

    .search {
      border-radius: 4px;

      form {
        border-radius: 4px;

        > input {
          border-radius: 4px;
        }
      }
    }

    .add-button {
      i.add,
      i.share {
        font-size: 18px;
        line-height: 18px;
        margin-right: 6px;
      }
    }
  }

  .flex-item:nth-child(1) {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
  }

  .flex-item:nth-child(2) {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
  }

  .first-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: auto;
  }

  .second-content {
    display: flex;
    margin-top: 1rem;
  }


  .relative {
    position: relative;
  }

  .check-all {
    position: absolute !important;
    top: 8%;
    left: 4%;
  }

  span.name {
    text-transform: lowercase;
  }

  .info {
    margin-left: 0.5em;
    color: gray;
    font-size: 20px;
  }

  .container-status {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .photo {
    max-width: 40px;
    max-height: 40px;
    border-radius: 50%;
  }

  @media (max-width: 767px) {
    .custom-table {
      width: 200vw;
    }
  }

  @media (min-width: 768px) and (max-width: 992px) {
    .custom-table {
      width: 100vw;
    }
  }

  @media (max-width: 992px) {
    .relative {
      overflow-x: scroll;
    }

    .custom-table > .content > article > .cell:last-child {
      min-width: 100px;
    }

    .custom-table > .header > div:first-child,
    .custom-table > .content > article > div:first-child {
      display: none;
    }
  }

  @media (min-width: 768px) {
    .flex-container {
      flex-direction: row;
    }

    .second-content {
      margin-top: 0;
    }
  }

  .content-spinner {
    flex: 1;
    /* height: 300px; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${ ({ theme }) => theme.colors.grey.light };
  }

  // Filter Search
  .flex-container > article {
    border: 0;
  }

  .flex-container > article > section:last-child {
    top: 0;
  }

  .table {
    flex: 1;
    padding: 32px;
    box-sizing: border-box;
    background-color: ${ ({ theme }) => theme.colors.grey.light };
    overflow-y: auto;
  }

  .custom-table > .content > article {
    padding: 0;
    min-height: 40px;
    max-height: 40px;

    button.circular {
      width: 30px;
      height: 30px;
    }
  }
`
