import { Spinner } from '@valudio/ui'
import classnames from 'classnames'
import React from 'react'
import { useIntl } from 'react-intl'
import { IConsultationSubject } from '../../../../models'
import { Row } from './Row'
import StyledSection from './styles'

interface IProps {
  subjects: IConsultationSubject[]
  isLoading: boolean
  isIndicationDisabled: boolean
  onDisableOrDeleteSubject: (subjectId: number, disabled: boolean, deleted: boolean) => void
}

export const ReportTable: React.FC<IProps> = ({
  subjects, isLoading, isIndicationDisabled, onDisableOrDeleteSubject
}) => {
  const { formatMessage } = useIntl()

  const mandatorySubjects = subjects
    .filter(s => s.typeSubject.id === 1)
    .sort((prev, next) => prev.sorting > next.sorting ? 1 : -1)
    .map(s => (
      <Row
        key={ `subject-${ s.id }` }
        subject={ s }
        onDisableOrDelete={ (disabled, deleted) => onDisableOrDeleteSubject(s.id, disabled, deleted) }
      />
    ))

  const electiveSubjects = subjects
    .filter(s => s.typeSubject.id === 2)
    .sort((prev, next) => prev.sorting > next.sorting ? 1 : -1)
    .map(s => (
      <Row
        key={ `subject-${ s.id }` }
        subject={ s }
        onDisableOrDelete={ (disabled, deleted) => onDisableOrDeleteSubject(s.id, disabled, deleted) }
      />
    ))

  const qualificationSubjects = subjects
    .filter(s => s.typeSubject.id === 3)
    .sort((prev, next) => prev.sorting > next.sorting ? 1 : -1)
    .map(s => (
      <Row
        key={ `subject-${ s.id }` }
        subject={ s }
        onDisableOrDelete={ (disabled, deleted) => onDisableOrDeleteSubject(s.id, disabled, deleted) }
      />
    ))

  const internshipSubjects = subjects
    .filter(s => s.typeSubject.id === 4)
    .sort((prev, next) => prev.sorting > next.sorting ? 1 : -1)
    .map(s => (
      <Row
        key={ `subject-${ s.id }` }
        subject={ s }
        onDisableOrDelete={ (disabled, deleted) => onDisableOrDeleteSubject(s.id, disabled, deleted) }
      />
    ))

  return (
    <StyledSection className={ classnames({ loading: isLoading }) }>
      <section className="mandatory">
        <header className="table-header">
          <div className="column subjects">
            <span>{ formatMessage({ id: 'mandatorySubjects' }) }</span>
          </div>
          <div className="column hours">
            <span>{ formatMessage({ id: 'hours' }) }</span>
          </div>
          <div className="column semester">
            <h2>{ formatMessage({ id: 'firstSemester' }) }</h2>
            <ul>
              <li>{ formatMessage({ id: !isIndicationDisabled ? 'indicationPeriod' : 'firstPeriod' }) }</li>
              <li>{ formatMessage({ id: !isIndicationDisabled ? 'firstPeriod' : 'secondPeriod' }) }</li>
              <li>{ formatMessage({ id: 'exam' }) }</li>
              <li>{ formatMessage({ id: 'total' }) }</li>
            </ul>
          </div>
          <div className="column semester">
            <h2>{ formatMessage({ id: 'secondSemester' }) }</h2>
            <ul>
              <li>{ formatMessage({ id: !isIndicationDisabled ? 'indicationPeriod' : 'third' }) }</li>
              <li>{ formatMessage({ id: !isIndicationDisabled ? 'secondPeriod' :'fourth' }) }</li>
              <li>{ formatMessage({ id: 'exam' }) }</li>
              <li>{ formatMessage({ id: 'total' }) }</li>
            </ul>
          </div>
          <div className="column total">
            <h2>{ formatMessage({ id: 'total' }) }</h2>
          </div>
        </header>
        <section className="table-content">
          { isLoading ? <Spinner className="spinner__container" /> : mandatorySubjects }
        </section>
      </section>

      { electiveSubjects.length && !isLoading ?
        (
          <section className="elective">
            <header className="table-header">
              <div className="column subjects">
                <span>{ formatMessage({ id: 'electiveSubjects' }) }</span>
              </div>
              <div className="column hours" />
              <div className="column semester" />
              <div className="column semester" />
              <div className="column total" />
            </header>
            <section className="table-content">
              { electiveSubjects }
            </section>
          </section>
        ) : null
      }

      { internshipSubjects.length && !isLoading ?
        (
          <section className="elective">
            <header className="table-header">
              <div className="column subjects">
                <span>{ formatMessage({ id: 'internshipSubjects' }) }</span>
              </div>
              <div className="column hours" />
              <div className="column semester" />
              <div className="column semester" />
              <div className="column total" />
            </header>
            <section className="table-content">
              { internshipSubjects }
            </section>
          </section>
        ) : null
      }

      { qualificationSubjects.length && !isLoading ?
        (
          <section className="elective">
            <header className="table-header">
              <div className="column subjects">
                <span>{ formatMessage({ id: 'qualificationSubjects' }) }</span>
              </div>
              <div className="column hours" />
              <div className="column semester" />
              <div className="column semester" />
              <div className="column total" />
            </header>
            <section className="table-content">
              { qualificationSubjects }
            </section>
          </section>
        ) : null
      }

    </StyledSection>
  )
}
