import styled from 'styled-components'

export default styled.nav`
  flex: 1;
  padding: 2px;
  height: 40px;
  box-sizing: border-box;
  background-color: ${ ({ theme }) => theme.colors.grey.medium };
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  position: relative;
  overflow: hidden;

  div {
    flex: 1;
    height: 36px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    color: ${ ({ theme }) => theme.colors.typography.medium };
    transition: ease all 0.2s;

    &:hover:not(.empty) {
      background-color: ${ ({ theme }) => theme.colors.grey.light };
    }

    &.empty {
      pointer-events: none;
      cursor: default;
    }

    &.active {
      background-color: ${ ({ theme }) => theme.colors.white };
      color: ${ ({ theme }) => theme.colors.primary.dark };
      pointer-events: none;
    }
  }

  &.loading {
    &:after {
      content: "";
      display: block;
      position: absolute;
      top:0;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      background: linear-gradient(90deg, transparent, ${ ({ theme }) => theme.colors.white }E6, transparent);
      animation: loading 0.8s infinite;
    }
  }

  @keyframes loading {
    100% {
      transform: translateX(100%);
    }
  }
`
