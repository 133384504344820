import { useCallback, useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import axios, { AxiosResponse } from 'axios'
import { IOption } from '@valudio/ui'
import {
  IStudentEditObject,
  IStudentImportSummary,
  IStudentObject,
  IStudentRow,
  IStudentSubjectAction
} from '../models'
import { SessionContext } from '../context'
import { prefix } from '../config'
import { useAuth } from '.'

export const useStudents = () => {
  const { formatMessage } = useIntl()
  const { logOut } = useAuth()
  const { setAlert, setLoading } = useContext(SessionContext)
  const [students, setStudents] = useState<IStudentRow[]>([])
  const [studentOptions, setStudentOptions] = useState<IOption[]>([])
  const bearerToken = localStorage.getItem(`${ prefix }:token`)
  const bearer = 'Bearer ' + bearerToken

  const fetchStudents = useCallback(async () => {
    setLoading(true)
    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/Student/GetAll`, {
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json'
      }
    }).then((response: AxiosResponse<IStudentObject[]>) => {
      const data: IStudentObject[] = response.data
      const studentRows = data.map(d => ({...d, fullName: `${d.firstName || ''} ${d.lastName || ''}`}))
      setStudents(studentRows)
    }).catch(async (error) => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') console.log(error)
      }
      setLoading(false)
    }).finally(() => {
      setLoading(false)
    })
  }, [bearer, logOut, setLoading])

  const searchStudents = useCallback(async (query: string) => {
    setLoading(true)
    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/Student/Filter?search=${query}`, {
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json'
      }
    }).then((response: AxiosResponse<IStudentRow[]>) => {
      const data: IStudentRow[] = response.data
      setStudents(data)
      setLoading(false)
    }).catch(async (error) => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') console.log(error)
      }
      setLoading(false)
    })
  }, [bearer, logOut, setLoading])

  const createStudent = async (newStudent: IStudentEditObject) => {
    setLoading(true)
    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/Student/Create`,
      JSON.stringify(newStudent),
      {
        headers: {
          accept: '*/*',
          Authorization: bearer,
          'Content-Type': 'application/json-patch+json'
        }
      }
    ).then(async () => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'created' })
      })
      setStudents([])
      await fetchStudents()
    }).catch(async (error) => {
      setAlert({
        type: 'error',
        message: error.Message ?? formatMessage({ id: 'genericErrorCreate' })
      })
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') console.log(error)
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  const updateStudent = async (updatedStudent: IStudentEditObject) => {
    setLoading(true)
    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/Student/Update`,
      JSON.stringify(updatedStudent),
      {
        headers: {
          accept: '*/*',
          Authorization: bearer,
          'Content-Type': 'application/json-patch+json'
        }
      }
    ).then(async () => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'updated' })
      })
      setStudents([])
      await fetchStudents()
    }).catch(async (error) => {
      setAlert({
        type: 'error',
        message: error.Message ?? formatMessage({ id: 'genericErrorUpdate' })
      })
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') console.log(error)
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  const deleteStudent = async (id: number) => {
    setLoading(true)
    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/Student/Delete?id=${id}`, undefined, {
      headers: {
        accept: '*/*',
        Authorization: bearer
      }
    }).then(async () => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'deleted' })
      })
      setStudents([])
      await fetchStudents()
    }).catch(async (error) => {
      setAlert({
        type: 'error',
        message: error.Message ?? formatMessage({ id: 'genericErrorDelete' })
      })
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') console.log(error)
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  const fetchStudentsByClass = useCallback(async (id: string): Promise<void> => {
    setLoading(true)

    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/Student/GetStudentsByClass/${ id }`, {
      method: 'GET',
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json-patch+json'
      }
    }).then((response: AxiosResponse<IStudentRow[]>) => {
      setStudents(response.data)
      setStudentOptions(() => {
        return response.data
          .sort((prev, next) => {
            const prevStudent = `${ prev.lastName }${ prev.firstName }`.toLowerCase().replaceAll(' ', '')
            const nextStudent = `${ next.lastName }${ next.firstName }`.toLowerCase().replaceAll(' ', '')
            return prevStudent > nextStudent ? 1 : -1
          })
          .map((s: any) => ({
            ...s, id: s.id.toString(),
            label: `${ s.lastName.toUpperCase() } ${ s.firstName }`,
            name: s.firstName,
            lastname: s.lastName.toUpperCase()
          }))
      })
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Subjects error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ?? formatMessage({ id: 'genericErrorListFetch' })
        })
      }
    }).finally(() => {
      setLoading(false)
    })
  }, [bearer, formatMessage, logOut, setAlert, setLoading])

  const importStudents = async (file: File): Promise<IStudentImportSummary | void> => {
    const data = new FormData()
    data.append('file', file)

    return await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/Student/ImportStudents`,
      data,
      {
        headers: {
          accept: '*/*',
          Authorization: 'Bearer ' + bearerToken,
          'Content-Type': 'multipart/form-data'
        }
      }
    ).then(async (response: AxiosResponse<IStudentImportSummary>) => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'created' })
      })
      return response.data
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Students import error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ??
            `${ formatMessage({ id: 'studentsImportError' }) }`
        })
      }
    })
  }

  const disableDeleteSubject = async (body: IStudentSubjectAction): Promise<void> => {
    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/Student/SetDisabledDeleteStudent`,
      JSON.stringify(body),
      {
        headers: {
          accept: '*/*',
          Authorization: bearer,
          'Content-Type': 'application/json-patch+json'
        }
      }
    ).then(() => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'subjectDisabledOrDeleted' })
      })
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Student disable/delete error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ??
            `${ formatMessage({ id: 'subjectDisableDeleteError' }) }`
        })
      }
    })
  }

  return {
    students,
    studentOptions,
    setStudentOptions,
    fetchStudents,
    searchStudents,
    createStudent,
    updateStudent,
    deleteStudent,
    fetchStudentsByClass,
    importStudents,
    disableDeleteSubject
  }
}

