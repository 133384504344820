import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { publicsRoutes, publicsUrls } from '../routing'

const PublicRoutes: React.FC = () => {
  return (
    <>
      {publicsRoutes.map((route, i) => (
        <Route key={`${route.url}${i}`} exact path={route.url}>
          <route.content />
        </Route>
      ))}
      <Redirect to={ publicsUrls.login } />
    </>
  )
}

export default PublicRoutes
