import axios, { AxiosResponse } from 'axios'
import { useCallback, useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext } from '../context'
import {
  gradeArrayToGradeTemplate,
  gradeTemplateToGradeArray,
  initialDraftSubject,
  IStudentImportSummary,
  ISubject,
  ISubjectDraft,
  ISubjectRow
} from '../models'
import { prefix } from '../config'
import { useAuth } from '.'

export const useSubjects = () => {
  const { formatMessage } = useIntl()
  const { setAlert, setOpenModalConfirm } = useContext(SessionContext)
  const { logOut } = useAuth()
  const [subjects, setSubjects] = useState<ISubjectRow[]>([])
  const [editSubject, setEditSubject] = useState<ISubjectDraft>(initialDraftSubject)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isSubjectFetching, setIsSubjectFetching] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const bearerToken = localStorage.getItem(`${ prefix }:token`)
  const bearer = 'Bearer ' + bearerToken

  const fetchSubjects = useCallback(async (): Promise<void> => {
    setIsFetching(true)

    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/Subject/GetAll`, {
      method: 'GET',
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json-patch+json'
      }
    }).then((response: AxiosResponse) => {
      setSubjects(response.data)
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Subjects error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ?? formatMessage({ id: 'genericErrorListFetch' })
        })
      }
    }).finally(() => {
      setIsFetching(false)
    })
  }, [bearer, formatMessage, logOut, setAlert])

  const searchSubjects = useCallback(async (query: string) => {
    setIsFetching(true)

    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/Subject/Filter?search=${ query }`, {
      method: 'GET',
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json'
      }
    }).then((response: AxiosResponse) => {
      setSubjects(response.data)
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Subjects error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ?? formatMessage({ id: 'genericErrorListFetch' })
        })
      }
    }).finally(() => {
      setIsFetching(false)
    })
  }, [bearer, formatMessage, logOut, setAlert])

  const getSubjectById = async (id: number) => {
    setIsSubjectFetching(true)
    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/Subject/GetOne/${ id }`, {
      method: 'GET',
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json'
      }
    }).then((response: AxiosResponse) => {
      const subject: ISubject = response.data
      const competencies = subject.competencies.map(c => {
        return {
          ...c,
          rowId: c.id,
          name: c.name,
          idSubject: c.idSubject,
          gradeTemplateItems: gradeArrayToGradeTemplate(c.gradeTemplateItems)
        }
      })
      const editSubject: ISubjectDraft = {
        ...subject,
        students: subject.students.map(s => s.id),
        teachers: subject.teachers.map(t => t.id),
        classes: subject.classes.map(t => t.id),
        gradeTemplateItems: gradeArrayToGradeTemplate(subject.gradeTemplateItems),
        competencies
      }
      setEditSubject(editSubject)
      setIsModalVisible(true)
      setIsSubjectFetching(false)
    }).catch(async error => {
      if (error && error.response && error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Subject fetch error: ', error)
        }
        // Change literal
        setAlert({
          type: 'error',
          message: error.Message ?? formatMessage({ id: 'genericErrorListFetch' })
        })
      }
      setIsModalVisible(false)
      setIsSubjectFetching(false)
    })
  }

  const getSubjectByIdForDuplicate = async (id: number) => {
    setIsSubjectFetching(true)
    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/Subject/GetOne/${ id }`, {
      method: 'GET',
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json'
      }
    }).then((response: AxiosResponse) => {
      const subject: ISubject = response.data
      const competencies = subject.competencies.map(c => {
        return {
          ...c,
          rowId: c.id,
          name: c.name,
          idSubject: c.idSubject,
          gradeTemplateItems: gradeArrayToGradeTemplate(c.gradeTemplateItems)
        }
      })
      const duplicateSubject: ISubjectDraft = {
        ...subject,
        id: 0,
        students: [],
        teachers: [],
        classes: [],
        gradeTemplateItems: gradeArrayToGradeTemplate(subject.gradeTemplateItems),
        competencies
      }
      setEditSubject(duplicateSubject)
      setIsModalVisible(true)
      setIsSubjectFetching(false)
    }).catch(async error => {
      if (error && error.response && error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Subject fetch error: ', error)
        }
        // Change literal
        setAlert({
          type: 'error',
          message: error.Message ?? formatMessage({ id: 'genericErrorListFetch' })
        })
      }
      setIsModalVisible(false)
      setIsSubjectFetching(false)
    })
  }

  const createSubject = async (newSubject: ISubjectDraft) => {
    setIsFetching(true)

    const competencies = newSubject.competencies.map(c => {
      return {
        ...(c.id && { id: c.id }),
        name: c.name,
        idSubject: c.idSubject,
        gradeTemplateItems: gradeTemplateToGradeArray(c.gradeTemplateItems)
      }
    })
    const body = {
      ...newSubject,
      gradeTemplateItems: gradeTemplateToGradeArray(newSubject.gradeTemplateItems),
      typeSubject: newSubject.typeSubject?.id,
      competencies
    }
    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/Subject/Create`,
      JSON.stringify(body),
      {
        headers: {
          accept: '*/*',
          Authorization: 'Bearer ' + bearerToken,
          'Content-Type': 'application/json-patch+json'
        }
      }
    ).then(async () => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'created' })
      })
      setIsModalVisible(false)
      await fetchSubjects()
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Subject fetch error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ??
          `${ formatMessage({ id: 'genericErrorCreate' }) } ${ formatMessage({ id: 'subject' }) }`
        })
      }
      setIsFetching(false)
    })
  }

  const updateSubject = async (updateSubject: ISubjectDraft) => {
    setIsFetching(true)

    const competencies = updateSubject.competencies.map(c => {
      return {
        ...(c.id && { id: c.id }),
        name: c.name,
        idSubject: c.idSubject,
        gradeTemplateItems: gradeTemplateToGradeArray(c.gradeTemplateItems)
      }
    })
    const body = {
      ...updateSubject,
      gradeTemplateItems: gradeTemplateToGradeArray(updateSubject.gradeTemplateItems),
      typeSubject: updateSubject.typeSubject?.id,
      competencies
    }
    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/Subject/Update`,
      JSON.stringify(body),
      {
        headers: {
          accept: '*/*',
          Authorization: bearer,
          'Content-Type': 'application/json'
        }
      }
    ).then(async () => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'updated' })
      })
      setIsModalVisible(false)
      await fetchSubjects()
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Subject update error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ??
          `${ formatMessage({ id: 'genericErrorUpdate' }) } ${ formatMessage({ id: 'subject' }) }`
        })
      }
      setIsFetching(false)
    })
  }

  const deleteSubject = async (id: number) => {
    setIsFetching(true)
    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/Subject/Delete?id=${ id }`, null, {
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json'
      }
    }).then(async () => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'deleted' })
      })
      setOpenModalConfirm({ openModalConfirm: false, id: 0 })
      await fetchSubjects()
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Subject update error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ??
            `${ formatMessage({ id: 'genericErrorDelete' }) } ${ formatMessage({ id: 'subject' }) }`
        })
      }
    })
  }

  const importSubjectStudents = async (file: File): Promise<IStudentImportSummary | void> => {
    const data = new FormData()
    data.append('file', file)

    return await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/Student/ImportStudentsSubjects`,
      data,
      {
        headers: {
          accept: '*/*',
          Authorization: 'Bearer ' + bearerToken,
          'Content-Type': 'multipart/form-data'
        }
      }
    ).then(async (response: AxiosResponse<IStudentImportSummary>) => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'imported' })
      })
      return response.data
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Students import error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ??
            `${ formatMessage({ id: 'studentsImportError' }) }`
        })
      }
    })
  }

  return {
    subjects,
    fetchSubjects,
    searchSubjects,
    editSubject,
    setEditSubject,
    isModalVisible,
    setIsModalVisible,
    isFetching,
    setIsFetching,
    isSubjectFetching,
    setIsSubjectFetching,
    getSubjectById,
    getSubjectByIdForDuplicate,
    createSubject,
    updateSubject,
    deleteSubject,
    importSubjectStudents
  }
}
