import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  font-family: ${ ({ theme }) => theme.fontFamily };

  .error {
    margin: -1.5rem 0 0.5rem 0;
    font-size: 14px;
    color: red;
  }
`
