import { Button, Icon, Input, Modal, Spinner } from '@valudio/ui'
import axios, { AxiosResponse } from 'axios'
import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { prefix } from '../../config'
import { SessionContext, UiContext } from '../../context'
import { useAuth } from '../../hooks'
import StyledArticle from './styles'

const OtherSettingsModal: React.FC = () => {
  const { formatMessage } = useIntl()
  const { setAlert } = useContext(SessionContext)
  const { logOut, setApplicationPassword, getApplicationPassword } = useAuth()
  const { isOtherSettingsVisible, setIsOtherSettingsVisible } = useContext(UiContext)
  const [isDeleteVisible, setIsDeleteVisible] = useState(false)
  const [isApplicationPasswordVisible, setIsApplicationPasswordVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [appPwd, setAppPwd] = useState<string>()
  const bearerToken = localStorage.getItem(`${ prefix }:token`)
  const bearer = 'Bearer ' + bearerToken

  const title = () => {
    if (isDeleteVisible) {
      return formatMessage({ id: 'deleteData' })
    }
    if (isApplicationPasswordVisible) {
      return formatMessage({ id: 'accessCode' })
    }
    return formatMessage({ id: 'otherSettings' })
  }

  const handleOpenApplicationPasswordSettings = async () => {
    setIsLoading(true)
    const currentAppPwd = await getApplicationPassword() ?? undefined
    if (currentAppPwd || currentAppPwd === '') setAppPwd(currentAppPwd)
    setIsApplicationPasswordVisible(true)
    setIsLoading(false)
  }

  const handleClose = () => {
    setIsDeleteVisible(false)
    setIsOtherSettingsVisible(false)
    setIsApplicationPasswordVisible(false)
    setAppPwd('')
    setIsLoading(false)
  }

  const handleDeleteData = async () => {
    setIsLoading(true)
    await axios.post(
      `${ process.env.REACT_APP_API_URL }api/v1/DeleteAction/RemoveData`,
      null,
      {
        method: 'GET',
        headers: {
          accept: '*/*',
          Authorization: bearer,
          'Content-Type': 'application/json'
        }
      }
    ).then((response: AxiosResponse) => {
      return response.data
    }).catch(async error => {
      if (error && error.response && error.response.status === 401) {
        await logOut()
      }
      if (process.env.NODE_ENV === 'development') {
        console.log('Delete error: ', error)
      }
      // Change literal
      setAlert({
        type: 'error',
        message: `Delete error: ${ error }`
      })
    }).finally(() => {
      handleClose()
    })
  }

  const handleChangeApplicationPassword = async () => {
    if (typeof appPwd === 'string') {
      setIsLoading(true)
      await setApplicationPassword(appPwd)
      handleClose()
    }
  }

  return (
    <Modal
      isHidden={ !isOtherSettingsVisible }
      onOverlayClick={ handleClose }
      style={{ zIndex: 4000 }}
    >
      <StyledArticle>
        <header>
          <h3>{ title() }</h3>
          <Icon
            className='icon'
            icon='close'
            onClick={ handleClose }
          />
        </header>
        <section className="content">
          { isDeleteVisible
            ? isLoading
              ? (
                <Spinner />
              ) : (
                <>
                  <p>{ formatMessage({ id: 'deleteQuestion' }) }</p>
                  <Button type="primary" onClick={ handleDeleteData }>
                    { formatMessage({ id: 'deleteConfirm' }) }
                  </Button>
                </>
              )
            : null
          }
          { isApplicationPasswordVisible
            ? isLoading
              ? (
                <Spinner />
              ) : (
                <>
                  <Input initialValue={ appPwd } onChange={ setAppPwd } isDisabled={ isLoading } />
                  <Button
                    type="primary"
                    onClick={ handleChangeApplicationPassword }
                    isDisabled={ appPwd === undefined }
                  >
                    { formatMessage({ id: 'savePassword' }) }
                  </Button>
                </>
              )
            : null
          }
          {
            !isDeleteVisible && !isApplicationPasswordVisible
              ? (
                <>
                  <Button
                    type="secondary"
                    onClick={ () => setIsDeleteVisible(true) }
                  >
                    { formatMessage({ id: 'deleteAllData' }) }
                  </Button>
                  <Button
                    type="secondary"
                    onClick={ handleOpenApplicationPasswordSettings }
                  >
                    { formatMessage({ id: 'accessCode' }) }
                  </Button>
                </>
              ) : null
          }

        </section>
      </StyledArticle>
    </Modal>
  )
}

export default OtherSettingsModal
