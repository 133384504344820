import { Button, Icon, Input } from '@valudio/ui'
import React from 'react'
import { useIntl } from 'react-intl'
import { List } from '../../../../../../components'
import { ICompetencyDraft, IListItem, IListProperty, GradeType } from '../../../../../../models'

interface IProps {
  competencies: ICompetencyDraft[]
  onChange?: (competency: ICompetencyDraft) => void
  onDelete?: (id: number) => void
  onAdd?: () => void
}

export const Competencies: React.FC<IProps> = ({ competencies, onChange, onDelete, onAdd }) => {
  const { formatMessage } = useIntl()
  const listProperties: IListProperty[] = [
    { label: formatMessage({ id: 'name' }), key: 'name', className: 'name' },
    { label: formatMessage({ id: 'indicationPeriod' }), key: 'first' },
    { label: formatMessage({ id: 'firstPeriod' }), key: 'second' },
    { label: formatMessage({ id: 'examFirstSemester' }), key: 'examFirstSemester' },
    { label: formatMessage({ id: 'indicationPeriod' }), key: 'third' },
    { label: formatMessage({ id: 'secondPeriod' }), key: 'fourth' },
    { label: formatMessage({ id: 'examSecondSemester' }), key: 'examSecondSemester' },
    { label: '', key: 'delete', className: 'delete' }
  ]

  const listItems: IListItem[] = competencies.map(c => {
    const { first, second, examFirstSemester, third, fourth, examSecondSemester } = c.gradeTemplateItems
    return {
      key: `competency-${ c.rowId }`,
      name: (
        <Input
          initialValue={ c.name ?? '' }
          onChange={ name => handleChange(c, { name }) }
          placeholder={ formatMessage({ id: 'placeholderCompetencyName' }) }
        />
      ),
      first: (
        <Input
          initialValue={ first.max ? first.max.toString() : '' }
          placeholder={ formatMessage({ id: 'maxGrade' }) }
          onChange={
            value => handleChange(c, {
              gradeTemplateItems: {
                ...c.gradeTemplateItems,
                first: { max: value || value === '0' ? Number(value) : null, gradeType: GradeType.First }
              }
            })
          }
          type="number"
        />
      ),
      second: (
        <Input
          initialValue={ second.max ? second.max.toString() : '' }
          placeholder={ formatMessage({ id: 'maxGrade' }) }
          onChange={
            value => handleChange(c, {
              gradeTemplateItems: {
                ...c.gradeTemplateItems,
                second: { max: value || value === '0' ? Number(value) : null, gradeType: GradeType.Second }
              }
            })
          }
          type="number"
        />
      ),
      examFirstSemester: (
        <Input
          initialValue={ examFirstSemester.max ? examFirstSemester.max.toString() : '' }
          placeholder={ formatMessage({ id: 'maxGrade' }) }
          onChange={
            value => {
              handleChange(c, {
                gradeTemplateItems: {
                  ...c.gradeTemplateItems,
                  examFirstSemester: {
                    max: value || value === '0' ? Number(value) : null,
                    gradeType: GradeType.ExamFirstSemester
                  }
                }
              })
            }
          }
          type="number"
        />
      ),
      third: (
        <Input
          initialValue={ third.max ? third.max.toString() : '' }
          placeholder={ formatMessage({ id: 'maxGrade' }) }
          onChange={
            value => handleChange(c, {
              gradeTemplateItems: {
                ...c.gradeTemplateItems,
                third: { max: value || value === '0' ? Number(value) : null, gradeType: GradeType.Third }
              }
            })
          }
          type="number"
        />
      ),
      fourth: (
        <Input
          initialValue={ fourth.max ? fourth.max.toString() : '' }
          placeholder={ formatMessage({ id: 'maxGrade' }) }
          onChange={
            value => handleChange(c, {
              gradeTemplateItems: {
                ...c.gradeTemplateItems,
                fourth: { max: value || value === '0' ? Number(value) : null, gradeType: GradeType.Fourth }
              }
            })
          }
          type="number"
        />
      ),
      examSecondSemester: (
        <Input
          initialValue={ examSecondSemester.max ? examSecondSemester.max.toString() : '' }
          placeholder={ formatMessage({ id: 'maxGrade' }) }
          onChange={
            value => {
              handleChange(c, {
                gradeTemplateItems: {
                  ...c.gradeTemplateItems,
                  examSecondSemester: {
                    max: value || value === '0' ? Number(value) : null,
                    gradeType: GradeType.ExamSecondSemester
                  }
                }
              })
            }
          }
          type="number"
        />
      ),
      delete: (
        <Button
          isCircular
          onClick={ () => handleDelete(c.rowId) }
        >
          <Icon className='icon' icon='delete' />
        </Button>
      )
    }
  })

  const handleChange = (competency: ICompetencyDraft, value: { [key: string]: any }): void => {
    const modifiedCompetency = { ...competency, ...value }
    if (onChange && typeof onChange === 'function') onChange(modifiedCompetency)
  }

  const handleDelete = (index: number) => {
    if (onDelete && typeof onDelete === 'function') onDelete(index)
  }

  return (
    <List
      className="compact"
      properties={ listProperties }
      items={ listItems }
    >
      <li style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Button onClick={ onAdd } isDisabled={ false } type="secondary">
          { formatMessage({ id: 'addCompetency' }) }
        </Button>
      </li>
    </List>
  )
}
