import { Input, IOption, Spinner } from '@valudio/ui'
import classnames from 'classnames'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { debounce } from 'ts-debounce'
import { SearchInput } from '../../../../components'
import { ISubjectGradeStudent } from '../../../../models'
import { StudentRow } from './StudentRow'
import StyledSection from './styles'

interface IProps {
  studentFilter?: string
  classFilter?: IOption
  students: ISubjectGradeStudent[]
  classes: IOption[]
  isEditActive: boolean
  isEditDisabled: boolean
  isLoading: boolean
  isHidden: boolean
  onChange: (updatedStudents: ISubjectGradeStudent[], student?: ISubjectGradeStudent) => void
  onChangeStudentFilter: (newStudent: string) => void
  onChangeClassFilter: (newClass?: IOption) => void
  competencyId?: number
}

export const GradesTable: React.FC<IProps> = ({
  studentFilter,
  classFilter,
  students,
  classes,
  isEditActive,
  isEditDisabled,
  isLoading,
  isHidden,
  onChange,
  onChangeClassFilter,
  onChangeStudentFilter,
  competencyId
}) => {
  const filterFormRef = useRef<HTMLFormElement>(null)
  const { formatMessage } = useIntl()
  const [filteredStudent, setFilteredStudent] = useState(
    () => studentFilter ?? ''
  )
  const [filteredClass, setFilteredClass] = useState(
    () => classFilter ? classFilter.label : ''
  )

  const handleStudentGradeChange = (value: { [key: string]: any }, id: number) => {
    const updatedStudents = students.map(s => {
      return s.id === id ? { ...s, ...value } : s
    } )
    const student = updatedStudents.find(s => s.id === id)
    onChange(updatedStudents, student)
  }

  const studentItems = () => {
    if (filteredStudent && !filteredClass) {
      return students
        .filter(s => `${ s.firstName } ${ s.lastName } (${ s.className })`.toLowerCase().includes(filteredStudent))
        .sort((prev, next) => {
          const previousStudent =
            `(${ prev.className })${ prev.lastName }${ prev.firstName }`.toLowerCase().replaceAll(' ', '')
          const nextStudent =
            `(${ next.className })${ next.lastName }${ next.firstName }`.toLowerCase().replaceAll(' ', '')
          return previousStudent > nextStudent
            ? 1
            : -1
        })
        .map(s => {
          return (
            <StudentRow
              key={
                // eslint-disable-next-line max-len
                `${ s.id }-${ s.firstName.toLowerCase() }${ s.lastName.toLowerCase() }-${ !competencyId ? '' : competencyId }`
              }
              student={ s }
              isEditActive={ isEditActive }
              isEditDisabled={ isEditDisabled }
              onChange={ value => handleStudentGradeChange(value, s.id) }
            />
          )
        })
    } else if (!filteredStudent && filteredClass) {
      return students
        .filter(s => s.className === filteredClass)
        .sort((prev, next) => {
          const prevStudent =
            `(${ prev.className })${ prev.lastName }${ prev.firstName }`.toLowerCase().replaceAll(' ', '')
          const nextStudent =
            `(${ next.className })${ next.lastName }${ next.firstName }`.toLowerCase().replaceAll(' ', '')
          return prevStudent > nextStudent
            ? 1
            : -1
        })
        .map(s => {
          return (
            <StudentRow
              key={
                // eslint-disable-next-line max-len
                `${ s.id }-${ s.firstName.toLowerCase() }${ s.lastName.toLowerCase() }-${ !competencyId ? '' : competencyId }`
              }
              student={ s }
              isEditActive={ isEditActive }
              isEditDisabled={ isEditDisabled }
              onChange={ value => handleStudentGradeChange(value, s.id) }
            />
          )
        })
    } else if (filteredStudent && filteredClass) {
      return students
        .filter(s => {
          const classIncluded = s.className.toLowerCase().includes(filteredClass.toLowerCase())
          const nameIncluded = s.firstName.toLowerCase().includes(filteredStudent)
            || s.lastName.toLowerCase().includes(filteredStudent)
          return classIncluded && nameIncluded
        })
        .sort((prev, next) => {
          const prevStudent =
            `(${ prev.className })${ prev.lastName }${ prev.firstName }`.toLowerCase().replaceAll(' ', '')
          const nextStudent =
            `(${ next.className })${ next.lastName }${ next.firstName }`.toLowerCase().replaceAll(' ', '')
          return prevStudent > nextStudent
            ? 1
            : -1
        }).map(s => {
          return (
            <StudentRow
              key={
                // eslint-disable-next-line max-len
                `${ s.id }-${ s.firstName.toLowerCase() }${ s.lastName.toLowerCase() }-${ !competencyId ? '' : competencyId }`
              }
              student={ s }
              isEditActive={ isEditActive }
              isEditDisabled={ isEditDisabled }
              onChange={ value => handleStudentGradeChange(value, s.id) }
            />
          )
        })
    } else {
      return students
        .sort((prev, next) => {
          const prevStudent =
            `(${ prev.className })${ prev.lastName }${ prev.firstName }`.toLowerCase().replaceAll(' ', '')
          const nextStudent =
            `(${ next.className })${ next.lastName }${ next.firstName }`.toLowerCase().replaceAll(' ', '')
          return prevStudent > nextStudent
            ? 1
            : -1
        })
        .map(s => {
          return (
            <StudentRow
              key={
                // eslint-disable-next-line max-len
                `${ s.id }-${ s.firstName.toLowerCase() }${ s.lastName.toLowerCase() }-${ !competencyId ? '' : competencyId }`
              }
              student={ s }
              isEditActive={ isEditActive }
              isEditDisabled={ isEditDisabled }
              onChange={ value => handleStudentGradeChange(value, s.id) }
            />
          )
        })
    }
  }

  const handleStudentFilterChange = (filter: string) => {
    setFilteredStudent(filter.toLowerCase())
    onChangeStudentFilter(filter.toLowerCase())
  }
  const debounceSearch = debounce(handleStudentFilterChange, 400)

  const handleClassFilterChange = (option: IOption) => {
    const className = option.label
    setFilteredClass(className)
    onChangeClassFilter(option)
  }

  const handleClearClassFilter = useCallback(() => {
    setFilteredClass('')
    onChangeClassFilter(undefined)
    filterFormRef.current?.reset()
  }, [onChangeClassFilter])

  useEffect(() => {
    if (classFilter === undefined) handleClearClassFilter()
  }, [classFilter, handleClearClassFilter])

  if (isHidden) return null
  return (
    <StyledSection>
      <header className="table-header">
        <div className="column students">
          <SearchInput
            currentOption={ classFilter }
            form={ filterFormRef.current ?? undefined }
            className="select"
            placeholder={ formatMessage({ id: 'chooseClassPlaceholder' }) }
            options={ classes }
            onChange={ handleClassFilterChange }
            isDisabled={ !classes.length }
            onClearStatus={ handleClearClassFilter }
          />
          <Input
            initialValue={ filteredStudent }
            onChange={ debounceSearch }
            placeholder={ `${ formatMessage({ id: 'searchStudent' }) }...` }
            isDisabled={ !students.length }
          />
        </div>
        <div className="column semester">
          <h2>{ formatMessage({ id: 'firstSemester' }) }</h2>
          <ul>
            <li>{ formatMessage({ id: 'indicationPeriod' }) }</li>
            <li>{ formatMessage({ id: 'firstPeriod' }) }</li>
            <li>{ formatMessage({ id: 'exam' }) }</li>
            <li>{ formatMessage({ id: 'total' }) }</li>
          </ul>
        </div>
        <div className="column semester">
          <h2>{ formatMessage({ id: 'secondSemester' }) }</h2>
          <ul>
            <li>{ formatMessage({ id: 'indicationPeriod' }) }</li>
            <li>{ formatMessage({ id: 'secondPeriod' }) }</li>
            <li>{ formatMessage({ id: 'exam' }) }</li>
            <li>{ formatMessage({ id: 'total' }) }</li>
          </ul>
        </div>
        <div className="column total">
          <h2>{ formatMessage({ id: 'total' }) }</h2>
        </div>
      </header>
      <section className={ classnames('table-content', { loading: isLoading }) }>
        { isLoading ? <Spinner /> : studentItems() }
      </section>
    </StyledSection>
  )
}
