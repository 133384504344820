import { useCallback, useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import axios, { AxiosResponse } from 'axios'
import { IOption } from '@valudio/ui'
import { IClass, IClassEdit, IClassResponse, IClassRow, initialClassDraft } from '../models'
import { ErrorCodes } from '../models/api'
import { prefix } from '../config'
import { SessionContext } from '../context'
import { useAuth } from '.'

export const useClasses = () => {
  const { formatMessage } = useIntl()
  const { setAlert } = useContext(SessionContext)
  const [classes, setClasses] = useState<IClassRow[]>([])
  const [editClass, setEditClass] = useState<IClassEdit>(initialClassDraft)
  const [classOptions, setClassOptions] = useState<IOption[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isClassFetching, setIsClassFetching] = useState(false)
  const { logOut } = useAuth()
  const bearerToken = localStorage.getItem(`${ prefix }:token`)
  const bearer = 'Bearer ' + bearerToken

  const fetchClasses = useCallback(async () => {
    setIsLoading(true)
    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/Class/GetAll`, {
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json'
      }
    }).then((response: AxiosResponse<IClassRow[]>) => {
      const data = response.data
      setClasses(data)
      setClassOptions(data.map((c: any) => ({ id: c.id.toString(), label: `${ c.year }${ c.code }` })))
      setTimeout(() => {
        setIsLoading(false)
      }, 400)
    }).catch(async (error) => {
      if (error.response.status === ErrorCodes.UNAUTHORIZED) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') console.log(error)
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }, [bearer, logOut])

  const searchClasses = useCallback(async (query: string) => {
    setIsLoading(true)
    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/Class/Filter?search=${query}`, {
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json'
      }
    }).then(async (response: AxiosResponse<IClassRow[]>) => {
      const data = response.data
      setClasses(data)
    }).catch(async (error) => {
      if (error.response.status === ErrorCodes.UNAUTHORIZED) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') console.log(error)
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }, [bearer, logOut])

  const createClass = async (newClass: IClass) => {
    const bearer = 'Bearer ' + bearerToken

    setIsLoading(true)
    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/Class/Create`,
      JSON.stringify(newClass),
      {
        headers: {
          accept: '*/*',
          Authorization: bearer,
          'Content-Type': 'application/json-patch+json'
        }
      }
    ).then(() => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'created' })
      })
    }).catch(async (error) => {
      setAlert({
        type: 'error',
        message: error.Message ?? formatMessage({ id: 'genericErrorCreate' })
      })
      if (error.response.status === ErrorCodes.UNAUTHORIZED) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') console.log(error)
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const updateClass = async (updatedClass: IClass) => {
    const bearer = 'Bearer ' + bearerToken

    setIsLoading(true)
    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/Class/Update`,
      JSON.stringify(updatedClass),
      {
        headers: {
          accept: '*/*',
          Authorization: bearer,
          'Content-Type': 'application/json-patch+json'
        }
      }
    ).then(() => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'updated' })
      })
    }).catch(async (error) => {
      setAlert({
        type: 'error',
        message: error.Message ?? formatMessage({ id: 'genericErrorUpdate' })
      })
      if (error.response.status === ErrorCodes.UNAUTHORIZED) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') console.log(error)
      }
    }).finally(() => {
      setTimeout(() => {
        setIsLoading(false)
      }, 400)
    })
  }

  const deleteClass = async (id: number) => {
    const bearer = 'Bearer ' + bearerToken

    setIsLoading(true)
    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/Class/Delete?id=${id}`, undefined, {
      headers: {
        accept: '*/*',
        Authorization: bearer
      }
    }).then(() => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'deleted' })
      })
    }).catch((error) => {
      setAlert({
        type: 'error',
        message: error.Message ?? formatMessage({ id: 'genericErrorDelete' })
      })
      if (process.env.NODE_ENV === 'development') console.log(error)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const getClassById = async (id: number) => {
    const bearer = 'Bearer ' + bearerToken
    setIsClassFetching(true)

    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/Class/GetOne/${id}`, {
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json'
      }
    }).then(async (response: AxiosResponse<IClassResponse>) => {
      const data: IClassResponse = response.data
      setEditClass({
        ...data,
        section: data.idSection && data.section ? { id: data.idSection, name: data.section } : undefined,
        subSection: data.idSubSection && data.subSection
          ? { id: data.idSubSection, name: data.subSection }
          : undefined,
        students: data.students.map(s => s.id),
        teachersLeaders: data.teachersLeaders.map(t => t.id),
        disableIndicationReport: data.disableIndicationReport ?? false
      })
    }).catch(async (error) => {
      setAlert({
        type: 'error',
        message: error.Message ?? formatMessage({ id: 'genericErrorFetch' })
      })
      if (error.response.status === ErrorCodes.UNAUTHORIZED) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') console.log(error)
      }
    }).finally(() => {
      setIsClassFetching(false)
    })
  }

  const fetchClassesByTeacher = useCallback(async () => {
    setIsLoading(true)

    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/Class/GetAllForGrade`, {
      method: 'GET',
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json-patch+json'
      }
    }).then((response: AxiosResponse<IClassRow[]>) => {
      setClassOptions(response.data.map((c: any) => ({
        id: c.id.toString(),
        label: `${ c.year }${ c.classCode }`,
        section: `${ c.section ?? '' }${ c.section && c.subSection ? `-${ c.subSection }` : '' }`
      })))
      setClasses(response.data)
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Subjects error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ?? formatMessage({ id: 'genericErrorListFetch' })
        })
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }, [bearer, formatMessage, logOut, setAlert])

  return {
    classes,
    editClass,
    setEditClass,
    classOptions,
    fetchClasses,
    searchClasses,
    isLoading,
    isClassFetching,
    setIsClassFetching,
    createClass,
    updateClass,
    deleteClass,
    getClassById,
    fetchClassesByTeacher
  }
}
