export interface IGrade {
  id: number
  max: number | null
  gradeType: GradeType
}

export interface IGradeTemplate {
  first: IGrade
  second: IGrade
  examFirstSemester: IGrade
  third: IGrade
  fourth: IGrade
  examSecondSemester: IGrade
}

export interface IGradeTemplateMaxes {
  first: number
  second: number
  examFirstSemester: number
  third: number
  fourth: number
  examSecondSemester: number
}

export interface IStudentGrade extends IGrade {
  note: number | null
  comments: string | null
}

export interface IStudentGradeLine {
  first: IStudentGrade
  second: IStudentGrade
  examFirstSemester: IStudentGrade
  third: IStudentGrade
  fourth: IStudentGrade
  examSecondSemester: IStudentGrade
}

export enum GradeType {
  First = 0,
  Second = 1,
  ExamFirstSemester = 2,
  Third = 3,
  Fourth = 4,
  ExamSecondSemester = 5
}

export function gradeArrayToGradeTemplate(grades: IGrade[]): IGradeTemplate {
  if (!grades || !grades.length) return initialGradeTemplate

  const first = grades.find(g => g.gradeType === GradeType.First)
  const second = grades.find(g => g.gradeType === GradeType.Second)
  const examFirstSemester = grades.find(g => g.gradeType === GradeType.ExamFirstSemester)
  const third = grades.find(g => g.gradeType === GradeType.Third)
  const fourth = grades.find(g => g.gradeType === GradeType.Fourth)
  const examSecondSemester = grades.find(g => g.gradeType === GradeType.ExamSecondSemester)

  return {
    first: first ?? { id: 0, max: null, gradeType: GradeType.First },
    second: second ?? { id: 0, max: null, gradeType: GradeType.Second },
    examFirstSemester: examFirstSemester ?? { id: 0, max: null, gradeType: GradeType.ExamFirstSemester },
    third: third ?? { id: 0, max: null, gradeType: GradeType.Third },
    fourth: fourth ?? { id: 0, max: null, gradeType: GradeType.Fourth },
    examSecondSemester: examSecondSemester ?? { id: 0, max: null, gradeType: GradeType.ExamSecondSemester }
  }
}
export function gradeArrayToStudentGradeLine(grades: IStudentGrade[]): IStudentGradeLine {
  if (!grades || !grades.length) return initialStudentGradeLine

  const first = grades.find(g => g.gradeType === GradeType.First)
  const second = grades.find(g => g.gradeType === GradeType.Second)
  const examFirstSemester = grades.find(g => g.gradeType === GradeType.ExamFirstSemester)
  const third = grades.find(g => g.gradeType === GradeType.Third)
  const fourth = grades.find(g => g.gradeType === GradeType.Fourth)
  const examSecondSemester = grades.find(g => g.gradeType === GradeType.ExamSecondSemester)

  return {
    first: first ?? { id: 0, note: null, max: null, gradeType: GradeType.First, comments: '' },
    second: second ?? { id: 0, note: null, max: null, gradeType: GradeType.Second, comments: '' },
    examFirstSemester:
      examFirstSemester ?? { id: 0, note: null, max: null, gradeType: GradeType.ExamFirstSemester, comments: '' },
    third: third ?? { id: 0, note: null, max: null, gradeType: GradeType.Third, comments: '' },
    fourth: fourth ?? { id: 0, note: null, max: null, gradeType: GradeType.Fourth, comments: '' },
    examSecondSemester:
      examSecondSemester ?? { id: 0, note: null, max: null, gradeType: GradeType.ExamSecondSemester, comments: '' }
  }
}

export function gradeArrayToStudentGradeLineForReport(
  grades: IStudentGrade[], gradeTemplate: IGradeTemplateMaxes
): IStudentGradeLine {
  if (!grades || !grades.length) return initialStudentGradeLine

  const first = grades.find(g => g.gradeType === GradeType.First)
  const second = grades.find(g => g.gradeType === GradeType.Second)
  const examFirstSemester = grades.find(g => g.gradeType === GradeType.ExamFirstSemester)
  const third = grades.find(g => g.gradeType === GradeType.Third)
  const fourth = grades.find(g => g.gradeType === GradeType.Fourth)
  const examSecondSemester = grades.find(g => g.gradeType === GradeType.ExamSecondSemester)

  return {
    first: first
      ? { ...first, max: gradeTemplate.first !== first.max ? first.max : null }
      : { id: 0, note: null, max: null, gradeType: GradeType.First, comments: '' },
    second: second
      ? { ...second, max: gradeTemplate.second !== second.max ? second.max : null }
      : { id: 0, note: null, max: null, gradeType: GradeType.Second, comments: '' },
    examFirstSemester: examFirstSemester
      ? {
        ...examFirstSemester,
        max: gradeTemplate.examFirstSemester !== examFirstSemester.max ? examFirstSemester.max : null
      } : { id: 0, note: null, max: null, gradeType: GradeType.ExamFirstSemester, comments: '' },
    third: third
      ? { ...third, max: gradeTemplate.third !== third.max ? third.max : null }
      : { id: 0, note: null, max: null, gradeType: GradeType.Third, comments: '' },
    fourth: fourth
      ? { ...fourth, max: gradeTemplate.fourth !== fourth.max ? fourth.max : null }
      : { id: 0, note: null, max: null, gradeType: GradeType.Fourth, comments: '' },
    examSecondSemester: examSecondSemester
      ? {
        ...examSecondSemester,
        max: gradeTemplate.examSecondSemester !== examSecondSemester.max ? examSecondSemester.max : null
      } : { id: 0, note: null, max: null, gradeType: GradeType.ExamSecondSemester, comments: '' }
  }
}

export function gradeTemplateToGradeArray(gradeTemplate: IGradeTemplate): IGrade[] {
  const { first, second, examFirstSemester, third, fourth, examSecondSemester } = gradeTemplate
  return [ first, second, examFirstSemester, third, fourth, examSecondSemester ]
}

export function studentGradeLineToGradeArray(gradeTemplate: IStudentGradeLine): IStudentGrade[] {
  const { first, second, examFirstSemester, third, fourth, examSecondSemester } = gradeTemplate
  return [ first, second, examFirstSemester, third, fourth, examSecondSemester ]
}

export function gradeArrayToGradeTemplateMaxes(grades: IGrade[]): IGradeTemplateMaxes {
  if (!grades || !grades.length) return initialGrateTemplateStrings

  const first = grades.find(g => g.gradeType === GradeType.First)
  const second = grades.find(g => g.gradeType === GradeType.Second)
  const examFirstSemester = grades.find(g => g.gradeType === GradeType.ExamFirstSemester)
  const third = grades.find(g => g.gradeType === GradeType.Third)
  const fourth = grades.find(g => g.gradeType === GradeType.Fourth)
  const examSecondSemester = grades.find(g => g.gradeType === GradeType.ExamSecondSemester)

  return {
    first: first?.max ?? 0,
    second: second?.max ?? 0,
    examFirstSemester: examFirstSemester?.max ?? 0,
    third: third?.max ?? 0,
    fourth: fourth?.max ?? 0,
    examSecondSemester: examSecondSemester?.max ?? 0
  }
}

export const initialGradeTemplate: IGradeTemplate = {
  first: { id: 0, max: 0, gradeType: GradeType.First },
  second: { id: 0, max: 0, gradeType: GradeType.Second },
  examFirstSemester: { id: 0, max: 0, gradeType: GradeType.ExamFirstSemester },
  third: { id: 0, max: 0, gradeType: GradeType.Third },
  fourth: { id: 0, max: 0, gradeType: GradeType.Fourth },
  examSecondSemester: { id: 0, max: 0, gradeType: GradeType.ExamSecondSemester }
}

export const initialStudentGradeLine: IStudentGradeLine = {
  first: { id: 0, note: 0, max: 50, gradeType: GradeType.First, comments: '' },
  second: { id: 0, note: 0, max: 50, gradeType: GradeType.Second, comments: '' },
  examFirstSemester: { id: 0, note: 0, max: 50, gradeType: GradeType.ExamFirstSemester, comments: '' },
  third: { id: 0, note: 0, max: 50, gradeType: GradeType.Third, comments: '' },
  fourth: { id: 0, note: 0, max: 50, gradeType: GradeType.Fourth, comments: '' },
  examSecondSemester: { id: 0, note: 0, max: 50, gradeType: GradeType.ExamSecondSemester, comments: '' }
}

export const initialGrateTemplateStrings: IGradeTemplateMaxes = {
  first: 0,
  second: 0,
  examFirstSemester: 0,
  third: 0,
  fourth: 0,
  examSecondSemester: 0
}
