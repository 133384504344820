import styled from 'styled-components'

export default styled.article`
  height: 100%;

  .duplicate-button {
    margin-right: 0.5em;
    color: ${({ theme }) => theme.colors.white };

    img {
      width: 16px;
      height: auto;
    }
  }

  .check-flag {
    font-size: 24px;
    color: ${({ theme }) => theme.colors.system.success };
  }

  .custom-table > .content > article {
    max-height: 100%;
  }

`
