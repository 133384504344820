import { DateTime } from 'luxon'
import {
  AdditionalExamsType,
  GeneralCommentType,
  gradeArrayToGradeTemplateMaxes,
  gradeArrayToStudentGradeLine,
  gradeArrayToStudentGradeLineForReport,
  GradeType,
  IGeneralComment,
  IGrade,
  IGradeTemplateMaxes,
  initialReportData,
  initialReportGeneralComments,
  IReport,
  IReportDataSectionTemplate,
  IReportDataSubject,
  IReportGeneralComments,
  IReportStudent,
  IReportSubject,
  ITeacherObject
} from '../models'

export const generalCommentsToReportComments = (generalComments: IGeneralComment[]): IReportGeneralComments => {
  const finalDecisionComments = generalComments
    .find(c => c.commentType === GeneralCommentType.FinalDecision)
  const finalDecisionJustification = generalComments
    .find(c => c.commentType === GeneralCommentType.FinalDecisionJustification)
  const certificateComments = generalComments
    .find(c => c.commentType === GeneralCommentType.CertificateCompetenceDecision)
  const certificateJustification = generalComments
    .find(c => c.commentType === GeneralCommentType.CertificateCompetenceJustification)
  const additionalExams = generalComments.filter(c => c.commentType === GeneralCommentType.AdditionalExams)
    .flatMap(c => c.commentsToList)
  const absencesJustified = generalComments.find(c => c.commentType === GeneralCommentType.AbsentDaysJustified)
  const absencesUnJustified = generalComments.find(c => c.commentType === GeneralCommentType.AbsentDaysUnJustified)
  const areSomeAdditionalExams = additionalExams.some(a => a.typeListAditionalExams === AdditionalExamsType.Exam)
  return {
    finalDecision: {
      ...initialReportGeneralComments.finalDecision,
      comments: finalDecisionComments ? finalDecisionComments.comments : '',
      justification: finalDecisionJustification ? finalDecisionJustification.comments : ''
    },
    certificateOfCompetence: {
      ...initialReportGeneralComments.certificateOfCompetence,
      comments: certificateComments ? certificateComments.comments : '',
      justification: certificateJustification ? certificateJustification.comments : ''
    },
    absences: {
      absencesConfirmed: absencesJustified ? absencesJustified.comments : '',
      absencesNotConfirmed: absencesUnJustified ? absencesUnJustified.comments : ''
    },
    areSomeAdditionalExams,
    additionalExams: additionalExams
      ? additionalExams.map(exam => ({ ...exam, typeListAditionalExams: exam.typeListAditionalExams.toString() }))
      : []
  }
}

export const formatReportData = (
  student: IReportStudent,
  subjects: IReportDataSubject[],
  sectionGradeTemplate: IGrade[],
  studentComments: IGeneralComment[],
  periodFilter: GradeType,
  locale: string,
  formatMessage: any,
  sectionTemplate?: IReportDataSectionTemplate
): IReport => {
  const gradeTemplate = gradeArrayToGradeTemplateMaxes(sectionGradeTemplate)
  const activeSubjects = subjects.filter(s => !s.isDeleted)
  const mandatories: IReportSubject[] =
    formatReportSubjects(
      student.id,
      activeSubjects.filter(s => s.typeSubject === 1).sort((prev, next) => prev.sorting > next.sorting ? 1 : -1),
      periodFilter,
      gradeTemplate
    )
  const electives: IReportSubject[] =
    formatReportSubjects(
      student.id,
      activeSubjects.filter(s => s.typeSubject === 2).sort((prev, next) => prev.sorting > next.sorting ? 1 : -1),
      periodFilter,
      gradeTemplate
    )
  const qualification: IReportSubject[] =
    formatReportSubjects(
      student.id,
      activeSubjects.filter(s => s.typeSubject === 3).sort((prev, next) => prev.sorting > next.sorting ? 1 : -1),
      periodFilter,
      gradeTemplate
    )
  const internship: IReportSubject[] =
    formatReportSubjects(
      student.id,
      activeSubjects.filter(s => s.typeSubject === 4).sort((prev, next) => prev.sorting > next.sorting ? 1 : -1),
      periodFilter,
      gradeTemplate
    )
  const comments = generalCommentsToReportComments(studentComments)
  const finalDecision = studentComments.find(c => c.commentType === GeneralCommentType.FinalDecision)
  const hasMin60 = mandatories.some(m => m.min60percent)
    || electives.some(e => e.min60percent)
    || qualification.some(q => q.min60percent)
    || internship.some(i => i.min60percent)
  const additionalExams = comments.additionalExams.map(c => ({
    ...c,
    typeListAditionalExams: formatMessage({
      id: Number(c.typeListAditionalExams) === AdditionalExamsType.Exam ? 'additionalExam' : 'homework'
    }),
    date: c.date ? DateTime.fromISO(c.date).setLocale(locale).toLocaleString() : ''
  }))
  return {
    ...initialReportData,
    student,
    subjects: {
      ...initialReportData.subjects,
      gradeTemplate,
      mandatories,
      electives,
      qualification,
      internship
    },
    generalComments: {
      ...comments,
      finalDecision: {
        ...comments.finalDecision,
        title: sectionTemplate?.finalDecisionTitle ?? comments.finalDecision.title
      },
      certificateOfCompetence: {
        ...comments.certificateOfCompetence,
        title: sectionTemplate?.certificateOfCompetenceTitle ?? comments.certificateOfCompetence.title,
        isVisible: sectionTemplate
          ? sectionTemplate?.certificateOfCompetenceIsVisible
          : comments.certificateOfCompetence.isVisible
      },
      additionalExams
    },
    signatures: {
      ...initialReportData.signatures,
      finalDecision: finalDecision ? finalDecision.comments : '',
      date: DateTime.now().toISO()
    },
    hasMin60,
    comment60percent: sectionTemplate?.comments ?? ''
  }
}

export const formatTeachersForReports = (teachers: ITeacherObject[]): ITeacherObject[] => {
  return teachers.map(t => ({ ...t, firstName: t.firstName.charAt(0) }))
}

export const formatReportSubjects = (
  studentId: number, subjects: IReportDataSubject[], periodFilter: GradeType, gradeTemplate: IGradeTemplateMaxes
): IReportSubject[] => {
  return subjects.map(s => {
    const periodCorrected = periodFilter < 2 ? periodFilter : periodFilter + 1
    const periodComments = s.grade.find(grade => grade.gradeType === periodCorrected)?.comments
    return {
      id: s.id,
      name: s.name,
      hours: s.hoursPerWeek && s.hoursPerWeek > 0 ? s.hoursPerWeek.toString() : '',
      idStudent: studentId,
      teachers: formatTeachersForReports(s.teachers),
      grades: gradeArrayToStudentGradeLineForReport(s.grade, gradeTemplate),
      totals: {
        totalFirstSemester: s.totalFirstSemester ? s.totalFirstSemester.toString() : '',
        totalSecondSemester: s.totalSecondSemester ? s.totalSecondSemester.toString() : '',
        total: s.total && !s.isDisabled ? s.total.toString() : ''
      },
      competencies: s.competencies.map(c => ({
        name: c.name,
        grades: gradeArrayToStudentGradeLine(c.grade),
        total: c.total
      })),
      comments: periodComments ?? '',
      min60percent: s.min60percent,
      isAdministrativeSubject: s.isAdministrativeSubject
    }
  })
}

export const getScholarYearTitle = (): string => {
  const today = DateTime.now()
  const todayMonth = today.month

  if (todayMonth >= 9 && todayMonth <= 12) {
    const currentYear = today.year
    const nextYear = today.plus({ year: 1 }).year
    return `${ currentYear }-${ nextYear }`
  } else {
    const currentYear = today.year
    const prevYear = today.minus({ year: 1 }).year
    return `${ prevYear }-${ currentYear }`
  }
}

export const getCertificateScholarYear = (): number => {
  const today = DateTime.now()
  const todayMonth = today.month

  return todayMonth >= 9 && todayMonth < 12 ? today.plus({ year: 1 }).year : today.year
}
