import styled from 'styled-components'

export default styled.div`
  height: 100%;

  .frame {
    overflow-x: visible !important;
    overflow-y: visible !important;
  }
`
