import React, { useEffect, useState } from 'react'
import { GradeType, ISubjectGradeStudent, ISubjectGradeTotals } from '../../../../../models'
import { EditableRowItem } from '../EditableRowItem'
import StyledDiv from './styles'

interface IProps {
  student: ISubjectGradeStudent
  isEditActive: boolean
  isEditDisabled: boolean
  onChange: (value: { [key: string]: any }) => void
}

export const StudentRow: React.FC<IProps> = ({ student, isEditActive, isEditDisabled, onChange }) => {
  const [ grades, setGrades ] = useState(student.grade)
  const [ totals, setTotals ] = useState<ISubjectGradeTotals>(() => ({
    total: student.total,
    totalFirstSemester: student.totalFirstSemester,
    totalSecondSemester: student.totalSecondSemester
  }))
  const { first, second, examFirstSemester, third, fourth, examSecondSemester } = grades
  const { total, totalFirstSemester, totalSecondSemester } = totals

  const handleNoteChange = (newNote: string , gradeType: GradeType) => {
    const parsedNewNote = newNote ? parseFloat(newNote) : null

    switch (gradeType) {
    case GradeType.First: {
      if (grades.first.note !== parsedNewNote) {
        onChange({
          grade: {
            ...grades,
            first: { ...grades.first, note: parsedNewNote }
          }
        })
      }
      setGrades({ ...grades, first: { ...grades.first, note: parsedNewNote } })
      break
    }
    case GradeType.Second: {
      if (grades.second.note !== parsedNewNote) {
        onChange({
          grade: {
            ...grades,
            second: { ...grades.second, note: parsedNewNote }
          }
        })
      }
      setGrades({ ...grades, second: { ...grades.second, note: parsedNewNote } })
      break
    }
    case GradeType.ExamFirstSemester: {
      if (grades.examFirstSemester.note !== parsedNewNote) {
        onChange({
          grade: {
            ...grades,
            examFirstSemester: { ...grades.examFirstSemester, note: parsedNewNote }
          }
        })
      }
      setGrades({ ...grades, examFirstSemester: { ...grades.examFirstSemester, note: parsedNewNote } })
      break
    }
    case GradeType.Third: {
      if (grades.third.note !== parsedNewNote) {
        onChange({
          grade: {
            ...grades,
            third: { ...grades.third, note: parsedNewNote }
          }
        })
      }
      setGrades({ ...grades, third: { ...grades.third, note: parsedNewNote } })
      break
    }
    case GradeType.Fourth: {
      if (grades.fourth.note !== parsedNewNote) {
        onChange({
          grade: {
            ...grades,
            fourth: { ...grades.fourth, note: parsedNewNote }
          }
        })
      }
      setGrades({ ...grades, fourth: { ...grades.fourth, note: parsedNewNote } })
      break
    }
    case GradeType.ExamSecondSemester: {
      if (grades.examSecondSemester.note !== parsedNewNote) {
        onChange({
          grade: {
            ...grades,
            examSecondSemester: { ...grades.examSecondSemester, note: parsedNewNote }
          }
        })
      }
      setGrades({ ...grades, examSecondSemester: { ...grades.examSecondSemester, note: parsedNewNote } })
      break
    }
    default: {
      console.log('Grade Type not supported')
      break
    }
    }
  }

  const handleMaxChange = (newMax: string , gradeType: GradeType) => {
    const parsedNewMax = newMax || newMax === '0' ? parseFloat(newMax) : null

    switch (gradeType) {
    case GradeType.First: {
      if (grades.first.max !== parsedNewMax) {
        onChange({
          grade: {
            ...grades,
            first: {
              ...grades.first,
              note: !newMax ? null : grades.first.note,
              max: parsedNewMax
            }
          }
        })
      }
      setGrades({
        ...grades,
        first: {
          ...grades.first,
          note: !newMax ? null : grades.first.note,
          max: parsedNewMax
        }
      })
      break
    }
    case GradeType.Second: {
      if (grades.second.max !== parsedNewMax) {
        onChange({
          grade: {
            ...grades,
            second: {
              ...grades.second,
              note: !newMax ? null : grades.second.note,
              max: parsedNewMax
            }
          }
        })
      }
      setGrades({
        ...grades,
        second: {
          ...grades.second,
          note: !newMax ? null : grades.second.note,
          max: parsedNewMax
        }
      })
      break
    }
    case GradeType.ExamFirstSemester: {
      if (grades.examFirstSemester.max !== parsedNewMax) {
        onChange({
          grade: {
            ...grades,
            examFirstSemester: {
              ...grades.examFirstSemester,
              note: !newMax ? null : grades.examFirstSemester.note,
              max: parsedNewMax
            }
          }
        })
      }
      setGrades({
        ...grades,
        examFirstSemester: {
          ...grades.examFirstSemester,
          note: !newMax ? null : grades.examFirstSemester.note,
          max: parsedNewMax
        }
      })
      break
    }
    case GradeType.Third: {
      if (grades.third.max !== parsedNewMax) {
        onChange({
          grade: {
            ...grades,
            third: {
              ...grades.third,
              note: !newMax ? null : grades.third.note,
              max: parsedNewMax
            }
          }
        })
      }
      setGrades({
        ...grades,
        third: {
          ...grades.third,
          note: !newMax ? null : grades.third.note,
          max: parsedNewMax
        }
      })
      break
    }
    case GradeType.Fourth: {
      if (grades.fourth.max !== parsedNewMax) {
        onChange({
          grade: {
            ...grades,
            fourth: {
              ...grades.fourth,
              note: !newMax ? null : grades.fourth.note,
              max: parsedNewMax
            }
          }
        })
      }
      setGrades({
        ...grades,
        fourth: {
          ...grades.fourth,
          note: !newMax ? null : grades.fourth.note,
          max: parsedNewMax
        }
      })
      break
    }
    case GradeType.ExamSecondSemester: {
      if (grades.examSecondSemester.max !== parsedNewMax) {
        onChange({
          grade: {
            ...grades,
            examSecondSemester: {
              ...grades.examSecondSemester,
              note: !newMax ? null : grades.examSecondSemester.note,
              max: parsedNewMax
            }
          }
        })
      }
      setGrades({
        ...grades,
        examSecondSemester: {
          ...grades.examSecondSemester,
          note: !newMax ? null : grades.examSecondSemester.note,
          max: parsedNewMax
        }
      })
      break
    }
    default: {
      console.log('Grade Type not supported')
      break
    }
    }
  }

  useEffect(() => {
    setTotals({
      total: student.total,
      totalFirstSemester: student.totalFirstSemester,
      totalSecondSemester: student.totalSecondSemester
    })
  }, [student.total, student.totalFirstSemester, student.totalSecondSemester])

  return (
    <StyledDiv>
      <div className="column students">
        <div className="name">
          <span title={ `${ student.lastName.toUpperCase() } ${ student.firstName } (${ student.className })` }>
            { student.lastName.toUpperCase() } { student.firstName } ({ student.className })
          </span>
        </div>
      </div>
      <div className="column semester">
        <EditableRowItem
          note={ first.note }
          max={ first.max }
          tabIndex={ 1 }
          isEditActive={ isEditActive }
          isEditDisabled={ isEditDisabled }
          onChange={ newNote => handleNoteChange(newNote, GradeType.First) }
          onMaxChange={ newMax => handleMaxChange(newMax, GradeType.First) }
        />
        <EditableRowItem
          note={ second.note }
          max={ second.max }
          tabIndex={ 2 }
          isEditActive={ isEditActive }
          isEditDisabled={ isEditDisabled }
          onChange={ newNote => handleNoteChange(newNote, GradeType.Second) }
          onMaxChange={ newMax => handleMaxChange(newMax, GradeType.Second) }
        />
        <EditableRowItem
          note={ examFirstSemester.note }
          max={ examFirstSemester.max }
          tabIndex={ 3 }
          isEditActive={ isEditActive }
          isEditDisabled={ isEditDisabled }
          onChange={ newNote => handleNoteChange(newNote, GradeType.ExamFirstSemester) }
          onMaxChange={ newMax => handleMaxChange(newMax, GradeType.ExamFirstSemester) }
        />
        <p>
          <span>{ totalFirstSemester !== null ? `${ totalFirstSemester }%` : ' - %' }</span>
        </p>
      </div>
      <div className="column semester">
        <EditableRowItem
          note={ third.note }
          max={ third.max }
          tabIndex={ 4 }
          isEditActive={ isEditActive }
          isEditDisabled={ isEditDisabled }
          onChange={ newNote => handleNoteChange(newNote, GradeType.Third) }
          onMaxChange={ newMax => handleMaxChange(newMax, GradeType.Third) }
        />
        <EditableRowItem
          note={ fourth.note }
          max={ fourth.max }
          tabIndex={ 5 }
          isEditActive={ isEditActive }
          isEditDisabled={ isEditDisabled }
          onChange={ newNote => handleNoteChange(newNote, GradeType.Fourth) }
          onMaxChange={ newMax => handleMaxChange(newMax, GradeType.Fourth) }
        />
        <EditableRowItem
          note={ examSecondSemester.note }
          max={ examSecondSemester.max }
          tabIndex={ 6 }
          isEditActive={ isEditActive }
          isEditDisabled={ isEditDisabled }
          onChange={ newNote => handleNoteChange(newNote, GradeType.ExamSecondSemester) }
          onMaxChange={ newMax => handleMaxChange(newMax, GradeType.ExamSecondSemester) }
        />
        <p>
          <span>{ totalSecondSemester !== null ? `${ totalSecondSemester }%` : ' - %' }</span>
        </p>
      </div>
      <div className="column total">
        <span>{ total !== null ? `${ total }%` : ' - %' }</span>
      </div>
    </StyledDiv>
  )
}
