import { Button, Icon, ITableColumn, ITableItem, Spinner } from '@valudio/ui'
import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { debounce } from 'ts-debounce'
import { CustomTable, ModalConfirm } from '../../../components'
import { SessionContext } from '../../../context'
import { useSubjectParents } from '../../../hooks'
import { initialDraftSubjectParent, ISubjectParent } from '../../../models'
import { DraftModal } from './components'
import StyledArticle from './styles'

const SubjectParents: React.FC = () => {
  const { formatMessage } = useIntl()
  const { id: deleteId, openModalConfirm, setOpenModalConfirm } = useContext(SessionContext)
  const {
    subjectParents,
    editSubjectParent,
    setEditSubjectParent,
    isFetching,
    isDraftFecthing,
    setIsDraftFecthing,
    isModalVisible,
    setIsModalVisible,
    fetchSubjectParents,
    searchSubjectParents,
    getSubjectParentById,
    createSubjectParent,
    updateSubjectParent,
    deleteSubjectParent
  } = useSubjectParents()
  const [editingSubjectParentId, setEditingSubjecParentId] = useState(0)
  const [isTableUpdated, setIsTableUpdated] = useState(false)

  const subjectParentColumns: ITableColumn[] = [
    {
      key: 'id',
      label: '',
      style: {
        display: 'none'
      }
    },
    {
      key: 'code',
      label: formatMessage({ id: 'code' }),
      style: {
        flex: 0.5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'name',
      label: formatMessage({ id: 'name' }),
      style: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'sorting',
      label: formatMessage({ id: 'sorting' }),
      style: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'status',
      label: '',
      style: {
        flex: 0.2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexBasis: '80px'
      }
    }
  ]

  const subjectParentItems: ITableItem[] = subjectParents.map(subjectParent => ({
    ...subjectParent,
    status: isDraftFecthing && editingSubjectParentId && editingSubjectParentId === subjectParent.id ? (
      <Spinner style={{ width: '30px', height: '30px', marginRight: '0' }} />
    ) : (
      <>
        <Button
          isCircular
          onClick={() => handleOpenDraftModal(subjectParent.id)}
          isDisabled={ isDraftFecthing }
        >
          <Icon className='icon' icon='edit' />
        </Button>
        <Button
          style={{ marginLeft: '0.5em' }}
          isCircular
          type='tertiary'
          isDisabled={ isDraftFecthing }
          onClick={() =>
            setOpenModalConfirm({ openModalConfirm: true, id: subjectParent.id })
          }
        >
          <Icon className='icon' icon='delete' />
        </Button>
      </>
    )
  })).sort((prev, next) => prev.sorting > next.sorting ? 1 : -1)

  const handleSearch = async (query: string) => {
    if (query) await searchSubjectParents(query)
    else await fetchSubjectParents()
  }

  const debounceSearch = debounce(handleSearch, 400)

  const handleOpenDraftModal = async (subjectId?: number) => {
    setIsDraftFecthing(true)

    if (subjectId) {
      await handleSubjectParentEdit(subjectId)
    } else {
      setIsModalVisible(true)
      setIsDraftFecthing(false)
    }
  }

  const handleModalClose = () => {
    setEditingSubjecParentId(0)
    setEditSubjectParent(initialDraftSubjectParent)
    setIsModalVisible(false)
  }

  const handleSubjectParentEdit = async (subjectId: number) => {
    setEditingSubjecParentId(subjectId)
    await getSubjectParentById(subjectId)
  }

  const handleSubmit = async (subjectParentItem: ISubjectParent) => {
    handleModalClose()

    if (editSubjectParent.id) {
      await updateSubjectParent(subjectParentItem)
    } else {
      await createSubjectParent(subjectParentItem)
    }

    setIsTableUpdated(true)
  }

  const handleDeleteConfirm = async () => {
    await deleteSubjectParent(deleteId)
    setIsTableUpdated(true)
  }

  useEffect(() => {
    if (!subjectParents.length) fetchSubjectParents()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledArticle>
      <CustomTable
        title={ formatMessage({ id: 'subjectParents' }) }
        columns={ subjectParentColumns }
        items={ subjectParentItems }
        loading={ isFetching && !(openModalConfirm || isModalVisible) }
        setValueSearch={ debounceSearch }
        tableUpdated={ isTableUpdated }
        setTableUpdated={ setIsTableUpdated }
        buttonNew={
          <Button
            type='primary'
            style={{ marginLeft: '0.5rem' }}
            onClick={ handleOpenDraftModal }
            isLoading={ isDraftFecthing && !editingSubjectParentId }
          >
            <Icon icon='add' /> {formatMessage({ id: 'createSubjectParent' })}
          </Button>
        }
      />
      <DraftModal
        item={ editSubjectParent }
        isHidden={ !isModalVisible }
        onClose={ handleModalClose }
        onSubmit={ handleSubmit }
        isCreatingOrUpdating={ isFetching }
      />
      <ModalConfirm
        title={ formatMessage({ id: 'subjectParent' }) }
        message={ formatMessage({ id: 'removeSubjectConfirmation' }) }
        isHidden={ !openModalConfirm }
        isLoading={ isFetching }
        onClose={() => setOpenModalConfirm({ openModalConfirm: false, id: 0 })}
        onConfirm={ handleDeleteConfirm }
      />
    </StyledArticle>
  )
}

export default SubjectParents
