import { Button, Icon, ITableColumn, ITableItem, Spinner } from '@valudio/ui'
import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { debounce } from 'ts-debounce'
import { CustomTable, ModalConfirm } from '../../../components'
import { SessionContext } from '../../../context'
import { useReferenceGrades } from '../../../hooks'
import { initialDraftReferenceGrade, IReferenceGrade } from '../../../models'
import { DraftModal } from './components'
import StyledArticle from './styles'

const ReferenceGrades: React.FC = () => {
  const { formatMessage } = useIntl()
  const { id: deleteId, openModalConfirm, setOpenModalConfirm } = useContext(SessionContext)
  const {
    referenceGrades,
    editReferenceGrade,
    setEditReferenceGrade,
    isModalVisible,
    setIsModalVisible,
    isFetching,
    isDraftFecthing,
    setIsDraftFecthing,
    fetchReferenceGrades,
    searchReferenceGrades,
    getReferenceGradeById,
    createReferenceGrade,
    updateReferenceGrade,
    deleteReferenceGrade
  } = useReferenceGrades()
  const [editingReferenceGradeId, setEditingReferenceGradeId] = useState(0)
  const [isTableUpdated, setIsTableUpdated] = useState(false)

  const referenceGradeColumns: ITableColumn[] = [
    {
      key: 'id',
      label: '',
      style: {
        display: 'none'
      }
    },
    {
      key: 'year',
      label: formatMessage({ id: 'year' }),
      style: {
        flex: 0.2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'section',
      label: formatMessage({ id: 'section' }),
      style: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'subSection',
      label: formatMessage({ id: 'subSection' }),
      style: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'first',
      label: formatMessage({ id: 'indicationPeriod' }),
      style: {
        flex: 0.5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'second',
      label: formatMessage({ id: 'firstPeriod' }),
      style: {
        flex: 0.5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'examFirstSemester',
      label: formatMessage({ id: 'exam' }),
      style: {
        flex: 0.5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'third',
      label: formatMessage({ id: 'indicationPeriod' }),
      style: {
        flex: 0.5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'fourth',
      label: formatMessage({ id: 'secondPeriod' }),
      style: {
        flex: 0.5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'examSecondSemester',
      label: formatMessage({ id: 'exam' }),
      style: {
        flex: 0.5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'status',
      label: '',
      style: {
        flex: 0.2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexBasis: '80px'
      }
    }
  ]

  const referenceGradeItems: ITableItem[] = referenceGrades.map(referenceGrade => ({
    ...referenceGrade,
    first: referenceGrade.gradeTemplateItems.first.max,
    second: referenceGrade.gradeTemplateItems.second.max,
    examFirstSemester: referenceGrade.gradeTemplateItems.examFirstSemester.max,
    third: referenceGrade.gradeTemplateItems.third.max,
    fourth: referenceGrade.gradeTemplateItems.fourth.max,
    examSecondSemester: referenceGrade.gradeTemplateItems.examSecondSemester.max,
    status: isDraftFecthing && editingReferenceGradeId && editingReferenceGradeId === referenceGrade.id ? (
      <Spinner style={{ width: '30px', height: '30px', marginRight: '0' }} />
    ) : (
      <>
        <Button
          isCircular
          onClick={ () => handleOpenDraftModal(referenceGrade.id) }
          isDisabled={ isDraftFecthing }
        >
          <Icon className='icon' icon='edit' />
        </Button>
        <Button
          style={{ marginLeft: '0.5em' }}
          isCircular
          type='tertiary'
          isDisabled={ isDraftFecthing }
          onClick={() =>
            setOpenModalConfirm({ openModalConfirm: true, id: referenceGrade.id })
          }
        >
          <Icon className='icon' icon='delete' />
        </Button>
      </>
    )
  }))

  const handleOpenDraftModal = async (subjectId?: number) => {
    setIsDraftFecthing(true)

    if (subjectId) {
      await handleSampleTextEdit(subjectId)
    } else {
      setIsModalVisible(true)
      setIsDraftFecthing(false)
    }
  }

  const handleSampleTextEdit = async (subjectId: number) => {
    setEditingReferenceGradeId(subjectId)
    await getReferenceGradeById(subjectId)
  }

  const handleSubmit = async (referenceGradeItem: IReferenceGrade) => {
    handleModalClose()

    if (editReferenceGrade.id) {
      await updateReferenceGrade(referenceGradeItem)
    } else {
      await createReferenceGrade(referenceGradeItem)
    }

    setIsTableUpdated(true)
  }

  const handleModalClose = () => {
    setEditingReferenceGradeId(0)
    setEditReferenceGrade(initialDraftReferenceGrade)
    setIsModalVisible(false)
  }

  const handleDeleteConfirm = async () => {
    await deleteReferenceGrade(deleteId)
    // setIsTableUpdated(true)
  }

  const handleSearch = async (query: string) => {
    if (query) await searchReferenceGrades(query)
    else await fetchReferenceGrades()
  }

  const debounceSearch = debounce(handleSearch, 400)

  useEffect(() => {
    if (!referenceGrades.length) fetchReferenceGrades()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledArticle>
      <CustomTable
        title={ formatMessage({ id: 'referenceGrades' }) }
        columns={ referenceGradeColumns }
        items={ referenceGradeItems }
        loading={ isFetching }
        setValueSearch={ debounceSearch }
        tableUpdated={ isTableUpdated }
        setTableUpdated={ setIsTableUpdated }
        buttonNew={
          <Button
            type='primary'
            style={{ marginLeft: '0.5rem' }}
            onClick={ handleOpenDraftModal }
            isLoading={ false }
          >
            <Icon icon='add' /> {formatMessage({ id: 'createReferenceGrade' })}
          </Button>
        }
      />
      <DraftModal
        item={ editReferenceGrade }
        isHidden={ !isModalVisible }
        onClose={ handleModalClose }
        onSubmit={ handleSubmit }
        isCreatingOrUpdating={ isFetching }
      />
      <ModalConfirm
        title={ formatMessage({ id: 'referenceGrade' }) }
        message={ formatMessage({ id: 'removeReferenceGradeConfirmation' }) }
        isHidden={ !openModalConfirm }
        isLoading={ isFetching }
        onClose={() => setOpenModalConfirm({ openModalConfirm: false, id: 0 })}
        onConfirm={ handleDeleteConfirm }
      />
    </StyledArticle>
  )
}

export default ReferenceGrades
