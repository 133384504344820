import styled from 'styled-components'

export default styled.article`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  > header {
    padding: 20px 32px;
    box-sizing: border-box;
    z-index: 3000;

    h2 {
      margin: 0 0 28px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      color: ${ ({ theme }) => theme.colors.typography.dark };
    }

    .filter__container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      > .filters {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;

        .filter__row {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-end;

          &:first-of-type {
            margin-bottom: 14px;
          }

          .select__container {
            width: 312px;
            margin-right: 28px;

            > .label {
              font-size: 16px;
              letter-spacing: 0;
              line-height: 24px;
              color: ${ ({ theme }) => theme.colors.typography.medium };
              margin-bottom: 8px;
            }

            .select,
            > .select-container {
              width: 100%;
              box-sizing: border-box;
            }
          }

          > button {
            i {
              font-size: 24px;
            }
          }

          .prev,
          .next {
            margin-right: 10px;
          }
        }
      }

      .actions {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        align-items: center;
        margin-left: auto;

        button:first-of-type {
          margin-left: 20px;
        }
      }
    }

    @media (max-width: 1400px) {
      padding: 10px 16px;

      .filter__container {
        > .filters {
          .filter__row {
            &:first-of-type {
              margin-bottom: 7px;
            }

            > button {
              i {
                font-size: 18px;
              }
            }

            .select__container {
              margin-right: 10px;
            }

            .circular {
              width: 30px;
              height: 30px;
              border-radius: 15px;
            }

            .comments {
              font-size: 12px;
              width: auto;
              padding: 0 8px;
              box-sizing: border-box;
            }
          }
        }

        .actions {
          button {
            font-size: 12px;
            width: auto;
            margin-left: 8px;
          }

          button:first-of-type {
            margin-left: 8px;
          }
        }
      }
    }
  }

  .subjects-table {
    flex: 1;
    padding: 20px 32px 0;
    box-sizing: border-box;
    background-color: ${ ({ theme }) => theme.colors.grey.light };
    overflow: hidden;
    overflow-x: auto;

    @media (max-width: 1400px) {
      padding: 16px;
    }

    .spinner-container {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`
