import styled from 'styled-components'

export default styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow-y: auto;
  margin-bottom: 20px;

  .table-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    position: sticky;
    top: 0;
    background-color: ${ ({ theme }) => theme.colors.grey.light };
    z-index: 3;

    .column {
      margin-right: 8px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      min-height: 56px;
      border-radius: 5px;
      background-color: ${ ({ theme }) => theme.colors.white };
      margin-bottom: 8px;
      padding: 16px 0 0;
      box-sizing: border-box;

      &.students {
        background-color: ${ ({ theme }) => theme.colors.grey.light };
        padding: 0;
        flex: 0 0 auto;
        flex-basis: 260px;
        max-width: 260px;
        min-width: 250px;

        input {
          background-color: ${ ({ theme }) => theme.colors.white };
          width: 100%;
          border-radius: 5px;
        }

        .select {
          width: 100%;
          background-color: ${ ({ theme }) => theme.colors.white };
          margin-bottom: 8px;
          border-radius: 5px;
        }
      }

      &.comments {
        flex: 1;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0 8px 0 14px;
        margin-right: 0;

        h2 {
          margin-right: 10px;
        }
      }

      h2 {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        color: ${ ({ theme }) => theme.colors.typography.dark };
      }
    }
  }

  .table-content {
    flex: 1;

    &.loading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`
