import { IOption } from '@valudio/ui'

export type Year = 1 | 2 | 3 | 4 | 5 | 6 | 7 | null

export const yearSelectOptions: IOption[] = [
  { id: '1', value: '1' },
  { id: '2', value: '2' },
  { id: '3', value: '3' },
  { id: '4', value: '4' },
  { id: '5', value: '5' },
  { id: '6', value: '6' },
  { id: '7', value: '7' }
]
