import styled from 'styled-components'

export default styled.div`
  .container-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .content-buttons > button {
    margin-bottom: 1em;
  }

  .frame {
    height: 40%;
    width: 60%;
    overflow-x: visible !important;
    overflow-y: visible !important;
  }

  @media (min-width: 992px) {
    .frame {
      height: 40%;
      width: 40%;
    }

    .content-buttons {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: 0.5em;
      margin-right: 0.5em;
    }

    .content-buttons > button {
      margin-bottom: 0;
    }

    .confirm {
      margin-left: 0.5em;
    }
  }

  .content-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 2em;
    padding-right: 2em;
  }

  .content-header > button {
    margin: 0;
  }
`
