import { Button, Field, Icon, Input, Modal } from '@valudio/ui'
import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { initialDraftSubjectParent, ISubjectParent } from '../../../../../models'
import StyledDiv from './styles'

interface IProps {
  isHidden: boolean
  item?: ISubjectParent
  isCreatingOrUpdating?: boolean
  onClose?: () => void
  onSubmit?: (item: ISubjectParent) => void
}

export const DraftModal: React.FC<IProps> = ({ isHidden, item, isCreatingOrUpdating, onClose, onSubmit }) => {
  const formRef = useRef<HTMLFormElement>(null)
  const { formatMessage } = useIntl()
  const [draft, setDraft] = useState<ISubjectParent>(initialDraftSubjectParent)
  const isSubmitDisabled = (): boolean => {
    return !draft.code
      || !draft.name
      || !draft.sorting
  }

  const handleChange = (value: { [key: string]: any }): void => {
    setDraft(draft => ({ ...draft, ...value }))
  }

  const handleCleanup = () => {
    setDraft(initialDraftSubjectParent)
    if (formRef && formRef.current) formRef.current.reset()
  }

  const handleClose = () => {
    handleCleanup()
    if (onClose && typeof onClose === 'function') onClose()
  }

  const handleSubmit = () => {
    if (onSubmit && typeof onSubmit === 'function') onSubmit(draft)
    handleCleanup()
  }

  // Set draft item
  useEffect(() => {
    if (item && !draft.id) setDraft(item)
  }, [draft.id, item])

  return (
    <Modal isHidden={ isHidden } onOverlayClick={ handleClose }  className="edit-modal">
      <StyledDiv>
        <div className="content-header">
          <h3>{ formatMessage({ id: `${ draft?.id ? 'updateSubjectParent' : 'createSubjectParent' }` }) }</h3>
          <Icon
            className='icon'
            icon='close'
            onClick={ handleClose }
          />
        </div>
        <form ref={ formRef }>
          <div className="form-row">
            <Field label={ `${ formatMessage({ id: 'code' }) } *` } className="field">
              <Input
                form={ formRef.current ?? undefined }
                initialValue={ draft.code }
                onChange={ code => handleChange({ code }) }
                placeholder={ formatMessage({ id: 'placeholderSubjectCode' }) }
              />
            </Field>
          </div>
          <div className="form-row">
            <Field label={ `${ formatMessage({ id: 'name' }) } *` } className="field">
              <Input
                form={ formRef.current ?? undefined }
                initialValue={ draft.name }
                onChange={ name => handleChange({ name }) }
                placeholder={ formatMessage({ id: 'placeholderSubjectName' }) }
              />
            </Field>
          </div>
          <div className="form-row">
            <Field label={ `${ formatMessage({ id: 'sorting' }) } *` } className="field">
              <Input
                form={ formRef.current ?? undefined }
                initialValue={ draft.sorting ? draft.sorting.toString() : undefined }
                onChange={ value => handleChange({ sorting: Number(value) }) }
                type="number"
                placeholder={ formatMessage({ id: 'placeholderSubjectSorting' }) }
              />
            </Field>
          </div>
          <div className="actions">
            <Button
              type="primary"
              onClick={ handleSubmit }
              isLoading={ isCreatingOrUpdating }
              isDisabled={ isSubmitDisabled() }
            >
              { (formatMessage({id: `${ 'saveSubjectParent' }`})) }
            </Button>
          </div>
        </form>
      </StyledDiv>
    </Modal>
  )
}
