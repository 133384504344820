import { Button, DateTime, Icon, Input, IOption } from '@valudio/ui'
import React from 'react'
import { useIntl } from 'react-intl'
import { List, Select } from '../../../../../components'
import { AdditionalExamsType, IAdditionalExam, IListItem, IListProperty } from '../../../../../models'

interface IProps {
  additionalComments: IAdditionalExam[]
  onChange?: (additionalExam: IAdditionalExam) => void
  onDelete?: (id: number) => void
  onAdd?: () => void
}

export const AdditionalExams: React.FC<IProps> = ({ additionalComments, onChange, onDelete, onAdd }) => {
  const { formatMessage } = useIntl()
  const examHomeworkOptions: IOption[] = [
    { id: '0', label: formatMessage({ id: 'additionalExam' }) },
    { id: '1', label: formatMessage({ id: 'homework' }) }
  ]
  const typeValue = (type: AdditionalExamsType) => {
    const typeOption = examHomeworkOptions.find(ex => Number(ex.id) === type)
    return typeOption
  }

  const listProperties: IListProperty[] = [
    { label: formatMessage({ id: 'examHomework' }) , key: 'typeListAditionalExams' },
    { label: formatMessage({ id: 'subjectName' }), key: 'subjectName' },
    { label: formatMessage({ id: 'date' }), key: 'date' },
    { label: '', key: 'delete', className: 'delete' }
  ]

  const listItems: IListItem[] = additionalComments.map(additionalComment => ({
    key: `additionalComment-${ additionalComment.rowId }`,
    typeListAditionalExams: (
      <Select
        labelProp="label"
        value={ typeValue(additionalComment.typeListAditionalExams) }
        options={ examHomeworkOptions }
        onChange={ option => handleTypeChange(additionalComment, option) }
      />
    ),
    subjectName: (
      <Input
        initialValue={ additionalComment.subjectName }
        onChange={ subjectName => handleChange(additionalComment, { ...additionalComment, subjectName }) }
      />
    ),
    date: (
      <DateTime
        initialValue={ additionalComment.date }
        onChange={ date => handleChange(additionalComment, { ...additionalComment, date }) }
        showTime={ false }
      />
    ),
    delete: (
      <Button
        isCircular
        onClick={ () => handleDelete(additionalComment.rowId) }
      >
        <Icon className='icon' icon='delete' />
      </Button>
    )
  }))

  const handleDelete = (rowId: number) => {
    if (onDelete && typeof onDelete === 'function') onDelete(rowId)
  }

  const handleChange = (additionalExam: IAdditionalExam, value: { [key: string]: any }): void => {
    const modifiedAdditionalExam = { ...additionalExam, ...value }
    if (onChange && typeof onChange === 'function') onChange(modifiedAdditionalExam)
  }

  const handleTypeChange = (additionalExam: IAdditionalExam, option: IOption) => {
    const typeListAditionalExams = Number(option.id) as AdditionalExamsType
    handleChange(additionalExam, { ...additionalExam, typeListAditionalExams })
  }

  return (
    <List
      className="compact"
      properties={ listProperties }
      items={ listItems }
    >
      <li
        className="add-button"
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}
      >
        <Button onClick={ onAdd } isDisabled={ false } type="secondary">
          { formatMessage({ id: 'addExamHomework' }) }
        </Button>
      </li>
    </List>
  )
}
