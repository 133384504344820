import { IGrade, IGradeTemplate, initialGradeTemplate } from './grade'

export interface ICompetency {
  id: number
  name: string
  gradeTemplateItems: IGrade[]
  idSubject?: number
}

export interface ICompetencyDraft {
  id?: number
  rowId: number
  name: string
  gradeTemplateItems: IGradeTemplate
  idSubject?: number
}

export interface ICompetencyGrade {
  id: number
  name: string
  idSubject: number
  gradeTemplateLines: IGradeTemplate
}

export const initialCompetencyDraft: ICompetencyDraft = {
  id: 0,
  rowId: 0,
  name: '',
  idSubject: 0,
  gradeTemplateItems: initialGradeTemplate
}
