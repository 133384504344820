import { IOption } from '@valudio/ui'
import React, {useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { isClickingOutside } from '../../helpers'
import { Dropdown } from './Dropdown'
import { StyledForm, StyledTextarea } from './styles'

interface IProps {
  value: string
  options: IOption[]
  initialRows?: number
  textPrefix?: string
  onSearchChange?: (text: string) => void
  onChange?: (value: string) => void
}

export const AutocompleteTextarea: React.FC<IProps> = ({
  initialRows, value, options, textPrefix, onChange, onSearchChange
}) => {
  const { formatMessage } = useIntl()
  const formRef = useRef<HTMLFormElement>(null)
  const ref = useRef<HTMLTextAreaElement>(null)
  const [currentValue, setCurrentValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const rows = initialRows ?? 1
  const maxRows = 200

  const handleFocus = () => {
    handleRowChange()
    setIsOpen(true)
  }

  const handleRowChange = () => {
    const textareaLineHeight = 24

    if (ref && ref.current) {
      const previousRows = ref.current.rows
      ref.current.rows = 1
      const currentRows = ~~(ref.current.scrollHeight / textareaLineHeight)

      if (currentRows === previousRows) {
        ref.current.rows = currentRows
      }

      if (currentRows >= maxRows) {
        ref.current.rows = maxRows
        ref.current.scrollTop = ref.current.scrollHeight
      }

      ref.current.rows = currentRows < maxRows ? currentRows : maxRows
    }
  }

  const handleFormatValue = (value: string) => {
    if (textPrefix && value) {
      const originalValue = value.replace(textPrefix, '')
      return `${ textPrefix }${ originalValue }`
    } else return value
  }

  const handleChange = (nextValue: string) => {
    if (onChange && typeof onChange === 'function') onChange(handleFormatValue(nextValue))
    setCurrentValue(handleFormatValue(nextValue))
    handleRowChange()
  }

  const handleClick = (option: IOption) => {
    setCurrentValue(handleFormatValue(option.label))
    if (onChange && typeof onChange === 'function') onChange(handleFormatValue(option.label))
    handleRowChange()
    setIsOpen(false)
  }

  const handleDocumentClick = (event: MouseEvent) => {
    if (isClickingOutside(ref, formRef, event)) setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  useEffect(() => {
    if (value && !currentValue) {
      setCurrentValue(value)
    }

    return () => {
      setCurrentValue('')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLayoutEffect(() => {
    handleRowChange()
  }, [])

  return (
    <StyledForm ref={ formRef || null } onClick={ event => event.preventDefault() } style={{ width: '100%' }}>
      <StyledTextarea
        ref={ ref }
        rows={ rows }
        style={{ minHeight: initialRows ? `${ initialRows * 24 }px` : 0 }}
        onFocus={ handleFocus }
        onChange={ event => handleChange(event.currentTarget.value) }
        value={ currentValue }
      >
        {/* { currentValue } */}
      </StyledTextarea>
      <Dropdown
        placeholder={ formatMessage({ id: 'search' }) }
        isHidden={ !isOpen }
        options={ options }
        onClick={ handleClick }
        onTextChange={ onSearchChange }
        // isLoading={ isSearching }
      />
    </StyledForm>
  )
}
