import { Button, Icon, Modal } from '@valudio/ui'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext } from '../../context'
import Styled from './styles'

interface IProps {
  title: string
  message?: string
  isHidden?: boolean
  isLoading?: boolean
  onClose?: () => void
  onConfirm?: () => void
}

export const ModalConfirm: React.FC<IProps> = ({
  title,
  message,
  isHidden,
  isLoading,
  onClose,
  onConfirm
}) => {
  const { formatMessage } = useIntl()
  const { id, setOpenModalConfirm } = useContext(SessionContext)

  const handleSubmit = () => {
    if (onConfirm && typeof onConfirm === 'function') {
      onConfirm()
    }
  }

  return (
    <Modal isHidden={ isHidden } onOverlayClick={ onClose }>
      <Styled>
        <div className='content-header'>
          <h3>{`${formatMessage({ id: 'delete' })} ${title}`}</h3>
          <Icon
            className='icon'
            icon='close'
            onClick={() => setOpenModalConfirm({ openModalConfirm: false, id })}
          />
        </div>
        <div className='container-form'>
          <h3>{message}</h3>
          <div className='content-buttons'>
            <Button
              type='tertiary'
              onClick={() =>
                setOpenModalConfirm({ openModalConfirm: false, id })
              }
              isDisabled={ isLoading }
            >
              {formatMessage({ id: 'cancel' })}
            </Button>
            <Button className='confirm' onClick={handleSubmit} isLoading={ isLoading }>
              {formatMessage({ id: 'confirm' })}
            </Button>
          </div>
        </div>
      </Styled>
    </Modal>
  )
}
