import styled from 'styled-components'

export default styled.p`
  flex: 1;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin: 0 4px;
  height: 34px;
  padding: 2px;
  box-sizing: border-box;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &.edit {
    justify-content: space-between;
    border: 1px solid ${ ({ theme }) => theme.colors.grey.medium };
    border-radius: 3px;
    background-color: ${ ({ theme }) => theme.colors.grey.light };
  }

  &.focused {
    border-color: ${ ({ theme }) => theme.colors.primary.medium };
    background-color: ${ ({ theme }) => theme.colors.primary.light };
  }

  input {
    width: 39px;
    height: 24px;
    border: 1px solid transparent;
    border-radius: 3px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    color: ${ ({ theme }) => theme.colors.typography.dark };
    outline: none;
    background-color: transparent;

    &:focus:not(:read-only) {
      border-color: ${ ({ theme }) => theme.colors.primary.medium };

      & + input:read-only {
        background-color: ${ ({ theme }) => theme.colors.grey.light };
        border-color: ${ ({ theme }) => theme.colors.grey.medium };
      }
    }

    &:read-only {
      pointer-events: none;
    }

    &:-webkit-outer-spin-button,
    &:-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &.invalid {
      color: ${ ({ theme }) => theme.colors.system.error };
      border-color: ${ ({ theme }) => theme.colors.system.error };
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }

  span {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 28px;
    margin: 0 2px;
  }
`
