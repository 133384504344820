import { ICompetency, ICompetencyDraft, ICompetencyGrade } from './competency'
import { IGrade, IGradeTemplate, initialGradeTemplate } from './grade'
import { IStudentObject } from './students'
import { ISubjectParent } from './subject-parent'
import { ISubjectType } from './subject-type'
import { Year } from './year'
import { ITeacherObject } from '.'

export interface ISubject {
  id: number
  code?: string
  name?: string
  hoursPerWeek: number | null
  typeSubject: ISubjectType
  year: Year
  idSubjectParent: number
  gradeTemplateItems: IGrade[]
  students: any[]
  classes: any[]
  teachers: ITeacherObject[]
  competencies: ICompetency[]
  min60percent: boolean
  isAdministrativeSubject: boolean
}

export interface ISubjectDraft {
  id: number
  code?: string
  name?: string
  hoursPerWeek: number | null
  typeSubject?: ISubjectType
  year: Year
  idSubjectParent: number
  gradeTemplateItems: IGradeTemplate
  students: number[]
  classes: number[]
  teachers: number[]
  competencies: ICompetencyDraft[]
  min60percent: boolean
  isAdministrativeSubject: boolean
}

export interface ISubjectRow {
  id: number
  code: string
  name: string
  hoursPerWeek: number | null
  typeSubject: string
  subjectParent: string
  year: number
  teachers: ITeacherObject[]
  isAdministrativeSubject: boolean
}

export interface ISubjectGrade {
  id: number
  code: string
  name: string
  hoursPerWeek: number | null
  year: number
  idSubjectParent: number
  subjectParent?: ISubjectParent
  students: IStudentObject[]
  competencies: ICompetencyGrade[]
  gradeTemplateLines: IGradeTemplate
}

export enum SubjectTab {
  Competencies = 'competencies',
  Students = 'students',
  Teachers = 'teachers',
  Classes = 'classes'
}

export const initialDraftSubject: ISubjectDraft = {
  id: 0,
  code: '',
  name: '',
  hoursPerWeek: null,
  typeSubject: undefined,
  year: null,
  idSubjectParent: 0,
  gradeTemplateItems: initialGradeTemplate,
  students: [],
  teachers: [],
  classes: [],
  competencies: [],
  min60percent: false,
  isAdministrativeSubject: false
}

export const initialSubjectGrade: ISubjectGrade = {
  id: 0,
  code: '',
  name: '',
  hoursPerWeek: 0,
  year: 0,
  idSubjectParent: 0,
  subjectParent: undefined,
  students: [],
  competencies: [],
  gradeTemplateLines: initialGradeTemplate
}
