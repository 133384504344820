import styled from 'styled-components'

export default styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;

  &.disabled {
    pointer-events: none;

    input {
      pointer-events: none;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: ${ ({ theme }) => theme.colors.primary.medium };

      &:before {
        transform: translateX(16px);
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 1px ${ ({ theme }) => theme.colors.primary.medium };
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${ ({ theme }) => theme.colors.grey.dark };
    transition: all ease-out .1s;

    &.round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }

    &:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 4px;
      bottom: 4px;
      background-color: ${ ({ theme }) => theme.colors.white };
      transition: all ease-out .1s;
    }
  }
`
