import styled from 'styled-components'

export default styled.ul`
  &.list__content {
    position: relative;
    list-style: none;
    padding: 0;
    margin: 20px 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    .scrollable-rows {
      overflow-y: auto;
      height: 100%;
    }

    li {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      &.item {
        min-height: 64px;
        background-color: ${ ({ theme }) => theme.colors.white };
        padding: 8px 0;
        border-radius: 4px;
        margin-bottom: 8px;
        box-shadow: 0 2px 6px 0 rgba(66,74,97,0.12);
        /* z-index: 9000; */

        p,
        div {
          flex: 1;
          margin-right: 8px;
          box-sizing: border-box;

          &:last-of-type {
            margin-right: 0;
          }
        }

        > div {
          input {
            width: 100%;
            border-radius: 4px;
            margin-right: 8px;
            box-sizing: border-box;
          }

          &.name {
            min-width: 240px;
          }

          &.delete {
            max-width: 40px;
          }
        }

        > .select-container {
          width: 100%;
          /* z-index: 9001; */
        }
      }

      &.add-button > button {
        width: auto;
        padding: 0 10px;
        box-sizing: border-box;
      }
    }

    .list__header {
      padding: 0 8px;
      box-sizing: border-box;
      margin-bottom: 4px;

      p {
        color: ${ ({ theme }) => theme.colors.typography.medium };
        flex: 1;
        font-size: 13px;
        padding-left: 4px;
        margin: 0 8px 8px 0;

        &:last-of-type {
          margin: 0;
        }

        &.medium {
          flex: 2;
        }

        &.large {
          flex: 3;
        }

        &.align-right {
          text-align: right;
        }

        &.name {
          min-width: 240px;
        }

        &.delete {
          max-width: 40px;
        }

        &.reset {
          button {
            background: transparent;
            width: 108px;

            i {
              font-size: 12px;
              margin-right: 14px;
              display: inline-block;
            }

            &:hover {
              background: ${ ({ theme }) => theme.colors.primary.dark };
              color: white;
            }
          }
        }
      }
    }

    div {
      > li:not(.list__header) {
        padding: 0 16px;
        background-color: ${ ({ theme }) => theme.colors.textMediumGray };
        border-radius: 2px;
        height: 60px;
        position: relative;

        &.error {
          border: 1px solid ${ ({ theme }) => theme.colors.alert };

          i {
            position: absolute;
            right: 10px;
            color: ${ ({ theme }) => theme.colors.alert };
            font-size: 22px;
            max-height: 26px;
            top: 0;
            bottom: 0;
            margin: auto;
          }

          > div.error {
            border: none;
            padding: 0;

            i {
              display: none;
            }
          }
        }

        > div {
          color: ${ ({ theme }) => theme.colors.textMedium };
          font-size: 12px;
          font-weight: normal;
          flex: 1;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          &.align-right {
            text-align: right;
            align-items: flex-end;
          }

          &.medium {
            flex: 2;
          }

          &.large {
            flex: 3;
          }
        }
      }

    }

    &.compact {
      /* max-height: 456px; */
      margin-top: 0;
      height: 100%;
      overflow: hidden;

      /* .list__header {
        padding: 20px 8px 14px;
      } */

      > li:not(.list__header) {
        height: 68px;
        padding: 0 8px;

        > div {
          line-height: 18px;
        }
      }
    }
  }
`
