import { Menu, MenuItem } from '@valudio/ui'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import { LanguageContext, SessionContext, UiContext } from '../../context'
import { useAuth } from '../../hooks'
import { ILanguage, Locale } from '../../models'
import { privatesUrls } from '../../routing'
import Styled from './styles'

interface IProps {
  isVisible: boolean
}

export const Sidebar: React.FC<IProps> = ({ isVisible }) => {
  const history = useHistory()
  const { formatMessage } = useIntl()
  const { pathname } = useLocation()
  const { logOut } = useAuth()
  const { username, isAdmin, isClassLeader } = useContext(SessionContext)
  const { setIsPasswordModalVisible, setIsOtherSettingsVisible } = useContext(UiContext)
  const { setLocale } = useContext(LanguageContext)

  const languages: ILanguage[] = [
    {
      label: formatMessage({ id: 'de' }),
      locale: 'de'
    },
    // {
    //   label: formatMessage({ id: 'fr' }),
    //   locale: 'fr'
    // },
    // {
    //   label: formatMessage({ id: 'it' }),
    //   locale: 'it'
    // },
    {
      label: formatMessage({ id: 'en' }),
      locale: 'en'
    }
  ]

  const gradeConsultation = isAdmin || isClassLeader
    ? (
      <MenuItem
        icon='material'
        label={formatMessage({ id: 'gradeConsultation' })}
        onClick={() => history.push(privatesUrls.gradeConsultation)}
        isActive={ pathname === privatesUrls.gradeConsultation }
      />
    ) : null

  const settings = isAdmin
    ? (
      <MenuItem
        label={formatMessage({ id: 'settings' })}
        icon='settings'
        dropdownItems={[
          {
            isActive: pathname === privatesUrls.teachers,
            label: formatMessage({ id: 'teachers' }),
            onClick: () => history.push(privatesUrls.teachers)
          },
          {
            isActive: pathname === privatesUrls.students,
            label: formatMessage({ id: 'students' }),
            onClick: () => history.push(privatesUrls.students)
          },
          {
            isActive: pathname === privatesUrls.classes,
            label: formatMessage({ id: 'classes' }),
            onClick: () => history.push(privatesUrls.classes)
          },
          {
            isActive: pathname === privatesUrls.subjects,
            label: formatMessage({ id: 'subjects' }),
            onClick: () => history.push(privatesUrls.subjects)
          },
          {
            isActive: pathname === privatesUrls.subjectParents,
            label: formatMessage({ id: 'subjectParents' }),
            onClick: () => history.push(privatesUrls.subjectParents)
          },
          {
            isActive: pathname === privatesUrls.referenceGrades,
            label: formatMessage({ id: 'referenceGrades' }),
            onClick: () => history.push(privatesUrls.referenceGrades)
          },
          {
            isActive: pathname === privatesUrls.sampleTexts,
            label: formatMessage({ id: 'sampleTexts' }),
            onClick: () => history.push(privatesUrls.sampleTexts)
          }
          // {
          //   isActive: pathname === privatesUrls.sections,
          //   label: formatMessage({ id: 'sections' }),
          //   onClick: () => history.push(privatesUrls.sections)
          // }
        ]}
        isDropdown
      />
    ) : null

  if (!isVisible) return null
  return (
    <Styled>
      <Menu
        className='menu'
        languageLabel={ formatMessage({ id: 'language' }) }
        languages={ languages }
        logoSrc='assets/images/menu.png'
        onLanguageClick={ locale => setLocale(locale as Locale) }
        onSignOut={ logOut }
        username={username}
        isExpanded={true}
      >
        <MenuItem
          icon='projects'
          label={formatMessage({ id: 'gradeInput' })}
          onClick={() => history.push(privatesUrls.gradeInput)}
          isActive={ pathname === privatesUrls.gradeInput }
        />
        { gradeConsultation }
        { settings }
        { isAdmin
          ? (
            <MenuItem
              icon='change-password'
              label={ formatMessage({ id: 'otherSettings' }) }
              onClick={ () => setIsOtherSettingsVisible(true) }
              style={{ marginTop: 'auto' }}
            />
          ) : null
        }
        <MenuItem
          icon='user'
          label={ formatMessage({ id: 'editPassword' }) }
          onClick={ () => setIsPasswordModalVisible(true) }
          style={{ marginTop: isAdmin ? 'initial' : 'auto' }}
        />
      </Menu>
    </Styled>
  )
}
