import { RefObject } from 'react'

export const isChildNode = (parent: any, child: any): boolean => {
  if (parent === child) return true
  let node = child.parentNode

  while (node !== null) {
    if (node === parent) return true
    node = node.parentNode
  }
  return false
}

export const isClickingOutside = (ref: RefObject<any>, wrapperRef: RefObject<any>, event: MouseEvent) => {
  const clicklableElementsExists = ref.current && wrapperRef.current && event.target
  const isNotClickingOnChildOrReference = !isChildNode(ref.current, event.target) && ref.current !== event.target
  const isNotClickingOnChildOrWrapper =
    !isChildNode(wrapperRef.current, event.target) &&  wrapperRef.current !== event.target

  return clicklableElementsExists && isNotClickingOnChildOrReference && isNotClickingOnChildOrWrapper
}

export const addWheelScrollEventListener = () => {
  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
  const noteInputs = document.querySelectorAll('input.note-input')
  const maxInputs = document.querySelectorAll('input.max-input')
  if (isChrome && noteInputs?.length && maxInputs?.length) {
    noteInputs.forEach(input => {
      input.addEventListener('wheel', event => {
        event.preventDefault()
      }, { passive: false })
      input.addEventListener('mousewheel', event => {
        event.preventDefault()
      }, { passive: false })
      input.addEventListener('touchmove', event => {
        event.preventDefault()
      }, { passive: false })
    })
    maxInputs.forEach(input => {
      input.addEventListener('wheel', event => {
        event.preventDefault()
      }, { passive: false })
      input.addEventListener('mousewheel', event => {
        event.preventDefault()
      }, { passive: false })
      input.addEventListener('touchmove', event => {
        event.preventDefault()
      }, { passive: false })
    })
  } else if (noteInputs?.length && maxInputs?.length) {
    noteInputs.forEach(input => {
      input.addEventListener('wheel', event => {
        event.preventDefault()
      })
      input.addEventListener('mousewheel', event => {
        event.preventDefault()
      })
      input.addEventListener('touchmove', event => {
        event.preventDefault()
      })
    })
    maxInputs.forEach(input => {
      input.addEventListener('wheel', event => {
        event.preventDefault()
      })
      input.addEventListener('mousewheel', event => {
        event.preventDefault()
      })
      input.addEventListener('touchmove', event => {
        event.preventDefault()
      })
    })
  }
}

export const removeWheelScrollEventListener = () => {
  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
  const noteInputs = document.querySelectorAll('input.note-input')
  const maxInputs = document.querySelectorAll('input.max-input')
  if (isChrome && noteInputs?.length && maxInputs?.length) {
    noteInputs.forEach(input => {
      input.removeEventListener('wheel', event => {
        event.preventDefault()
      })
      input.removeEventListener('mousewheel', event => {
        event.preventDefault()
      })
      input.removeEventListener('touchmove', event => {
        event.preventDefault()
      })
    })
    maxInputs.forEach(input => {
      input.removeEventListener('wheel', event => {
        event.preventDefault()
      })
      input.removeEventListener('mousewheel', event => {
        event.preventDefault()
      })
      input.removeEventListener('touchmove', event => {
        event.preventDefault()
      })
    })
  }
}
