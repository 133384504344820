/* eslint-disable max-len */
import { Button, Checkbox, Field, Icon, IOption, Modal, Spinner } from '@valudio/ui'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { SearchInput } from '..'
import { SessionContext } from '../../context'
import { IModal, initialUserEdit, IStudentEditObject, ITeacherEditObject } from '../../models'
import Input from '../Input'
import Styled from './styles'

interface IProps extends IModal {
  classes?: IOption[]
  onUpdate: (editItem: ITeacherEditObject | IStudentEditObject) => void
}

export const ModalEdit: React.FC<IProps> = ({
  classes, title, isHidden, onClose, onUpdate
}) => {
  const formRef = useRef<HTMLFormElement>(null)
  const { formatMessage } = useIntl()
  const { userEdit, loading, setOpenModalEdit, isAdmin: isUserAdmin } = useContext(SessionContext)
  const [id, setId] = useState(0)
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [userName, setUserName] = useState<string>('')
  const [identificationNumber, setIdentificationNumber] = useState('')
  const [idClass, setIdClass] = useState<number>()
  const [initialClass, setInitialClass] = useState<IOption>()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [isAdmin, setIsAdmin] = useState(false)
  const [active, setActive] = useState(false)
  const [isEmpty, setIsEmpty] = useState(true)

  const handleClassChange = (option: IOption) => {
    setIdClass(Number(option.id))
  }

  const handleClose = () => {
    handleCleanup()
    if (onClose && typeof onClose === 'function') onClose()
  }

  const handleSubmit = () => {
    setActive(true)
    if (title === 'Student') {
      const editStudent = {
        id,
        firstName,
        lastName,
        identificationNumber,
        active,
        idClass
      }
      onUpdate(editStudent)
    }
    if (title === 'Teacher') {
      const editTeacher = password
        ? {
          id,
          firstName,
          lastName,
          userName,
          email,
          password,
          active,
          isAdmin
        } : {
          id,
          firstName,
          lastName,
          userName,
          email,
          active,
          isAdmin
        }
      onUpdate(editTeacher)
    }
    handleCleanup()
    setOpenModalEdit({
      openModalEdit: false,
      userEdit: initialUserEdit
    })
  }

  const handleCleanup = () => {
    setActive(false)
    setId(0)
    setFirstName('')
    setLastName('')
    setUserName('')
    setIdentificationNumber('')
    setEmail('')
    setPassword('')
    setIsAdmin(false)
    setIsEmpty(true)
    if (formRef && formRef.current) formRef.current.reset()
  }

  useEffect(() => {
    if (!isHidden && !!userEdit.id && !id) {
      setId(userEdit.id)
      setFirstName(userEdit.firstName)
      setLastName(userEdit.lastName)
      setUserName(userEdit.userName)
      setIdentificationNumber(userEdit?.identificationNumber ?? '')
      setEmail(userEdit.email)
      setPassword(userEdit.password ?? '')
      setIsAdmin(userEdit.isAdmin ?? false)
      setIdClass(userEdit.idClass ?? undefined)

      const userClass = classes?.find(c => Number(c.id) === userEdit.idClass)
      if (userClass) setInitialClass(userClass)
      else {
        setTimeout(() => {
          setIsEmpty(false)
        }, 500)
      }

    }
  }, [isHidden, userEdit, id, classes])

  useEffect(() => {
    if (initialClass?.id) setIsEmpty(false)
  }, [ initialClass ])

  return (
    <Modal
      isHidden={ isHidden }
      onOverlayClick={ handleClose }
      className="modal-edit"
    >
      <Styled>
        { isEmpty ? (
          <div className="spinner-container">
            <Spinner />
          </div>
        ) : (
          <>
            <div className='content-header'>
              <h3>
                {`${ formatMessage({id: title === 'Teacher' ? 'teachers' : 'student'}) } ${formatMessage({ id: 'editTitle' }).toLowerCase()}`}
              </h3>
              <Icon className='icon' icon='close' onClick={ handleClose } />
            </div>
            <form className={ `container-form ${ title === 'Student' ? 'student' : '' }` } ref={ formRef }>
              <Input
                className='input'
                label={ formatMessage({ id: 'labelFirstName'}) }
                onChange={setFirstName}
                initialValue={firstName}
                placeholder='Introduce your first name'
                type='text'
              />
              <Input
                className='input'
                label={ formatMessage({ id: 'labelLastName'}) }
                onChange={setLastName}
                initialValue={lastName}
                placeholder='Introduce your last name'
                type='text'
              />
              {title === 'Teacher' ? (
                <>
                  <Input
                    className='input'
                    label={ formatMessage({ id: 'userName'}) }
                    onChange={setUserName}
                    initialValue={userName}
                    placeholder='Introduce your user name'
                    type='text'
                  />
                  <Input
                    className='input'
                    label={ formatMessage({ id: 'email'}) }
                    onChange={setEmail}
                    initialValue={email}
                    placeholder='Introduce your email'
                    type='email'
                  />
                  {
                    isUserAdmin
                      ? (
                        <Input
                          className='input'
                          label={ formatMessage({ id: 'labelPassword'}) }
                          onChange={ setPassword }
                          initialValue={ password }
                          placeholder={ formatMessage({ id: 'placeholderPassword'}) }
                          type='password'
                        />
                      ) : null
                  }
                  <div className="checkbox-container">
                    <Checkbox
                      value={ isAdmin }
                      onChange={ setIsAdmin }
                    />
                    <span>{ formatMessage({ id: 'admin' }) }</span>
                  </div>
                </>
              ) : (
                <>
                  <Input
                    className='input'
                    label={ formatMessage({ id: 'labelIdentificationNumber'}) }
                    onChange={setIdentificationNumber}
                    initialValue={identificationNumber}
                    placeholder='Introduce your identification number'
                    type='number'
                  />
                  <Field label={ formatMessage({ id: 'class' }) } className="optional">
                    <SearchInput
                      currentOption={ initialClass }
                      className="select"
                      placeholder={ formatMessage({ id: 'choosePlaceholder' }) }
                      options={ classes ?? [] }
                      isDisabled={ !classes || !classes.length }
                      onChange={ handleClassChange }
                    />
                  </Field>
                </>
              )}
              <div className='content-buttons'>
                <Button type='tertiary' onClick={ handleClose }>
                  {formatMessage({ id: 'cancel' })}
                </Button>
                {title === 'Teacher' ? (
                  <Button
                    style={{ marginLeft: '0.5rem' }}
                    isDisabled={
                      !firstName || !lastName || !userName || !email || loading
                    }
                    onClick={handleSubmit}
                  >
                    {formatMessage({ id: 'save' })}
                  </Button>
                ) : (
                  <Button
                    style={{ marginLeft: '0.5rem' }}
                    isDisabled={
                      !firstName || !lastName || !identificationNumber || loading
                    }
                    onClick={handleSubmit}
                    isLoading={ loading }
                  >
                    {formatMessage({ id: 'save' })}
                  </Button>
                )}
              </div>
            </form>
          </>
        )}
      </Styled>
    </Modal>
  )
}
