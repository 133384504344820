import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;

  .column {
    height: 40px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: ${ ({ theme }) => theme.colors.white };
    margin: 0 8px 8px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    color: ${ ({ theme }) => theme.colors.typography.dark };

    &:last-of-type {
      margin-bottom: 0;
      margin-right: 0;
    }

    &.students {
      flex-basis: 300px;
      min-width: 245px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      /* max-width: 306px; */

      .name {
        overflow: hidden;
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10px 0 16px;

        span {
          display: inline-block;
          box-sizing: border-box;
          width: 100%;
          max-width: 256px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-lines: 1;
        }
      }
    }

    &.semester {
      flex: 1;
      padding: 3px;
      box-sizing: border-box;
      min-width: 390px;

      p {
        flex: 1;
        text-align: center;
        box-sizing: border-box;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &.total {
      width: 100px;
      min-width: 74px;
      margin-right: 0;
      justify-content: center;
    }

    .spinner.small {
      width: 30px;
      height: 30px;
    }
  }
`
