import { IGrade, IGradeTemplate, initialGradeTemplate, ISection, ISubSection, Year } from '.'

export interface IReferenceGradeRowResponse {
  id: number
  year: Year
  idSection: number
  idSubSection: number
  section: string
  subSection: string | null
  sectionTemplateLines: IGrade[]
  finalDecisionTitle: string
  certificateOfCompetenceTitle: string
  certificateOfCompetenceIsVisible: boolean
}

export interface IReferenceGradeRow {
  id: number
  year: Year
  idSection: number
  idSubSection: number
  section: string
  subSection: string | null
  gradeTemplateItems: IGradeTemplate
}

export interface IReferenceGradeResponse {
  id: number
  year: Year
  idSection: number
  idSubSection: number
  section: ISection
  subSection: ISubSection | null
  sectionTemplateLines: IGrade[]
  comments: string
  finalDecisionTitle: string
  certificateOfCompetenceTitle: string
  certificateOfCompetenceIsVisible: boolean
}

export interface IReferenceGrade {
  id: number
  year: Year
  idSection: number
  idSubSection: number
  section: ISection
  subSection: ISubSection | null
  gradeTemplateItems: IGradeTemplate
  comments: string
  finalDecisionTitle: string
  certificateOfCompetenceTitle: string
  certificateOfCompetenceIsVisible: boolean
}

export const initialDraftReferenceGrade: IReferenceGrade = {
  id: 0,
  year: 1,
  idSection: 0,
  idSubSection: 0,
  section: {
    id: 0,
    name: ''
  },
  subSection: null,
  gradeTemplateItems: initialGradeTemplate,
  comments: '',
  finalDecisionTitle: '',
  certificateOfCompetenceTitle: '',
  certificateOfCompetenceIsVisible: false
}
