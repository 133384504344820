import React, { ChangeEvent, useContext, useRef, useState, KeyboardEvent } from 'react'
import classnames from 'classnames'
import { SessionContext } from '../../../../../context'
import { roundNumber } from '../../../../../helpers'
import StyledP from './styles'

interface IProps {
  note: number | null
  max: number | null
  tabIndex: number
  isEditActive: boolean
  isEditDisabled: boolean
  onChange?: (value: string) => void
  onMaxChange?: (value: string) => void
}

export const EditableRowItem: React.FC<IProps> = ({
  note, max, tabIndex, isEditActive, isEditDisabled, onChange, onMaxChange
}) => {
  const formRef = useRef<HTMLFormElement>(null)
  const { isAdmin } = useContext(SessionContext)
  const [isFocused, setIsFocused] = useState(false)
  const [noteRoundValue, setNoteRoundValue] = useState(
    () => note || note === 0 ? roundNumber(note).toString() : undefined
  )
  const [maxRoundValue, setMaxRoundValue] = useState(
    () => max ? roundNumber(max).toString() : undefined
  )

  const handleChange = (nextValue: string) => {
    if (onChange && typeof onChange === 'function') onChange(nextValue)
  }

  const handleMaxChange = (nextValue: string) => {
    if (!isAdmin) return
    if (onMaxChange && typeof onMaxChange === 'function') onMaxChange(nextValue)
  }

  const handleNoteInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const nextValue = event.currentTarget.value
    setNoteRoundValue(nextValue ?? undefined)
  }

  const handleMaxInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!isAdmin) return
    const nextValue = event.currentTarget.value
    setMaxRoundValue(nextValue ?? undefined)
  }

  const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
    const nextValue = event.currentTarget.value
    const rounded = nextValue && nextValue !== '0'
      ? roundNumber(nextValue).toString()
      : nextValue
    setIsFocused(false)
    setNoteRoundValue(rounded)
    handleChange(rounded)
  }

  const handleMaxBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (!isAdmin) return
    const nextValue = event.currentTarget.value
    const rounded = nextValue && nextValue !== '0'
      ? roundNumber(nextValue).toString()
      : nextValue
    setIsFocused(false)
    setMaxRoundValue(rounded)
    handleMaxChange(rounded)
    if (!nextValue && formRef && formRef.current) formRef.current.reset()
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === 'ArrowDown' ||
      event.key === 'ArrowUp'
    ) {
      event.preventDefault()
    }
  }

  const content = isEditActive && !isEditDisabled
    ? (
      <>
        <form ref={ formRef } onSubmit={ event => event.preventDefault() }>
          <input
            className={
              classnames('note-input', {
                invalid: (note !== null && max !== null && note > max)
                  || max === null && note !== null
                  || note && note < 0
              })
            }
            value={ noteRoundValue }
            // defaultValue={ note !== null ? roundNumber(note) : '' }
            type="number"
            placeholder="-"
            min={ 0 }
            max={ max ?? undefined }
            onChange={ handleNoteInputChange }
            onFocus={ () => setIsFocused(true) }
            onBlur={ handleBlur }
            onKeyDown={ handleKeyDown }
            tabIndex={ Number(`${ tabIndex }0`) }
            disabled={ !max }
            step="0.5"
          />
        </form>
        <span>/</span>
        <input
          className={ classnames('max-input', { invalid: max && max < 0 }) }
          // defaultValue={ max !== null ? max : '' }
          type="text"
          placeholder="-"
          min={ 0 }
          value={ maxRoundValue }
          onChange={ handleMaxInputChange }
          onFocus={ () => setIsFocused(true) }
          onBlur={ handleMaxBlur }
          onKeyDown={ handleKeyDown }
          tabIndex={ isAdmin ? Number(`${ tabIndex }1`) : -1 }
          readOnly={ !isAdmin }
          step="0.5"
        />
      </>
    ) : (
      <>
        <span>{ note || note === 0 ? Math.round((note + Number.EPSILON) * 100) / 100 : '-' }</span>
        <span>/ { max ?? '-' }</span>
      </>
    )

  return (
    <StyledP className={ classnames({ edit: isEditActive && !isEditDisabled }, { focused: isFocused }) }>
      { content }
    </StyledP>
  )
}
