export const literals = {
  de: {
    de: 'Deutsch',
    fr: 'Französisch',
    it: 'Italienisch',
    en: 'Englisch',
    language: 'Sprache',
    errorSigninIn: 'Benutzername oder Passwort inkorrekt',
    passwordMaxChars: 'Das Passwort muss mindens 4 Zeichen haben.',
    placeholderUsername: 'Benutzername eingeben',
    placeholderPassword: 'Passwort eingeben',
    placeholderAccessCode: 'Zugangscode eingeben',
    login: 'Login',
    password: 'Passwort',
    teachers: 'Lehrer',
    addTeacher: 'Lehrer hinzufügen',
    search: 'Suche',
    removeTeacherConfirmation: 'Möchtest du diesen Lehrer löschen?',
    removeStudentConfirmation: 'Möchtest du diesen Schüler löschen?',
    removeClassConfirmation: 'Möchtest du diese Klasse löschen?',
    removeSubjectConfirmation: 'Möchtest du dieses Fach löschen?',
    removeReferenceGradeConfirmation: 'Möchtest du dieses Normwerte löschen?',
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
    save: 'Speichern',
    delete: 'Löschen',
    students: 'Schüler',
    classes: 'Klassen',
    dashboard: 'Dashboard',
    settings: 'Einstellungen',
    fullName: 'Name',
    userName: 'Benutzername',
    email: 'E-Mail',
    addNewTitle: 'Hinzufügen',
    editTitle: 'Bearbeiten',
    student: 'Schüler',
    addNewStudent: 'Schüler hinzufügen',
    teacher: 'Lehrer',
    class: 'Klasse',
    genericErrorListFetch: 'Fehler beim Landen der Liste',
    genericErrorCreate: 'Fehler beim Erstellen',
    genericErrorUpdate: 'Fehler beim Aktualisieren',
    genericErrorDelete: 'Fehler beim Löschen',
    genericErrorFetch: 'Fehler beim Laden',
    created: 'Erfolgreich erstellt',
    updated: 'Erfolgreich aktualisiert',
    deleted: 'Erfolgreich gelöscht',
    addNewClass: 'Neue Klasse erstellen',
    updateClassesList: 'Klassen aktualisieren',
    addToClassesList: 'Zu  Klassen hinzufügen',
    updateClass: 'Klassen aktualisieren',
    saveClass: 'Klasse speichern',
    select: 'Auswählen',
    labelClassName: 'Name',
    placeholderClassName: 'Klassenname eingeben',
    classCodePlaceholder: 'Kürzel eingeben',
    classYearPlaceholder: 'Jahr auswählen',
    classSectionPlaceholder: 'Abteilung auswählen',
    classSubSectionPlaceholder: 'Unterabteilung auswählen',
    section: 'Abteilung',
    sections: 'Abteilungen',
    subSection: 'Unterabteilung',
    labelFirstName: 'Vorname',
    placeholderFirstName: 'Vornamen eingeben',
    labelLastName: 'Nachname',
    placeholderLastName: 'Nachname eingeben',
    labelIdentificationNumber: 'Matrikulation',
    placeholderIdentificationNumber: 'Matrikulation eingeben',
    placeholderUserName: 'Kürzel eingeben',
    placeholderYourEmail: 'E-Mail eingeben',
    labelPassword: 'Passwort',
    lableIdentificationNumber: 'Matrikulation eingeben',
    classLeaders: 'Klassenleiter',
    selectAll: 'Alle auswählen',
    unselectAll: 'Alle abwählen',
    code: 'Kürzel',
    name: 'Name',
    year: 'Jahr',
    subject: 'Fach',
    subjects: 'Fächer',
    addNewSubject: 'Neues Fach erstellen',
    rootType: 'Gemeinsame Ausbildung',
    electiveType: 'Wahlfach',
    qualificationType: 'Qualifikationsprüfung',
    internshipType: 'Praktika',
    classType: 'Wahlfächer',
    gradeTemplateItems: 'Maximalnoten',
    maxGrade: 'Maximalnote',
    competencies: 'Kompetenzen',
    addCompetency: 'Kompetenz hinzufügen',
    saveSubject: 'Fach speichern',
    updateSubject: 'Fach aktualisieren',
    gradeTemplate: 'Maximalnote',
    first: '1. Periode',
    second: '2. Periode',
    examFirstSemester: 'Prüfung 1. Semester',
    third: '3. Periode',
    fourth: '4. Periode',
    examSecondSemester: 'Prüfung 2. Semester',
    subjectType: 'Fachtyp',
    subjectParent: 'Fachdefinition',
    subjectParents: 'Fachdefinitionen',
    hoursPerWeek: 'Stundenanzahl',
    placeholderSubjectCode: 'Kürzel eingeben',
    placeholderSubjectName: 'Fachname eingeben',
    placeholderSubjectYear: 'Jahr auswählen',
    placeholderSubjectType: 'Fachtyp auswählen',
    placeholderCompetencyName: 'Kompetenznamen eingeben',
    placeholderSubjectParent: 'Fach auswählen',
    gradeInput: 'Noteneingabe',
    choose: 'Auswählen',
    choosePlaceholder: 'Auswählen...',
    chooseClassPlaceholder: 'Klasse auswählen',
    searchStudent: 'Schüler suchen',
    editMode: 'Eingabe aktivieren',
    grades: 'Noten',
    observations: 'Bemerkungen',
    firstSemester: '1. Semester',
    secondSemester: '2. Semester',
    firstPeriod: '1. Periode',
    indicationPeriod: 'Hinweisnote',
    secondPeriod: '2. Periode',
    exam: 'Prüfung',
    total: 'Total',
    gradeConsultation: 'Noteneinsicht',
    mandatorySubjects: 'Gemeinsame Ausbildung',
    electiveSubjects: 'Wahlfach',
    qualificationSubjects: 'Qualifikationsprüfung',
    internshipSubjects: 'Praktika',
    hours: 'Stunden',
    allSaints: 'Allerheiligen',
    christmas: 'Weihnachten',
    eastern: 'Ostern',
    summer: 'Sommer',
    createPdfClass: 'Klassenzeugnis generieren',
    createPdfStudent: 'Schülerzeugnis generieren',
    disableIndicationReport: 'Hinweiszeugnis deaktivieren',
    comments: 'Kommentare',
    sorting: 'Reihenfolge',
    admin: 'Admin',
    editPassword: 'Passwort ändern',
    updatePassword: 'Passwort aktualisieren',
    passwordUpdated: 'Passwort aktualisiert',
    errorUpdatePassword: 'Fehler beim Passwort aktualisieren',
    referenceGrades: 'Normwerte',
    referenceGrade: 'Normwert',
    addNewReferenceGrade: 'Neue Normwert',
    view: 'Anzeige',
    reportComments: 'Zeugniskommentare',
    finalDecision: 'Entscheidung',
    finalDecisionJustification: 'Begründung',
    certificateCompetenceDecision: 'Entscheidung Befähigungsnachweis',
    certificateCompetenceJustification: 'Befähigungsnachweis Begründung',
    additionalExams: 'Nachprüfungen und Ferienarbeiten',
    absentDaysJustified: 'Ärztlich belegte Fehltage',
    absentDaysUnjustified: 'Nicht ärztlich belegte Fehltage',
    saveComments: 'Kommentare speichern',
    homework: 'Ferienarbeit',
    examHomework: 'Ferienarbeit/Nachprüfung',
    subjectName: 'Fach',
    date: 'Datum',
    addExamHomework: 'Ferienarbeit/Nachprüfung hinzufügen',
    additionalExam: 'Nachprüfung',
    sampleTexts: 'Mustertexte',
    addNewSampleText: 'Neuen Mustertext hinzufügen',
    sampleText: 'Mustertext',
    removeSampleTextConfirmation: 'Möchten Sie diesen Mustertext entfernen?',
    text: 'Text',
    saveSampleText: 'Mustertext speichern',
    updateSampleText: 'Mustertext aktualisieren',
    min60Percent: 'Min. 60%',
    otherSettings: 'Diverses',
    deleteQuestion: 'Möchten Sie alle Daten löschen?',
    deleteConfirm: 'Ja, alle löschen',
    deleteAllData: 'Alle Daten löschen',
    deleteData: 'Daten löschen',
    disable: 'Deaktivieren',
    importStudents: 'Schüler importieren',
    totalImported: 'Insgesamt importiert',
    errors: 'Fehler',
    warnings: 'Warnungen',
    file: 'Datei',
    close: 'Schließen',
    import: 'Importieren',
    studentsImportError: 'Fehler beim Importieren der Schüler',
    importedSummary: 'Importierte Zusammenfassung',
    generateReport: 'Zeugnis generieren',
    title: 'Titel',
    titlePlaceholder: 'Geben Sie den Titel des Berichts ein',
    scholarYear: 'Schuljahr',
    scholarYearPlaceholder: 'Bericht Schuljahr einführen',
    signatures: 'Unterschriften',
    finalDecisionPlaceholder: 'Führen Sie die endgültige Entscheidung ein',
    showDecision: 'Endgültige Entscheidung anzeigen',
    showClassLeaderSignature: 'Unterschrift des Klassenleiters anzeigen',
    showAdminSignature: 'Unterschrift des Schulleiters anzeigen',
    showParentSignature: 'Unterschrift des Erziehungsberechtigten anzeigen',
    titleReport: 'Zeugnis',
    titleReportComments: 'Juni',
    titleReportCompetencies: 'Details der Fachnoten und Bemerkungen der Fachlehrer und des Klassenrates',
    titleFinalDecision: 'Titel der endgültigen Entscheidung und Begründung',
    titleFinalDecisionDefault: 'Begründung der Entscheidung des Klassenrates zum Studienzeugnis',
    titleCertificateOfCompetence: 'Titel der Entscheidung zum Befähigungsnachweis und Begründung',
    titleCertificateOfCompetenceDefault: 'Begründung der Entscheidung des Klassenrates zum Befähigungsnachweis',
    showFinalDecisionJustification: 'Begründung der endgültigen Entscheidung anzeigen',
    showCertificateOfCompetenceJustification: 'Kompetenzzertifikat anzeigen Begründung',
    subjectDisabledOrDeleted: 'Betreff gelöscht / deaktiviert',
    subjectDisableDeleteError: 'Fehler beim Löschen / Deaktivieren des Betreffs',
    createOverviewReport: 'Übersichtsbericht generieren',
    certificateAppendix: 'Zeugnis Anhang',
    competenciesAppendix: 'Kompetenzblatt / Bemerkungen',
    createSubjectParent: 'Fachdefinition erstellen',
    saveSubjectParent: 'Fachdefinition speichern',
    updateSubjectParent: 'Fachdefinition bearbeiten',
    placeholderSubjectSorting: 'Reihenfolge eingeben',
    createReferenceGrade: 'Normwert erstellen',
    editReferenceGrade: 'Normwert bearbeiten',
    saveReferenceGrade: 'Normwert speichern',
    isAdministrativeSubject: 'Klassenratsfach',
    accessCode: 'Zugangscode',
    savePassword: 'Passwort speichern',
    sessionExpired: 'Sitzung abgelaufen',
    selectCommentsPeriod: 'Periode auswählen',
    commentsSaved: 'Kommentare gespeichert',
    commentsSaveError: 'Fehler beim Speichern der Kommentare. Bitte versuche es noch einmal',
    ok: 'Okay',
    modifiedMaxWarning: 'Die Maxima für bestehende Noten bitte in der Noteneingabe manuell anpassen.'
  },
  fr: {
    de: 'Allemand',
    fr: 'Français',
    it: 'Italien',
    en: 'Anglais',
    language: 'Langue',
    errorSigninIn: 'Error Signing in',
    passwordMaxChars: 'Password should be at least four characters',
    placeholderUsername: 'Introduce your username',
    placeholderPassword: 'Introduce your password',
    placeholderAccessCode: 'Introduce application password - FR',
    login: 'Log In',
    password: 'Password - FR',
    teachers: 'Teachers',
    addTeacher: 'Add new teacher',
    search: 'Search',
    removeTeacherConfirmation: 'Do you want to remove this teacher?',
    removeStudentConfirmation: 'Do you want to remove this student?',
    removeClassConfirmation: 'Do you want to remove this class?',
    removeSubjectConfirmation: 'Do you want to remove this subject?',
    removeReferenceGradeConfirmation: 'Do you want to remove this reference grade?',
    cancel: 'Cancel',
    confirm: 'Confirm',
    save: 'Save',
    delete: 'Delete',
    students: 'Students',
    classes: 'Classes',
    dashboard: 'Dashboard',
    settings: 'Settings',
    fullName: 'Full Name',
    userName: 'User Name',
    email: 'Email',
    addNewTitle: 'Add new',
    editTitle: 'Edit',
    student: 'Student',
    addNewStudent: 'Add new student - FR',
    teacher: 'Teacher',
    class: 'Class',
    genericErrorListFetch: 'Error loading list, please try again',
    genericErrorCreate: 'Error creating',
    genericErrorUpdate: 'Error updating',
    genericErrorDelete: 'Error deleting',
    genericErrorFetch: 'Couldn\'t get user',
    created: 'Created successfully',
    updated: 'Updated successfully',
    deleted: 'Deleted successfully',
    addNewClass: 'Add New Class',
    updateClassesList: 'Update classes list',
    addToClassesList: 'Add to classes list',
    updateClass: 'Update Class',
    saveClass: 'Save Class',
    select: 'Select',
    labelClassName: 'Class Name',
    placeholderClassName: 'Introduce a class name - FR',
    classCodePlaceholder: 'Introduce a class code - FR',
    classYearPlaceholder: 'Select class year - FR',
    classSectionPlaceholder: 'Select class section - FR',
    classSubSectionPlaceholder: 'Select class sub section - FR',
    section: 'Section - FR',
    sections: 'Sections - FR',
    subSection: 'SubSection - FR',
    labelFirstName: 'First Name',
    placeholderFirstName: 'Introduce your first name',
    labelLastName: 'Last Name',
    placeholderLastName: 'Introduce your last name',
    labelIdentificationNumber: 'Identification Number',
    placeholderIdentificationNumber: 'Introduce your identification number',
    placeholderUserName: 'Introduce your user name',
    placeholderYourEmail: 'Introduce your email',
    labelPassword: 'Password',
    lableIdentificationNumber: 'Identification Number - FR',
    classLeaders: 'Class leaders - FR',
    selectAll: 'Select All - FR',
    unselectAll: 'Unselect All - FR',
    code: 'Code - FR',
    name: 'Name - FR',
    year: 'Year - FR',
    subject: 'Subject - FR',
    subjects: 'Subjects - FR',
    addNewSubject: 'Add New Subject - FR',
    rootType: 'Root type - FR',
    electiveType: 'Elective - FR',
    qualificationType: 'Qualification - FR',
    internshipType: 'Internship - FR',
    classType: 'Class type - FR',
    gradeTemplateItems: 'Grade template Items - FR',
    maxGrade: 'Max Grade - FR',
    competencies: 'Competencies - FR',
    addCompetency: 'Add Competency - FR',
    saveSubject: 'Save Subject - FR',
    updateSubject: 'Update Subject - FR',
    gradeTemplate: 'Grade template - FR',
    first: 'First - FR',
    second: 'Second - FR',
    examFirstSemester: 'Exam First Semester - FR',
    third: 'Third - FR',
    fourth: 'Fourth - FR',
    examSecondSemester: 'Exam Second Semester - FR',
    subjectType: 'Subject Type - FR',
    subjectParent: 'Subject Parent - FR',
    subjectParents: 'Subject Parents - FR',
    hoursPerWeek: 'Hours per week - FR',
    placeholderSubjectCode: 'Introduce a subject code - FR',
    placeholderSubjectName: 'Introduce a subject name - FR',
    placeholderSubjectYear: 'Select subject year - FR',
    placeholderSubjectType: 'Select subject type - FR',
    placeholderCompetencyName: 'Introduce a competency name - FR',
    placeholderSubjectParent: 'Select subject parent - FR',
    gradeInput: 'Grade Input - FR',
    choose: 'Choose - FR',
    choosePlaceholder: 'Choose... - FR',
    chooseClassPlaceholder: 'Choose class - FR',
    searchStudent: 'Search student - FR',
    editMode: 'Edit-Modus - FR',
    grades: 'Grades - FR',
    observations: 'Observations - FR',
    firstSemester: '1. Semester - FR',
    secondSemester: '2. Semester - FR',
    firstPeriod: '1. period - FR',
    secondPeriod: '2. period - FR',
    exam: 'Exam - FR',
    total: 'Total - FR',
    indicationPeriod: 'Indication grade - FR',
    gradeConsultation: 'Grade Consultation - FR',
    mandatorySubjects: 'Mandatory subjects - FR',
    electiveSubjects: 'Elective subjects - FR',
    qualificationSubjects: 'Qualification subjects - FR',
    internshipSubjects: 'Internship subjects - FR',
    hours: 'Hours - FR',
    allSaints: 'All saints - FR',
    christmas: 'Christmas - FR',
    eastern: 'Eastern - FR',
    summer: 'Summer - FR',
    createPdfClass: 'Create PDF class - FR',
    createPdfStudent: 'Create PDF student - FR',
    disableIndicationReport: 'Disable indication report - FR',
    comments: 'Comments - FR',
    sorting: 'Sorting - FR',
    admin: 'Admin - FR',
    editPassword: 'Edit Password-FR',
    updatePassword: 'Update password - FR',
    passwordUpdated: 'Password updated - FR',
    errorUpdatePassword: 'Error updating password - FR',
    referenceGrades: 'Reference Grades - FR',
    referenceGrade: 'Reference Grade - FR',
    addNewReferenceGrade: 'Add New Reference grade - FR',
    view: 'View - FR',
    reportComments: 'Report comments - FR',
    finalDecision: 'Final decision - FR',
    finalDecisionJustification: 'Final decision justification - FR',
    certificateCompetenceDecision: 'Certificate of competence decision - FR',
    certificateCompetenceJustification: 'Certificate of competence justification - FR',
    additionalExams: 'Additional exams - FR',
    absentDaysJustified: 'Absent days justified - FR',
    absentDaysUnjustified: 'Absent days unjustified - FR',
    saveComments: 'Save comments - FR',
    homework: 'Homework - FR',
    examHomework: 'Exam/Homework - FR',
    subjectName: 'Subject name - FR',
    date: 'Date - FR',
    addExamHomework: 'Add Exam/Homework - FR',
    additionalExam: 'Additional exam - FR',
    sampleTexts: 'Sample Texts - FR',
    addNewSampleText: 'Add new Sample Text - FR',
    sampleText: 'Sample Text - FR',
    removeSampleTextConfirmation: 'Do you want to remove this sample text? - FR',
    text: 'Text - FR',
    saveSampleText: 'Save Sample Text - FR',
    updateSampleText: 'Update Sample Text - FR',
    min60Percent: 'Min. 60% - FR',
    otherSettings: 'Other settings - FR',
    deleteQuestion: 'Do you want to delete all data? - FR',
    deleteConfirm: 'Yes, delete all - FR',
    deleteAllData: 'Delete all data - FR',
    deleteData: 'Delete data - FR',
    disable: 'Disable - FR',
    importStudents: 'Schüler importieren - FR',
    totalImported: 'Insgesamt importiert - FR',
    errors: 'Fehler - FR',
    warnings: 'Warnungen - FR',
    file: 'Datei - FR',
    close: 'Schließen - FR',
    import: 'Importieren - FR',
    studentsImportError: 'Fehler beim Importieren der Schüler - FR',
    importedSummary: 'Imported summary - FR',
    generateReport: 'Generate report - FR',
    title: 'Title - FR',
    titlePlaceholder: 'Introduce report title - FR',
    schoolarYear: 'Schoolar year - FR',
    schoolarYearPlaceholder: 'Introcude report schoolar year - FR',
    signatures: 'Signatures - FR',
    finalDecisionPlaceholder: 'Introduce the final decision - FR',
    showDecision: 'Show final decision - FR',
    showClassLeaderSignature: 'Show class leader signature - FR',
    showAdminSignature: 'Show admin signature - FR',
    showParentSignature: 'Show parent signature - FR',
    titleReport: 'Certificate - FR',
    titleReportComments: 'June - FR',
    titleReportCompetencies: 'Competence Sheet - FR',
    titleFinalDecision: 'Title of Final decision justification - FR',
    titleFinalDecisionDefault: 'Reason for the decision of the class council on the certificate of study - FR',
    titleCertificateOfCompetence: 'Title of Certificate of competence justification - FR',
    // eslint-disable-next-line max-len
    titleCertificateOfCompetenceDefault: 'Justification for the decision of the class council on the certificate of proficiency - FR',
    showFinalDecisionJustification: 'Show Final decision justification - FR',
    showCertificateOfCompetenceJustification: 'Show Certificate of competence Justification - FR',
    subjectDisabledOrDeleted: 'Subject Deleted/Disabled - FR',
    subjectDisableDeleteError: 'Error Deleting/Disabling subject - FR',
    createOverviewReport: 'Create overview report - FR',
    certificateAppendix: 'Certificate appendix - FR',
    competenciesAppendix: 'Competency sheet / comments - FR',
    createSubjectParent: 'Create Subject parent - FR',
    saveSubjectParent: 'Save Subject parent - FR',
    updateSubjectParent: 'Update Subject parent - FR',
    placeholderSubjectSorting: 'Enter subject order - FR',
    createReferenceGrade: 'Create reference grade - FR',
    editReferenceGrade: 'Edit reference grade - FR',
    saveReferenceGrade: 'Save reference grade - FR',
    isAdministrativeSubject: 'Administrative subject - FR',
    accessCode: 'Access code - FR',
    savePassword: 'Save password - FR',
    sessionExpired: 'Session expired - FR',
    selectCommentsPeriod: 'Select comments period - FR',
    commentsSaved: 'Comments saved - FR',
    commentsSaveError: 'Error saving comments, please try again - FR',
    ok: 'Okay - FR',
    modifiedMaxWarning: 'Please adjust the maxima for existing grades manually when entering grades. - FR'
  },
  it: {
    de: 'Tedesco',
    fr: 'Francese',
    it: 'Italiano',
    en: 'Inglese',
    language: 'Linguaggio',
    errorSigninIn: 'Error Signing in',
    passwordMaxChars: 'Password should be at least four characters',
    placeholderUsername: 'Introduce your username',
    placeholderPassword: 'Introduce your password',
    placeholderAccessCode: 'Introduce application password - IT',
    login: 'Log In',
    password: 'Password - IT',
    teachers: 'Teachers',
    addTeacher: 'Add new teacher',
    search: 'Search',
    removeTeacherConfirmation: 'Do you want to remove this teacher?',
    removeStudentConfirmation: 'Do you want to remove this student?',
    removeClassConfirmation: 'Do you want to remove this class?',
    removeSubjectConfirmation: 'Do you want to remove this subject?',
    removeReferenceGradeConfirmation: 'Do you want to remove this reference grade? - IT',
    cancel: 'Cancel',
    confirm: 'Confirm',
    save: 'Save',
    delete: 'Delete',
    students: 'Students',
    classes: 'Classes',
    dashboard: 'Dashboard',
    settings: 'Settings',
    fullName: 'Full Name',
    userName: 'User Name',
    email: 'Email',
    addNewTitle: 'Add new',
    editTitle: 'Edit',
    student: 'Student',
    addNewStudent: 'Add new student - IT',
    teacher: 'Teacher',
    class: 'Class',
    genericErrorListFetch: 'Error loading list, please try again',
    genericErrorCreate: 'Error creating',
    genericErrorUpdate: 'Error updating',
    genericErrorDelete: 'Error deleting',
    genericErrorFetch: 'Couldn\'t get user',
    created: 'Created successfully',
    updated: 'Updated successfully',
    deleted: 'Deleted successfully',
    addNewClass: 'Add New Class',
    updateClassesList: 'Update classes list',
    addToClassesList: 'Add to classes list',
    updateClass: 'Update Class',
    saveClass: 'Save Class',
    select: 'Select',
    labelClassName: 'Class Name',
    placeholderClassName: 'Introduce a class name - IT',
    classCodePlaceholder: 'Introduce a class code - IT',
    classYearPlaceholder: 'Select class year - IT',
    classSectionPlaceholder: 'Select class section - IT',
    classSubSectionPlaceholder: 'Select class sub section',
    section: 'Section - IT',
    sections: 'Sections - IT',
    subSection: 'SubSection - IT',
    labelFirstName: 'First Name',
    placeholderFirstName: 'Introduce your first name',
    labelLastName: 'Last Name',
    placeholderLastName: 'Introduce your last name',
    labelIdentificationNumber: 'Identification Number',
    placeholderIdentificationNumber: 'Introduce your identification number',
    placeholderUserName: 'Introduce your user name',
    placeholderYourEmail: 'Introduce your email',
    labelPassword: 'Password',
    lableIdentificationNumber: 'Identification Number - IT',
    classLeaders: 'Class leaders - IT',
    selectAll: 'Select All - IT',
    unselectAll: 'Unselect All - IT',
    code: 'Code - IT',
    name: 'Name - IT',
    year: 'Year - IT',
    subject: 'Subject - IT',
    subjects: 'Subjects - IT',
    addNewSubject: 'Add New Subject - IT',
    rootType: 'Root type - IT',
    electiveType: 'Elective - IT',
    qualificationType: 'Qualification - IT',
    internshipType: 'Internship - IT',
    classType: 'Class type - IT',
    gradeTemplateItems: 'Grade template Items - IT',
    maxGrade: 'Max Grade - IT',
    competencies: 'Competencies - IT',
    addCompetency: 'Add Competency - IT',
    saveSubject: 'Save Subject - IT',
    updateSubject: 'Update Subject - IT',
    gradeTemplate: 'Grade template - IT',
    first: 'First - IT',
    second: 'Second - IT',
    examFirstSemester: 'Exam First Semester- IT',
    third: 'Third - IT',
    fourth: 'Fourth - IT',
    examSecondSemester: 'Exam Second Semester- IT',
    subjectType: 'Subject Type - IT',
    subjectParent: 'Subject Parent - IT',
    subjectParents: 'Subject Parents - IT',
    hoursPerWeek: 'Hours per week - IT',
    placeholderSubjectCode: 'Introduce a subject code - IT',
    placeholderSubjectName: 'Introduce a subject name - IT',
    placeholderSubjectYear: 'Select subject year - IT',
    placeholderSubjectType: 'Select subject type - IT',
    placeholderCompetencyName: 'Introduce a competency name - IT',
    placeholderSubjectParent: 'Select subject parent - IT',
    gradeInput: 'Grade Input - IT',
    choose: 'Choose - IT',
    choosePlaceholder: 'Choose... - IT',
    chooseClassPlaceholder: 'Choose class - IT',
    searchStudent: 'Search student - IT',
    editMode: 'Edit-Modus - IT',
    grades: 'Grades - IT',
    observations: 'Observations - IT',
    firstSemester: '1. Semester - IT',
    secondSemester: '2. Semester - IT',
    firstPeriod: '1. period - IT',
    secondPeriod: '2. period - IT',
    exam: 'Exam - IT',
    total: 'Total - IT',
    indicationPeriod: 'Indication grade - IT',
    gradeConsultation: 'Grade Consultation - IT',
    mandatorySubjects: 'Mandatory subjects - IT',
    electiveSubjects: 'Elective subjects - IT',
    qualificationSubjects: 'Qualification subjects - IT',
    internshipSubjects: 'Internship subjects - IT',
    hours: 'Hours - IT',
    allSaints: 'All saints - IT',
    christmas: 'Christmas - IT',
    eastern: 'Eastern - IT',
    summer: 'Summer - IT',
    createPdfClass: 'Create PDF class - IT',
    createPdfStudent: 'Create PDF student - IT',
    disableIndicationReport: 'Disable indication report - IT',
    comments: 'Comments - IT',
    sorting: 'Sorting - IT',
    admin: 'Admin - IT',
    editPassword: 'Edit Password - IT',
    updatePassword: 'Update password - IT',
    passwordUpdated: 'Password updated - IT',
    errorUpdatePassword: 'Error updating password - IT',
    referenceGrades: 'Reference Grades - IT',
    referenceGrade: 'Reference Grade - IT',
    addNewReferenceGrade: 'Add New Reference grade - IT',
    view: 'View - IT',
    reportComments: 'Report comments - IT',
    finalDecision: 'Final decision - IT',
    finalDecisionJustification: 'Final decision justification - IT',
    certificateCompetenceDecision: 'Certificate of competence decision - IT',
    certificateCompetenceJustification: 'Certificate of competence justification - IT',
    additionalExams: 'Additional exams - IT',
    absentDaysJustified: 'Absent days justified - IT',
    absentDaysUnjustified: 'Absent days unjustified - IT',
    saveComments: 'Save comments - IT',
    homework: 'Homework - IT',
    examHomework: 'Exam/Homework - IT',
    subjectName: 'Subject name - IT',
    date: 'Date - IT',
    addExamHomework: 'Add Exam/Homework - IT',
    additionalExam: 'Additional exam - IT',
    sampleTexts: 'Sample Texts - IT',
    addNewSampleText: 'Add new Sample Text - IT',
    sampleText: 'Sample Text - IT',
    removeSampleTextConfirmation: 'Do you want to remove this sample text? - IT',
    text: 'Text - IT',
    saveSampleText: 'Save Sample Text - IT',
    updateSampleText: 'Update Sample Text - IT',
    min60Percent: 'Min. 60% - IT',
    otherSettings: 'Other settings - IT',
    deleteQuestion: 'Do you want to delete all data? - IT',
    deleteConfirm: 'Yes, delete all - IT',
    deleteAllData: 'Delete all data - IT',
    deleteData: 'Delete data - IT',
    disable: 'Deaktivieren - IT',
    importStudents: 'Import students - IT',
    totalImported: 'Total imported - IT',
    errors: 'Errors - IT',
    warnings: 'Warnings - IT',
    file: 'File - IT',
    close: 'Close - IT',
    import: 'Import - IT',
    studentsImportError: 'Error importing students - IT',
    importedSummary: 'Imported summary - IT',
    generateReport: 'Generate report - IT',
    title: 'Title - IT',
    titlePlaceholder: 'Introduce report title - IT',
    schoolarYear: 'Schoolar year - IT',
    schoolarYearPlaceholder: 'Introcude report schoolar year - IT',
    signatures: 'Signatures - IT',
    finalDecisionPlaceholder: 'Introduce the final decision - IT',
    showDecision: 'Show final decision - IT',
    showClassLeaderSignature: 'Show class leader signature - IT',
    showAdminSignature: 'Show admin signature - IT',
    showParentSignature: 'Show parent signature - IT',
    titleReport: 'Certificate - IT',
    titleReportComments: 'June 2020 - IT',
    titleReportCompetencies: 'Competence Sheet - IT',
    titleFinalDecision: 'Title of Final decision justification - IT',
    titleFinalDecisionDefault: 'Reason for the decision of the class council on the certificate of study - IT',
    titleCertificateOfCompetence: 'Title of Certificate of competence justification - IT',
    // eslint-disable-next-line max-len
    titleCertificateOfCompetenceDefault: 'Justification for the decision of the class council on the certificate of proficiency - IT',
    showFinalDecisionJustification: 'Show Final decision justification - IT',
    showCertificateOfCompetenceJustification: 'Show Certificate of competence Justification - IT',
    subjectDisabledOrDeleted: 'Subject Deleted/Disabled - IT',
    subjectDisableDeleteError: 'Error Deleting/Disabling subject - IT',
    createOverviewReport: 'Create overview report - IT',
    certificateAppendix: 'Certificate appendix - IT',
    competenciesAppendix: 'Competency sheet / comments - IT',
    createSubjectParent: 'Create Subject parent - IT',
    saveSubjectParent: 'Save Subject parent - IT',
    updateSubjectParent: 'Update Subject parent - IT',
    placeholderSubjectSorting: 'Enter subject order - IT',
    createReferenceGrade: 'Create reference grade - IT',
    editReferenceGrade: 'Edit reference grade - IT',
    saveReferenceGrade: 'Save reference grade - IT',
    isAdministrativeSubject: 'Administrative subject - IT',
    accessCode: 'Access code - IT',
    savePassword: 'Save password - IT',
    sessionExpired: 'Session expired - IT',
    selectCommentsPeriod: 'Select comments period - IT',
    commentsSaved: 'Comments saved - IT',
    commentsSaveError: 'Error saving comments, please try again - IT',
    ok: 'Okay - IT',
    modifiedMaxWarning: 'Please adjust the maxima for existing grades manually when entering grades. - IT'
  },
  en: {
    de: 'German',
    fr: 'French',
    it: 'Italian',
    en: 'English',
    language: 'Language',
    errorSigninIn: 'Error Signing in',
    passwordMaxChars: 'Password should be at least four characters',
    placeholderUsername: 'Introduce your username',
    placeholderPassword: 'Introduce your password',
    placeholderAccessCode: 'Introduce application password',
    login: 'Log In',
    password: 'Password',
    teachers: 'Teachers',
    addTeacher: 'Add new teacher',
    search: 'Search',
    removeTeacherConfirmation: 'Do you want to remove this teacher?',
    removeStudentConfirmation: 'Do you want to remove this student?',
    removeClassConfirmation: 'Do you want to remove this class?',
    removeSubjectConfirmation: 'Do you want to remove this subject?',
    removeReferenceGradeConfirmation: 'Do you want to remove this reference grade?',
    cancel: 'Cancel',
    confirm: 'Confirm',
    save: 'Save',
    delete: 'Delete',
    students: 'Students',
    classes: 'Classes',
    dashboard: 'Dashboard',
    settings: 'Settings',
    fullName: 'Full Name',
    userName: 'User Name',
    email: 'Email',
    addNewTitle: 'Add new',
    editTitle: 'Edit',
    student: 'Student',
    addNewStudent: 'Add new student',
    teacher: 'Teacher',
    class: 'Class',
    genericErrorListFetch: 'Error loading list, please try again',
    genericErrorCreate: 'Error creating',
    genericErrorUpdate: 'Error updating',
    genericErrorDelete: 'Error deleting',
    genericErrorFetch: 'Couldn\'t get user',
    created: 'Created successfully',
    updated: 'Updated successfully',
    deleted: 'Deleted successfully',
    addNewClass: 'Add New Class',
    updateClassesList: 'Update classes list',
    addToClassesList: 'Add to classes list',
    updateClass: 'Update Class',
    saveClass: 'Save Class',
    select: 'Select',
    labelClassName: 'Class Name',
    placeholderClassName: 'Introduce a class name',
    classCodePlaceholder: 'Introduce a class code',
    classYearPlaceholder: 'Select class year',
    classSectionPlaceholder: 'Select class section',
    classSubSectionPlaceholder: 'Select class sub section',
    section: 'Section',
    sections: 'Sections',
    subSection: 'SubSection',
    labelFirstName: 'First Name',
    placeholderFirstName: 'Introduce your first name',
    labelLastName: 'Last Name',
    placeholderLastName: 'Introduce your last name',
    labelIdentificationNumber: 'Identification Number',
    placeholderIdentificationNumber: 'Introduce your identification number',
    placeholderUserName: 'Introduce your user name',
    placeholderYourEmail: 'Introduce your email',
    labelPassword: 'Password',
    lableIdentificationNumber: 'Identification Number',
    classLeaders: 'Class leaders',
    selectAll: 'Select All',
    unselectAll: 'Unselect All',
    code: 'Code',
    name: 'Name',
    year: 'Year',
    subject: 'Subject',
    subjects: 'Subjects',
    addNewSubject: 'Add New Subject',
    rootType: 'Root type',
    electiveType: 'Elective',
    qualificationType: 'Qualification',
    internshipType: 'Internship',
    classType: 'Class type',
    gradeTemplateItems: 'Grade template Items',
    maxGrade: 'Max grade',
    competencies: 'Competencies',
    addCompetency: 'Add Competency',
    saveSubject: 'Save Subject',
    updateSubject: 'Update Subject',
    gradeTemplate: 'Grade template',
    first: 'First',
    second: 'Second',
    examFirstSemester: 'Exam First Semester',
    third: 'Third',
    fourth: 'Fourth',
    examSecondSemester: 'Exam Second Semester',
    subjectType: 'Subject Type',
    subjectParent: 'Subject Parent',
    subjectParents: 'Subject Parents',
    hoursPerWeek: 'Hours per week',
    placeholderSubjectCode: 'Introduce a subject code',
    placeholderSubjectName: 'Introduce a subject name',
    placeholderSubjectYear: 'Select subject year',
    placeholderSubjectType: 'Select subject type',
    placeholderCompetencyName: 'Introduce a competency name',
    placeholderSubjectParent: 'Select subject parent',
    gradeInput: 'Grade Input',
    choose: 'Choose',
    choosePlaceholder: 'Choose...',
    chooseClassPlaceholder: 'Choose class',
    searchStudent: 'Search student',
    editMode: 'Edit-Modus',
    grades: 'Grades',
    observations: 'Observations',
    firstSemester: '1. Semester',
    secondSemester: '2. Semester',
    firstPeriod: '1. period',
    secondPeriod: '2. period',
    exam: 'Exam',
    total: 'Total',
    indicationPeriod: 'Indication grade',
    gradeConsultation: 'Grade Consultation',
    mandatorySubjects: 'Mandatory subjects',
    electiveSubjects: 'Elective subjects',
    qualificationSubjects: 'Qualification subjects',
    internshipSubjects: 'Internship subjects',
    hours: 'Hours',
    allSaints: 'All saints',
    christmas: 'Christmas',
    eastern: 'Eastern',
    summer: 'Summer',
    createPdfClass: 'Create PDF class',
    createPdfStudent: 'Create PDF student',
    disableIndicationReport: 'Disable indication report',
    comments: 'Comments',
    sorting: 'Sorting',
    admin: 'Admin',
    editPassword: 'Edit Password',
    updatePassword: 'Update password',
    passwordUpdated: 'Password updated',
    errorUpdatePassword: 'Error updating password',
    referenceGrades: 'Reference Grades',
    referenceGrade: 'Reference Grade',
    addNewReferenceGrade: 'Add New Reference grade',
    view: 'View',
    reportComments: 'Report comments',
    finalDecision: 'Final decision',
    finalDecisionJustification: 'Final decision justification',
    certificateCompetenceDecision: 'Certificate of competence decision',
    certificateCompetenceJustification: 'Certificate of competence justification',
    additionalExams: 'Additional exams',
    absentDaysJustified: 'Absent days justified',
    absentDaysUnjustified: 'Absent days unjustified',
    saveComments: 'Save comments',
    homework: 'Homework',
    examHomework: 'Exam/Homework',
    subjectName: 'Subject name',
    date: 'Date',
    addExamHomework: 'Add Exam/Homework',
    additionalExam: 'Additional exam',
    sampleTexts: 'Sample Texts',
    addNewSampleText: 'Add new Sample Text',
    sampleText: 'Sample Text',
    removeSampleTextConfirmation: 'Do you want to remove this sample text?',
    text: 'Text',
    saveSampleText: 'Save Sample Text',
    updateSampleText: 'Update Sample Text',
    min60Percent: 'Min. 60%',
    otherSettings: 'Other settings',
    deleteQuestion: 'Do you want to delete all data?',
    deleteConfirm: 'Yes, delete all',
    deleteAllData: 'Delete all data',
    deleteData: 'Delete data',
    disable: 'Disable',
    importStudents: 'Import students',
    totalImported: 'Total imported',
    errors: 'Errors',
    warnings: 'Warnings',
    file: 'File',
    close: 'Close',
    import: 'Import',
    studentsImportError: 'Error importing students',
    importedSummary: 'Imported summary',
    generateReport: 'Generate report',
    title: 'Title',
    titlePlaceholder: 'Introduce report title',
    schoolarYear: 'Schoolar year',
    schoolarYearPlaceholder: 'Introcude report schoolar year',
    signatures: 'Signatures',
    finalDecisionPlaceholder: 'Introduce the final decision',
    showDecision: 'Show final decision',
    showClassLeaderSignature: 'Show class leader signature',
    showAdminSignature: 'Show admin signature',
    showParentSignature: 'Show parent signature',
    titleReport: 'Certificate',
    titleReportComments: 'June',
    titleReportCompetencies: 'Competence Sheet',
    titleFinalDecision: 'Title of Final decision justification',
    titleFinalDecisionDefault: 'Reason for the decision of the class council on the certificate of study',
    titleCertificateOfCompetence: 'Title of Certificate of competence justification',
    // eslint-disable-next-line max-len
    titleCertificateOfCompetenceDefault: 'Justification for the decision of the class council on the certificate of proficiency',
    showFinalDecisionJustification: 'Show Final decision justification',
    showCertificateOfCompetenceJustification: 'Show Certificate of competence Justification',
    subjectDisabledOrDeleted: 'Subject Deleted/Disabled',
    subjectDisableDeleteError: 'Error Deleting/Disabling subject',
    createOverviewReport: 'Create overview report',
    certificateAppendix: 'Certificate appendix',
    competenciesAppendix: 'Competency sheet / comments',
    createSubjectParent: 'Create Subject parent',
    saveSubjectParent: 'Save Subject parent',
    updateSubjectParent: 'Update Subject parent',
    placeholderSubjectSorting: 'Enter subject order',
    createReferenceGrade: 'Create reference grade',
    editReferenceGrade: 'Edit reference grade',
    saveReferenceGrade: 'Save reference grade',
    isAdministrativeSubject: 'Administrative subject',
    accessCode: 'Access code',
    savePassword: 'Save password',
    sessionExpired: 'Session expired',
    selectCommentsPeriod: 'Select Period',
    commentsSaved: 'Comments saved',
    commentsSaveError: 'Error saving comments, please try again',
    ok: 'Okay',
    modifiedMaxWarning: 'Please adjust the maxima for existing grades manually when entering grades.'
  }
}
