import { Button, Icon, ITableColumn, ITableItem, Spinner } from '@valudio/ui'
import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { debounce } from 'ts-debounce'
import { CustomTable, ModalConfirm } from '../../../components'
import { SessionContext } from '../../../context'
import { useClasses, useStudents, useSubjectParents, useSubjects, useTeachers } from '../../../hooks'
import { initialDraftSubject, IStudentImportSummary, ISubjectDraft } from '../../../models'
import { DraftModal, ImportModal } from './components'
import StyledArticle from './styles'

const Subjects: React.FC = () => {
  const { formatMessage } = useIntl()
  const { id: deleteId, openModalConfirm, setOpenModalConfirm } = useContext(SessionContext)
  const { teachers, fetchTeachers } = useTeachers()
  const { students, fetchStudents } = useStudents()
  const { classes, fetchClasses } = useClasses()
  const {
    subjects,
    isFetching,
    editSubject,
    setEditSubject,
    isModalVisible,
    setIsModalVisible,
    isSubjectFetching,
    setIsSubjectFetching,
    fetchSubjects,
    searchSubjects,
    getSubjectById,
    getSubjectByIdForDuplicate,
    createSubject,
    updateSubject,
    deleteSubject,
    importSubjectStudents
  } = useSubjects()
  const { subjectParentOptions, fetchSubjectParents } = useSubjectParents()
  const [isImportModalVisible, setIsImportModalVisible] = useState(false)
  const [importSummary, setImportSummary] = useState<IStudentImportSummary>()
  const [isImporting, setIsImporting] = useState(false)
  const [isTableUpdated, setIsTableUpdated] = useState(false)
  const [editingSubjectId, setEditingSubjectId] = useState(0)

  const formatSubjectTypeLabel = (id: string) => {
    switch (id) {
    case '1':
      return formatMessage({ id: 'rootType' })
    case '2':
      return formatMessage({ id: 'electiveType' })
    case '3':
      return formatMessage({ id: 'qualificationType' })
    case '4':
      return formatMessage({ id: 'internshipType' })
    default:
      return formatMessage({ id: 'rootType' })
    }
  }

  const subjectsColumns: ITableColumn[] = [
    {
      key: 'id',
      label: '',
      style: {
        display: 'none'
      }
    },
    {
      key: 'year',
      label: formatMessage({ id: 'year' }),
      style: {
        flex: 0.4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'subjectParent',
      label: formatMessage({ id: 'subjectParent' }),
      style: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'hoursPerWeek',
      label: formatMessage({ id: 'hoursPerWeek' }),
      style: {
        flex: 0.5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'typeSubject',
      label: formatMessage({ id: 'subjectType' }),
      style: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'code',
      label: formatMessage({ id: 'code' }),
      style: {
        flex: 0.5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'isAdministrativeSubject',
      label: formatMessage({ id: 'isAdministrativeSubject' }),
      className: 'admin',
      style: {
        flex: 0.5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'teachers',
      label: formatMessage({ id: 'teachers' }),
      className: 'teachers',
      style: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexWrap: 'wrap'
      }
    },
    {
      key: 'status',
      label: '',
      style: {
        flex: 0.2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexBasis: '80px'
      }
    }
  ]
  const subjectItems: ITableItem[] = subjects.map(subject => ({
    ...subject,
    typeSubject: formatSubjectTypeLabel(subject.typeSubject),
    isAdministrativeSubject: subject.isAdministrativeSubject ? <Icon icon="check" className="check-flag" /> : null,
    teachers: subject.teachers.map(t => `${ t.firstName.charAt(0) }. ${ t.lastName }`).join(', ').trim(),
    status: isSubjectFetching && editingSubjectId && editingSubjectId === subject.id ? (
      <Spinner style={{ width: '30px', height: '30px', marginRight: '0' }} />
    ) : (
      <>
        <Button
          className="duplicate-button"
          isCircular
          onClick={() => handleDuplicateSubject(subject.id)}
          isDisabled={ isSubjectFetching }
        >
          <img src="/assets/images/duplicate.svg" alt="Duplicate"/>
        </Button>
        <Button
          isCircular
          onClick={() => handleOpenDraftModal(subject.id)}
          isDisabled={ isSubjectFetching }
        >
          <Icon className='icon' icon='edit' />
        </Button>
        <Button
          style={{ marginLeft: '0.5em' }}
          isCircular
          type='tertiary'
          isDisabled={ isSubjectFetching }
          onClick={() =>
            setOpenModalConfirm({ openModalConfirm: true, id: subject.id })
          }
        >
          <Icon className='icon' icon='delete' />
        </Button>
      </>
    )
  }))

  const handleSearch = async (query: string) => {
    if (query) await searchSubjects(query)
    else await fetchSubjects()
  }

  const debounceSearch = debounce(handleSearch, 400)

  const handleOpenDraftModal = async (subjectId?: number) => {
    setIsSubjectFetching(true)

    if (subjectId) {
      setEditingSubjectId(subjectId)
      handleSubjectEdit(subjectId)
    } else {
      await Promise.all([
        fetchTeachers(),
        fetchStudents(),
        fetchClasses(),
        fetchSubjectParents()
      ])
      setIsModalVisible(true)
      setIsSubjectFetching(false)
    }
  }

  const handleDuplicateSubject = async (subjectId: number) => {
    setIsSubjectFetching(true)
    setEditingSubjectId(subjectId)
    await Promise.all([
      fetchTeachers(),
      fetchStudents(),
      fetchClasses(),
      fetchSubjectParents(),
      getSubjectByIdForDuplicate(subjectId)
    ])
  }

  const handleModalClose = () => {
    setEditingSubjectId(0)
    setEditSubject(initialDraftSubject)
    setIsModalVisible(false)
  }

  const handleSubjectEdit = async (subjectId: number) => {
    await Promise.all([
      fetchTeachers(),
      fetchStudents(),
      fetchClasses(),
      fetchSubjectParents(),
      getSubjectById(subjectId)
    ])
  }

  const handleSubmit = async (subjectItem: ISubjectDraft) => {
    handleModalClose()

    if (editSubject.id) {
      await updateSubject(subjectItem)
    } else {
      await createSubject(subjectItem)
    }

    setIsTableUpdated(true)
  }

  const handleDeleteConfirm = async () => {
    await deleteSubject(deleteId)
    setIsTableUpdated(true)
  }

  const handleImportStudents = async (file: File) => {
    setIsImporting(true)

    try {
      const summary = await importSubjectStudents(file)
      if (summary) setImportSummary(summary)
    } catch (error) {
      setIsImportModalVisible(false)
      setImportSummary(undefined)
    } finally {
      setIsImporting(false)
    }
  }

  const handleCloseImportModal = () => {
    setIsImportModalVisible(false)
    setImportSummary(undefined)
  }

  useEffect(() => {
    if (!subjects.length) fetchSubjects()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledArticle>
      <CustomTable
        title={ formatMessage({ id: 'subjects' }) }
        columns={ subjectsColumns }
        items={ subjectItems }
        loading={ isFetching && !(openModalConfirm || isModalVisible) }
        setValueSearch={ debounceSearch }
        tableUpdated={ isTableUpdated }
        setTableUpdated={ setIsTableUpdated }
        buttonNew={
          <>
            <Button type="secondary" onClick={ () => setIsImportModalVisible(true) }>
              <Icon icon="share"/> { formatMessage({ id: 'importStudents' }) }
            </Button>
            <Button
              type='primary'
              style={{ marginLeft: '0.5rem' }}
              onClick={ handleOpenDraftModal }
              isLoading={ isSubjectFetching && !editingSubjectId }
            >
              <Icon icon='add' /> {formatMessage({ id: 'addNewSubject' })}
            </Button>
          </>
        }
      />
      <DraftModal
        item={ editSubject }
        students={ students }
        teachers={ teachers }
        classes={ classes }
        subjectParents={ subjectParentOptions }
        isHidden={ !isModalVisible }
        onClose={ handleModalClose }
        onSubmit={ handleSubmit }
        isCreatingOrUpdating={ isFetching }
      />
      <ModalConfirm
        title={ formatMessage({ id: 'subject' }) }
        message={ formatMessage({ id: 'removeSubjectConfirmation' }) }
        isHidden={ !openModalConfirm }
        isLoading={ isFetching }
        onClose={() => setOpenModalConfirm({ openModalConfirm: false, id: editSubject.id })}
        onConfirm={ handleDeleteConfirm }
      />
      <ImportModal
        summary={ importSummary }
        isHidden={ !isImportModalVisible }
        isImporting={ isImporting }
        onImportStudents={ handleImportStudents }
        onClose={ handleCloseImportModal }
      />
    </StyledArticle>
  )
}

export default Subjects
