import { Button, Snackbar, Spinner } from '@valudio/ui'
import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Input from '../../components/Input'
import { SessionContext } from '../../context'
import { useAuth } from '../../hooks'
import { CardForm, ContainerAbsolute, Icon } from './styles'

const Login: React.FC = () => {
  const { loading, authorized, alert } = useContext(SessionContext)
  const { formatMessage } = useIntl()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordApp, setPasswordApp] = useState('')
  const [errorMessagePassword, setErrorMessagePassword] = useState('')
  const { logIn } = useAuth()

  useEffect(() => {
    if (password) {
      setErrorMessagePassword('')
    }
    password.length !== 0 && password.length < 4
      ? setErrorMessagePassword(formatMessage({ id: 'passwordMaxChars' }))
      : setErrorMessagePassword('')
  }, [authorized, password, username, formatMessage])

  const handleSubmit = async () => {
    await logIn({ username, password, passwordApp })
  }

  return (
    <ContainerAbsolute>
      { loading ? (
        <Spinner />
      ) : (
        <form>
          <CardForm>
            { alert && (
              <Snackbar className='alert' type={alert.type} message={alert.message} />
            ) }
            <Icon>
              <img src='assets/images/logo.jpg' alt='Maria Goretti Schule' />
            </Icon>
            <Input
              className='input'
              label={ formatMessage({ id: 'userName' }) }
              onChange={ setUsername }
              initialValue={ username }
              placeholder={ formatMessage({ id: 'placeholderUsername' }) }
            />
            <Input
              className='input'
              label={ formatMessage({ id: 'password' }) }
              onChange={ setPassword }
              initialValue={ password }
              placeholder={ formatMessage({ id: 'placeholderPassword' }) }
              type='password'
              isInvalid={ !!errorMessagePassword }
              error={ errorMessagePassword }
            />
            <Input
              className='input'
              label={ formatMessage({ id: 'accessCode' }) }
              onChange={ setPasswordApp }
              initialValue={ passwordApp }
              placeholder={ formatMessage({ id: 'placeholderAccessCode' }) }
              type='password'
            />
            <Button
              style={{ margin: '0 auto' }}
              onClick={ handleSubmit }
              isDisabled={ !!errorMessagePassword || !password || !username }
            >
              { formatMessage({ id: 'login' }) }
            </Button>
          </CardForm>
        </form>
      )}
    </ContainerAbsolute>
  )
}

export default Login
