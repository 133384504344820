import React, { createContext, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { literals } from '../literals'
import { Locale } from '../models'

export interface ILanguageContext {
  locale: Locale
  messages: any
  setLocale: (newLocale: Locale) => void
}

const LanguageContext = createContext<ILanguageContext>({
  locale: 'de',
  messages: literals['de'],
  setLocale: () => undefined
})

const LanguageProvider: React.FC = ({ children }) => {
  const [ language, setLanguage ] = useState<Locale>('de')
  const [ messages, setMessages ] = useState(literals['de'])

  const handleSetLocale = (newLocale: Locale) => {
    setLanguage(newLocale)
    setMessages(literals[newLocale] as any)
  }

  return (
    <LanguageContext.Provider
      value={{
        locale: language,
        messages,
        setLocale: handleSetLocale
      }}
    >
      <IntlProvider locale={ language } messages={ messages }>
        { children }
      </IntlProvider>
    </LanguageContext.Provider>
  )
}

export { LanguageContext, LanguageProvider }
