import styled from 'styled-components'

export default styled.article`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  > header {
    padding: 20px 32px;
    box-sizing: border-box;
    z-index: 3000;

    h2 {
      margin: 0 0 28px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      color: ${ ({ theme }) => theme.colors.typography.dark };
    }

    .edit {
      width: 100;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      > span {
        margin-left: 16px;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        color: ${ ({ theme }) => theme.colors.typography.medium };
      }
    }

    .subject-filter {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;

      .subject-select__container {
        width: 312px;

        .label {
          font-size: 16px;
          letter-spacing: 0;
          line-height: 24px;
          color: ${ ({ theme }) => theme.colors.typography.medium };
          margin-bottom: 8px;
        }

        .subject-select {
          width: 100%;
          box-sizing: border-box;
          border-radius: 4px;
        }
      }

    }
  }

  .grade-table {
    flex: 1;
    padding: 20px 32px 0;
    box-sizing: border-box;
    background-color: ${ ({ theme }) => theme.colors.grey.light };
    overflow: hidden;
    overflow-x: auto;

    .spinner-container {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`
