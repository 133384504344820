import { Snackbar } from '@valudio/ui'
import React, { useContext, useEffect } from 'react'
import { Redirect, Switch } from 'react-router-dom'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import { SessionContext } from './context'
import { PrivateRoutes, PublicRoutes } from './routes'
import { Sidebar } from './components'
import { prefix } from './config'
import { IToken } from './models'
import { OtherSettingsModal, PasswordModal } from './containers'
import './App.css'

const App = () => {
  const {
    token,
    alert,
    isAdmin,
    isClassLeader,
    setAlert,
    setErrorMessage,
    setSession,
    setUsername,
    setIsAdmin,
    setIsClassLeader
  } = useContext(SessionContext)

  const tokenStorage = localStorage.getItem(`${ prefix }:token`) || ''
  const usernameStorage = localStorage.getItem(`${ prefix }:username`) || ''
  const refreshTokenStorage = localStorage.getItem(`${ prefix }:refreshToken`) || ''
  const isSignedIn = !!tokenStorage

  useEffect(() => {
    let decodedToken: IToken
    if (!token && tokenStorage) {
      decodedToken = jwt_decode(tokenStorage)
      setSession({
        token: tokenStorage,
        refreshToken: refreshTokenStorage
      })
      setUsername(usernameStorage)
      setIsAdmin(decodedToken.isAdmin === 'true')
      setIsClassLeader(decodedToken.isClassLeader === 'true')
    }

    if (token) {
      decodedToken = jwt_decode(tokenStorage)
      setIsAdmin(decodedToken.isAdmin === 'true')
      setIsClassLeader(decodedToken.isClassLeader === 'true')
    }

    if (alert) {
      setTimeout(() => {
        setAlert(null)
        setErrorMessage(false)
      }, 5000)
    }
  }, [
    token,
    alert,
    tokenStorage,
    refreshTokenStorage,
    usernameStorage,
    setAlert,
    setErrorMessage,
    setSession,
    setUsername,
    setIsAdmin,
    setIsClassLeader
  ])

  return (
    <>
      { isAdmin ? <OtherSettingsModal /> : null }
      <PasswordModal />
      <Switch>
        {!isSignedIn ? (
          <PublicRoutes />
        ) : (
          <div style={{ display: 'flex' }}>
            <Sidebar isVisible={ isSignedIn } />
            <div className='custom-container'>
              {alert && (
                <Snackbar className='alert' type={alert.type} message={alert.message} />
              )}
              <PrivateRoutes isAdmin={ isAdmin } isClassLeader={ isClassLeader } />
            </div>
          </div>
        )}
        {!isSignedIn && <Redirect to='/login' />}
      </Switch>
    </>
  )
}

export default App
