import { IBaseProps, Icon } from '@valudio/ui'
import React from 'react'
import StyledLi from './styles'

interface IProps extends IBaseProps {
  onClick: () => void
  isSelected: boolean
}

const Item: React.FC<IProps> = ({ isHidden, children, onClick, isSelected }) => {
  if (isHidden) return null

  return (
    <StyledLi
      className={ `option ${ isSelected ? 'selected' : '' }` }
      onClick={ onClick }
    >
      <span className="label">{ children }</span>
      <Icon isHidden={ !isSelected } icon="check" />
    </StyledLi>
  )
}

export default Item
