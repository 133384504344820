import axios, { AxiosResponse } from 'axios'
import { useCallback, useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { prefix } from '../config'
import { ISection, ISubSection, ErrorCodes } from '../models'
import { SessionContext } from '../context'
import { useAuth } from '.'

export const useSections = () => {
  const { formatMessage } = useIntl()
  const { setAlert } = useContext(SessionContext)
  const { logOut } = useAuth()
  const [sections, setSections] = useState<ISection[]>([])
  const [subSections, setSubSections] = useState<ISubSection[]>([])
  const [isFetching, setIsFetching] = useState(false)
  // const [isDraftFecthing, setIsDraftFecthing] = useState(false)
  // const [isModalVisible, setIsModalVisible] = useState(false)
  const bearerToken = localStorage.getItem(`${ prefix }:token`)
  const bearer = 'Bearer ' + bearerToken

  const fetchSections = useCallback(async (): Promise<void> => {
    setIsFetching(true)

    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/Section/GetAllSections`, {
      method: 'GET',
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json-patch+json'
      }
    }).then((response: AxiosResponse) => {
      setSections(response.data)
    }).catch(async error => {
      if (error.response.status === ErrorCodes.UNAUTHORIZED) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Sections error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ?? formatMessage({ id: 'genericErrorListFetch' })
        })
      }
    }).finally(() => {
      setIsFetching(false)
    })
  }, [bearer, formatMessage, logOut, setAlert])

  const fetchSubSections = useCallback(async (id: number): Promise<void> => {
    try {
      await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/Section/GetSubSectionsBySection/${id}`, {
        method: 'GET',
        headers: {
          accept: '*/*',
          Authorization: bearer,
          'Content-Type': 'application/json-patch+json'
        }
      }).then((response: AxiosResponse) => {
        setSubSections(response.data)
      })
    } catch (error: any) {
      if (error.response.status === ErrorCodes.UNAUTHORIZED) await logOut()
      if (process.env.NODE_ENV === 'development') {
        console.log('Sections error: ', error)
      }
    }
  }, [bearer, logOut])

  return {
    sections,
    setSections,
    subSections,
    setSubSections,
    isFetching,
    setIsFetching,
    fetchSections,
    fetchSubSections
  }
}
