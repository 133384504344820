import { Button, Field, Icon, Modal } from '@valudio/ui'
import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { AutoResizeTextarea } from '../../../../../components'
import { initialSampleTextDraft, ISampleText } from '../../../../../models'
import StyledDiv from './styles'

interface IProps {
  item: ISampleText
  isHidden: boolean
  isCreatingOrUpdating?: boolean
  onClose?: () => void
  onSubmit?: (item: ISampleText) => void
}

export const DraftModal: React.FC<IProps> = ({ item, isHidden, isCreatingOrUpdating, onClose, onSubmit }) => {
  const { formatMessage } = useIntl()
  const formRef = useRef<HTMLFormElement>(null)
  const [draft, setDraft] = useState<ISampleText>(initialSampleTextDraft)

  const handleChange = (value: { [key: string]: any }): void => {
    setDraft(draft => ({ ...draft, ...value }))
  }

  const handleCleanup = () => {
    setDraft(initialSampleTextDraft)
  }

  const handleClose = () => {
    handleCleanup()
    if (onClose && typeof onClose === 'function') onClose()
  }

  const handleSubmit = () => {
    if (onSubmit && typeof onSubmit === 'function') onSubmit(draft)
    handleCleanup()
  }

  useEffect(() => {
    if (item && !draft.id) setDraft(item)
  }, [draft.id, item])

  return (
    <Modal isHidden={ isHidden } onOverlayClick={ handleClose } className="edit-modal">
      <StyledDiv>
        <div className="content-header">
          <h3>{ formatMessage({ id: `${ draft?.id ? 'editTitle' : 'addNewSampleText' }` }) }</h3>
          <Icon
            className='icon'
            icon='close'
            onClick={ handleClose }
          />
        </div>
        <form ref={ formRef }>
          <div className="fields">
            <div className="form-row">
              <Field label={ formatMessage({ id: 'text' }) } className="field">
                <AutoResizeTextarea
                  value={ draft.text }
                  initialRows={ 4 }
                  onBlur={
                    text => handleChange({ text })
                  }
                />
              </Field>
            </div>
          </div>
          <div className="actions">
            <Button
              type="primary"
              onClick={ handleSubmit }
              isLoading={ isCreatingOrUpdating }
              isDisabled={ !draft.text }
            >
              { (formatMessage({ id: `${ draft.id ? 'updateSampleText' : 'saveSampleText' }` })) }
            </Button>
          </div>
        </form>
      </StyledDiv>
    </Modal>
  )
}
