import { Button, Checkbox, Field, Icon, Input, IOption, Modal } from '@valudio/ui'
import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { AutoResizeTextarea, GradeTemplate, Select } from '../../../../../components'
import { useSections } from '../../../../../hooks'
import { initialDraftReferenceGrade, IReferenceGrade, Year, yearSelectOptions } from '../../../../../models'
import StyledDiv from './styles'

interface IProps {
  item: IReferenceGrade
  isHidden: boolean
  isCreatingOrUpdating?: boolean
  onClose?: () => void
  onSubmit?: (item: IReferenceGrade) => void
}

export const DraftModal: React.FC<IProps> = ({ item, isHidden, isCreatingOrUpdating, onClose, onSubmit }) => {
  const formRef = useRef<HTMLFormElement>(null)
  const subSectionFormRef = useRef<HTMLFormElement>(null)
  const { formatMessage } = useIntl()
  const { sections, setSections, subSections, setSubSections, fetchSections, fetchSubSections } = useSections()
  const [draft, setDraft] = useState<IReferenceGrade>(initialDraftReferenceGrade)
  const [sectionOptions, setSectionOptions] = useState<IOption[]>([])
  const [subSectionOptions, setSubSectionOptions] = useState<IOption[]>([])
  const [isInitialized, setIsInitialized] = useState(false)

  const isGradeTemplateIncomplete = () => {
    const { first, second, examFirstSemester, third, fourth, examSecondSemester } = draft.gradeTemplateItems
    return !first.max
     || !second.max
     || !third.max
     || !fourth.max
     || !examFirstSemester.max
     || !examSecondSemester.max
  }

  const isSubmitDisabled = (): boolean => {
    return !draft.year
     || !draft.section.id
     || isGradeTemplateIncomplete()
     || !draft.finalDecisionTitle
  }

  const handleChange = (value: { [key: string]: any }): void => {
    setDraft(draft => ({ ...draft, ...value }))
  }

  const handleYearChange = (option: IOption) => {
    const year = Number(option.id) as Year
    handleChange({ year })
  }

  const handleSectionChange = (option: IOption) => {
    const idSection = Number(option.id)
    const section = { id: idSection, name: option.name }
    handleChange({ section })
    handleChange({ idSection })
    handleChange({ subSection: null })
    handleChange({ idSubSection: null })
    if (subSectionFormRef && subSectionFormRef.current) subSectionFormRef.current.reset()
    fetchSubSections(idSection)
  }

  const handleSubSectionChange = (option: IOption) => {
    const idSubSection = Number(option.id)
    const subSection = { id: idSubSection, name: option.name }
    handleChange({ idSubSection })
    handleChange({ subSection })
  }

  const handleCleanup = () => {
    setSections([])
    setSubSections([])
    setSectionOptions([])
    setSubSectionOptions([])
    setIsInitialized(false)
    setDraft(initialDraftReferenceGrade)
    if (formRef && formRef.current) formRef.current.reset()
    if (subSectionFormRef && subSectionFormRef.current) subSectionFormRef.current.reset()
  }

  const handleClose = () => {
    handleCleanup()
    if (onClose && typeof onClose === 'function') onClose()
  }

  const handleSubmit = () => {
    if (onSubmit && typeof onSubmit === 'function') onSubmit(draft)
    handleCleanup()
  }

  useEffect(() => {
    if (item && !draft.id) setDraft(item)
  }, [draft.id, item])

  useEffect(() => {
    if (!isHidden && !sections.length) {
      fetchSections()
    }
  }, [fetchSections, isHidden, item, sectionOptions, sections, setSections, setSubSections])

  // Set Sections options
  useEffect(() => {
    if (sections.length && !sectionOptions.length) {
      setSectionOptions(() => sections.map(s => ({...s, id: s.id.toString()})))
    }
  }, [sections, sectionOptions.length])

  useEffect(() => {
    if (!!draft.section?.id && !isInitialized) {
      setIsInitialized(true)
      fetchSubSections(draft.section.id)
    }
  }, [draft.section, fetchSubSections, isInitialized, subSectionOptions.length])

  // Set SubSections options on Section change
  useEffect(() => {
    setSubSectionOptions(() => subSections.map(s => ({...s, id: s.id.toString()})))
  }, [subSections])

  return (
    <Modal isHidden={ isHidden } onOverlayClick={ handleClose } className="edit-modal">
      <StyledDiv>
        <div className='content-header'>
          <h3>{ formatMessage({ id: `${ draft?.id ? 'editReferenceGrade' : 'createReferenceGrade' }` }) }</h3>
          <Icon
            className='icon'
            icon='close'
            onClick={ handleClose }
          />
        </div>
        <form ref={ formRef }>
          <div className="form-row">
            <Field label={ `${ formatMessage({ id: 'year' }) } *` } className="field select year">
              <Select
                form={ formRef.current ?? undefined }
                placeholder={ formatMessage({ id: 'placeholderSubjectYear' }) }
                labelProp="value"
                value={ draft.year ? { id: draft.year.toString(), value: draft.year.toString() } : undefined }
                options={ yearSelectOptions }
                onChange={ handleYearChange }
              />
            </Field>
            <Field label={ `${ formatMessage({ id: 'section' }) } *` } className="field select">
              <Select
                form={ formRef.current ?? undefined }
                placeholder={ formatMessage({ id: 'classSectionPlaceholder' }) }
                labelProp="name"
                value={ draft.section ? { id: draft.section.id.toString(), name: draft.section.name } : undefined }
                options={ sectionOptions }
                onChange={ handleSectionChange }
              />
            </Field>
            <form ref={ subSectionFormRef } className="field select">
              <Field label={ formatMessage({ id: 'subSection' }) }>
                <Select
                  form={ subSectionFormRef.current ?? undefined }
                  placeholder={ formatMessage({ id: 'classSubSectionPlaceholder' }) }
                  labelProp="name"
                  value={
                    draft.subSection
                      ? { id: draft.subSection.id.toString(), name: draft.subSection.name }
                      : undefined
                  }
                  options={ subSectionOptions }
                  onChange={ handleSubSectionChange }
                />
              </Field>
            </form>
          </div>
          <GradeTemplate
            gradeTemplateItems={ draft.gradeTemplateItems }
            onChange={ handleChange }
            form={ formRef.current ?? undefined }
          />
          <div className="form-row comments">
            <Field label={ formatMessage({ id: 'comments' }) } className="field">
              <AutoResizeTextarea
                value={ draft.comments }
                initialRows={ 4 }
                onBlur={ comments => handleChange({ comments }) }
              />
            </Field>
          </div>
          <div className="form-row">
            <Field label={ `${ formatMessage({ id: 'titleFinalDecision' }) } *` } className="field">
              <Input
                form={ formRef.current ?? undefined }
                initialValue={ draft.finalDecisionTitle }
                onChange={ finalDecisionTitle => handleChange({ finalDecisionTitle }) }
                placeholder={ formatMessage({ id: 'titlePlaceholder' }) }
              />
            </Field>
            <div className="field checkbox" style={{ marginTop: 0 }}>
              <Checkbox
                value={ draft.certificateOfCompetenceIsVisible }
                onChange={
                  certificateOfCompetenceIsVisible => handleChange({ certificateOfCompetenceIsVisible })
                }
              />
              <Field
                label={ `${ formatMessage({ id: 'titleCertificateOfCompetence' }) }` }
                className="field"
                style={{ marginRight: 0 }}
              >
                <Input
                  form={ formRef.current ?? undefined }
                  initialValue={ draft.certificateOfCompetenceTitle }
                  onChange={
                    certificateOfCompetenceTitle => handleChange({ certificateOfCompetenceTitle })
                  }
                />
              </Field>
            </div>
          </div>
          <div className="actions">
            <Button
              type="primary"
              onClick={ handleSubmit }
              isLoading={ isCreatingOrUpdating }
              isDisabled={ isSubmitDisabled() }
            >
              { (formatMessage({id: `${ 'saveReferenceGrade' }`})) }
            </Button>
          </div>
        </form>
      </StyledDiv>
    </Modal>
  )
}
