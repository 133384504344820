import { Icon, IInputProps, IOption } from '@valudio/ui'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { isClickingOutside } from '../../helpers'
import { Dropdown } from './Dropdown'
import Row from './Row'
import { StyledContainer, StyledContent, StyledHeader } from './styles'

interface IProps extends IInputProps<IOption[]> {
  labelProp: string
  options: IOption[]
  selected: IOption[]
}

export const SelectList: React.FC<IProps> = ({
  className, labelProp, options, selected, onChange, isHidden, style, isInvalid, isDisabled, initialValue, form
}) => {
  const { formatMessage } = useIntl()
  const ref = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [query, setQuery] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [selectOptions, setSelectOptions] = useState<IOption[]>([])
  const isDisabledOrEmpty = isDisabled || !options || !!options && !options.length
  const classNames = `
    ${ className || '' }
    ${ isOpen ? 'open' : '' }
    ${ isInvalid ? 'invalid' : '' }
    ${ isDisabledOrEmpty ? 'disabled' : '' }
  `

  const rows = selected.map(item => {
    return (
      <Row
        key={ `${ className }-${ item.id }` }
        name={ item.value }
        onDelete={ () => handleClick(item) }
      />
    )
  })

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.currentTarget.value)
  }

  const handleClick = (option: IOption) => {
    const selectedOptions = selected.find(s => s.value === option.value)
      ? selected.filter(s => s.value !== option.value)
      : [ ...selected, option ]

    onChange(selectedOptions)
    // setIsOpen(false)
  }

  const handleBulkSelect = (filtered?: IOption[]) => {
    const nextSelected = selected.length > 0 ? [] : filtered || options

    onChange(nextSelected)
    setIsOpen(false)
    setQuery('')
  }

  const handleDocumentClick = (event: MouseEvent) => {
    if (isClickingOutside(ref, wrapperRef, event)) {
      setIsOpen(false)
      setQuery('')
    }
  }

  useEffect(() => {
    const availableOptions = options
      .filter(option => !selected.includes(option))
      .sort((prev, next) => prev[labelProp] > next[labelProp] ? 1 : -1)
    const selectedOptions = selected.sort((prev, next) => prev[labelProp] > next[labelProp] ? 1 : -1)
    setSelectOptions([...availableOptions, ...selectedOptions])
  }, [labelProp, options, selected])

  useEffect(() => {
    if (form) form.addEventListener('reset', () => onChange([]))
    document.addEventListener('click', handleDocumentClick)
  }, [form, initialValue, onChange])

  useEffect(() => {
    if (!isOpen && inputRef && inputRef.current) inputRef.current.blur()
  }, [isOpen])

  if (isHidden) return null
  return (
    <StyledContainer>
      <StyledHeader className={ classNames } style={ style } ref={ ref }>
        <section
          className="wrapper"
          onClick={ setIsOpen.bind(undefined, !isDisabledOrEmpty && !isOpen) }
          ref={ wrapperRef }
        >
          <input
            ref={ inputRef }
            className={ `search-input ${ className || '' }` }
            onChange={ handleChangeSearch }
            value={ query }
            placeholder={ `${ formatMessage({ id: 'search' }) }...` }
            onClick={ setIsOpen.bind(undefined, !isDisabledOrEmpty && !isOpen) }
          />
          <Icon className="icon" icon={ 'search' }/>
        </section>
        <Dropdown
          isHidden={ !isOpen || isDisabledOrEmpty }
          options={ selectOptions }
          labelProp={ labelProp }
          selected={ selected }
          query={ query }
          onClick={ handleClick }
          onBulkSelect={ handleBulkSelect }
        />
      </StyledHeader>
      <StyledContent>
        { rows }
      </StyledContent>
    </StyledContainer>
  )
}
