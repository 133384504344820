import React from 'react'
import { IBaseProps } from '@valudio/ui'
import StyledArticle from './styles'

export const SmallSpinner: React.FC<IBaseProps> = ({ isHidden, style, className }) => {
  if (isHidden) return null

  const classNames = `${ className ? className : '' }`

  return (
    <StyledArticle className={ classNames } style={ style }>
      <div className="spinner">
        <div className="double-bounce1" />
        <div className="double-bounce2" />
      </div>
    </StyledArticle>
  )
}
