import axios, { AxiosResponse } from 'axios'
import { useContext, useState } from 'react'
import { IOption } from '@valudio/ui'
import { useIntl } from 'react-intl'
import { prefix } from '../config'
import { SessionContext } from '../context'
import {
  GradeType,
  IReport,
  IClassReport,
  IReportStudent,
  IReportDataStudent,
  IGeneralComment,
  IReportDataSubject
} from '../models'
import { formatReportData } from '../helpers'
import { useAuth } from '.'

export const useReports = () => {
  const { setAlert } = useContext(SessionContext)
  const { logOut } = useAuth()
  const { locale, formatMessage } = useIntl()
  const [reportData, setReportData] = useState<IReport>()
  const [classReportData, setClassReportData] = useState<IClassReport>()
  const bearerToken = localStorage.getItem(`${ prefix }:token`)
  const bearer = 'Bearer ' + bearerToken

  const getReportData = async (
    students: IOption[], currentClass: string, currentSection: string, periodFilter: GradeType
  ): Promise<void> => {
    const ids = students.map(s => s.id)
    await axios.post(
      `${ process.env.REACT_APP_API_URL }api/v1/Grade/GetGradesSubjectsStudents`,
      JSON.stringify(ids),
      {
        method: 'GET',
        headers: {
          accept: '*/*',
          Authorization: bearer,
          'Content-Type': 'application/json'
        }
      }
    ).then((response: AxiosResponse<IReportDataStudent[]>) => {
      if (students.length === 1) {
        const student = students[0]
        const reportStudent: IReportStudent = {
          id: Number(student.id),
          name: student.firstName,
          lastname: student.lastName,
          class: currentClass,
          section: currentSection
        }
        const studentData = response.data
          .find((data: IReportDataStudent) => data.idStudent === reportStudent.id)
        const studentSubjects: IReportDataSubject[] = studentData?.grades ?? []
        const comments: IGeneralComment[] = studentData?.comments ?? []
        const sectionTemplateLines = studentData?.sectionTemplates.length
          ? studentData?.sectionTemplates[0].sectionTemplateLines
          : []
        const sectionTemplate = studentData?.sectionTemplates.length
          ? studentData?.sectionTemplates[0]
          : undefined
        const dataForReport =
          formatReportData(
            reportStudent,
            studentSubjects,
            sectionTemplateLines,
            comments,
            periodFilter,
            locale,
            formatMessage,
            sectionTemplate
          )
        setReportData(dataForReport)
      } else {
        const classStudents = students.map(s => {
          const reportStudent: IReportStudent = {
            id: Number(s.id),
            name: s.firstName,
            lastname: s.lastName,
            class: currentClass,
            section: currentSection
          }
          const studentData = response.data
            .find((data: IReportDataStudent) => data.idStudent === reportStudent.id)
          const studentSubjects: IReportDataSubject[] = studentData?.grades ?? []
          const comments: IGeneralComment[] = studentData?.comments ?? []
          const sectionTemplateLines = studentData?.sectionTemplates.length
            ? studentData?.sectionTemplates[0].sectionTemplateLines
            : []
          const sectionTemplate = studentData?.sectionTemplates.length
            ? studentData?.sectionTemplates[0]
            : undefined
          return formatReportData(
            reportStudent,
            studentSubjects,
            sectionTemplateLines,
            comments,
            periodFilter,
            locale,
            formatMessage,
            sectionTemplate
          )
        })
        setClassReportData({ students: classStudents })
      }
      // return response.data
    }).catch(async error => {
      if (error && error.response && error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Report data error: ', error)
        }
        // Change literal
        setAlert({
          type: 'error',
          message: error.Message ?? `Report data error: ${ error }`
        })
      }
    })
  }

  const generateReportForStudent = async (report: IClassReport, period: GradeType, isIndicationDisabled: boolean) => {
    const { name, lastname }: IReportStudent = report.students[0].student
    const className = report.students[0].student.class
    let reportSegment = ''

    switch (period) {
    case GradeType.First: {
      reportSegment = `all-saints${ isIndicationDisabled ? '' : '-indication' }`
      break
    }
    case GradeType.Second: {
      reportSegment = `christmas${ isIndicationDisabled ? '' : '-indication' }`
      break
    }
    case GradeType.ExamFirstSemester: {
      reportSegment = `eastern${ isIndicationDisabled ? '' : '-indication' }`
      break
    }
    default: {
      reportSegment = `summer${ isIndicationDisabled ? '' : '-indication' }`
      break
    }
    }

    await axios.post(
      'https://node-functions.azurewebsites.net/api/ToPdf' +
      '?code=WPQMWrGlJqZApCR9q1mYWA/F1JLv/Ivcs4dKPyNh6Fne94J9u6HtNw==&asArray=true&url=' +
      `https://valudiohtmltopdf.blob.core.windows.net/html-files/${ reportSegment }/index.hbs`,
      JSON.stringify(report.students),
      {
        method: 'GET',
        headers: {
          accept: '*/*',
          Authorization: bearer,
          'Content-Type': 'application/json'
        },
        responseType: 'blob'
      }
    ).then((response: AxiosResponse) => {
      const file = new Blob([response.data], {
        type: 'application/pdf'
      })
      const url = window.URL.createObjectURL(file)
      const link = document.createElement('a')
      const fileName = report.students.length === 1
        ? `${ name } ${ lastname } zeugnis.pdf`
        : `${ className } zeugnis.pdf`
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    }).catch(async error => {
      if (error && error.response && error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Delete error: ', error)
        }
        // TODO: Change literal
        setAlert({
          type: 'error',
          message: error.Message ?? `Generate report error: ${ error }`
        })
      }
    })
  }

  const generateOverviewReport = async (
    students: IOption[], currentClass: string, currentSection: string, periodFilter: GradeType
  ) => {
    const ids = students.map(s => s.id)
    const rawData = await axios.post(
      `${ process.env.REACT_APP_API_URL }api/v1/Grade/GetGradesSubjectsStudents`,
      JSON.stringify(ids),
      {
        method: 'GET',
        headers: {
          accept: '*/*',
          Authorization: bearer,
          'Content-Type': 'application/json'
        }
      }
    ).then((response: AxiosResponse<IReportDataStudent[]>) => {
      return response.data
    }).catch(async error => {
      if (error && error.response && error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Overview report error: ', error)
        }
        // Change literal
        setAlert({
          type: 'error',
          message: error.Message ?? `Overview report error: ${ error }`
        })
      }
    })

    if (rawData) {
      const classStudents = students.map(s => {
        const reportStudent: IReportStudent = {
          id: Number(s.id),
          name: s.firstName,
          lastname: s.lastName,
          class: currentClass,
          section: currentSection
        }
        const studentData = rawData
          .find((data: IReportDataStudent) => data.idStudent === reportStudent.id)
        const studentSubjects: IReportDataSubject[] = studentData?.grades
          ? studentData.grades.map(grade => grade.isDisabled ? { ...grade, total: '' } : grade)
          : []
        const comments: IGeneralComment[] = studentData?.comments ?? []
        const sectionTemplateLines = studentData?.sectionTemplates.length
          ? studentData?.sectionTemplates[0].sectionTemplateLines
          : []
        return formatReportData(
          reportStudent, studentSubjects, sectionTemplateLines, comments, periodFilter, locale, formatMessage
        )
      })
      const body = { report: { students: classStudents } }
      await axios.post(
        'https://node-functions.azurewebsites.net/api/ToPdf?landscape=true&asArray=false' +
        '&code=WPQMWrGlJqZApCR9q1mYWA/F1JLv/Ivcs4dKPyNh6Fne94J9u6HtNw==&url=' +
        'https://valudiohtmltopdf.blob.core.windows.net/html-files/overview-report/index.hbs',
        JSON.stringify(body),
        {
          method: 'GET',
          headers: {
            accept: '*/*',
            Authorization: bearer,
            'Content-Type': 'application/json'
          },
          responseType: 'blob'
        }
      ).then((response: AxiosResponse) => {
        const file = new Blob([response.data], {
          type: 'application/pdf'
        })
        const url = window.URL.createObjectURL(file)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `Übersicht_${currentClass}.pdf`)
        document.body.appendChild(link)
        link.click()
      }).catch(async error => {
        if (error && error.response && error.response.status === 401) {
          await logOut()
        } else {
          if (process.env.NODE_ENV === 'development') {
            console.log('Generate overview report error: ', error)
          }
          // TODO: Change literal
          setAlert({
            type: 'error',
            message: error.Message ?? `Generate overview report error: ${ error }`
          })
        }
      })
    }

  }

  return {
    reportData,
    setReportData,
    classReportData,
    setClassReportData,
    getReportData,
    generateReportForStudent,
    generateOverviewReport
  }
}
