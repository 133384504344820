import { Button, Field, Icon, IOption } from '@valudio/ui'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { CommentsTable, SearchInput, Select } from '../../components'
import { useClasses, useGrades, useReports, useStudents } from '../../hooks'
import {
  GradeType,
  ICommentRowItem,
  IGeneralComment,
  IMenuOption,
  IReportUserInputContent,
  ITabItem,
  studentGradeLineToGradeArray
} from '../../models'
import { CommentsModal, ReportGenerationOptions, ReportModal, ReportTable, ReportTabs } from './components'
import StyledArticle from './styles'

const GradeConsultation: React.FC = () => {
  const { formatMessage, locale } = useIntl()
  const formRef = useRef<HTMLFormElement>(null)
  const { classOptions, classes, fetchClassesByTeacher } = useClasses()
  const { studentOptions, setStudentOptions, fetchStudentsByClass, disableDeleteSubject } = useStudents()
  const {
    reportSubjects,
    setReportSubjects,
    isSubjectFetching,
    setIsSubjectFetching,
    getSubjectGrades,
    generalComments,
    setGeneralComments,
    getStudentGeneralComments,
    setStudentGeneralComments
  } = useGrades()
  const {
    reportData,
    setReportData,
    classReportData,
    setClassReportData,
    getReportData,
    generateReportForStudent,
    generateOverviewReport
  } = useReports()
  const [currentClass, setCurrentClass] = useState<string>('')
  const [currentSection, setCurrentSection] = useState<string>('')
  const [currentStudent, setCurrentStudent] = useState<IOption>()
  const [isIndicationDisabled, setIsIndicationDisabled] = useState(false)
  const [currentView, setCurrentView] = useState<IOption>({ id: 'grades', label: formatMessage({ id: 'grades' }) })
  const [periodFilter, setPeriodFilter] = useState(GradeType.First)
  const [areClassesFetched, setAreClassesFetched] = useState(false)
  const [reportComments, setReportComments] = useState<ICommentRowItem[]>([])
  const [isCommentsModalVisible, setIsCommentsModalVisible] = useState(false)
  const [isReportModalVisible, setIsReportModalVisible] = useState(false)
  const [isFetchingOrUpdatingComments, setIsFetchingOrUpdatingComments] = useState(false)
  const [isFetchingReportData, setIsFetchingReportData] = useState(false)
  const [isGeneratingReports, setIsGeneratingReports] = useState(false)

  const viewOptions: IOption[] = [
    { id: 'grades', label: formatMessage({ id: 'grades' }) },
    { id: 'comments', label: formatMessage({ id: 'comments' }) }
  ]
  const tabs: ITabItem[] = [
    { key: '0', label: formatMessage({ id: 'allSaints' }) },
    { key: '1', label: formatMessage({ id: 'christmas' }) },
    { key: '2', label: formatMessage({ id: 'eastern' }) },
    { key: '3', label: formatMessage({ id: 'summer' }) }
  ]
  const generateReportOptions: IMenuOption[] = [
    {
      icon: 'customer',
      label: formatMessage({ id: 'createPdfStudent' }),
      action: () => handleOpenReportModal(),
      isDisabled: isGeneratingReports || isFetchingReportData || !currentStudent
    },
    {
      icon: 'workers',
      label: formatMessage({ id: 'createPdfClass' }),
      action: () => handleOpenReportModal(studentOptions),
      isDisabled: isGeneratingReports || isFetchingReportData || !currentClass
    },
    {
      icon: 'reports',
      label: formatMessage({ id: 'createOverviewReport' }),
      action: () => handleGenerateOverviewReport(),
      isDisabled: isGeneratingReports || isFetchingReportData || !currentClass
    }
  ]

  const isPrevButtonDisabled = (): boolean => {
    if (!studentOptions.length || !currentStudent) return true
    const studentIndex = studentOptions.findIndex(s => s.id === currentStudent?.id)
    return studentIndex === 0
  }

  const isNextButtonDisabled = (): boolean => {
    if (!studentOptions.length || !currentStudent) return true
    const studentIndex = studentOptions.findIndex(s => s.id === currentStudent?.id)
    return studentIndex + 1 === studentOptions.length
  }

  const handleClassChange = async (option: IOption) => {
    const id = option.id
    const currentClass = classes.find(c => c.id.toString() === id)
    formRef.current?.reset()
    setStudentOptions([])
    setReportSubjects([])
    setCurrentStudent(undefined)
    setIsIndicationDisabled(false)
    if (currentClass) {
      setCurrentClass(option.label)
      setCurrentSection(option.section)
      setIsIndicationDisabled(!!currentClass.disableIndicationReport)
    }
    await fetchStudentsByClass(id)
  }

  const handleStudentChange = useCallback(async (option: IOption) => {
    const id = option.id
    setCurrentStudent(option)
    await getSubjectGrades(id, periodFilter)
  }, [getSubjectGrades, periodFilter])

  const handleSelectPreviousStudent = async () => {
    if (currentStudent) {
      const index = studentOptions.findIndex(s => s.id === currentStudent.id)
      await handleStudentChange(studentOptions[index - 1])
    }
  }

  const handleSelectNextStudent = async () => {
    if (currentStudent) {
      const index = studentOptions.findIndex(s => s.id === currentStudent.id)
      await handleStudentChange(studentOptions[index + 1])
    }
  }

  const handleSelectTab = async (key: string) =>  {
    const selectedPeriod = Number(key)
    setPeriodFilter(selectedPeriod)
    if (currentStudent) await getSubjectGrades(currentStudent.id, selectedPeriod)
  }

  const handleOpenCommentsModal = async () => {
    setIsFetchingOrUpdatingComments(true)
    if (currentStudent) {
      const comments = await getStudentGeneralComments(currentStudent.id)
      setGeneralComments(comments)
    }
    setIsCommentsModalVisible(true)
    setIsFetchingOrUpdatingComments(false)
  }

  const handleCommentsModalClose = () => {
    setIsCommentsModalVisible(false)
    setGeneralComments([])
    setIsCommentsModalVisible(false)
    setIsFetchingOrUpdatingComments(false)
  }

  const handleCommentsSubmit = async (commentsStudents: IGeneralComment[]) => {
    setIsFetchingOrUpdatingComments(true)
    if (currentStudent) await setStudentGeneralComments({ idStudent: currentStudent.id, commentsStudents })
  }

  const handleOpenReportModal = async (students?: IOption[]) => {
    setIsFetchingReportData(true)
    if (students && students.length) await getReportData(students, currentClass, currentSection, periodFilter)
    if (currentStudent) await getReportData([currentStudent], currentClass, currentSection, periodFilter)
    setIsReportModalVisible(true)
    setIsFetchingReportData(false)
  }

  const handleGenerateReport = async (userInputData: IReportUserInputContent) => {
    setIsGeneratingReports(true)
    let report

    if (classReportData) {
      report = {
        students: classReportData.students.map(data => ({
          ...data,
          showGradeReport: userInputData.showGradeReport,
          showCommentsReport: userInputData.showCommentsReport,
          showCompetenciesReport: userInputData.showCompetenciesReport,
          title: userInputData.title,
          scholarYear: userInputData.scholarYear,
          titleComments: userInputData.titleComments,
          titleCompetencies: userInputData.titleCompetencies,
          generalComments: {
            ...data.generalComments,
            finalDecision: {
              ...data.generalComments.finalDecision,
              title: userInputData.generalComments.finalDecision.title,
              isVisible: userInputData.generalComments.finalDecision.isVisible
            },
            certificateOfCompetence: {
              ...data.generalComments.certificateOfCompetence,
              title: userInputData.generalComments.certificateOfCompetence.title,
              isVisible: userInputData.generalComments.certificateOfCompetence.isVisible
            }
          },
          signatures: {
            ...data.signatures,
            date: DateTime.fromISO(userInputData.signatures.date).setLocale(locale).toFormat('dd.MMMM yyyy'),
            smallDate: DateTime.fromISO(userInputData.signatures.date).setLocale(locale).toFormat('MMMM yyyy'),
            showDecision: userInputData.signatures.showDecision,
            showClassLeaderSignature: userInputData.signatures.showClassLeaderSignature,
            showAdminSignature: userInputData.signatures.showAdminSignature,
            showParentSignature: userInputData.signatures.showParentSignature
          }
        }))
      }
      await generateReportForStudent(report, periodFilter, isIndicationDisabled)
    } else if (reportData) {
      report = {
        students: [
          {
            ...reportData,
            showGradeReport: userInputData.showGradeReport,
            showCommentsReport: userInputData.showCommentsReport,
            showCompetenciesReport: userInputData.showCompetenciesReport,
            title: userInputData.title,
            scholarYear: userInputData.scholarYear,
            titleComments: userInputData.titleComments,
            titleCompetencies: userInputData.titleCompetencies,
            generalComments: {
              ...reportData.generalComments,
              finalDecision: {
                ...reportData.generalComments.finalDecision,
                title: userInputData.generalComments.finalDecision.title,
                isVisible: userInputData.generalComments.finalDecision.isVisible
              },
              certificateOfCompetence: {
                ...reportData.generalComments.certificateOfCompetence,
                title: userInputData.generalComments.certificateOfCompetence.title,
                isVisible: userInputData.generalComments.certificateOfCompetence.isVisible
              }
            },
            signatures: {
              ...reportData.signatures,
              date: DateTime.fromISO(userInputData.signatures.date).setLocale(locale).toFormat('dd.MMMM yyyy'),
              smallDate: DateTime.fromISO(userInputData.signatures.date).setLocale(locale).toFormat('MMMM yyyy'),
              showDecision: userInputData.signatures.showDecision,
              showClassLeaderSignature: userInputData.signatures.showClassLeaderSignature,
              showAdminSignature: userInputData.signatures.showAdminSignature,
              showParentSignature: userInputData.signatures.showParentSignature
            }
          }
        ]
      }
      await generateReportForStudent(report, periodFilter, isIndicationDisabled)
    }

    setReportData(undefined)
    setClassReportData(undefined)
    setIsGeneratingReports(false)
    setIsReportModalVisible(false)
  }

  const handleReportModalClose = () => {
    setIsReportModalVisible(false)
    setReportData(undefined)
    setClassReportData(undefined)
  }

  const handleGenerateOverviewReport = async () => {
    setIsFetchingReportData(true)
    setIsGeneratingReports(true)

    await generateOverviewReport(studentOptions, currentClass, currentSection, periodFilter)

    setIsFetchingReportData(false)
    setIsGeneratingReports(false)
  }

  const handleDeleteOrDisableSubject = async (idSubject: number, isDisabled: boolean, isDeleted: boolean) => {
    const id = currentStudent?.id

    setIsSubjectFetching(true)
    if (id) {
      await disableDeleteSubject({ idSubject, idStudent: Number(id), isDeleted, isDisabled })
      await getSubjectGrades(id, periodFilter)
    }
  }

  useEffect(() => {
    if (!classOptions.length && !areClassesFetched) {
      fetchClassesByTeacher()
      setAreClassesFetched(true)
    }
  }, [areClassesFetched, classOptions.length, fetchClassesByTeacher])

  useEffect(() => {
    if (studentOptions.length && !currentStudent) {
      handleStudentChange(studentOptions[0])
    }
  }, [currentStudent, handleStudentChange, studentOptions])

  useEffect(() => {
    if (reportSubjects.length) {
      const subjectComments: ICommentRowItem[] = reportSubjects.map(subject => {
        const grades = studentGradeLineToGradeArray(subject.grade)
        const periodComments = grades.find(grade => {
          if (periodFilter <= GradeType.Second) {
            return grade.gradeType === periodFilter
          } else {
            return grade.gradeType === (periodFilter + 1)
          }
        })?.comments
        return {
          id: subject.id,
          label: subject.name,
          comments: periodComments ?? '',
          isEditDisabled: false
        }
      })
      setReportComments(subjectComments)
    }
  }, [periodFilter, reportSubjects])

  return (
    <>
      <StyledArticle>
        <header>
          <div className="filter__container">
            <form ref={ formRef } className="filters">
              <div className="filter__row">
                <Field label={ formatMessage({ id: 'class' }) } className="select__container">
                  <SearchInput
                    className="select"
                    placeholder={ formatMessage({ id: 'choosePlaceholder' }) }
                    options={ classOptions }
                    onChange={ handleClassChange }
                    isDisabled={ !classOptions.length }
                  />
                </Field>
                <Field label={ formatMessage({ id: 'student' }) } className="select__container">
                  <SearchInput
                    form={ formRef.current ?? undefined }
                    className="select"
                    placeholder={ formatMessage({ id: 'choosePlaceholder' }) }
                    options={ studentOptions }
                    currentOption={ currentStudent }
                    onChange={ handleStudentChange }
                    isDisabled={ !studentOptions.length }
                  />
                </Field>
                <Button
                  className="prev"
                  type="primary"
                  isDisabled={ isPrevButtonDisabled() }
                  onClick={ handleSelectPreviousStudent }
                  isCircular
                >
                  <Icon icon="left"/>
                </Button>
                <Button
                  className="next"
                  type="primary"
                  isDisabled={ isNextButtonDisabled() }
                  onClick={ handleSelectNextStudent }
                  isCircular
                >
                  <Icon icon="right"/>
                </Button>
                <Button
                  className="comments"
                  type="primary"
                  isDisabled={ !currentStudent }
                  onClick={ handleOpenCommentsModal }
                  isLoading={ isFetchingOrUpdatingComments && !isCommentsModalVisible }
                >
                  { formatMessage({ id: 'reportComments' }) }
                </Button>
                <div className="actions">
                  <ReportGenerationOptions
                    options={ generateReportOptions }
                    isDisabled={ isFetchingReportData || isGeneratingReports }
                    isLoading={ isFetchingReportData || isGeneratingReports }
                    isHidden={ !currentClass }
                    style={{ marginLeft: '8px' }}
                  />
                </div>
              </div>
              <div className="filter__row">
                <Field label={ formatMessage({ id: 'view' }) } className="select__container">
                  <Select
                    value={ currentView }
                    labelProp="label"
                    options={ viewOptions }
                    onChange={ setCurrentView }
                    form={ formRef.current ?? undefined }
                    isDisabled={ !currentStudent }
                  />
                </Field>
                <ReportTabs
                  tabs={ tabs }
                  selectedTab={ periodFilter.toString() }
                  isVisible={ !!reportSubjects.length }
                  isLoading={ isSubjectFetching }
                  onClick={ handleSelectTab }
                />
              </div>
            </form>
          </div>
        </header>
        <section className="subjects-table">
          {
            currentView.id === 'grades'
              ? (
                <ReportTable
                  subjects={ reportSubjects }
                  isLoading={ isSubjectFetching }
                  isIndicationDisabled={ isIndicationDisabled }
                  onDisableOrDeleteSubject={ handleDeleteOrDisableSubject }
                />
              ) : (
                <CommentsTable
                  items={ reportComments }
                  classes={ classOptions }
                  isLoading={ isSubjectFetching }
                  isEditActive={ false }
                  isHidden={ currentView.id === 'grades' }
                  onCommentChange={ console.log }
                  isClassFilterEnabled={ false }
                />
              )
          }
        </section>
      </StyledArticle>
      <CommentsModal
        student={ currentStudent?.label ?? ''}
        generalComments={ generalComments }
        isHidden={ !isCommentsModalVisible }
        onClose={ handleCommentsModalClose }
        onSubmit={ handleCommentsSubmit }
        isUpdatingComments={ isFetchingOrUpdatingComments }
      />
      <ReportModal
        targetTitle={ classReportData ? currentClass : currentStudent?.label ?? '' }
        isHidden={ !isReportModalVisible }
        isLoading={ isGeneratingReports }
        generalComments={ reportData?.generalComments }
        onClose={ handleReportModalClose }
        onSubmit={ handleGenerateReport }
      />
    </>
  )
}

export default GradeConsultation
