import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;

  .column {
    min-height: 40px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: ${ ({ theme }) => theme.colors.white };
    margin: 0 8px 8px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    color: ${ ({ theme }) => theme.colors.typography.dark };
    box-sizing: border-box;

    &:last-of-type {
      margin-right: 0;
    }

    &.students {
      flex: 0 0 auto;
      flex-basis: 260px;
      max-width: 260px;
      min-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-lines: 1;

      span {
        padding: 0 16px;
        box-sizing: border-box;
      }
    }

    &.comments {
      flex: 1;
      padding: 3px;
      overflow-x: hidden;

      > p {
        width: 100%;
        margin: 0;
        padding: 0 8px;
      }
      
    }
  }
`
