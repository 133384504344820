import { Button, Icon, ITableColumn, ITableItem, Spinner } from '@valudio/ui'
import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { debounce } from 'ts-debounce'
import { CustomTable, ModalConfirm } from '../../../components'
import { SessionContext } from '../../../context'
import { useSampleTexts } from '../../../hooks'
import { initialSampleTextDraft, ISampleText } from '../../../models'
import { DraftModal } from './components'
import StyledArticle from './styles'

const SampleTexts: React.FC = () => {
  const { formatMessage } = useIntl()
  const { id: deleteId, openModalConfirm, setOpenModalConfirm } = useContext(SessionContext)
  const {
    sampleTexts,
    editSampleText,
    setEditSampleText,
    isFetching,
    isDraftFecthing,
    setIsDraftFecthing,
    isModalVisible,
    setIsModalVisible,
    fetchSampleTexts,
    searchSampleTexts,
    getSampleTextBtId,
    createSampleText,
    updateSampleText,
    deleteSampleText
  } = useSampleTexts()
  const [editingSampleTextId, setEditingSampleTextId] = useState(0)
  const [isTableUpdated, setIsTableUpdated] = useState(false)

  const sampleTextColumns: ITableColumn[] = [
    {
      key: 'id',
      label: '',
      style: {
        display: 'none'
      }
    },
    {
      key: 'text',
      label: formatMessage({ id: 'text' }),
      style: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'status',
      label: '',
      style: {
        flex: 0.2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexBasis: '80px'
      }
    }
  ]

  const sampleTextItems: ITableItem[] = sampleTexts.map(sampleText => ({
    ...sampleText,
    text: (
      <p className="sample-text-value" title={ sampleText.text }>{ sampleText.text }</p>
    ),
    status: isDraftFecthing && editingSampleTextId && editingSampleTextId === sampleText.id ? (
      <Spinner style={{ width: '30px', height: '30px', marginRight: '0' }} />
    ) : (
      <>
        <Button
          isCircular
          onClick={ () => handleOpenDraftModal(sampleText.id) }
          isDisabled={ isDraftFecthing }
        >
          <Icon className='icon' icon='edit' />
        </Button>
        <Button
          style={{ marginLeft: '0.5em' }}
          isCircular
          type='tertiary'
          isDisabled={ isDraftFecthing }
          onClick={() =>
            setOpenModalConfirm({ openModalConfirm: true, id: sampleText.id })
          }
        >
          <Icon className='icon' icon='delete' />
        </Button>
      </>
    )
  }))

  const handleOpenDraftModal = async (sampleTextId?: number) => {
    setIsDraftFecthing(true)

    if (sampleTextId) {
      await handleSampleTextEdit(sampleTextId)
    } else {
      setIsModalVisible(true)
      setIsDraftFecthing(false)
    }
  }

  const handleSampleTextEdit = async (sampleTextId: number) => {
    setEditingSampleTextId(sampleTextId)
    await getSampleTextBtId(sampleTextId)
  }

  const handleSubmit = async (sampleText: ISampleText) => {
    handleModalClose()

    if (editSampleText.id) {
      await updateSampleText(sampleText)
    } else {
      await createSampleText(sampleText)
    }

    setIsTableUpdated(true)
  }

  const handleModalClose = () => {
    setEditingSampleTextId(0)
    setEditSampleText(initialSampleTextDraft)
    setIsModalVisible(false)
  }

  const handleDeleteConfirm = async () => {
    await deleteSampleText(deleteId)
    // setIsTableUpdated(true)
  }

  const handleSearch = async (query: string) => {
    if (query) await searchSampleTexts(query)
    else await fetchSampleTexts()
  }

  const debounceSearch = debounce(handleSearch, 400)

  useEffect(() => {
    if (!sampleTexts.length) fetchSampleTexts()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledArticle>
      <CustomTable
        title={ formatMessage({ id: 'sampleTexts' }) }
        columns={ sampleTextColumns }
        items={ sampleTextItems }
        loading={ isFetching }
        setValueSearch={ debounceSearch }
        tableUpdated={ isTableUpdated }
        setTableUpdated={ setIsTableUpdated }
        buttonNew={
          <Button
            type='primary'
            style={{ marginLeft: '0.5rem' }}
            onClick={ handleOpenDraftModal }
            isLoading={ false }
          >
            <Icon icon='add' /> {formatMessage({ id: 'addNewSampleText' })}
          </Button>
        }
      />
      <DraftModal
        item={ editSampleText }
        isHidden={ !isModalVisible }
        onClose={ handleModalClose }
        onSubmit={ handleSubmit }
        isCreatingOrUpdating={ isFetching }
      />
      <ModalConfirm
        title={ formatMessage({ id: 'sampleText' }) }
        message={ formatMessage({ id: 'removeSampleTextConfirmation' }) }
        isHidden={ !openModalConfirm }
        isLoading={ isFetching }
        onClose={() => setOpenModalConfirm({ openModalConfirm: false, id: 0 })}
        onConfirm={ handleDeleteConfirm }
      />
    </StyledArticle>
  )
}

export default SampleTexts
