import { Button, Icon } from '@valudio/ui'
import React, { useEffect, useRef, useState } from 'react'
import { isClickingOutside } from '../../helpers'
import { IMenuOption } from '../../models'
import { Option } from './Option'
import StyledDiv from './styles'

interface IProps {
  options: IMenuOption[]
  isDisabled: boolean
  isHidden: boolean
  className?: string
}

export const DropdownMenu: React.FC<IProps> = ({ options, isDisabled, isHidden, className }) => {
  const ref = useRef<HTMLDivElement>(null)
  const listRef = useRef<HTMLUListElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isBottomVisible, setIsBottomVisible] = useState(true)

  const toggleOpen = () => setIsOpen(current => !current)

  const handleDocumentClick =  (event: MouseEvent) => {
    if (isClickingOutside(ref, listRef, event)) setIsOpen(false)
  }

  const isDropdownBottomVisible = () => {
    const parentCoords =  ref && ref.current ? ref?.current.getBoundingClientRect() : undefined
    const dropdownCoords = listRef && listRef.current ? listRef?.current.getBoundingClientRect() : undefined
    const isVisible = !!(parentCoords && dropdownCoords
      && (parentCoords.bottom + dropdownCoords.height) <= (window.innerHeight || document.documentElement.clientHeight))
    setIsBottomVisible(isVisible)
  }

  const menuOptions = isOpen
    ? (
      <ul className={`options ${ isBottomVisible ? 'top' : 'bottom' }`} ref={ listRef }>
        { options.map(o => <Option key={ `menu-option-${ o.label }` } option={ o } onClick={ toggleOpen } />) }
      </ul>
    ) : null

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  useEffect(() => {
    if (isOpen) isDropdownBottomVisible()
  }, [isOpen])

  if (isHidden) return null
  return (
    <StyledDiv ref={ ref } className={ className }>
      <Button onClick={ toggleOpen } type="tertiary" isCircular isDisabled={ isDisabled }>
        <Icon icon="points"/>
      </Button>
      { menuOptions }
    </StyledDiv>
  )
}
