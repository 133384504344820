import { Icon, IOption } from '@valudio/ui'
import classnames from 'classnames'
import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { isClickingOutside } from '../../helpers'
import { Dropdown } from './Dropdown'
import StyledArticle from './styles'

interface IProps {
  options: IOption[]
  placeholder: string
  onChange: (selected: IOption) => void
  currentOption?: IOption
  form?: HTMLFormElement
  className?: string
  isDisabled?: boolean
  isSearching?: boolean
  onSearchChange?: (text: string) => void
  onClearStatus?: () => void
}

export const SearchInput: React.FC<IProps> = ({
  options, className, onChange, currentOption, isDisabled, placeholder, form, onSearchChange, isSearching, onClearStatus
}) => {
  const formRef = useRef<HTMLFormElement>(null)
  const ref = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const { formatMessage } = useIntl()
  const [ selected, setSelected ] = useState<IOption>()
  const [ isOpen, setIsOpen ] = useState(false)

  const handleReset = (event: FormEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setSelected(undefined)
    formRef.current?.reset()
    if (onClearStatus && typeof onClearStatus === 'function') onClearStatus()
  }

  const clearButton = onClearStatus
    ? (
      <button onClick={ handleReset } className={`clear ${ !selected && 'hidden' } `}>
        <Icon icon="close" />
      </button>
    ) : null

  const handleClick = useCallback((option: IOption) => {
    setSelected(option)
    setIsOpen(false)
    onChange(option)
  }, [ onChange ])

  const handleDocumentClick = (event: MouseEvent) => {
    if (isClickingOutside(ref, wrapperRef, event)) setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    if (form) form.addEventListener('reset', () => setSelected(undefined))
    return () => {
      document.removeEventListener('click', handleDocumentClick)
      if (form) form.removeEventListener('reset', () => setSelected)
    }
  }, [form])

  useEffect(() => {
    const input: HTMLInputElement | null = document.querySelector('.input-search')
    if (isOpen && !isDisabled && input) input.focus()
  }, [isOpen, isDisabled])

  useEffect(() => {
    if (currentOption) setSelected(currentOption)
  }, [ currentOption ])

  return (
    <form ref={ formRef || null } onClick={ event => event.preventDefault() } style={{ width: '100%' }}>
      <StyledArticle className={ classnames(className || '', { open: isOpen, disabled: isDisabled}) } ref={ ref }>
        <section ref={ wrapperRef } className="wrapper" onClick={ () => setIsOpen(!isDisabled && !isOpen) }>
          <span className={`${ !selected && 'empty' }`}>{ selected ? selected.label : placeholder }</span>
          { clearButton }
          <Icon className="icon" icon={ isOpen ? 'up' : 'down' }/>
        </section>
        <Dropdown
          placeholder={ formatMessage({ id: 'search' }) }
          isHidden={ !isOpen || !!isDisabled }
          options={ options }
          onClick={ handleClick }
          onTextChange={ onSearchChange }
          isLoading={ isSearching }
        />
      </StyledArticle>
    </form>
  )
}
