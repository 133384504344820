import { Field, Input } from '@valudio/ui'
import React from 'react'
import { useIntl } from 'react-intl'
import { GradeType, IGradeTemplate } from '../../models'

interface IProps {
  gradeTemplateItems: IGradeTemplate
  onChange: (value: { [key: string]: any }) => void
  form?: HTMLFormElement
}

export const GradeTemplate: React.FC<IProps> = ({ gradeTemplateItems, onChange, form }) => {
  const { formatMessage } = useIntl()
  const { first, second, examFirstSemester, third, fourth, examSecondSemester } = gradeTemplateItems
  return (
    <>
      <div className="form-row">
        <div className="field">
          <h5>
            { formatMessage({ id: 'gradeTemplateItems' }) }
          </h5>
        </div>
      </div>
      <div className="form-row">
        <Field label={ `${ formatMessage({ id: 'indicationPeriod' }) }` } className="field grade">
          <Input
            form={ form }
            initialValue={ first.max ? first.max.toString() : undefined }
            onChange={
              value => onChange({
                gradeTemplateItems: {
                  ...gradeTemplateItems,
                  first: { max: value || value === '0' ? Number(value) : null, gradeType: GradeType.First }
                }
              })
            }
            placeholder={ formatMessage({ id: 'maxGrade' }) }
            type="number"
          />
        </Field>
        <Field label={ `${ formatMessage({ id: 'firstPeriod' }) }` }  className="field grade">
          <Input
            form={ form }
            initialValue={ second.max ? second.max.toString() : undefined }
            onChange={
              value => onChange({
                gradeTemplateItems: {
                  ...gradeTemplateItems,
                  second: { max: value || value === '0' ? Number(value) : null, gradeType: GradeType.Second }
                }
              })
            }
            placeholder={ formatMessage({ id: 'maxGrade' }) }
            type="number"
          />
        </Field>
        <Field label={ `${ formatMessage({ id: 'examFirstSemester' }) }` }  className="field grade">
          <Input
            form={ form }
            initialValue={ examFirstSemester.max ? examFirstSemester.max.toString() : undefined }
            onChange={
              value => {
                onChange({
                  gradeTemplateItems: {
                    ...gradeTemplateItems,
                    examFirstSemester: {
                      max: value || value === '0' ? Number(value) : null,
                      gradeType: GradeType.ExamFirstSemester
                    }
                  }
                })
              }
            }
            placeholder={ formatMessage({ id: 'maxGrade' }) }
            type="number"
          />
        </Field>
        <Field label={ `${ formatMessage({ id: 'indicationPeriod' }) }` }  className="field grade">
          <Input
            form={ form }
            initialValue={ third.max ? third.max.toString() : undefined }
            onChange={
              value => onChange({
                gradeTemplateItems: {
                  ...gradeTemplateItems,
                  third: { max: value || value === '0' ? Number(value) : null, gradeType: GradeType.Third }
                }
              })
            }
            placeholder={ formatMessage({ id: 'maxGrade' }) }
            type="number"
          />
        </Field>
        <Field label={ `${ formatMessage({ id: 'secondPeriod' }) }` }  className="field grade">
          <Input
            form={ form }
            initialValue={ fourth.max ? fourth.max.toString() : undefined }
            onChange={
              value => onChange({
                gradeTemplateItems: {
                  ...gradeTemplateItems,
                  fourth: { max: value || value === '0' ? Number(value) : null, gradeType: GradeType.Fourth }
                }
              })
            }
            placeholder={ formatMessage({ id: 'maxGrade' }) }
            type="number"
          />
        </Field>
        <Field label={ `${ formatMessage({ id: 'examSecondSemester' }) }` }  className="field grade">
          <Input
            form={ form }
            initialValue={ examSecondSemester.max ? examSecondSemester.max.toString() : undefined }
            onChange={
              value => {
                onChange({
                  gradeTemplateItems: {
                    ...gradeTemplateItems,
                    examSecondSemester: {
                      max: value || value === '0' ? Number(value) : null,
                      gradeType: GradeType.ExamSecondSemester
                    }
                  }
                })
              }
            }
            placeholder={ formatMessage({ id: 'maxGrade' }) }
            type="number"
          />
        </Field>
      </div>
    </>
  )
}
