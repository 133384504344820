import { Input, IOption, Spinner } from '@valudio/ui'
import classnames from 'classnames'
import React, { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { debounce } from 'ts-debounce'
import { SearchInput, Select } from '..'
import { GradeType, ICommentRowItem } from '../../models'
import { CommentRow } from './CommentRow'
import StyledSection from './styles'

interface IProps {
  studentFilter?: string
  classFilter?: IOption
  items: ICommentRowItem[]
  classes: IOption[]
  isLoading: boolean
  isEditActive: boolean
  isHidden: boolean
  isClassFilterEnabled: boolean
  onCommentChange: (item: ICommentRowItem) => void
  onPeriodChange?: (period: GradeType) => void
  onChangeStudentFilter?: (newStudent: string) => void
  onChangeClassFilter?: (newClass?: IOption) => void
}

export const CommentsTable: React.FC<IProps> = ({
  studentFilter,
  classFilter,
  items,
  classes,
  isLoading,
  isEditActive,
  isHidden,
  isClassFilterEnabled,
  onPeriodChange,
  onCommentChange,
  onChangeStudentFilter,
  onChangeClassFilter
}) => {
  const filterFormRef = useRef<HTMLFormElement>(null)
  const { formatMessage } = useIntl()
  const [filteredStudent, setFilteredStudent] = useState(
    () => studentFilter ?? ''
  )
  const [filteredClass, setFilteredClass] = useState(
    () => classFilter ? classFilter.label : ''
  )
  const [currentPeriod, setCurrentPeriod] = useState<IOption>()

  const periodOptions: IOption[] = [
    { id: '0', label: formatMessage({ id: 'allSaints' }) },
    { id: '1', label: formatMessage({ id: 'christmas' }) },
    { id: '3', label: formatMessage({ id: 'eastern' }) },
    { id: '4', label: formatMessage({ id: 'summer' }) }
  ]

  const handlePeriodChange = (option: IOption) => {
    setCurrentPeriod(option)
    if (onPeriodChange && typeof onPeriodChange === 'function') onPeriodChange(Number(option.id))
  }
  const handleFilterChange = (filter: string) => {
    setFilteredStudent(filter.toLowerCase())
    if (onChangeStudentFilter && typeof onChangeStudentFilter === 'function') {
      onChangeStudentFilter(filter.toLowerCase())
    }
  }
  const debounceSearch = debounce(handleFilterChange, 400)

  const handleCommentChange = (id: number, rawComments: string) => {
    const comments = rawComments.replaceAll(/(?:\r\n|\r|\n)/g, '<br />')
    if (!currentPeriod) return
    const updatedItems = items.find(item => item.id === id)
    if (updatedItems) {
      onCommentChange({ ...updatedItems, comments })
    }
  }

  const studentItems = () => {
    if (filteredStudent && !filteredClass) {
      return items
        .filter(item => item.label.toLowerCase().includes(filteredStudent))
        .map(item => {
          return (
            <CommentRow
              key={ `${ item.id }-comment-${ currentPeriod }` }
              item={ item }
              isEditActive={ isEditActive }
              isLoading={ isLoading }
              isDisabled={ !currentPeriod || item.isEditDisabled }
              onChange={ value => handleCommentChange(item.id, value) }
            />
          )
        })
    } else if (filteredStudent && filteredClass) {
      return items
        .filter(item => item.label.toLowerCase().includes(filteredStudent || filteredClass.toLowerCase()))
        .map(item => {
          return (
            <CommentRow
              key={ `${ item.id }-comment-${ currentPeriod }` }
              item={ item }
              isEditActive={ isEditActive }
              isLoading={ isLoading }
              isDisabled={ !currentPeriod || item.isEditDisabled }
              onChange={ value => handleCommentChange(item.id, value) }
            />
          )
        })
    } else if (!filteredStudent && filteredClass) {
      return items
        .filter(item => item.label.toLowerCase().includes(filteredClass.toLowerCase()))
        .map(item => {
          return (
            <CommentRow
              key={ `${ item.id }-comment-${ currentPeriod }` }
              item={ item }
              isEditActive={ isEditActive }
              isLoading={ isLoading }
              isDisabled={ !currentPeriod || item.isEditDisabled }
              onChange={ value => handleCommentChange(item.id, value) }
            />
          )
        })
    } else {
      return items.map(item => {
        return (
          <CommentRow
            key={ `${ item.id }-comment-${ currentPeriod }` }
            item={ item }
            isEditActive={ isEditActive }
            isLoading={ isLoading }
            isDisabled={ !currentPeriod || item.isEditDisabled }
            onChange={ value => handleCommentChange(item.id, value) }
          />
        )
      })
    }
  }

  const handleClassFilterChange = (option: IOption) => {
    const className = option.label
    setFilteredClass(className)
    if (onChangeClassFilter && typeof onChangeClassFilter === 'function') {
      onChangeClassFilter(option)
    }
  }

  const handleClearClassFilter = () => {
    setFilteredClass('')
    if (onChangeClassFilter && typeof onChangeClassFilter === 'function') {
      onChangeClassFilter(undefined)
    }
  }

  if (isHidden) return null
  return (
    <StyledSection>
      <header className="table-header">
        <div className="column students">
          { isClassFilterEnabled
            ? (
              <SearchInput
                currentOption={ classFilter }
                form={ filterFormRef.current ?? undefined }
                className="select"
                placeholder={ formatMessage({ id: 'chooseClassPlaceholder' }) }
                options={ classes }
                onChange={ handleClassFilterChange }
                isDisabled={ !classes.length }
                onClearStatus={ handleClearClassFilter }
              />
            ) : null
          }
          <Input
            initialValue={ studentFilter }
            onChange={ debounceSearch }
            placeholder={ `${ formatMessage({ id: 'search' }) }...` }
            isDisabled={ false }
          />
        </div>
        <div className="column comments">
          <h2>{ formatMessage({ id: 'comments' }) }</h2>
          { onPeriodChange
            ? (
              <Select
                labelProp="label"
                value={ currentPeriod }
                options={ periodOptions }
                placeholder={ formatMessage({ id: 'selectCommentsPeriod' }) }
                onChange={ handlePeriodChange }
              />
            ) : null
          }
        </div>
      </header>
      <section className={ classnames('table-content', { loading: isLoading }) }>
        { isLoading ? <Spinner /> : studentItems() }
      </section>
    </StyledSection>
  )
}
