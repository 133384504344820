export interface ISampleText {
  id: number
  text: string
  textType: SampleTextType
}

export enum SampleTextType {
  Decision = 0
}

export const initialSampleTextDraft: ISampleText = {
  id: 0,
  text: '',
  textType: SampleTextType.Decision
}
