import styled from 'styled-components'

export default styled.article`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  width: 180px;
  position: relative;
  height: 40px;
  background: ${ ({ theme }) => theme.colors.grey.light };
  padding: 0 8px 0 12px;
  border-radius: 2px;
  border: 1.5px solid transparent;
  cursor: pointer;
  box-sizing: border-box;

  &.open {
    border: 1.5px solid ${ ({ theme }) => theme.colors.primary.medium };
  }

  &.invalid {
    border: 1.5px solid ${ ({ theme }) => theme.colors.system.error };
    .value {
      background: ${ ({ theme }) => theme.colors.system.error }35;
      color: ${ ({ theme }) => theme.colors.system.error };
    }
  }

  &.disabled {
    background: ${ ({ theme }) => theme.colors.grey.medium };
    cursor: not-allowed;
  }

  .wrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
    height: 100%;
    position: relative;

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.empty {
        color: ${ ({ theme }) => theme.colors.typography.light };
        user-select: none;
      }
    }

    .values {
      display: flex;
      flex-direction: row;
      flex: 1;
      overflow: hidden;
      margin: 0 8px 0 0;

      .placeholder {
        color: ${ ({ theme }) => theme.colors.typography.light };
        font-family: ${ ({ theme }) => theme.fontFamily };
        user-select: none;
      }

      .value {
        overflow: hidden;
        margin: 0 8px 0 0;
      }
    }
  }

  .clear {
    position: absolute;
    right: 28px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    background-color: ${ ({ theme }) => theme.colors.grey.medium };
    color: ${ ({ theme }) => theme.colors.typography.dark };
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    i {
      font-size: 15px;
    }

    &:hover {
      background-color: ${ ({ theme }) => theme.colors.primary.light };
      color: ${ ({ theme }) => theme.colors.primary.dark };
    }

    &:active {
      background-color: ${ ({ theme }) => theme.colors.primary.medium };
      color: ${ ({ theme }) => theme.colors.primary.light };
    }

    &.hidden {
      display: none;
    }
  }
`
