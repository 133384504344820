import styled from 'styled-components'

export default styled.li`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  cursor: pointer;
  color: ${ ({ theme }) => theme.colors.typography.medium };

  /* i {
    font-size: 22px;
    line-height: 24px;
    margin-right: 10px;
  } */

  span {
    font-size: 16px;
    font-weight: normal;
  }

  &:not(.disabled):hover {
    background-color: ${ ({ theme }) => theme.colors.primary.light };
    color: ${ ({ theme }) => theme.colors.primary.dark };
  }

  &.disabled {
    user-select: none;
    pointer-events: none;
    background-color: ${ ({ theme }) => theme.colors.grey.light };
    color: ${ ({ theme }) => theme.colors.typography.light };
  }
`
