import {
  IGradeTemplate,
  ITeacherObject,
  IGeneralComment,
  IStudentGrade,
  IStudentGradeLine,
  IGrade,
  IGradeTemplateMaxes
} from '.'

// Data received to mount Reports
export interface IReportDataResponse {
  students: IReportDataStudent[]
}

export interface IReportDataStudent {
  id: number
  idStudent: number
  grades: IReportDataSubject[]
  comments: IGeneralComment[]
  sectionTemplates: IReportDataSectionTemplate[]
}

export interface IReportDataSubject {
  id: number
  comments: string
  idSubject: number
  code: string
  name: string
  hoursPerWeek: number
  section: string
  typeSubject: number
  year: number
  min60percent: boolean
  teachers: ITeacherObject[]
  competencies: IReportDataCompetency[]
  grade: IStudentGrade[]
  totalFirstSemester: string
  totalSecondSemester: string
  total: string
  isDisabled: boolean
  isDeleted: boolean
  isAdministrativeSubject: boolean
  sorting: number
}

export interface IReportDataSectionTemplate {
  finalDecisionTitle: string
  certificateOfCompetenceTitle: string
  certificateOfCompetenceIsVisible: boolean
  sectionTemplateLines: IGrade[]
  comments: string
}

export interface IReportDataCompetency {
  id: number
  name: string
  grade: IStudentGrade[]
  totalFirstSemester: string
  totalSecondSemester: string
  total: string
}

// Data formatted for report templates
export interface IClassReport {
  students: IReport[]
}

export interface IReport {
  showGradeReport: boolean
  showCommentsReport: boolean
  showCompetenciesReport: boolean
  title: string
  titleComments: string
  titleCompetencies: string
  scholarYear: string
  // sorting: number
  student: IReportStudent
  subjects: IReportSubjectData
  generalComments: IReportGeneralComments
  signatures: IReportSignatures
  hasMin60: boolean
  comment60percent: string
}

export interface IReportStudent {
  id: number
  name: string
  lastname: string
  class: string
  section: string
}

export interface IReportSubjectData {
  gradeTemplate: IGradeTemplateMaxes
  mandatories: IReportSubject[]
  electives: IReportSubject[]
  qualification: IReportSubject[]
  internship: IReportSubject[]
  comments: string
}

export interface IReportSubjectTotals {
  totalFirstSemester: string
  totalSecondSemester: string
  total: string
}

export interface IReportSubject {
  id: number
  name: string
  hours: string
  idStudent: number
  // sorting: number
  teachers: ITeacherObject[]
  grades: IGradeTemplate
  totals: IReportSubjectTotals
  competencies: IReportCompetency[]
  comments: string
  min60percent: boolean
  isAdministrativeSubject: boolean
}

export interface IReportCompetency {
  name: string
  grades: IStudentGradeLine
  total: string
}

export interface IReportGradeTemplate {
  first: number
  second: number
  examFirstSemester: number
  third: number
  fourth: number
  examSecondSemester: number
}

export interface IReportGeneralComments {
  finalDecision: IReportComment
  certificateOfCompetence: IReportComment
  additionalExams: IReportAdditionalExam[]
  areSomeAdditionalExams: boolean
  absences: IReportAbsences
}

export interface IReportAdditionalExam {
  typeListAditionalExams: string
  subjectName: string
  date: string
}

export interface IReportComment {
  title: string
  comments: string
  justification: string
  isVisible: boolean
}

export interface IReportAbsences {
  absencesConfirmed: string
  absencesNotConfirmed: string
}

export interface IReportSignatures {
  showDecision: boolean
  finalDecision: string
  showClassLeaderSignature: boolean
  showAdminSignature: boolean
  showParentSignature: boolean
  date: string
  smallDate: string
}

const initialReportStudent: IReportStudent = {
  id: 0,
  name: '',
  lastname: '',
  class: '',
  section: ''
}

export const initialReportGradeTemplate: IReportGradeTemplate = {
  first: 0,
  second: 0,
  examFirstSemester: 0,
  third: 0,
  fourth: 0,
  examSecondSemester: 0
}

const initialReportSubjects: IReportSubjectData = {
  gradeTemplate: initialReportGradeTemplate,
  mandatories: [],
  electives: [],
  qualification: [],
  internship: [],
  comments: ''
}

export const initialReportGeneralComments: IReportGeneralComments = {
  finalDecision: {
    title: '',
    comments: '',
    justification: '',
    isVisible: true
  },
  certificateOfCompetence: {
    title: '',
    comments: '',
    justification: '',
    isVisible: true
  },
  additionalExams: [],
  areSomeAdditionalExams: false,
  absences: {
    absencesConfirmed: '',
    absencesNotConfirmed: ''
  }
}

export const initialSignatures: IReportSignatures = {
  showDecision: true,
  finalDecision: '',
  showClassLeaderSignature: true,
  showAdminSignature: true,
  showParentSignature: true,
  date: '',
  smallDate: ''
}

export const initialReportData: IReport = {
  showGradeReport: true,
  showCommentsReport: true,
  showCompetenciesReport: true,
  title: '',
  titleComments: '',
  titleCompetencies: '',
  scholarYear: '',
  // sorting: 0,
  student: initialReportStudent,
  subjects: initialReportSubjects,
  generalComments: initialReportGeneralComments,
  signatures: initialSignatures,
  hasMin60: false,
  comment60percent: ''
}
