import React from 'react'
import { Icon } from '@valudio/ui'
import { IMenuOption } from '../../../../../models'
import LiStyled from './styles'

interface IProps {
  option: IMenuOption
  onClick?: () => void
}

export const Option: React.FC<IProps> = ({ option, onClick }) => {
  const handleClick = () => {
    if (option.action && typeof option.action === 'function' )option.action()
    if (onClick && typeof onClick === 'function') onClick()
  }

  return (
    <LiStyled className={ option.isDisabled ? 'disabled' : '' } onClick={ handleClick }>
      { option.icon ? <Icon icon={ option.icon } /> : null }
      <span>{ option.label }</span>
    </LiStyled>
  )
}

export default Option
