import { useCallback, useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import axios, { AxiosResponse } from 'axios'
import { SessionContext } from '../context'
import { initialDraftTeacher, ITeacherEditObject, ITeacherObject, ITeacherRow } from '../models'
import { prefix } from '../config'
import { useAuth } from '.'

export const useTeachers = () => {
  const { formatMessage } = useIntl()
  const { setAlert, setLoading } = useContext(SessionContext)
  const { logOut } = useAuth()
  const [teachers, setTeachers] = useState<ITeacherRow[]>([])
  const [editTeacher, setEditTeacher] = useState<ITeacherEditObject>(initialDraftTeacher)
  const [isTeacherFetching, setIsTeacherFetching] = useState(false)
  const bearerToken = localStorage.getItem(`${ prefix }:token`)
  const bearer = 'Bearer ' + bearerToken

  const fetchTeachers = useCallback(async () => {
    setLoading(true)
    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/Teacher/GetAll`, {
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json'
      }
    }).then((response: AxiosResponse<ITeacherEditObject[]>) => {
      const data: ITeacherEditObject[] = response.data
      setTeachers(() => data.map(t => ({...t, fullName: `${ t.firstName } ${ t.lastName }`})))
      setLoading(false)
    }).catch(async (error) => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') console.log('ERROR GET TEACHERS', error)
      }
      setLoading(false)
    }).finally(() => {
      setLoading(false)
    })
  }, [bearer, logOut, setLoading])

  const searchTeachers = useCallback(async (query: string) => {
    setLoading(true)
    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/Teacher/Filter?search=${query}`, {
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json'
      }
    }).then((response: AxiosResponse<ITeacherEditObject[]>) => {
      const data: ITeacherEditObject[] = response.data
      setTeachers(() => data.map(t => ({...t, fullName: `${ t.firstName } ${ t.lastName }`})))
      setTimeout(() => {
        setLoading(false)
      }, 400)
    }).catch((error) => {
      if (process.env.NODE_ENV === 'development') {
        console.log('ERROR SEARCHING TEACHERS', error)
      }
      setLoading(false)
    }).finally(() => {
      setLoading(false)
    })
  }, [bearer, setLoading])

  const createTeacher = async (newTeacher: ITeacherObject) => {
    setLoading(true)
    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/Teacher/Create`,
      JSON.stringify(newTeacher),
      {
        headers: {
          accept: '*/*',
          Authorization: bearer,
          'Content-Type': 'application/json-patch+json'
        }
      }
    ).then(async () => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'created' })
      })
      setTeachers([])
      await fetchTeachers()
    }).catch(async (error) => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') console.log(error)
      }
      setAlert({
        type: 'error',
        message: error.Message ?? `Error creating ${formatMessage({ id: 'teacher' })}`
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  const updateTeacher = async (updatedTeacher: ITeacherEditObject) => {
    setLoading(true)
    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/Teacher/Update`,
      JSON.stringify(updatedTeacher),
      {
        headers: {
          accept: '*/*',
          Authorization: bearer,
          'Content-Type': 'application/json-patch+json'
        }
      }
    ).then(async () => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'updated' })
      })
      setTeachers([])
      await fetchTeachers()
    }).catch(async (error) => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') console.log(error)
      }
      setAlert({
        type: 'error',
        message: error.Message ?? formatMessage({ id: 'genericErrorUpdate' })
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  const deleteTeacher = async (id: number) => {
    setLoading(true)
    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/Teacher/Delete?id=${id}`, undefined, {
      headers: {
        accept: '*/*',
        Authorization: bearer
      }
    }).then(async () => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'deleted' })
      })
      setTeachers([])
      await fetchTeachers()
    }).catch(async (error) => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') console.log(error)
      }
      setAlert({
        type: 'error',
        message: error.Message ?? formatMessage({ id: 'genericErrorDelete' })
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  const getTeacherById = async (id: string) => {
    setIsTeacherFetching(true)
    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/Class/GetOne/${id}`, {
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json'
      }
    }).then((response: AxiosResponse<ITeacherEditObject>) => {
      const data: ITeacherEditObject = response.data
      setEditTeacher(data)
      setIsTeacherFetching(false)
      setIsTeacherFetching(false)
    }).catch(async (error) => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') console.log(error)
      }
      setAlert({
        type: 'error',
        message: error.Message ?? formatMessage({ id: 'genericErrorFetch' })
      })
      setIsTeacherFetching(false)
    })
  }

  const updatePassword = async (userName: string, newPassword: string) => {
    setIsTeacherFetching(true)
    const body = { userName, newPassword }

    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/Teacher/ChangePassword`,
      JSON.stringify(body),
      {
        headers: {
          accept: '*/*',
          Authorization: 'Bearer ' + bearerToken,
          'Content-Type': 'application/json-patch+json'
        }
      }
    ).then(async () => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'passwordUpdated' })
      })
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Password update error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message
            ?? `${ formatMessage({ id: 'errorUpdatePassword' }) }: ${ error.response.data.Message }`
        })
      }
    }).finally(() => setIsTeacherFetching(false))
  }

  return {
    teachers,
    editTeacher,
    isTeacherFetching,
    fetchTeachers,
    searchTeachers,
    createTeacher,
    updateTeacher,
    deleteTeacher,
    getTeacherById,
    updatePassword
  }
}
