import React from 'react'
import StyledLi from './styles'

interface IProps {
  label: string
  onClick: () => void
  isHidden: boolean
}

export const Option: React.FC<IProps> = ({ label, onClick, isHidden }) => {
  if (isHidden) return null
  return (
    <StyledLi className="option" onClick={ onClick }>
      <span className="label">{ label }</span>
    </StyledLi>
  )
}
