import styled from 'styled-components'

export default styled.div`
  min-width: 200px;
  flex-basis: 200px;
  box-sizing: border-box;

  > .menu {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    article.menu > .wrapper {
      display: initial !important;
    }
  }

  .menu {
    .items {
      flex: 1;
      justify-content: flex-start;

      .item {
        flex: 0 1 auto;
      }
    }
  }
`
