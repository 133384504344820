import { Input, Spinner, Table } from '@valudio/ui'
import React, { useEffect } from 'react'
import { ITable } from '../../models'
import Styled from './styles'

export const CustomTable: React.FC<ITable> = ({
  title,
  columns,
  items,
  loading,
  tableUpdated,
  valueSearch,
  setTableUpdated,
  setValueSearch,
  buttonNew
}) => {
  const handleSearch = () => {
    setValueSearch(valueSearch)
    setTableUpdated(false)
  }

  useEffect(() => {
    if (tableUpdated) {
      handleSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableUpdated])

  return (
    <Styled>
      <div className="table-header">
        <h2 style={{ textAlign: 'left' }}>{title}</h2>
        <div className='flex-container'>
          <div className='flex-item first-content search'>
            <form style={{ display: 'flex' }} onSubmit={ e => e.preventDefault() }>
              <Input
                initialValue={valueSearch}
                onChange={setValueSearch}
                placeholder='Search'
              />
              {/* <Button
                type='tertiary'
                style={{ maxWidth: '40px', pointerEvents: 'none' }}
              >
                <Icon icon='search' />
              </Button> */}
            </form>
          </div>
          <div className='flex-item'></div>
          <div className='flex-item second-content add-button'>
            {buttonNew && buttonNew}
          </div>
        </div>
      </div>
      {loading ? (
        <div className='content-spinner'>
          <Spinner />
        </div>
      ) : (
        <div className='relative table'>
          <Table className='custom-table' columns={columns} items={items} />
        </div>
      )}
    </Styled>
  )
}
