import React from 'react'
import { useIntl } from 'react-intl'
import { ITabItem } from '../../../../models'
import StyledNav from './styles'

interface IProps {
  tabs: ITabItem[]
  selectedTab: string
  isVisible: boolean
  isLoading?: boolean
  onClick?: (key: string, isCompetency: boolean) => void
}

export const TabsNav: React.FC<IProps> = ({ tabs, selectedTab, isVisible, isLoading, onClick }) => {
  const { formatMessage } = useIntl()

  const tabItems = () => {
    if (!isVisible) {
      return null
    } else if (tabs.length) {
      return (
        <>
          <div
            className={ selectedTab === 'grades' ? 'active' : '' }
            onClick={ () => handleTabClick('grades') }
          >
            <span>{ formatMessage({ id: 'grades' }) }</span>
          </div>
          {
            tabs.map(tab => (
              <div
                key={ tab.key }
                className={ selectedTab === tab.key ? 'active' : '' }
                onClick={ () => handleTabClick(tab.key, true) }
              >
                <span>{ tab.label }</span>
              </div>
            ))
          }
          <div
            className={ selectedTab === 'comments' ? 'active' : '' }
            onClick={ () => handleTabClick('comments', true) }
          >
            <span>{ formatMessage({ id: 'comments' }) }</span>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div
            className={ selectedTab === 'grades' ? 'active' : '' }
            onClick={ () => handleTabClick('grades') }
          >
            <span>{ formatMessage({ id: 'grades' }) }</span>
          </div>
          <div className="empty"><span> - </span></div>
          <div className="empty"><span> - </span></div>
          <div className="empty"><span> - </span></div>
          <div
            className={ selectedTab === 'comments' ? 'active' : '' }
            onClick={ () => handleTabClick('comments', true) }
          >
            <span>{ formatMessage({ id: 'comments' }) }</span>
          </div>
        </>
      )
    }
  }

  const handleTabClick = (key: string, isCompetency = false) => {
    if (selectedTab !== key && onClick && typeof onClick === 'function') {
      onClick(key, isCompetency)
    }
  }

  return (
    <StyledNav className={ isLoading ? 'loading' : '' }>
      { tabItems() }
    </StyledNav>
  )
}
