import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  
  &.disabled {
    .column {
      color: ${ ({ theme }) => theme.colors.typography.light };
    }
  }
  
  &.deleted {
    .column {
      color: ${ ({ theme }) => theme.colors.system.error };
    }
  }

  .column {
    height: 40px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: ${ ({ theme }) => theme.colors.white };
    margin: 0 8px 8px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    color: ${ ({ theme }) => theme.colors.typography.dark };

    &:last-of-type {
      margin-bottom: 0;
      margin-right: 0;
    }

    &.subjects {
      /* flex: 0.5; */
      width: 228px;

      > span {
        padding: 0 16px;
        box-sizing: border-box;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-lines: 1;
      }
    }

    &.hours {
      width: 42px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;  
    }

    &.semester {
      flex: 1;

      padding: 3px;
      box-sizing: border-box;

      p {
        flex: 1;
        text-align: center;
        box-sizing: border-box;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &.total {
      width: 100px;
      margin-right: 0;
      justify-content: center;
    }

    .spinner.small {
      width: 30px;
      height: 30px;
    }
  }
`
