import { Button, Icon, ITableColumn, ITableItem } from '@valudio/ui'
import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { debounce } from 'ts-debounce'
import { CustomTable, ModalConfirm, ModalEdit } from '../../../components'
import { SessionContext } from '../../../context'
import { useTeachers } from '../../../hooks'
import { IStudentEditObject, ITeacherEditObject, ITeacherObject } from '../../../models'
import ModalNew from './ModalNew'
import StyledDiv from './styles'

const Teachers: React.FC = () => {
  const { formatMessage } = useIntl()
  const {
    id: deleteId,
    openModal,
    openModalEdit,
    openModalConfirm,
    setOpenModal,
    setOpenModalEdit,
    setOpenModalConfirm,
    userEdit,
    loading
  } = useContext(SessionContext)
  const [isTableUpdated, setIsTableUpdated] = useState(false)
  const { teachers, fetchTeachers, searchTeachers, createTeacher, updateTeacher, deleteTeacher } = useTeachers()

  const teachersTableColumns: ITableColumn[] = [
    {
      key: 'id',
      label: '',
      style: {
        flex: 0,
        display: 'none'
      }
    },
    {
      key: 'fullName',
      label: formatMessage({ id: 'fullName' }),
      style: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        textAlign: 'left'
      }
    },
    {
      key: 'userName',
      label: formatMessage({ id: 'userName' }),
      style: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'email',
      label: formatMessage({ id: 'email' }),
      style: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'isAdmin',
      label: formatMessage({ id: 'admin' }),
      className: 'admin',
      style: {
        flex: 0.5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'status',
      label: '',
      style: {
        flex: 0.5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
      }
    }
  ]

  const teachersTableItems: ITableItem[] = teachers.map(teacher => ({
    id: teacher.id,
    fullName: `${ teacher.lastName } ${ teacher.firstName }`,
    userName: teacher.userName,
    email: teacher.email,
    isAdmin: teacher.isAdmin ? <Icon icon="check" className="check-flag" /> : null,
    status: (
      <>
        <Button
          isCircular
          onClick={() => setOpenModalEdit({ openModalEdit: true, userEdit: teacher })}
        >
          <Icon className='icon' icon='edit' />
        </Button>
        <Button
          style={{ marginLeft: '0.5em' }}
          isCircular
          type='tertiary'
          onClick={() => setOpenModalConfirm({ openModalConfirm: true, id: teacher.id })}
        >
          <Icon className='icon' icon='delete' />
        </Button>
      </>
    )
  }))

  const handleSearch = async (query: string) => {
    if (query) await searchTeachers(query)
    else await fetchTeachers()
  }

  const debounceSearch = debounce(handleSearch, 400)

  const handleCreate = async (newTeacher: ITeacherObject) => {
    await createTeacher(newTeacher)
    setIsTableUpdated(true)
    setOpenModal(false)
  }

  const handleUpdate = async (updatedTeacher: ITeacherEditObject | IStudentEditObject) => {
    await updateTeacher(updatedTeacher as ITeacherEditObject)
    setIsTableUpdated(true)
  }

  const handleDeleteConfirm = async () => {
    await deleteTeacher(deleteId)
    setIsTableUpdated(true)
    setOpenModalConfirm({ openModalConfirm: false, id: 0 })
  }

  useEffect(() => {
    if (!teachers.length) fetchTeachers()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledDiv className='App'>
      <CustomTable
        title={ formatMessage({ id: 'teachers' }) }
        columns={ teachersTableColumns }
        items={ teachersTableItems }
        loading={ loading }
        // valueSearch={ query }
        setValueSearch={ debounceSearch }
        tableUpdated={ isTableUpdated }
        setTableUpdated={ setIsTableUpdated }
        buttonNew={
          <Button
            type='primary'
            style={{ marginLeft: '0.5rem' }}
            onClick={() => setOpenModal(!openModal)}
          >
            <Icon icon='add' /> { formatMessage({ id: 'addTeacher' }) }
          </Button>
        }
      />
      <ModalNew
        isHidden={ !openModal }
        title={'Teacher'}
        setTableUpdated={setIsTableUpdated}
        onClose={() => setOpenModal(false)}
        onCreate={ handleCreate }
      />
      <ModalEdit
        isHidden={ !(openModalEdit && !!userEdit.id) }
        title={'Teacher'}
        setTableUpdated={setIsTableUpdated}
        onClose={() => setOpenModalEdit({ openModalEdit: false, userEdit })}
        onUpdate={ handleUpdate }
      />
      <ModalConfirm
        isHidden={ !openModalConfirm }
        title={formatMessage({ id: 'teacher' })}
        message={formatMessage({ id: 'removeTeacherConfirmation' })}
        onClose={() => setOpenModalConfirm({ openModalConfirm: false, id: 0 })}
        onConfirm={ handleDeleteConfirm }
      />
    </StyledDiv>
  )
}

export default Teachers
