import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: column;
  min-width: 60vw;
  width: 100%;
  /* max-width: 1100px; */
  height: 95vh;
  overflow-y: hidden;

  .content-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 1em;
    padding-right: 1em;
    box-shadow: 0 2px 6px 0 rgba(66,74,97,0.12);
  }

  > form  {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: hidden;

    .fields {
      flex: 1;
      overflow: auto;
      background-color: ${({ theme }) => theme.colors.grey.light };
    }

    .form-row {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      flex-wrap: nowrap;
      padding-bottom: 1.5em;
      background-color: white;

      /* &:first-of-type {
        padding-top: 1.5em;
      } */

      .field {
        flex: 1;
        margin-left: 1em;
        margin-right: 1em;

        h5 {
          margin-top: 0;
        }

        &.absent {
          flex: 0.5;
        }

        .label {
          text-align: left;
        }

        input {
          border-radius: 4px;
          max-width: 100%;
          height: 42px;

          &:not(:focus) {
            border: 1px solid ${({ theme }) => theme.colors.grey.medium };
          }

          &[type=number] {
            position: relative;
            top: 1px;
          }
        }
      }
    }

    .exams {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: visible;
      background-color: white;

      > header {
        width: 100%;
        margin-top: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0 1em;
        box-sizing: border-box;
      }

      .list {
        flex: 1;
        padding: 1em 1em 0;
        box-sizing: border-box;
        background-color: ${({ theme }) => theme.colors.grey.light };
        overflow: visible;

        .list__content.compact {
          overflow: visible;

          .scrollable-rows {
            overflow-y: visible;
          }
        }
      }
    }

    .actions {
      width: 100%;
      padding: 1em;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-top: auto;
      background-color: ${({ theme }) => theme.colors.grey.light };
      box-shadow: 0px -2px 5px rgba(66,74,97,0.12);
    }
  }
`
