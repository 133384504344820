import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { GradeInput } from '../pages'
import { privatesRoutes, privatesUrls } from '../routing'

interface IProps {
  isAdmin: boolean
  isClassLeader: boolean
}

const PrivateRoutes: React.FC<IProps> = ({ isAdmin, isClassLeader }) => {
  const routes = isAdmin
    ? privatesRoutes.filter(route => route.isAdminVisible).map((route, i) => {
      return (
        <Route exact path={route.url} key={`${route.url}${i}`}>
          <route.content />
        </Route>
      )
    })
    : isClassLeader
      ? privatesRoutes.filter(route => route.isClassLeaderVisible).map((route, i) => {
        return (
          <Route exact path={route.url} key={`${route.url}${i}`}>
            <route.content />
          </Route>
        )
      })
      : (
        <Route exact path={ privatesUrls.gradeInput }>
          <GradeInput />
        </Route>
      )

  return (
    <>
      { routes }
      <Redirect to={ privatesUrls.gradeInput } />
    </>
  )
}

export default PrivateRoutes
