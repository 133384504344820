import { Button, Icon, ITableColumn, ITableItem, Spinner } from '@valudio/ui'
import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { debounce } from 'ts-debounce'
import { CustomTable, ModalConfirm } from '../../../components'
import { SessionContext } from '../../../context'
import { useClasses, useStudents, useTeachers } from '../../../hooks'
import { IClass, IClassEdit, initialClassDraft } from '../../../models'
import { DraftModal } from './components'
import Styled from './styles'

const Classes: React.FC = () => {
  const { formatMessage } = useIntl()
  const {
    id: deleteId,
    openModalConfirm,
    setOpenModalConfirm
  } = useContext(SessionContext)
  const { teachers, fetchTeachers } = useTeachers()
  const { students, fetchStudents } = useStudents()
  const {
    classes,
    isLoading,
    fetchClasses,
    searchClasses,
    createClass,
    updateClass,
    getClassById,
    editClass,
    setEditClass,
    isClassFetching,
    setIsClassFetching,
    deleteClass
  } = useClasses()
  const [isTableUpdated, setIsTableUpdated] = useState(false)
  const [editingClassId, setEditingClassId] = useState(0)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const classesColumns: ITableColumn[] = [
    {
      key: 'id',
      label: '',
      style: {
        display: 'none'
      }
    },
    {
      key: 'year',
      label: formatMessage({ id: 'year'}),
      style: {
        flex: 0.25,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'code',
      label: formatMessage({ id: 'code'}),
      style: {
        flex: 0.25,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'section',
      label: formatMessage({ id: 'section' }),
      style: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'subSection',
      label: formatMessage({ id: 'subSection' }),
      style: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'status',
      label: '',
      style: {
        flex: 0.2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexBasis: '80px'
      }
    }
  ]
  const classItems: ITableItem[] = classes.map(c => ({
    id: c.id,
    code: c.code,
    section: c.section?.name,
    subSection: c.subSection?.name,
    year: c.year,
    status: isClassFetching && editingClassId && editingClassId === c.id ? (
      <Spinner style={{ width: '30px', height: '30px', marginRight: '0' }} />
    ) : (
      <>
        <Button
          isCircular
          onClick={() => handleOpenDraftModal(c.id)}
          isDisabled={ isClassFetching }
        >
          <Icon className='icon' icon='edit' />
        </Button>
        <Button
          style={{ marginLeft: '0.5em' }}
          isCircular
          type='tertiary'
          isDisabled={ isClassFetching }
          onClick={() =>
            setOpenModalConfirm({ openModalConfirm: true, id: c.id })
          }
        >
          <Icon className='icon' icon='delete' />
        </Button>
      </>
    )
  }))

  const handleSearch = async (query: string) => {
    if (query) await searchClasses(query)
    else await fetchClasses()
  }

  const debounceSearch = debounce(handleSearch, 400)

  const handleOpenDraftModal = async (classId?: number) => {
    setIsClassFetching(true)

    if (classId) {
      handleClassEdit(classId)
    } else {
      setIsModalVisible(true)
    }
    await Promise.all([
      fetchTeachers(),
      fetchStudents()
    ])
  }

  const handleModalClose = () => {
    setEditingClassId(0)
    setEditClass(initialClassDraft)
    setIsModalVisible(false)
    setIsClassFetching(false)
  }

  const handleClassEdit = async (classId: number) => {
    setEditingClassId(classId)
    await getClassById(classId)
    setIsModalVisible(true)
  }

  const handleSubmit = async (classItem: IClassEdit) => {
    const submitClass: IClass = {
      id: classItem.id,
      code: classItem.code,
      name: classItem.name,
      idSection: classItem.section?.id ?? null,
      idSubSection: classItem.subSection?.id ?? null,
      students: classItem.students,
      teachersLeaders: classItem.teachersLeaders,
      year: classItem.year,
      disableIndicationReport: classItem.disableIndicationReport
    }

    handleModalClose()
    if (editClass.id) {
      await updateClass(submitClass)
    } else {
      await createClass(submitClass)
    }

    setIsTableUpdated(true)
    setIsClassFetching(false)
  }

  const handleDeleteConfirm = async () => {
    await deleteClass(deleteId)
    setIsTableUpdated(true)
    setOpenModalConfirm({ openModalConfirm: false, id: editClass.id })
  }

  useEffect(() => {
    if (isTableUpdated) {
      fetchClasses()
      setIsTableUpdated(false)
    }
  }, [fetchClasses, isTableUpdated])

  useEffect(() => {
    if (!classes.length) fetchClasses()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Styled className='App'>
      <CustomTable
        title={ formatMessage({ id: 'classes' }) }
        columns={classesColumns}
        items={classItems}
        loading={ isLoading }
        setValueSearch={ debounceSearch }
        tableUpdated={ isTableUpdated }
        setTableUpdated={setIsTableUpdated}
        buttonNew={
          <Button
            type='primary'
            style={{ marginLeft: '0.5rem' }}
            onClick={ handleOpenDraftModal }
          >
            <Icon icon='add' /> {formatMessage({ id: 'addNewClass' })}
          </Button>
        }
      />
      <ModalConfirm
        isHidden={ !openModalConfirm }
        title={formatMessage({ id: 'class' })}
        message={formatMessage({ id: 'removeClassConfirmation' })}
        onClose={() => setOpenModalConfirm({ openModalConfirm: false, id: editClass.id })}
        onConfirm={ handleDeleteConfirm }
      />
      <DraftModal
        item={ editClass }
        students={ students }
        teachers={ teachers }
        isHidden={ !isModalVisible }
        isCreatingOrUpdating={ isLoading }
        onClose={ handleModalClose }
        onSubmit={ handleSubmit }
      />
    </Styled>
  )
}

export default Classes
