import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider as ValudioProvider } from '@valudio/ui'
import { LanguageProvider, SessionProvider, UiProvider } from '../../context'
import { theme } from '../../theme'

export const Provider: React.FC = ({ children }) => {
  return (
    <ValudioProvider theme={ theme }>
      <LanguageProvider>
        <SessionProvider>
          <UiProvider>
            <BrowserRouter>
              { children }
            </BrowserRouter>
          </UiProvider>
        </SessionProvider>
      </LanguageProvider>
    </ValudioProvider>
  )
}
