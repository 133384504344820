import { Button, Checkbox, Field, Icon, Input, IOption, Modal } from '@valudio/ui'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { GradeTemplate, SearchInput, Select, SelectList } from '../../../../../components'
import {
  IClassRow,
  ICompetencyDraft,
  initialCompetencyDraft,
  initialDraftSubject,
  IStudentRow,
  ISubjectDraft,
  ITeacherRow,
  SubjectTab,
  Year,
  yearSelectOptions
} from '../../../../../models'
import { Competencies } from './Competencies'
import { FormDiv, WarnDiv } from './styles'

interface IProps {
  students: IStudentRow[]
  teachers: ITeacherRow[]
  classes: IClassRow[]
  subjectParents: IOption[]
  isHidden: boolean
  item?: ISubjectDraft
  isCreatingOrUpdating?: boolean
  onClose?: () => void
  onSubmit?: (item: ISubjectDraft) => void
}

export const DraftModal: React.FC<IProps> = ({
  students, teachers, classes, subjectParents, isHidden, item, isCreatingOrUpdating, onClose, onSubmit
}) => {
  const formRef = useRef<HTMLFormElement>(null)
  const { formatMessage } = useIntl()
  const [draft, setDraft] = useState<ISubjectDraft>(initialDraftSubject)
  const [activeTab, setActiveTab] = useState<SubjectTab>(SubjectTab.Competencies)
  const [studentsOptions, setStudentsOptions] = useState<IOption[]>([])
  const [teachersOptions, setTeachersOptions] = useState<IOption[]>([])
  const [classesOptions, setClassesOptions] = useState<IOption[]>([])
  const [selectedStudents, setSelectedStudents] = useState<IOption[]>([])
  const [selectedTeachers, setSelectedTeachers] = useState<IOption[]>([])
  const [selectedClasses, setSelectedClasses] = useState<IOption[]>([])
  const [draftCompetencies, setDraftCompetencies] = useState<ICompetencyDraft[]>([])
  const [usedCompetencyIds, setUsedCompetencyIds] = useState<number[]>([])
  const [areMaxChanged, setAreMaxChanged] = useState(false)
  const [isWarningStepVisible, setIsWarningStepVisible] = useState(false)

  const subjectTypeOptions = [
    { id: '1', name: formatMessage({ id: 'rootType' }) },
    { id: '2', name: formatMessage({ id: 'electiveType' }) },
    { id: '3', name: formatMessage({ id: 'qualificationType' }) },
    { id: '4', name: formatMessage({ id: 'internshipType' }) }
  ]

  const initialSubjectType = () => {
    return subjectTypeOptions.find(st => st.id === draft.typeSubject?.id.toString())
  }

  const initialSubjectParent = () => {
    return subjectParents.find(sp => sp.id === draft.idSubjectParent.toString())
  }

  const isSubmitDisabled = (): boolean => {
    return !draft.year || !draft.idSubjectParent
  }

  const tabContent = (): ReactNode => {
    if (activeTab === SubjectTab.Competencies) {
      return (
        <div className="tab-content">
          <Competencies
            competencies={ draftCompetencies }
            onAdd={ handleAddCompetency }
            onChange={ handleCompetencyChange }
            onDelete={ handleDeleteCompetency }
          />
        </div>
      )
    }
    if (activeTab === SubjectTab.Students) {
      return (
        <div className="tab-content">
          <SelectList
            form={ formRef.current ?? undefined }
            className="students"
            labelProp="value"
            options={ studentsOptions }
            selected={ selectedStudents }
            onChange={ handleStudentsChange }
          />
        </div>
      )
    }
    if (activeTab === SubjectTab.Teachers) {
      return (
        <div className="tab-content">
          <SelectList
            form={ formRef.current ?? undefined }
            className="teachers"
            labelProp="value"
            options={ teachersOptions }
            selected={ selectedTeachers }
            onChange={ handleTeachersChange }
          />
        </div>
      )
    }
    if (activeTab === SubjectTab.Classes) {
      return (
        <div className="tab-content">
          <SelectList
            form={ formRef.current ?? undefined }
            className="teachers"
            labelProp="value"
            options={ classesOptions }
            selected={ selectedClasses }
            onChange={ handleClassesChange }
          />
        </div>
      )
    }
    return null
  }

  const handleChange = (value: { [key: string]: any }): void => {
    setDraft(draft => ({ ...draft, ...value }))
  }

  const handleGradeChange = (value: { [key: string]: any }): void => {
    if (draft?.id) setAreMaxChanged(true)
    setDraft(draft => ({ ...draft, ...value }))
  }

  const handleYearChange = (option: IOption) => {
    const year = Number(option.id) as Year
    handleChange({ year })
  }

  const handleHoursPerWeekChange = (value: string) => {
    const hoursPerWeek = value ? value : null
    handleChange({ hoursPerWeek })
  }

  const handleSubjectTypeChange = (option: IOption) => {
    const typeSubject = { id: Number(option.id), name: option.name }
    handleChange({ typeSubject })
  }

  const handleSubjectParentChange = (option: IOption) => {
    const idSubjectParent = Number(option.id)
    handleChange({ idSubjectParent })
  }

  const handleAddCompetency = () => {
    const newId = usedCompetencyIds.length ? Math.max(...usedCompetencyIds) + 1 : 1
    const competencies: ICompetencyDraft[] = [
      ...draftCompetencies,
      { ...initialCompetencyDraft, rowId: newId }
    ]
    setUsedCompetencyIds(current => [...current, newId])
    setDraftCompetencies(competencies)
    handleChange({ competencies })
  }

  const handleCompetencyChange = (updatedCompetency: ICompetencyDraft) => {
    const competencies = draftCompetencies.map(c => {
      return c.rowId === updatedCompetency.rowId ? updatedCompetency : c
    })
    setDraftCompetencies(competencies)
    if (draft?.id) setAreMaxChanged(true)
    handleChange({ competencies })
  }

  const handleDeleteCompetency = (rowId: number) => {
    const competencies = draftCompetencies.filter(c => c.rowId !== rowId)
    setDraftCompetencies(competencies)
    handleChange({ competencies })
  }

  const handleStudentsChange = (options: IOption[]) => {
    const students = options.map(o => Number(o.id))
    setSelectedStudents(options)
    handleChange({ students })
  }

  const handleTeachersChange = (options: IOption[]) => {
    const teachers = options.map(o => Number(o.id))
    setSelectedTeachers(options)
    handleChange({ teachers })
  }

  const handleClassesChange = (options: IOption[]) => {
    const classes = options.map(o => Number(o.id))
    setSelectedClasses(options)
    handleChange({ classes })
  }

  const handleCleanup = () => {
    setStudentsOptions([])
    setTeachersOptions([])
    setClassesOptions([])
    setSelectedStudents([])
    setSelectedTeachers([])
    setSelectedClasses([])
    setDraftCompetencies([])
    setUsedCompetencyIds([])
    setActiveTab(SubjectTab.Competencies)
    setDraft(initialDraftSubject)
    setAreMaxChanged(false)
    setIsWarningStepVisible(false)
    if (formRef && formRef.current) formRef.current.reset()
  }

  const handleClose = () => {
    handleCleanup()
    if (onClose && typeof onClose === 'function') onClose()
  }

  const handleSubmit = () => {
    if (areMaxChanged) setIsWarningStepVisible(true)
    else {
      if (onSubmit && typeof onSubmit === 'function') onSubmit(draft)
      handleCleanup()
    }
  }

  const handleSubmitFromWarning = () => {
    if (onSubmit && typeof onSubmit === 'function') onSubmit(draft)
    handleCleanup()
  }

  // Set draft item
  useEffect(() => {
    if (item && !draft.id) setDraft(item)
  }, [draft.id, item])

  // Set students select options
  useEffect(() => {
    if (students.length && !studentsOptions.length) {
      const options = students
        .sort((prev, next) => {
          const prevStudent =
            // eslint-disable-next-line max-len
            `${ prev.classYear ?? '' }${ prev.classCode ?? '' }${ prev.lastName }${ prev.firstName }`
              .toLowerCase()
              .replaceAll(' ', '')
          const nextStudent =
            // eslint-disable-next-line max-len
            `${ next.classYear ?? '' }${ next.classCode ?? '' }${ next.lastName }${ next.firstName }`
              .toLowerCase()
              .replaceAll(' ', '')
          return prevStudent > nextStudent
            ? 1
            : -1
        })
        .filter(student => !item?.students.includes(student.id))
        .map(s => {
          return {
            id: s.id.toString(),
            value: `${ s.classYear ?? '' }${ s.classCode ?? '' } ${ s.lastName.toUpperCase() } ${ s.firstName }` }
        })

      setStudentsOptions(options)
    }
  }, [item, students, studentsOptions.length])

  // Set teachers select options
  useEffect(() => {
    if (teachers.length && !teachersOptions.length) {
      const options = teachers.map((teacher: ITeacherRow) => ({
        id: teacher.id.toString(),
        value: `${ teacher.lastName.toUpperCase() } ${ teacher.firstName } (${ teacher.userName })`
      }))
      setTeachersOptions(options)
    }
  }, [teachers, teachersOptions.length])

  // Set classes select options
  useEffect(() => {
    if (classes.length && !classesOptions.length) {
      const options = classes.map((c: IClassRow) => ({
        id: c.id.toString(),
        value: `${ c.year } ${ c.code }`
      }))
      setClassesOptions(options)
    }
  }, [classes, classesOptions.length])

  // Set Initial selected draft students
  useEffect(() => {
    if (students.length && draft.students.length && !selectedStudents.length) {
      const selected = students
        .filter(s => draft.students.includes(s.id))
        .sort((prev, next) => {
          // eslint-disable-next-line max-len
          return `${ prev.classYear ?? '' }${ prev.classCode ?? '' } ${ prev.lastName } ${ prev.firstName }` > `${ next.classYear ?? '' }${ next.classCode ?? '' } ${ next.lastName } ${ next.firstName }`
            ? 1
            : -1
        })
        .map(s => {
          return {
            id: s.id.toString(),
            value: `${ s.classYear ?? '' }${ s.classCode ?? '' } ${ s.lastName.toUpperCase() } ${ s.firstName }` }
        })
      setSelectedStudents(selected)
    }
  }, [draft.students, selectedStudents.length, students, students.length])

  // Set Initial selected draft teachers
  useEffect(() => {
    if (teachers.length && draft.teachers.length && !selectedTeachers.length) {
      const selected = teachers
        .filter(t => draft.teachers.includes(t.id))
        .map(t => ({ id: t.id.toString(), value: `${ t.lastName.toUpperCase() } ${ t.firstName } (${ t.userName })` }))
      setSelectedTeachers(selected)
    }
  }, [draft.teachers, selectedTeachers.length, teachers, teachers.length])

  // Set Initial selected draft classes
  useEffect(() => {
    if (classes.length && draft.classes.length && !selectedClasses.length) {
      const selected = classes
        .filter(c => draft.classes.includes(c.id))
        .map(c => ({ id: c.id.toString(), value: `${ c.year } ${ c.code }` }))
      setSelectedClasses(selected)
    }
  }, [classes, draft.classes, draft.classes.length, selectedClasses.length])

  // Set Initial draft competencies
  useEffect(() => {
    if (draft.competencies && !draftCompetencies.length && !usedCompetencyIds.length) {
      const competenciesIds: number[] = []
      const competenciesRows: ICompetencyDraft[] = draft.competencies.map((c, i) => {
        const rowId = c.rowId ?? i + 1
        competenciesIds.push(rowId)
        return { ...c, rowId }
      })
      setDraftCompetencies(competenciesRows)
      setUsedCompetencyIds(competenciesIds)
    }
  }, [draft.competencies, draftCompetencies.length, usedCompetencyIds.length])

  return (
    <Modal isHidden={ isHidden } onOverlayClick={ handleClose } className="edit-modal">
      {
        isWarningStepVisible
          ? (
            <WarnDiv>
              <div className='content-header'>
                <h3>{ formatMessage({ id: 'modifiedMaxWarning' }) }</h3>
                <Icon
                  className='icon'
                  icon='close'
                  onClick={ handleClose }
                />
              </div>
              <form ref={ formRef }>
                <div className="actions">
                  <Button
                    type="primary"
                    onClick={ handleSubmitFromWarning }
                    isLoading={ isCreatingOrUpdating }
                    isDisabled={ isSubmitDisabled() }
                  >
                    { (formatMessage({id: 'ok' })) }
                  </Button>
                </div>
              </form>
            </WarnDiv>
          ) : (
            <FormDiv>
              <div className='content-header'>
                <h3>{ formatMessage({ id: `${ draft?.id ? 'editTitle' : 'addNewSubject' }` }) }</h3>
                <Icon
                  className='icon'
                  icon='close'
                  onClick={ handleClose }
                />
              </div>
              <form ref={ formRef }>
                <div className="form-row">
                  <Field label={ `${ formatMessage({ id: 'subjectParent' }) } *` } className="field select parent">
                    <SearchInput
                      currentOption={ initialSubjectParent() }
                      form={ formRef.current ?? undefined }
                      options={ subjectParents }
                      className="select-input"
                      placeholder={ formatMessage({ id: 'placeholderSubjectParent' }) }
                      onChange={ handleSubjectParentChange }
                    />
                  </Field>
                  <Field label={ `${ formatMessage({ id: 'year' }) } *` } className="field select year">
                    <Select
                      form={ formRef.current ?? undefined }
                      placeholder={ formatMessage({ id: 'placeholderSubjectYear' }) }
                      labelProp="value"
                      value={ draft.year ? { id: draft.year.toString(), value: draft.year.toString() } : undefined }
                      options={ yearSelectOptions }
                      onChange={ handleYearChange }
                    />
                  </Field>
                  <Field label={ `${ formatMessage({ id: 'hoursPerWeek' }) } *` } className="field hours">
                    <Input
                      form={ formRef.current ?? undefined }
                      initialValue={ draft.hoursPerWeek?.toString() ?? '' }
                      onChange={ handleHoursPerWeekChange }
                      type="number"
                    />
                  </Field>
                  <Field label={ `${ formatMessage({ id: 'subjectType' }) } *` } className="field select">
                    <Select
                      form={ formRef.current ?? undefined }
                      placeholder={ formatMessage({ id: 'placeholderSubjectType' }) }
                      labelProp="name"
                      value={ initialSubjectType() }
                      options={ subjectTypeOptions }
                      onChange={ handleSubjectTypeChange }
                    />
                  </Field>
                  <Field label={ formatMessage({ id: 'code' }) } className="field">
                    <Input
                      form={ formRef.current ?? undefined }
                      initialValue={ draft.code }
                      onChange={ code => handleChange({ code }) }
                      placeholder={ formatMessage({ id: 'placeholderSubjectCode' }) }
                    />
                  </Field>
                  <Field label={ formatMessage({ id: 'name' }) } className="field">
                    <Input
                      form={ formRef.current ?? undefined }
                      initialValue={ draft.name }
                      onChange={ name => handleChange({ name }) }
                      placeholder={ formatMessage({ id: 'placeholderSubjectName' }) }
                    />
                  </Field>
                </div>
                <GradeTemplate
                  gradeTemplateItems={ draft.gradeTemplateItems }
                  onChange={ handleGradeChange }
                  form={ formRef.current ?? undefined }
                />
                <div className="form-row">
                  <div className="field checkbox">
                    <Checkbox
                      value={ draft.min60percent }
                      onChange={ min60percent => handleChange({ min60percent }) }
                    />
                    <span>{ formatMessage({ id: 'min60Percent' }) }</span>
                  </div>
                  <div className="field checkbox">
                    <Checkbox
                      value={ draft.isAdministrativeSubject }
                      onChange={ isAdministrativeSubject => handleChange({ isAdministrativeSubject }) }
                    />
                    <span>{ formatMessage({ id: 'isAdministrativeSubject' }) }</span>
                  </div>
                </div>
                <section className="tabs">
                  <nav>
                    <div
                      className={ `tab ${ activeTab === SubjectTab.Competencies ? 'active' : '' }` }
                      onClick={ () => setActiveTab(SubjectTab.Competencies) }
                    >
                      { formatMessage({ id: 'competencies' }) }
                    </div>
                    <div
                      className={ `tab ${ activeTab === SubjectTab.Students ? 'active' : '' }` }
                      onClick={ () => setActiveTab(SubjectTab.Students) }
                    >
                      { formatMessage({ id: 'students' }) }
                    </div>
                    <div
                      className={ `tab ${ activeTab === SubjectTab.Teachers ? 'active' : '' }` }
                      onClick={ () => setActiveTab(SubjectTab.Teachers) }
                    >
                      { formatMessage({ id: 'teachers' }) }
                    </div>
                    <div
                      className={ `tab ${ activeTab === SubjectTab.Classes ? 'active' : '' }` }
                      onClick={ () => setActiveTab(SubjectTab.Classes) }
                    >
                      { formatMessage({ id: 'classes' }) }
                    </div>
                  </nav>
                  { tabContent() }
                </section>
                <div className="actions">
                  <Button
                    type="primary"
                    onClick={ handleSubmit }
                    isLoading={ isCreatingOrUpdating }
                    isDisabled={ isSubmitDisabled() }
                  >
                    { (formatMessage({id: `${ draft.id ? 'updateSubject' : 'saveSubject' }`})) }
                  </Button>
                </div>
              </form>
            </FormDiv>
          )
      }
    </Modal>
  )
}
