import styled from 'styled-components'

const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 85vw;
  width: 100%;
  max-width: 1100px;
  height: 85vh;
  overflow-y: hidden;

  .content-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 1em;
    padding-right: 1em;
  }

  form {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: hidden;

    .form-row {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: nowrap;

      &:first-of-type {
        margin-bottom: 1.5em;
      }

      .field {
        flex: 1;
        margin-left: 1em;
        margin-right: 1em;

        &.parent {
          flex: 2;

          form {
            overflow-y: visible;
            flex-direction: row;

            .select-input {
              flex: 1;
            }
          }

          .select-container {
            > section {
              width: auto;
            }
          }
        }

        &.checkbox {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-top: 1.5em;
          flex: 0 1 auto;

          span {
            margin-left: 4px;
          }
        }

        h5 {
          margin-top: 0;
        }

        &.hours,
        &.year {
          max-width: 120px;
        }

        &.year {
          .select-container {
            > section {
              max-width: 120px;
              width: 120px;
            }
          }
        }

        &.grade {
          max-width: calc((100% / 6) - 2em);
        }

        &.select {
          /* z-index: 1000; */

          &.year {
            /* width: 80px;
            flex-basis: 80px; */
            flex: 0.4;
          }

          .select-container {
            width: 100%;
            box-sizing: border-box;

            > section {
              z-index: 2000;
            }
          } 
        }

        .label {
          text-align: left;
        }

        input {
          border-radius: 4px;
          max-width: 100%;
        }
      }
    }

    .tabs {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 20px;
      overflow-y: hidden;

      nav {
        width: 100%;
        margin-top: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        div {
          cursor: pointer;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 12px 0;
          box-sizing: border-box;
          border-bottom: 4px solid transparent;
          color: ${({ theme }) => theme.colors.typography.medium };
          font-size: 16px;

          &.active {
            border-bottom-color: ${({ theme }) => theme.colors.primary.medium };
            color: ${({ theme }) => theme.colors.primary.medium };
          }
        }
      }

      .tab-content {
        flex: 1;
        padding: 1em 1em 0;
        box-sizing: border-box;
        background-color: ${({ theme }) => theme.colors.grey.light };
        overflow: hidden;
      }
    }

    .actions {
      width: 100%;
      padding: 1em;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      background-color: ${({ theme }) => theme.colors.grey.light };
    }
  }
`

const WarnDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 35vw;
  width: 100%;
  max-width: 50vw;
  height: auto;
  overflow-y: hidden;

  .content-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 1em;
    padding-right: 1em;
  }

  .actions {
    width: 100%;
    padding: 1em;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.grey.light };
  }
`

export { FormDiv, WarnDiv }
