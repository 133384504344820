import styled from 'styled-components'

export const ContainerAbsolute = styled.div`
  font-family: ${ ({ theme }) => theme.fontFamily };
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #e0e0e033;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const CardForm = styled.div`
  background-color: #FFF;
  padding: 2em;
  padding-bottom: 3em;
  max-width: 450px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  box-shadow: rgba(66, 74, 97, 0.15) 0px 2px 16px 1px;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0 auto;
  position: relative;

  div > label {
    font-weight: 400;
    color: #687494;
  }

  div > .input {
    margin: 0.5rem 0 2rem 0;
    max-width: 400px;
    min-width: 100%;
    border-radius: 4px;
  }

  .alert {
    top: -60px;
    height: auto;
    min-height: 40px;
    max-width: 440px !important;
    width: max-content !important;

    .message {
      font-size: 16px !important;
    }
  }
`

export const Icon = styled.h1`
  font-size: 2.5rem;
  font-weight: 800;
  color: #886bfb;
  text-shadow: 0px 1px 2px #687494;
  text-align: center;
    > span {
      color: #8bde3f;
  }
`

