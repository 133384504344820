import React from 'react'
import { useIntl } from 'react-intl'
import { DropdownMenu } from '../../../../../components'
import { IConsultationSubject, IMenuOption } from '../../../../../models'
import StyledDiv from './styles'

interface IProps {
  subject: IConsultationSubject
  onDisableOrDelete: (disabled: boolean, deleted: boolean) => void
}

export const Row: React.FC<IProps> = ({ subject, onDisableOrDelete }) => {
  const { formatMessage } = useIntl()
  const studentActions: IMenuOption[] = [
    { label: formatMessage({ id: 'disable' }), action: () => onDisableOrDelete(true, false), isDisabled: false },
    { label: formatMessage({ id: 'delete' }), action: () => onDisableOrDelete(false, true), isDisabled: false }
  ]
  return (
    <StyledDiv className={ `${ subject.isDisabled ? 'disabled' : '' } ${ subject.isDeleted ? 'deleted' : '' }` }>
      <div className={ `column subjects${ subject.isDisabled ? ' disabled' : '' }` }>
        <span title={ subject.name }>{ subject.name }</span>
        <DropdownMenu
          options={ studentActions }
          isDisabled={ false }
          isHidden={ false }
        />
      </div>
      <div className="column hours">
        <span>{ subject.hoursPerWeek }</span>
      </div>
      <div className="column semester">
        <p>
          <span>
            { subject.grade.first.note ? Math.round((subject.grade.first.note + Number.EPSILON) * 100) / 100  : '-' }
          </span>
          <span>/ { subject.grade.first.max }</span>
        </p>
        <p>
          <span>
            { subject.grade.second.note ? Math.round((subject.grade.second.note + Number.EPSILON) * 100) / 100 : '-' }
          </span>
          <span>/ { subject.grade.second.max }</span>
        </p>
        <p>
          <span>
            {
              subject.grade.examFirstSemester.note
                ? Math.round((subject.grade.examFirstSemester.note + Number.EPSILON) * 100) / 100
                : '-'
            }
          </span>
          <span>/ { subject.grade.examFirstSemester.max }</span>
        </p>
        <p>
          <span>{ subject.totalFirstSemester !== null ? `${ subject.totalFirstSemester }%` : ' - %' }</span>
        </p>
      </div>
      <div className="column semester">
        <p>
          <span>
            { subject.grade.third.note ? Math.round((subject.grade.third.note + Number.EPSILON) * 100) / 100 : '-' }
          </span>
          <span>/ { subject.grade.third.max }</span>
        </p>
        <p>
          <span>
            { subject.grade.fourth.note ? Math.round((subject.grade.fourth.note + Number.EPSILON) * 100) / 100 : '-' }
          </span>
          <span>/ { subject.grade.fourth.max }</span>
        </p>
        <p>
          <span>
            {
              subject.grade.examSecondSemester.note
                ? Math.round((subject.grade.examSecondSemester.note + Number.EPSILON) * 100) / 100
                : '-'
            }
          </span>
          <span>/ { subject.grade.examSecondSemester.max }</span>
        </p>
        <p>
          <span>{ subject.totalSecondSemester !== null ? `${ subject.totalSecondSemester }%` : ' - %' }</span>
        </p>
      </div>
      <div className="column total">
        <span>
          {
            !subject.isDisabled
              ? subject.total !== null ? `${ subject.total }%` : ' - %'
              : null
          }
        </span>
      </div>
    </StyledDiv>
  )
}
