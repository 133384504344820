import { Button, Checkbox, Field, Icon, Input, IOption, Modal } from '@valudio/ui'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { Select, SelectList } from '../../../../../components'
import { useSections } from '../../../../../hooks'
import {
  ClassTab,
  Year,
  yearSelectOptions,
  IClassEdit,
  initialClassDraft,
  IStudentRow,
  ITeacherRow
} from '../../../../../models'
import Styled from './styles'

interface IProps {
  students: IStudentRow[]
  teachers: ITeacherRow[]
  isHidden: boolean
  item?: IClassEdit
  isCreatingOrUpdating?: boolean
  onClose?: () => void
  onSubmit?: (item: IClassEdit) => void
}

export const DraftModal: React.FC<IProps> = ({
  students, teachers, isHidden, item, isCreatingOrUpdating, onClose, onSubmit
}) => {
  const formRef = useRef<HTMLFormElement>(null)
  const subSectionFormRef = useRef<HTMLFormElement>(null)
  const { formatMessage } = useIntl()
  const { sections, setSections, subSections, setSubSections, fetchSections, fetchSubSections } = useSections()
  const [draft, setDraft] = useState<IClassEdit>(initialClassDraft)
  const [sectionOptions, setSectionOptions] = useState<IOption[]>([])
  const [subSectionOptions, setSubSectionOptions] = useState<IOption[]>([])
  const [studentsOptions, setStudentsOptions] = useState<IOption[]>([])
  const [teachersOptions, setTeachersOptions] = useState<IOption[]>([])
  const [selectedStudents, setSelectedStudents] = useState<IOption[]>([])
  const [selectedTeachers, setSelectedTeachers] = useState<IOption[]>([])
  const [activeTab, setActiveTab] = useState<ClassTab>(ClassTab.Students)
  const [isInitialized, setIsInitialized] = useState(false)

  const tabContent = (): ReactNode => {
    if (activeTab === ClassTab.Students) {
      return (
        <div className="tab-content">
          <SelectList
            form={ formRef.current ?? undefined }
            className="students"
            labelProp="value"
            options={ studentsOptions }
            selected={ selectedStudents }
            onChange={ handleStudentsChange }
            isHidden={ isHidden }
          />
        </div>
      )
    }
    if (activeTab === ClassTab.Teachers) {
      return (
        <div className="tab-content">
          <SelectList
            form={ formRef.current ?? undefined }
            className="teachers"
            labelProp="value"
            options={ teachersOptions }
            selected={ selectedTeachers }
            onChange={ handleTeachersChange }
            isHidden={ isHidden }
          />
        </div>
      )
    }
    return null
  }

  const handleChange = (value: { [key: string]: any }): void => {
    setDraft(draft => ({ ...draft, ...value }))
  }

  const handleYearChange = (option: IOption) => {
    const year = Number(option.id) as Year
    handleChange({ year })
  }

  const handleSectionChange = (option: IOption) => {
    const idSection = Number(option.id)
    const section = { id: idSection, name: option.name }
    handleChange({ section })
    handleChange({ subSection: undefined })
    if (subSectionFormRef && subSectionFormRef.current) subSectionFormRef.current.reset()
    fetchSubSections(idSection)
  }

  const handleSubSectionChange = (option: IOption) => {
    const idSubSection = Number(option.id)
    const subSection = { id: idSubSection, name: option.name }
    handleChange({ subSection })
  }

  const handleStudentsChange = (options: IOption[]) => {
    const students = options.map(o => Number(o.id))
    setSelectedStudents(options)
    handleChange({ students })
  }

  const handleTeachersChange = (options: IOption[]) => {
    const teachersLeaders = options.map(o => Number(o.id))
    setSelectedTeachers(options)
    handleChange({ teachersLeaders })
  }

  const handleCleanup = () => {
    setSections([])
    setSubSections([])
    setSectionOptions([])
    setSubSectionOptions([])
    setStudentsOptions([])
    setTeachersOptions([])
    setSelectedStudents([])
    setSelectedTeachers([])
    setIsInitialized(false)
    setActiveTab(ClassTab.Students)
    setDraft(initialClassDraft)
    if (formRef && formRef.current) formRef.current.reset()
    if (subSectionFormRef && subSectionFormRef.current) subSectionFormRef.current.reset()
  }

  const handleClose = () => {
    handleCleanup()
    if (onClose && typeof onClose === 'function') onClose()
  }

  const handleSubmit = () => {
    if (onSubmit && typeof onSubmit === 'function') onSubmit(draft)
    handleCleanup()
  }

  // Set draft item
  useEffect(() => {
    if (item && !draft.id) setDraft(item)
  }, [draft.id, item])

  // Set students select options
  useEffect(() => {
    if (students.length && !studentsOptions.length) {
      const options = students
        .filter(student => !student.idClass || item?.students.includes(student.id))
        .sort((prev, next) => {
          const prevStudent = `${ prev.lastName }${ prev.firstName }`.toLowerCase().replaceAll(' ', '')
          const nextStudent = `${ next.lastName }${ next.firstName }`.toLowerCase().replaceAll(' ', '')
          return prevStudent > nextStudent
            ? 1
            : -1
        })
        .map((student: IStudentRow) => ({
          id: student.id.toString(),
          value: `${ student.lastName.toUpperCase() } ${ student.firstName }`
        }))
      setStudentsOptions(options)
    }
  }, [item, students, studentsOptions.length])

  // Set teachers select options
  useEffect(() => {
    if (teachers.length && !teachersOptions.length) {
      const options = teachers.map((teacher: ITeacherRow) => ({
        id: teacher.id.toString(),
        value: `${ teacher.lastName.toUpperCase() } ${ teacher.firstName } (${ teacher.userName })`
      }))
      setTeachersOptions(options)
    }
  }, [teachers, teachersOptions.length])

  // Set Initial selected draft students
  useEffect(() => {
    if (!!draft.id && students.length && draft.students.length && !selectedStudents.length) {
      const selected = students
        .filter(s => draft.students.includes(s.id))
        .sort((prev, next) => {
          const prevStudent = `${ prev.lastName }${ prev.firstName }`.toLowerCase().replaceAll(' ', '')
          const nextStudent = `${ next.lastName }${ next.firstName }`.toLowerCase().replaceAll(' ', '')
          return prevStudent > nextStudent
            ? 1
            : -1
        })
        .map((student: IStudentRow) => ({
          id: student.id.toString(),
          value: `${ student.lastName.toUpperCase() } ${ student.firstName }`
        }))
      setSelectedStudents(selected)
    }
  }, [draft, selectedStudents.length, students, students.length])

  // Set Initial selected draft teachers
  useEffect(() => {
    if (!!draft.id && teachers.length && draft.teachersLeaders.length && !selectedTeachers.length) {
      const selected = teachers
        .filter(t => draft.teachersLeaders.includes(t.id))
        .map(t => ({ id: t.id.toString(), value: `${ t.lastName.toUpperCase() } ${ t.firstName } (${ t.userName })` }))
      setSelectedTeachers(selected)
    }
  }, [draft, selectedTeachers.length, teachers, teachers.length])

  // Fetch sections on open
  useEffect(() => {
    if (!isHidden && !sections.length) {
      fetchSections()
    }
  }, [fetchSections, isHidden, item, sectionOptions, sections, setSections, setSubSections])

  // Set Sections options
  useEffect(() => {
    if (sections.length && !sectionOptions.length) {
      setSectionOptions(() => sections.map(s => ({...s, id: s.id.toString()})))
    }
  }, [sections, sectionOptions.length])

  useEffect(() => {
    if (!!draft.section?.id && !isInitialized) {
      setIsInitialized(true)
      fetchSubSections(draft.section.id)
    }
  }, [draft.section, fetchSubSections, isInitialized, subSectionOptions.length])

  // Set SubSections options on Section change
  useEffect(() => {
    setSubSectionOptions(() => subSections.map(s => ({...s, id: s.id.toString()})))
  }, [subSections])

  if (isHidden) return null
  return (
    <Modal isHidden={ isHidden } onOverlayClick={ handleClose } className="edit-modal">
      <Styled>
        <div className='content-header'>
          <h3>{ formatMessage({ id: `${ draft.id ? 'editTitle' : 'addNewClass' }` }) }</h3>
          <Icon
            className='icon'
            icon='close'
            onClick={ handleClose }
          />
        </div>
        <form ref={formRef}>
          <div className="form-row">
            <Field label={ formatMessage({ id: 'year' }) } className="field select year">
              <Select
                form={ formRef.current ?? undefined }
                placeholder={ formatMessage({ id: 'classYearPlaceholder' }) }
                labelProp="value"
                value={ draft.year ? { id: draft.year.toString(), value: draft.year.toString() } : undefined }
                options={ yearSelectOptions }
                onChange={ handleYearChange }
              />
            </Field>
            <Field label={ formatMessage({ id: 'code' }) } className="field">
              <Input
                form={ formRef.current ?? undefined }
                initialValue={ draft.code }
                onChange={ code => handleChange({ code }) }
                placeholder={ formatMessage({ id: 'classCodePlaceholder' }) }
              />
            </Field>
          </div>
          <div className="form-row">
            <Field label={ formatMessage({ id: 'section' }) } className="field select">
              <Select
                form={ formRef.current ?? undefined }
                placeholder={ formatMessage({ id: 'classSectionPlaceholder' }) }
                labelProp="name"
                value={ draft.section ? { id: draft.section.id.toString(), name: draft.section.name } : undefined }
                options={ sectionOptions }
                onChange={ handleSectionChange }
              />
            </Field>
            <form ref={ subSectionFormRef } className="field select">
              <Field label={ formatMessage({ id: 'subSection' }) }>
                <Select
                  form={ subSectionFormRef.current ?? undefined }
                  placeholder={ formatMessage({ id: 'classSubSectionPlaceholder' }) }
                  labelProp="name"
                  value={
                    draft.subSection
                      ? { id: draft.subSection.id.toString(), name: draft.subSection.name }
                      : undefined
                  }
                  options={ subSectionOptions }
                  onChange={ handleSubSectionChange }
                />
              </Field>
            </form>
          </div>
          <div className="form-row" style={{ marginBottom: 0 }}>
            <label className='label field' htmlFor="indication">
              <Checkbox
                value={ draft.disableIndicationReport }
                onChange={ disableIndicationReport => handleChange({ disableIndicationReport }) }
              />
              { formatMessage({ id: 'disableIndicationReport' }) }
            </label>
          </div>
          <section className="tabs">
            <nav>
              <div
                className={ `tab ${ activeTab === ClassTab.Students ? 'active' : '' }` }
                onClick={ () => setActiveTab(ClassTab.Students) }
              >
                { formatMessage({ id: 'students' }) }
              </div>
              <div
                className={ `tab ${ activeTab === ClassTab.Teachers ? 'active' : '' }` }
                onClick={ () => setActiveTab(ClassTab.Teachers) }
              >
                { formatMessage({ id: 'classLeaders' }) }
              </div>
            </nav>
            { tabContent() }
          </section>
          <div className="actions">
            <Button
              type="primary"
              onClick={ handleSubmit }
              isLoading={ isCreatingOrUpdating }
              isDisabled={ !draft.year }
            >
              { (formatMessage({id: `${ draft.id ? 'updateClass' : 'saveClass' }`})) }
            </Button>
          </div>
        </form>
      </Styled>
    </Modal>
  )
}

