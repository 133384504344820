import { Button, Field, Icon, Input, Modal } from '@valudio/ui'
import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { AutocompleteTextarea, AutoResizeTextarea } from '../../../../components'
import { useSampleTexts } from '../../../../hooks'
import {
  GeneralCommentType,
  IAdditionalExam,
  IGeneralComment,
  initialAdditionalExamDraft,
  initialGeneralComment
} from '../../../../models'
import { AdditionalExams } from './AdditionalExams'
import StyledDiv from './styles'

interface IProps {
  student: string
  isHidden: boolean
  generalComments?: IGeneralComment[]
  isUpdatingComments?: boolean
  onClose?: () => void
  onSubmit?: (item: any) => Promise<void>
}

export const CommentsModal: React.FC<IProps> = ({
  student, isHidden, generalComments, isUpdatingComments, onClose, onSubmit
}) => {
  const formRef = useRef<HTMLFormElement>(null)
  const { formatMessage } = useIntl()
  const { sampleTextOptions, fetchSampleTexts } = useSampleTexts()
  const [draft, setDraft] = useState<IGeneralComment[]>([])
  const [draftAdditionalExams, setDraftAdditionalExams] = useState<IAdditionalExam[]>([])
  const [usedAdditionalExamIds, setUsedAdditionalExamIds] = useState<number[]>([])

  const finalDecisionValue = () => {
    const comment = draft.find(c => c.commentType === GeneralCommentType.FinalDecision)
    return comment ? comment.comments.replaceAll('<br />', '\n') : ''
  }
  const finalDecisionJustificationValue = () => {
    const comment = draft.find(c => c.commentType === GeneralCommentType.FinalDecisionJustification)
    return comment ? comment.comments.replaceAll('<br />', '\n') : ''
  }
  const certificateDecision = () => {
    const comment = draft.find(c => c.commentType === GeneralCommentType.CertificateCompetenceDecision)
    return comment ? comment.comments.replaceAll('<br />', '\n') : ''
  }
  const certificateValue = () => {
    const comment = draft.find(c => c.commentType === GeneralCommentType.CertificateCompetenceJustification)
    return comment ? comment.comments.replaceAll('<br />', '\n') : ''
  }
  const initialAbsentJustified = () => {
    const comment = draft.find(c => c.commentType === GeneralCommentType.AbsentDaysJustified)
    return comment ? comment.comments : ''
  }
  const initialAbsentUnjustified = () => {
    const comment = draft.find(c => c.commentType === GeneralCommentType.AbsentDaysUnJustified)
    return comment ? comment.comments : ''
  }

  const handleChange = (commentType: GeneralCommentType ,value: { [key: string]: any }): void => {
    const commentItem = draft.find(comment => comment.commentType === commentType)
    setDraft(() => {
      if (commentItem) {
        return draft.map(c => c.commentType === commentItem.commentType ? { ...commentItem, ...value } : c)
      }
      return [...draft, { ...initialGeneralComment, commentType,  ...value }]
    })
  }

  const handleAddAdditionalExam = () => {
    const newId = usedAdditionalExamIds.length ? Math.max(...usedAdditionalExamIds) + 1 : 1
    const additionalExams: IAdditionalExam[] = [
      ...draftAdditionalExams,
      { ...initialAdditionalExamDraft, rowId: newId }
    ]
    setUsedAdditionalExamIds(current => [...current, newId])
    setDraftAdditionalExams(additionalExams)
    handleChange(GeneralCommentType.AdditionalExams, { commentsToList: additionalExams })
  }

  const handleAdditionalExamChange = (updatedAdditionalExam: IAdditionalExam) => {
    const exams = draftAdditionalExams.map(c => {
      return c.rowId === updatedAdditionalExam.rowId ? updatedAdditionalExam : c
    })
    setDraftAdditionalExams(exams)
    handleChange(GeneralCommentType.AdditionalExams, { commentsToList: exams })
  }

  const handleDeleteAdditionalExam = (rowId: number) => {
    const exams = draftAdditionalExams.filter(a => a.rowId !== rowId)
    setDraftAdditionalExams(exams)
    handleChange(GeneralCommentType.AdditionalExams, { commentsToList: exams })
  }

  const handleCleanup = () => {
    setDraft([])
    setDraftAdditionalExams([])
    setUsedAdditionalExamIds([])
    if (formRef && formRef.current) formRef.current.reset()
  }

  const handleClose = () => {
    handleCleanup()
    if (onClose && typeof onClose === 'function') onClose()
  }

  const handleSubmit = async () => {
    if (onSubmit && typeof onSubmit === 'function') await onSubmit(draft)
    handleClose()
  }

  useEffect(() => {
    fetchSampleTexts()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (generalComments?.length && !draft.length) {
      setDraft(generalComments)
    }
  }, [draft, generalComments])

  useEffect(() => {
    if (draft.length && !draftAdditionalExams.length && !usedAdditionalExamIds.length) {
      const examsComments = draft.find(comment => comment.commentType === GeneralCommentType.AdditionalExams)
      if (examsComments && examsComments.commentsToList.length) {
        const examsIds: number[] = []
        const examsRows: IAdditionalExam[] = examsComments.commentsToList.map((c, i) => {
          const rowId = c.rowId ?? i + 1
          examsIds.push(rowId)
          return { ...c, rowId }
        })
        setDraftAdditionalExams(examsRows)
        setUsedAdditionalExamIds(examsIds)
      }
    }
  }, [draft, draftAdditionalExams.length, usedAdditionalExamIds.length])

  return (
    <Modal
      isHidden={ isHidden }
      onOverlayClick={ handleClose }
      style={{ maxHeight: '95vh' }}
      className="edit-modal"
    >
      <StyledDiv>
        <header className="content-header">
          <h3>{ formatMessage({ id: 'reportComments' }) } - { student }</h3>
          <Icon
            className='icon'
            icon='close'
            onClick={ handleClose }
          />
        </header>
        <form ref={ formRef }>
          <div className="fields">
            {/* <div className="form-row">
            </div> */}
            <div className="form-row">
              <Field label={ formatMessage({ id: 'finalDecision' }) } className="field">
                <AutocompleteTextarea
                  value={ finalDecisionValue() }
                  options={ sampleTextOptions }
                  initialRows={ 4 }
                  textPrefix={ `${ student } erhält ` }
                  onChange={ rawComments => {
                    const comments = rawComments.replaceAll(/(?:\r\n|\r|\n)/g, '<br />')
                    handleChange(GeneralCommentType.FinalDecision, { comments })
                  }}
                />
              </Field>
              <Field label={ formatMessage({ id: 'finalDecisionJustification' }) } className="field">
                <AutoResizeTextarea
                  value={ finalDecisionJustificationValue() }
                  initialRows={ 4 }
                  onBlur={ rawComments => {
                    const comments = rawComments.replaceAll(/(?:\r\n|\r|\n)/g, '<br />')
                    handleChange(GeneralCommentType.FinalDecisionJustification, { comments })
                  }}
                />
              </Field>
            </div>
            <div className="form-row">
              <Field label={ formatMessage({ id: 'certificateCompetenceDecision' }) } className="field">
                <AutocompleteTextarea
                  value={ certificateDecision() }
                  options={ sampleTextOptions }
                  initialRows={ 4 }
                  textPrefix={ `${ student } erhält ` }
                  onChange={
                    rawComments => {
                      const comments = rawComments.replaceAll(/(?:\r\n|\r|\n)/g, '<br />')
                      handleChange(GeneralCommentType.CertificateCompetenceDecision, { comments })
                    }
                  }
                />
              </Field>
              <Field label={ formatMessage({ id: 'certificateCompetenceJustification' }) } className="field">
                <AutoResizeTextarea
                  value={ certificateValue() }
                  initialRows={ 4 }
                  onBlur={
                    rawComments => {
                      const comments = rawComments.replaceAll(/(?:\r\n|\r|\n)/g, '<br />')
                      handleChange(GeneralCommentType.CertificateCompetenceJustification, { comments })
                    }
                  }
                />
              </Field>
            </div>
            <div className="form-row">
              <Field label={ `${ formatMessage({ id: 'absentDaysJustified' }) } *` } className="field absent">
                <Input
                  form={ formRef.current ?? undefined }
                  initialValue={ initialAbsentJustified() }
                  onChange={ comments => handleChange(GeneralCommentType.AbsentDaysJustified, { comments }) }
                  type="number"
                />
              </Field>
              <Field label={ `${ formatMessage({ id: 'absentDaysUnjustified' }) } *` } className="field absent">
                <Input
                  form={ formRef.current ?? undefined }
                  initialValue={ initialAbsentUnjustified() }
                  onChange={ comments => handleChange(GeneralCommentType.AbsentDaysUnJustified, { comments }) }
                  type="number"
                />
              </Field>
            </div>
            <section className="exams">
              <header>
                <h4>{ formatMessage({ id: 'additionalExams' }) }</h4>
              </header>
              <div className="list">
                <AdditionalExams
                  additionalComments={ draftAdditionalExams }
                  onAdd={ handleAddAdditionalExam }
                  onChange={ handleAdditionalExamChange }
                  onDelete={ handleDeleteAdditionalExam }
                />
              </div>
            </section>
          </div>
          <div className="actions">
            <Button
              type="primary"
              onClick={ handleSubmit }
              isLoading={ isUpdatingComments }
            >
              { (formatMessage({ id: 'saveComments' })) }
            </Button>
          </div>
        </form>
      </StyledDiv>
    </Modal>
  )
}
