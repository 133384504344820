export interface ISubjectParent {
  id: number
  code:	string
  name:	string
  sorting: number
}

export const initialDraftSubjectParent: ISubjectParent = {
  id: 0,
  code: '',
  name: '',
  sorting: 0
}
