import { Button, Field, FileInput, Icon, Modal } from '@valudio/ui'
import React, { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { IStudentImportSummary } from '../../../../models'
import StyledArticle from './styles'

interface IProps {
  summary?: IStudentImportSummary
  isHidden: boolean
  isImporting: boolean
  onImportStudents: (file: File) => void
  onClose: () => void
}

export const ImportModal: React.FC<IProps> = ({ summary, isHidden, isImporting, onImportStudents, onClose }) => {
  const formRef = useRef<HTMLFormElement>(null)
  const { formatMessage } = useIntl()
  const [file, setFile] = useState<File>()

  const handleClose = () => {
    setFile(undefined)
    if (formRef && formRef.current) formRef.current.reset()
    if (onClose && typeof onClose === 'function') onClose()
  }

  const handleUpdate = () => {
    if (file) onImportStudents(file)
  }

  if (isHidden) return null
  return (
    <Modal
      isHidden={ isHidden }
      onOverlayClick={ handleClose }
      style={{ zIndex: 4000 }}
    >
      <StyledArticle>
        <header>
          <h3>{ formatMessage({ id: summary ? 'importedSummary' : 'importStudents' }) }</h3>
          <Icon
            className='icon'
            icon='close'
            onClick={ handleClose }
          />
        </header>
        <form ref={ formRef } onSubmit={ event => event.preventDefault() }>
          { summary
            ? (
              <div className="form-column">
                <div>
                  <p><span>{ formatMessage({ id: 'totalImported'}) }:</span> { summary.totalImporteds }</p>
                </div>
                <div>
                  <p><span>{ formatMessage({ id: 'errors'}) }:</span>  { !summary.errors.length ? '0' : '' }</p>
                  <ul>
                    { summary.errors.map(err => (<li key={ err }>- { err }</li>)) }
                  </ul>
                </div>
                <div>
                  <p><span>{ formatMessage({ id: 'warnings'}) }:</span> { !summary.warnings.length ? '0' : '' }</p>
                  <ul>
                    { summary.warnings.map(warn => (<li key={ warn }>- { warn }</li>)) }
                  </ul>
                </div>
              </div>
            ) : (
              <div className="form-row">
                <Field label={ formatMessage({ id: 'file' }) } className="field">
                  <FileInput className="file-input" onChange={ setFile } />
                </Field>
              </div>
            )
          }
        </form>
        <div className="actions">
          { summary
            ? (
              <Button
                type="primary"
                onClick={ handleClose }
              >
                { formatMessage({ id: 'close' }) }
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={ handleUpdate }
                isLoading={ isImporting }
                isDisabled={ !file }
              >
                { formatMessage({ id: 'import' }) }
              </Button>
            )
          }
        </div>
      </StyledArticle>
    </Modal>
  )
}
