import { useCallback, useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import axios, { AxiosResponse } from 'axios'
import { SessionContext } from '../context'
import {
  gradeArrayToStudentGradeLine,
  GradeType,
  IConsultationSubject,
  IConsultationSubjectResponse,
  IGeneralComment,
  initialSubjectGradeDetail,
  IStudentGeneralComments,
  ISubjectGradeCalculation,
  ISubjectGradeCompetencyResponse,
  ISubjectGradeDetail,
  ISubjectGradeResponse,
  ISubjectGradeStudentResponse,
  ISubjectRow
} from '../models'
import { prefix } from '../config'
import { useAuth } from '.'

export const useGrades = () => {
  const { formatMessage } = useIntl()
  const { setAlert } = useContext(SessionContext)
  const { logOut } = useAuth()
  const [subjects, setSubjects] = useState<ISubjectRow[]>([])
  const [subjectDetail, setSubjectDetail] = useState<ISubjectGradeDetail>(initialSubjectGradeDetail)
  const [reportSubjects, setReportSubjects] = useState<IConsultationSubject[]>([])
  const [generalComments, setGeneralComments] = useState<IGeneralComment[]>([])
  const [isFetching, setIsFetching] = useState(false)
  const [isSubjectFetching, setIsSubjectFetching] = useState(false)
  const bearerToken = localStorage.getItem(`${ prefix }:token`)
  const bearer = 'Bearer ' + bearerToken

  const fetchSubjectsByTeacher = useCallback(async (): Promise<void> => {
    setIsFetching(true)

    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/Subject/GetSubjectsByTeacher`, {
      method: 'GET',
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json-patch+json'
      }
    }).then((response: AxiosResponse) => {
      setSubjects(response.data)
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Subjects error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ?? formatMessage({ id: 'genericErrorListFetch' })
        })
      }
    }).finally(() => {
      setIsFetching(false)
    })
  }, [bearer, formatMessage, logOut, setAlert])

  const getSubjectDetail = async (id: string) => {
    setIsSubjectFetching(true)

    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/Grade/GetSubjectTemplateForGradeOne/${ id }`, {
      method: 'GET',
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json'
      }
    }).then((response: AxiosResponse) => {
      const subject: ISubjectGradeResponse = response.data
      const competencies = subject.competencies.map((c: ISubjectGradeCompetencyResponse) => {
        return {
          ...c,
          students: c.students.map(s => ({
            ...s,
            className: `${ s.classYear } ${ s.className }`,
            grade: gradeArrayToStudentGradeLine(s.grade)
          }))
        }
      })
      const students = subject.students.map(s => ({
        ...s,
        className: `${ s.classYear } ${ s.className }`,
        grade: gradeArrayToStudentGradeLine(s.grade)
      }))
      setSubjectDetail({ ...subject, students, competencies })
      setIsSubjectFetching(false)
    }).catch(async error => {
      if (error && error.response && error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Subject fetch error: ', error)
        }
        // Change literal
        setAlert({
          type: 'error',
          message: error.Message ?? formatMessage({ id: 'genericErrorListFetch' })
        })
      }
      setSubjectDetail(initialSubjectGradeDetail)
      setIsSubjectFetching(false)
    })
  }

  const calculateTotals = async (studentRow: ISubjectGradeCalculation): Promise<ISubjectGradeStudentResponse> => {
    return await axios.post(
      `${ process.env.REACT_APP_API_URL }api/v1/Grade/CalculateFullRow`,
      JSON.stringify(studentRow),
      {
        method: 'GET',
        headers: {
          accept: '*/*',
          Authorization: bearer,
          'Content-Type': 'application/json'
        }
      }
    ).then((response: AxiosResponse) => {
      return response.data
    }).catch(async error => {
      if (error && error.response && error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Calculate error: ', error)
        }
        // Change literal
        setAlert({
          type: 'error',
          message: error.Message ?? `Calculate error: ${ error }`
        })
      }
    })
  }

  const saveComments = async (studentRow: ISubjectGradeCalculation): Promise<void> => {
    await axios.post(
      `${ process.env.REACT_APP_API_URL }api/v1/Grade/SaveComments`,
      JSON.stringify(studentRow),
      {
        method: 'GET',
        headers: {
          accept: '*/*',
          Authorization: bearer,
          'Content-Type': 'application/json'
        }
      }
    ).then((response: AxiosResponse<boolean>) => {
      if (response.data) {
        setAlert({
          type: 'success',
          message: formatMessage({ id: 'commentsSaved' })
        })
      } else {
        setAlert({
          type: 'error',
          message: formatMessage({ id: 'commentsSaveError' })
        })
      }
      return response.data
    }).catch(async error => {
      if (error && error.response && error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Save comments error: ', error)
        }
        // Change literal
        setAlert({
          type: 'error',
          message: error.Message ?? `Save comments error: ${ error }`
        })
      }
    })
  }

  const getSubjectGrades = async (id: string, period: GradeType) => {
    setIsSubjectFetching(true)

    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/Grade/GetGradesSubjects/${ id }/${ period }`, {
      method: 'GET',
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json'
      }
    }).then((response: AxiosResponse) => {
      const subjects: IConsultationSubject[] = response.data.map((s: IConsultationSubjectResponse) => {
        return { ...s, grade: gradeArrayToStudentGradeLine(s.grade) }
      })
      setReportSubjects(subjects)
      setIsSubjectFetching(false)
    }).catch(async error => {
      if (error && error.response && error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Subject fetch error: ', error)
        }
        // Change literal
        setAlert({
          type: 'error',
          message: error.Message ?? formatMessage({ id: 'genericErrorListFetch' })
        })
      }
      setSubjectDetail(initialSubjectGradeDetail)
      setIsSubjectFetching(false)
    })
  }

  const getStudentGeneralComments = async (id: string): Promise<IGeneralComment[]> => {
    return await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/Grade/GetCommentsStudent/${ id }`, {
      method: 'GET',
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json'
      }
    }).then((response: AxiosResponse<IGeneralComment[]>) => {
      return response.data
    }).catch(async error => {
      if (error && error.response && error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('General comments fetch error: ', error)
        }
        // Change literal
        setAlert({
          type: 'error',
          message: error.Message ?? formatMessage({ id: 'genericErrorListFetch' })
        })
      }
      setGeneralComments([])
      return []
    })
  }

  const setStudentGeneralComments = async (body: IStudentGeneralComments) => {
    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/Grade/SetGeneralCommentsStudent`,
      JSON.stringify(body),
      {
        headers: {
          accept: '*/*',
          Authorization: bearer,
          'Content-Type': 'application/json'
        }
      }
    ).then(async () => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'updated' })
      })
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Subject update error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ??
          `${ formatMessage({ id: 'genericErrorUpdate' }) } ${ formatMessage({ id: 'subject' }) }`
        })
      }
      setIsFetching(false)
    }).finally(() => {
      setGeneralComments([])
    })
  }

  return {
    subjects,
    subjectDetail,
    setSubjectDetail,
    reportSubjects,
    setReportSubjects,
    isFetching,
    isSubjectFetching,
    setIsSubjectFetching,
    generalComments,
    setGeneralComments,
    fetchSubjectsByTeacher,
    getSubjectDetail,
    calculateTotals,
    saveComments,
    getSubjectGrades,
    getStudentGeneralComments,
    setStudentGeneralComments
  }
}
