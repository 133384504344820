import { Button, Checkbox, DateTime, Field, Icon, Input, Modal } from '@valudio/ui'
import { DateTime as LuxonTime } from 'luxon'
import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { getCertificateScholarYear, getScholarYearTitle } from '../../../../helpers'
import {
  initialReportData,
  initialSignatures,
  IReportGeneralComments,
  IReportUserInputContent
} from '../../../../models'
import StyledArticle from './styles'

interface IProps {
  targetTitle: string
  isHidden: boolean
  isLoading: boolean
  generalComments?: IReportGeneralComments
  onClose?: () => void
  onSubmit?: (report: IReportUserInputContent) => Promise<void>
}

export const ReportModal: React.FC<IProps> = ({
  targetTitle, isHidden, isLoading, generalComments, onClose, onSubmit
}) => {
  const { formatMessage } = useIntl()
  const formRef = useRef<HTMLFormElement>(null)
  const [report, setReport] = useState<IReportUserInputContent>()

  const isSubmitDisabled = !report
    || (report && !report.title)
    || (report && !report.scholarYear)
    || (report && !report.signatures.date)

  const handleChange = (value: { [key: string]: any }): void => {
    setReport((report: any) => ({ ...report, ...value }))
  }

  const handleClose = () => {
    setReport(undefined)
    if (formRef && formRef.current) formRef.current.reset()
    if (onClose && typeof onClose === 'function') onClose()
  }

  const handleSubmit = async () => {
    if (onSubmit && typeof onSubmit === 'function' && report) await onSubmit(report)
    handleClose()
  }

  useEffect(() => {
    if (!report && generalComments) {
      const initialData: IReportUserInputContent = {
        showGradeReport: true,
        showCommentsReport: true,
        showCompetenciesReport: true,
        title: formatMessage({ id: 'titleReport' }),
        titleComments: `${ formatMessage({ id: 'titleReportComments' }) } ${ getCertificateScholarYear() }`,
        titleCompetencies: formatMessage({ id: 'titleReportCompetencies' }),
        scholarYear: getScholarYearTitle(),
        generalComments,
        signatures:{
          ...initialSignatures,
          date: LuxonTime.now().toISO()
        }
      }
      setReport(initialData)
    }
  }, [formatMessage, report, generalComments])

  if (isHidden || !report) return null
  return (
    <Modal
      isHidden={ isHidden }
      onOverlayClick={ handleClose }
      className="report-modal"
    >
      <StyledArticle>
        <header className="content-header">
          <h3>{ formatMessage({ id: 'generateReport' }) } - { targetTitle }</h3>
          <Icon
            className='icon'
            icon='close'
            onClick={ handleClose }
          />
        </header>
        <form ref={ formRef }>
          <div className="fields">
            <div className="form-row title-row" style={{ marginBottom: '1.5em' }}>
              <div className="field checkbox">
                <Checkbox
                  value={ report.showGradeReport }
                  onChange={ showGradeReport => handleChange({ showGradeReport }) }
                />
                <h4>{ formatMessage({ id: 'titleReport' }) }</h4>
              </div>
            </div>
            <div className="form-row" style={{ marginBottom: '1.5em' }}>
              <Field label={ formatMessage({ id: 'title' }) } className="field">
                <Input
                  form={ formRef.current ?? undefined }
                  initialValue={ report.title }
                  onChange={ title => handleChange({ title }) }
                  placeholder={ formatMessage({ id: 'titlePlaceholder' }) }
                />
              </Field>
              <Field label={ formatMessage({ id: 'scholarYear' }) } className="field">
                <Input
                  form={ formRef.current ?? undefined }
                  initialValue={ report.scholarYear }
                  onChange={ scholarYear => handleChange({ scholarYear }) }
                  placeholder={ formatMessage({ id: 'scholarYearPlaceholder' }) }
                />
              </Field>
            </div>
            <div className="form-row">
              <Field label={ formatMessage({ id: 'date' }) } className="field date">
                <DateTime
                  initialValue={ report.signatures.date ?? LuxonTime.now().toISO() }
                  onChange={
                    date => handleChange({
                      signatures: report
                        ? { ...report.signatures, date }
                        : { ...initialReportData.signatures }
                    })
                  }
                  showTime={ false }
                />
              </Field>
            </div>
            <div className="form-row">
              <div className="field checkbox">
                <Checkbox
                  value={ report.signatures.showDecision }
                  onChange={
                    showDecision => handleChange({
                      signatures: report
                        ? { ...report.signatures, showDecision }
                        : { ...initialReportData.signatures }
                    })
                  }
                />
                <span>{ formatMessage({ id: 'showDecision' }) }</span>
              </div>
              <div className="field checkbox">
                <Checkbox
                  value={ report.signatures.showClassLeaderSignature }
                  onChange={
                    showClassLeaderSignature => handleChange({
                      signatures: report
                        ? { ...report.signatures, showClassLeaderSignature }
                        : { ...initialReportData.signatures }
                    })
                  }
                />
                <span>{ formatMessage({ id: 'showClassLeaderSignature' }) }</span>
              </div>
            </div>
            <div className="form-row">
              <div className="field checkbox">
                <Checkbox
                  value={ report.signatures.showAdminSignature }
                  onChange={
                    showAdminSignature => handleChange({
                      signatures: report
                        ? { ...report.signatures, showAdminSignature }
                        : { ...initialReportData.signatures }
                    })
                  }
                />
                <span>{ formatMessage({ id: 'showAdminSignature' }) }</span>
              </div>
              <div className="field checkbox">
                <Checkbox
                  value={ report.signatures.showParentSignature }
                  onChange={
                    showParentSignature => handleChange({
                      signatures: report
                        ? { ...report.signatures, showParentSignature }
                        : { ...initialReportData.signatures }
                    })
                  }
                />
                <span>{ formatMessage({ id: 'showParentSignature' }) }</span>
              </div>
            </div>
            <div className="form-row title-row" style={{ marginBottom: '1.5em' }}>
              <div className="field checkbox">
                <Checkbox
                  value={ report.showCommentsReport }
                  onChange={ showCommentsReport => handleChange({ showCommentsReport }) }
                />
                <h4>{ formatMessage({ id: 'certificateAppendix' }) }</h4>
              </div>
            </div>
            <div className="form-row" style={{ marginBottom: '1.5em' }}>
              <Field label={ formatMessage({ id: 'title' }) } className="field">
                <Input
                  form={ formRef.current ?? undefined }
                  initialValue={ report.titleComments ?? formatMessage({ id: 'titleReportComments' }) }
                  onChange={ titleComments => handleChange({ titleComments }) }
                />
              </Field>
            </div>
            <div className="form-row" style={{ marginBottom: '1.5em' }}>
              <Field label={ formatMessage({ id: 'titleFinalDecision' }) } className="field">
                <Input
                  form={ formRef.current ?? undefined }
                  initialValue={ generalComments?.finalDecision.title }
                  onChange={
                    title => handleChange({
                      generalComments: report
                        ? {
                          ...report.generalComments,
                          finalDecision: { ...report.generalComments.finalDecision, title }
                        }
                        : { ...initialReportData.generalComments }
                    })
                  }
                  placeholder={ formatMessage({ id: 'titlePlaceholder' }) }
                />
              </Field>
            </div>
            <div className="form-row">
              <div className="field checkbox" style={{ marginTop: 0 }}>
                <Checkbox
                  value={ report.generalComments?.certificateOfCompetence.isVisible }
                  onChange={
                    isVisible => handleChange({
                      generalComments: report
                        ? {
                          ...report.generalComments,
                          certificateOfCompetence: { ...report.generalComments.certificateOfCompetence, isVisible }
                        }
                        : { ...initialReportData.generalComments }
                    })
                  }
                />
                <Field
                  label={ formatMessage({ id: 'titleCertificateOfCompetence' }) }
                  className="field"
                  style={{ marginRight: 0 }}
                >
                  <Input
                    form={ formRef.current ?? undefined }
                    initialValue={ generalComments?.certificateOfCompetence.title }
                    onChange={
                      title => handleChange({
                        generalComments: report
                          ? {
                            ...report.generalComments,
                            certificateOfCompetence: { ...report.generalComments.certificateOfCompetence, title }
                          }
                          : { ...initialReportData.generalComments }
                      })
                    }
                    placeholder={ formatMessage({ id: 'titlePlaceholder' }) }
                  />
                </Field>
              </div>
            </div>
            <div className="form-row title-row" style={{ marginBottom: '1.5em' }}>
              <div className="field checkbox">
                <Checkbox
                  value={ report.showCompetenciesReport }
                  onChange={ showCompetenciesReport => handleChange({ showCompetenciesReport }) }
                />
                <h4>{ formatMessage({ id: 'competenciesAppendix' }) }</h4>
              </div>
            </div>
            <div className="form-row" style={{ marginBottom: '100px' }}>
              <Field label={ formatMessage({ id: 'title' }) } className="field">
                <Input
                  form={ formRef.current ?? undefined }
                  initialValue={ report.titleCompetencies ?? formatMessage({ id: 'titleReportCompetencies' }) }
                  onChange={ titleCompetencies => handleChange({ titleCompetencies }) }
                />
              </Field>
            </div>
          </div>
          <div className="actions">
            <Button
              type="primary"
              onClick={ handleSubmit }
              isLoading={ isLoading }
              isDisabled={ isSubmitDisabled }
            >
              { (formatMessage({ id: 'generateReport' })) }
            </Button>
          </div>
        </form>
      </StyledArticle>
    </Modal>
  )
}
