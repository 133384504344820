import React, { createContext, useState } from 'react'
import {
  IAlert,
  IModalConfirm,
  IModalEdit,
  ISession,
  ISessionContext,
  IToast,
  IUserEdit,
  IModalTabEdit,
  IClassEdit,
  IEditId,
  initialUserEdit,
  initialClassDraft
} from '../models'

const SessionContext = createContext<ISessionContext>({
  token: '',
  refreshToken: '',
  username: '',
  userEdit: initialUserEdit,
  classEdit: initialClassDraft,
  isAdmin: false,
  isClassLeader: false,
  errorMessage: false,
  loading: false,
  authorized: false,
  openModal: false,
  openModalEdit: false,
  openModalConfirm: false,
  openModalTabEdit: false,
  id: 0,
  isEdit: false,
  openModalTab: false,
  openToast: false,
  titleToast: '',
  typeToast: '',
  alert: { type: 'info', message: '' },
  setSession: () => undefined,
  setUsername: () => undefined,
  setIsAdmin: () => undefined,
  setIsClassLeader: () => undefined,
  setErrorMessage: () => undefined,
  setLoading: () => undefined,
  setAuthorized: () => undefined,
  setOpenModal: () => undefined,
  setOpenModalEdit: () => undefined,
  setOpenModalConfirm: () => undefined,
  setOpenModalTab: () => undefined,
  setOpenModalTabEdit: () => undefined,
  setOpenToast: () => undefined,
  setAlert: () => undefined,
  setIdEdit: () => undefined
})

const SessionProvider: React.FC = ({ children }) => {
  const isSignedInStorage = localStorage.getItem('isSignedIn')
  const isSignedIn = isSignedInStorage === 'true' ? true : false
  const [token, setToken] = useState('')
  const [refreshToken, setRefreshToken] = useState('')
  const [username, setUsername] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const [isClassLeader, setIsClassLeader] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [authorized, setAuthorized] = useState(isSignedIn)
  const [openModalEdit, setOpenModalEdit] = useState(false)
  const [userEdit, setUserEdit] = useState<IUserEdit>({
    id: 0,
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    password: '',
    roles: [],
    identificationNumber: ''
  })
  const [openModalTabEdit, setOpenModalTabEdit] = useState(false)
  const [classEdit, setClassEdit] = useState<IClassEdit>({
    id: 0,
    code: '',
    section: undefined,
    subSection: undefined,
    name: '',
    students: [],
    teachersLeaders: [],
    year: null,
    disableIndicationReport: false
  })
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [openModalTab, setOpenModalTab] = useState(false)
  const [openToast, setOpenToast] = useState(false)
  const [titleToast, setTitleToast] = useState('')
  const [typeToast, setTypeToast] = useState('')
  const [alert, setAlert] = useState<IAlert | null>(null)
  const [id, setId] = useState(0)
  const [isEdit, setIsEdit] = useState(false)

  const handleSetSession = ({ token, refreshToken }: ISession) => {
    setToken(token)
    setRefreshToken(refreshToken)
  }

  const handleIdEdit = ({id, isEdit}: IEditId) => {
    setId(id)
    setIsEdit(isEdit)
  }

  const handleModalTabEdit = ({ openModalTabEdit, classEdit }: IModalTabEdit) => {
    setOpenModalTabEdit(openModalTabEdit)
    setClassEdit(classEdit)
  }

  const handleModalEdit = ({ openModalEdit, userEdit }: IModalEdit) => {
    setOpenModalEdit(openModalEdit)
    setUserEdit(userEdit)
  }

  const handleModalConfirm = ({ openModalConfirm, id }: IModalConfirm) => {
    setOpenModalConfirm(openModalConfirm)
    setId(id)
  }

  const handleToast = ({ openToast, titleToast, typeToast }: IToast) => {
    setOpenToast(openToast)
    setTitleToast(titleToast)
    setTypeToast(typeToast)
  }

  return (
    <SessionContext.Provider
      value={{
        token,
        refreshToken,
        username,
        isAdmin,
        isClassLeader,
        errorMessage,
        loading,
        openModal,
        openModalEdit,
        openModalTabEdit,
        openModalConfirm,
        openModalTab,
        userEdit,
        classEdit,
        id,
        isEdit,
        openToast,
        titleToast,
        typeToast,
        authorized,
        alert,
        setSession: handleSetSession,
        setUsername,
        setIsAdmin,
        setIsClassLeader,
        setErrorMessage,
        setLoading,
        setOpenModal,
        setOpenModalEdit: handleModalEdit,
        setAuthorized,
        setOpenModalConfirm: handleModalConfirm,
        setOpenModalTab,
        setOpenModalTabEdit: handleModalTabEdit,
        setOpenToast: handleToast,
        setAlert,
        setIdEdit: handleIdEdit
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}

export { SessionContext, SessionProvider }
