import styled from 'styled-components'

export default styled.div`
  position: relative;
  padding-right: 4px;

  button.circular {
    border: none;
    width: 25px;
    height: 25px;
    z-index: 0;

    i {
      font-size: 22px;
      line-height: 24px;
    }
  }

  .options {
    position: absolute;
    left: 38px;
    border-radius: 4px;
    overflow: hidden;
    width: max-content;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.2);
    background-color: ${ ({ theme }) => theme.colors.white };
    padding: 0;
    margin: 0;
    z-index: 20;

    &.top {
      top: -6px;
    }

    &.bottom {
      bottom: -6px;
    }
  }
`
