import { Button, Icon, ITableColumn, ITableItem } from '@valudio/ui'
import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { debounce } from 'ts-debounce'
import { CustomTable, ModalConfirm, ModalEdit } from '../../../components'
import { SessionContext } from '../../../context'
import { useClasses, useStudents } from '../../../hooks'
import {
  initialUserEdit,
  IStudentEditObject,
  IStudentImportSummary,
  IStudentRow,
  ITeacherEditObject
} from '../../../models'
import { ImportModal } from './ImportModal'
import ModalNew from './ModalNew'
import Styled from './styles'

const Students: React.FC = () => {
  const { formatMessage } = useIntl()
  const {
    id: deleteId,
    openModal,
    openModalEdit,
    openModalConfirm,
    setOpenModal,
    setOpenModalConfirm,
    setOpenModalEdit,
    userEdit,
    loading
  } = useContext(SessionContext)
  const [isTableUpdated, setIsTableUpdated] = useState(false)
  const {
    students,
    fetchStudents,
    searchStudents,
    createStudent,
    updateStudent,
    deleteStudent,
    importStudents
  } = useStudents()
  const { classOptions, fetchClasses } = useClasses()
  const [isImportModalVisible, setIsImportModalVisible] = useState(false)
  const [importSummary, setImportSummary] = useState<IStudentImportSummary>()
  const [isImporting, setIsImporting] = useState(false)

  const studentsTableColumns: ITableColumn[] = [
    {
      key: 'id',
      label: '',
      style: {
        flex: 0,
        display: 'none'
      }
    },
    {
      key: 'fullName',
      label: formatMessage({ id: 'fullName' }),
      style: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'identificationNumber',
      label: formatMessage({ id: 'labelIdentificationNumber' }),
      style: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'class',
      label: formatMessage({ id: 'class' }),
      style: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    },
    {
      key: 'status',
      label: '',
      style: {
        flex: 0.5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
      }
    }
  ]

  const studentsTableItems: ITableItem[] = students.map(student => ({
    id: student.id,
    fullName: `${ student.lastName.toUpperCase() } ${ student.firstName }`,
    identificationNumber: student.identificationNumber,
    class: `${ student.classYear ?? '' }${ student.classCode ?? '' }`,
    userName: student.userName,
    email: student.email,
    status: (
      <>
        <Button
          isCircular
          onClick={() => handleOpenEditModal(student) }
        >
          <Icon className='icon' icon='edit' />
        </Button>
        <Button
          style={{ marginLeft: '0.5em' }}
          isCircular
          type='tertiary'
          onClick={() =>
            setOpenModalConfirm({ openModalConfirm: true, id: student.id })
          }
        >
          <Icon className='icon' icon='delete' />
        </Button>
      </>
    )
  }))

  const handleSearch = async (query: string) => {
    if (query) await searchStudents(query)
    else await fetchStudents()
  }

  const debounceSearch = debounce(handleSearch, 400)

  const handleOpenCreateModal = async () => {
    // if (!classOptions.length) await fetchClasses()
    setOpenModal(!openModal)
  }

  const handleOpenEditModal = async (student: IStudentRow) => {
    // if (!classOptions.length) await fetchClasses()
    setOpenModalEdit({ openModalEdit: true, userEdit: student })
  }

  const handleCreate = async (newStudent: IStudentEditObject) => {
    await createStudent(newStudent)
    setIsTableUpdated(true)
    setOpenModal(false)
  }

  const handleUpdate = async (updatedTeacher: ITeacherEditObject | IStudentEditObject) => {
    await updateStudent(updatedTeacher as IStudentEditObject)
    setIsTableUpdated(true)
  }

  const handleDeleteConfirm = async () => {
    await deleteStudent(deleteId)
    setIsTableUpdated(true)
    setOpenModalConfirm({ openModalConfirm: false, id: 0 })
  }

  const handleImportStudents = async (file: File) => {
    setIsImporting(true)

    try {
      const summary = await importStudents(file)
      if (summary) setImportSummary(summary)
    } catch (error) {
      setIsImportModalVisible(false)
      setImportSummary(undefined)
    } finally {
      setIsImporting(false)
    }
  }

  const handleCloseImportModal = () => {
    setIsImportModalVisible(false)
    setImportSummary(undefined)
  }

  useEffect(() => {
    if (!students.length) {
      fetchStudents()
      fetchClasses()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Styled className='App'>
      <CustomTable
        title={ formatMessage({ id: 'students' }) }
        columns={studentsTableColumns}
        items={studentsTableItems}
        loading={ loading }
        setValueSearch={debounceSearch}
        tableUpdated={isTableUpdated}
        setTableUpdated={setIsTableUpdated}
        buttonNew={
          <>
            <Button type="secondary" onClick={ () => setIsImportModalVisible(true) }>
              <Icon icon="share"/> { formatMessage({ id: 'importStudents' }) }
            </Button>
            <Button
              type='primary'
              style={{ marginLeft: '0.5rem' }}
              onClick={ handleOpenCreateModal }
            >
              <Icon icon='add' /> { formatMessage({ id: 'addNewStudent' }) }
            </Button>
          </>
        }
      />
      <ModalNew
        classes={ classOptions }
        isHidden={ !openModal }
        title={'Student'}
        setTableUpdated={setIsTableUpdated}
        onClose={() => setOpenModal(false)}
        onCreate={ handleCreate }
      />
      <ModalEdit
        classes={ classOptions }
        isHidden={ !(openModalEdit && !!userEdit.id) }
        title={'Student'}
        setTableUpdated={setIsTableUpdated}
        onClose={() => setOpenModalEdit({ openModalEdit: false, userEdit: initialUserEdit })}
        onUpdate={ handleUpdate }
      />
      <ModalConfirm
        isHidden={ !openModalConfirm }
        title={formatMessage({ id: 'student' })}
        message={formatMessage({ id: 'removeStudentConfirmation' })}
        onClose={() => setOpenModalConfirm({ openModalConfirm: false, id: 0 })}
        onConfirm={ handleDeleteConfirm }
      />
      <ImportModal
        summary={ importSummary }
        isHidden={ !isImportModalVisible }
        isImporting={ isImporting }
        onImportStudents={ handleImportStudents }
        onClose={ handleCloseImportModal }
      />
    </Styled>
  )
}

export default Students
