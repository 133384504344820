import {
  Classes,
  GradeConsultation,
  GradeInput,
  Login,
  ReferenceGrades,
  SampleTexts,
  Sections,
  Students,
  SubjectParents,
  Subjects,
  Teachers
} from './pages'

export const publicsUrls = {
  login: '/login'
}

export const privatesUrls = {
  gradeInput: '/',
  gradeConsultation: '/consultation',
  teachers: '/teachers',
  students: '/students',
  classes: '/classes',
  subjects: '/subjects',
  subjectParents: '/subject-parents',
  sections: '/sections',
  referenceGrades: '/reference-grades',
  sampleTexts: '/sample-texts'
}

export const publicsRoutes = [
  { url: publicsUrls.login, content: Login }
]

export const privatesRoutes = [
  { url: privatesUrls.gradeInput, content: GradeInput, isAdminVisible: true, isClassLeaderVisible: true },
  { url: privatesUrls.gradeConsultation, content: GradeConsultation, isAdminVisible: true, isClassLeaderVisible: true },
  { url: privatesUrls.teachers, content: Teachers, isAdminVisible: true, isClassLeaderVisible: false },
  { url: privatesUrls.students, content: Students, isAdminVisible: true, isClassLeaderVisible: false },
  { url: privatesUrls.classes, content: Classes, isAdminVisible: true, isClassLeaderVisible: false },
  { url: privatesUrls.subjects, content: Subjects, isAdminVisible: true, isClassLeaderVisible: false },
  { url: privatesUrls.subjectParents, content: SubjectParents, isAdminVisible: true, isClassLeaderVisible: false },
  { url: privatesUrls.sections, content: Sections, isAdminVisible: true, isClassLeaderVisible: false },
  { url: privatesUrls.referenceGrades, content: ReferenceGrades, isAdminVisible: true, isClassLeaderVisible: false },
  { url: privatesUrls.sampleTexts, content: SampleTexts, isAdminVisible: true, isClassLeaderVisible: false }
]
