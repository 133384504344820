import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Icon, IInputProps, IOption } from '@valudio/ui'
import { isChildNode } from '../../helpers'
import Dropdown from './Dropbox'
import Item from './Item'
import StyledArticle from './styles'

interface IProps extends IInputProps<IOption> {
  labelProp: string
  options: IOption[]
  value?: IOption
}

// eslint-disable-next-line max-len
export const Select: React.FC<IProps> = ({ value, isHidden, className, style, isDisabled, isInvalid, options, labelProp, onChange, placeholder }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [ isOpen, setIsOpen ] = useState(false)
  // const [ selected, setSelected ] = useState<IOption | undefined>(() =>
  //   value ?? undefined
  // )
  const isDisabledOrEmpty = isDisabled || !options || !!options && !options.length
  const classNames = `
    select-container
    ${ className || '' }
    ${ value ? 'filled' : '' }
    ${ isOpen ? 'active' : '' }
    ${ isInvalid ? 'invalid' : '' }
    ${ isDisabledOrEmpty ? 'disabled' : '' }
`

  const handleChange = (option: IOption) => {
    // setSelected(option)
    onChange(option)
    setIsOpen(false)
  }

  const items = options.map(x =>
    <Item key={ x.id } isSelected={ value?.id === x.id } onClick={ handleChange.bind(undefined, x) }>
      { x[labelProp] }
    </Item>
  )

  const handleDocumentClick = useCallback((event: Event) => {
    if ((isOpen && !isChildNode(ref.current, event.target)) || ref.current !== event.target) setIsOpen(false)
  }, [isOpen])

  useEffect(() => {
    // if (form) {
    //   form.addEventListener('reset', () => {
    //     const input: HTMLInputElement | null = document.querySelector(`input.value.${ className }`)
    //     if (input) input.value = ''
    //   })
    // }
    document.addEventListener('click', handleDocumentClick)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [handleDocumentClick])

  if (isHidden) return null
  return (
    <StyledArticle
      className={ classNames }
      style={ style }
      onClick={ setIsOpen.bind(undefined, !isDisabledOrEmpty && !isOpen) }
      ref={ ref }
    >
      <input
        className="value"
        placeholder={ placeholder }
        value={ value ? value[labelProp] : '' }
        readOnly
      />
      <Icon className="icon" icon={ isOpen && !isDisabledOrEmpty ? 'up' : 'down' }/>
      <Dropdown parentRef={ ref } isHidden={ !isOpen || isDisabledOrEmpty }>{ items }</Dropdown>
    </StyledArticle>
  )
}

export default Select
