import { defaultTheme, ITheme } from '@valudio/ui'

const theme: ITheme = {
  ...defaultTheme,
  fontFamily: '\'Lato\', sans-serif',
  colors: {
    ...defaultTheme.colors,
    primary: {
      light: '#c4e9ff',
      medium: '#082f69',
      dark: '#041939'
    }
  }
}

export { theme }
