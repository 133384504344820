import styled, { keyframes } from 'styled-components'

const bounceAnimation = keyframes`
  0%, 100% {
    transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
  }
`

export default styled.article`
  width: 30px;
  height: 30px;
  position: relative;
  margin: auto;

  .double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${ ({ theme }) => theme.colors.primary.medium };
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: ${ bounceAnimation } 2.0s infinite ease-in-out;
  }
  .double-bounce2 {
    animation-delay: -1.0s;
  }
`
