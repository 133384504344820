import { Icon, Modal } from '@valudio/ui'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext } from '../../context'
import { IModal } from '../../models'
import Styled from './styles'

export const ModalNew = ({ title, children, isHidden, onClose }: IModal) => {
  const { formatMessage } = useIntl()
  const { openModal, setOpenModal } = useContext(SessionContext)

  return (
    <Modal isHidden={ isHidden } onOverlayClick={ onClose }>
      <Styled>
        <div className='content-header'>
          <h3>
            {`${ title === 'Teacher' ? formatMessage({ id: 'addTeacher' }) : formatMessage({ id: 'addNewStudent' }) }`}
          </h3>
          <Icon
            className='icon'
            icon='close'
            onClick={() => setOpenModal(!openModal)}
          />
        </div>
        <form className='container-form'>{children}</form>
      </Styled>
    </Modal>
  )
}
