import { Field, IOption } from '@valudio/ui'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { CommentsTable, SearchInput, Switch } from '../../components'
import { addWheelScrollEventListener, hasGradesNegativeValues, removeWheelScrollEventListener } from '../../helpers'
import { useGrades } from '../../hooks'
import {
  gradeArrayToStudentGradeLine,
  GradeType,
  ICommentRowItem,
  initialSubjectGradeDetail,
  initialSubjectGradeStudent,
  ISubjectGradeCalculation,
  ISubjectGradeStudent,
  ISubjectGradeStudentResponse,
  ISubjectRow,
  ITabItem,
  studentGradeLineToGradeArray
} from '../../models'
import { GradesTable, TabsNav } from './components'
import StyledArticle from './styles'

const GradeInput: React.FC = () => {
  const { formatMessage } = useIntl()
  const {
    subjects,
    subjectDetail,
    setSubjectDetail,
    fetchSubjectsByTeacher,
    getSubjectDetail,
    calculateTotals,
    saveComments,
    isSubjectFetching,
    setIsSubjectFetching
  } = useGrades()
  const [isEditActive, setIsEditActive] = useState(false)
  const [subjectOptions, setSubjectOptions] = useState<IOption[]>([])
  const [classesOptions, setClassesOptions] = useState<IOption[]>([])
  const [selectedTab, setSelectedTab] = useState('grades')
  const [isCompetencySelected, setIsCompetencySelected] = useState(false)
  const [commentsPeriod, setCommentsPeriod] = useState<GradeType>()
  const [studentFilter, setStudentFilter] = useState('')
  const [classFilter, setClassFilter] = useState<IOption>()
  const isEditDisabled = selectedTab === 'grades' && !!subjectDetail.competencies.length
  const tabs: ITabItem[] = subjectDetail.competencies.map(c => ({
    key: c.id.toString(),
    label: c.name
  }))

  const toggleEditMode = () => {
    setIsEditActive(!isEditActive)
  }

  const handleSelectTab = async (id: string, isCompetency: boolean) =>  {
    setSelectedTab(id)
    setIsCompetencySelected(isCompetency)
    setCommentsPeriod(undefined)
    if (selectedTab !== 'grades' && !isCompetency) {
      await getSubjectDetail(subjectDetail.id.toString())
    }
    if (isEditActive && !isEditDisabled) {
      handleFocusFirstInput()
    }
  }

  const handleSelectSubject = async (option: IOption) => {
    const subjectId = option.id
    setSubjectDetail(initialSubjectGradeDetail)
    setSelectedTab('grades')
    setClassesOptions([])
    setClassFilter(undefined)
    await getSubjectDetail(subjectId)
    handleFocusFirstInput()
  }

  const handlePeriodChange = (period: GradeType) => {
    setIsSubjectFetching(true)
    setCommentsPeriod(period)
    setTimeout(() => {
      setIsSubjectFetching(false)
    }, 300)
  }

  const handleGradesChange = async (
    updatedStudents: ISubjectGradeStudent[], student = initialSubjectGradeStudent
  ): Promise<void> => {
    const calculatedStudent = !hasGradesNegativeValues(student)
      ? await handleGradesCalculation(student)
      : {
        id: student.id,
        firstName: student.firstName,
        lastName: student.lastName,
        className: student.className,
        classYear: student.classYear,
        grade: student.grade,
        totalFirstSemester: student.totalFirstSemester,
        totalSecondSemester: student.totalSecondSemester,
        total: student.total
      }
    const students = updatedStudents.map(s => {
      return s.id === student.id ?
        {
          ...student,
          totalFirstSemester: calculatedStudent.totalFirstSemester,
          totalSecondSemester: calculatedStudent.totalSecondSemester,
          total: calculatedStudent.total
        } : s
    })
    if (isCompetencySelected) {
      const competencies = subjectDetail.competencies.map(c => {
        return c.id.toString() === selectedTab ? { ...c, students } : c
      })
      setSubjectDetail(current => ({ ...current, competencies }))
    } else {
      setSubjectDetail(current => ({ ...current, students }))
    }
  }

  const handleStudentsCommentsChange = async (updatedItem: ICommentRowItem) => {
    const student = subjectDetail.students.find(s => updatedItem.id === s.id)
    if (student) {
      const arrayGradeStudent = {
        ...student,
        grade: studentGradeLineToGradeArray(student.grade)
      }
      const gradesWithComments = arrayGradeStudent.grade.map(g => {
        return g.gradeType === commentsPeriod
          ? { ...g, comments: updatedItem.comments }
          : g
      })
      const grade = gradeArrayToStudentGradeLine(gradesWithComments)
      await handleSaveComments({
        ...arrayGradeStudent,
        grade
      })
      const students = subjectDetail.students.map(s => {
        return s.id === student.id ?
          {
            ...student,
            grade
          } : s
      })
      setSubjectDetail(current => ({ ...current, students }))
    }
  }

  const handleGradesCalculation = async (student: ISubjectGradeStudent): Promise<ISubjectGradeStudentResponse> => {
    const rowCalculation: ISubjectGradeCalculation = {
      idStudent: student.id,
      idSubject: subjectDetail.id,
      idCompetency: isCompetencySelected ? Number(selectedTab) : null,
      lines: studentGradeLineToGradeArray(student.grade)
    }
    return await calculateTotals(rowCalculation)
  }

  const handleSaveComments = async (student: ISubjectGradeStudent): Promise<void> => {
    const rowCalculation: ISubjectGradeCalculation = {
      idStudent: student.id,
      idSubject: subjectDetail.id,
      idCompetency: isCompetencySelected ? Number(selectedTab) : null,
      lines: studentGradeLineToGradeArray(student.grade)
    }
    await saveComments(rowCalculation)
  }

  const handleFocusFirstInput = () => {
    setTimeout(() => {
      const inputs = document.querySelectorAll('input.note-input')
      if (inputs.length) {
        const firstInput: HTMLInputElement = inputs[0] as HTMLInputElement
        firstInput.focus()
      }
    }, 500)
  }

  const tabContent = () => {
    if (selectedTab === 'grades') {
      return (
        <GradesTable
          studentFilter={ studentFilter }
          classFilter={ classFilter }
          students={ subjectDetail.students }
          classes={ classesOptions }
          isEditActive={ isEditActive }
          isEditDisabled={ isEditDisabled }
          isLoading={ isSubjectFetching }
          isHidden={ selectedTab !== 'grades' }
          onChange={ handleGradesChange }
          onChangeStudentFilter={ setStudentFilter }
          onChangeClassFilter={ setClassFilter }
        />
      )
    } else if (selectedTab === 'comments') {
      const studentComments: ICommentRowItem[] = subjectDetail.students
        .sort((prev, next) => {
          const prevStudent =
            `(${ prev.className })${ prev.lastName }${ prev.firstName }`.toLowerCase().replaceAll(' ', '')
          const nextStudent =
            `(${ next.className })${ next.lastName }${ next.firstName }`.toLowerCase().replaceAll(' ', '')
          return prevStudent > nextStudent
            ? 1
            : -1
        })
        .map(student => {
          const grades = studentGradeLineToGradeArray(student.grade)
          const periodComments = grades.find(grade => grade.gradeType === commentsPeriod)?.comments
          //const isEditDisabled = grades.every(grade => !grade.note && grade.note !== 0)
          return {
            id: student.id,
            label: `${ student.lastName.toUpperCase() } ${ student.firstName } (${ student.className })`,
            comments: periodComments ?? '',
            isEditDisabled: false
          }
        })

      return (
        <CommentsTable
          studentFilter={ studentFilter }
          classFilter={ classFilter }
          items={ studentComments }
          classes={ classesOptions }
          isLoading={ isSubjectFetching }
          isEditActive={ isEditActive }
          isHidden={ selectedTab !== 'comments' }
          onPeriodChange={ handlePeriodChange }
          onCommentChange={ handleStudentsCommentsChange }
          isClassFilterEnabled
        />
      )
    } else {
      const competency = subjectDetail.competencies.find(c => c.id.toString() === selectedTab)
      const students = competency?.students ?? []
      // ? competency?.students.map(s => ({ ...s, className: `${ s.classYear } ${ s.className }` }))
      // : []
      return (
        <GradesTable
          studentFilter={ studentFilter }
          classFilter={ classFilter }
          classes={ classesOptions }
          students={ students }
          isEditActive={ isEditActive }
          isEditDisabled={ isEditDisabled }
          isLoading={ isSubjectFetching }
          isHidden={ !competency }
          onChange={ handleGradesChange }
          onChangeStudentFilter={ setStudentFilter }
          onChangeClassFilter={ setClassFilter }
          competencyId={ competency?.id }
        />
      )
    }
  }

  useEffect(() => {
    fetchSubjectsByTeacher()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!subjects.length && subjectOptions.length) return
    const options = subjects
      .sort((prev, next) => {
        return `${ prev.year } - ${ prev.name }` > `${ next.year } - ${ next.name }` ? 1 : -1
      })
      .map((subject: ISubjectRow) => ({
        id: subject.id.toString(),
        label: `${ subject.year } - ${ subject.name } ${ subject.hoursPerWeek }h`
      }))
    setSubjectOptions(options)
  }, [subjectOptions.length, subjects])

  useEffect(() => {
    if (!isEditActive || isEditDisabled) return
    handleFocusFirstInput()
    addWheelScrollEventListener()
  }, [isEditActive, isEditDisabled])

  useEffect(() => {
    const allClassesAvailable: string[] = Array.from(new Set(subjectDetail.students.map(student => student.className)))
    setClassesOptions(() => {
      return allClassesAvailable
        .sort((prev, next) => prev > next ? 1 : -1)
        .map(c => ({ id: c, label: c }))
    })
  }, [subjectDetail.students])

  useEffect(() => {
    return () => {
      removeWheelScrollEventListener()
    }
  }, [])

  return (
    <StyledArticle>
      <header>
        <h2>{ formatMessage({ id: 'grades' }) }</h2>
        <div className="edit">
          <Switch value={ isEditActive } onChange={ toggleEditMode } />
          <span>{ formatMessage({ id: 'editMode' }) }</span>
        </div>
        <div className="subject-filter">
          <Field label={ formatMessage({ id: 'subject' }) } className="subject-select__container">
            <SearchInput
              className="subject-select"
              placeholder={ formatMessage({ id: 'choosePlaceholder' }) }
              options={ subjectOptions }
              onChange={ handleSelectSubject }
              isDisabled={ !subjectOptions.length }
            />
          </Field>
          <TabsNav
            tabs={ tabs }
            selectedTab={ selectedTab }
            isVisible={ !!subjectDetail.id }
            isLoading={ isSubjectFetching }
            onClick={ handleSelectTab }
          />
        </div>
      </header>
      <section className="grade-table">
        { tabContent() }
      </section>
    </StyledArticle>
  )
}

export default GradeInput
