import styled from 'styled-components'

export default styled.div`
  padding: 1em;
  box-sizing: border-box;

  .spinner-container {
    margin: 4em;
  }

  .frame {
    overflow-x: visible !important;
    overflow-y: visible !important;
  }

  @media (max-width: 767px) {
    .frame {
      width: 80%;
    }
  }

  @media (min-width: 992px) {
    .frame {
      width: 35%;
      padding: 16px 24px 32px 24px;
    }

    label {
      text-align: left;
    }

    .container-form > .input {
      /* flex-grow: 1; */
      min-width: 40%;
    }
  }

  .content-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    h3 {
      margin: 0;
    }
  }

  .content-header > button {
    margin: 0;
  }

  label {
    text-align: left;
    margin: 0 0 6px;
    font-size: 14px;
    color: ${ ({ theme }) => theme.colors.typography.medium };
  }

  .optional {
    label::after { 
      content: '';
    }
  }

  label::after {
    content: '*';
    color: #008bf8;
    margin-left: 0.4rem;
  }

  label.error::after {
    display: none;
  }

  input {
    max-width: 100%;
    margin-bottom: 1.5rem;
  }

  .content-buttons {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 1024px) and (orientation: portrait) {
    .frame {
      height: 525px;
      width: 40%;
    } 
  }

  .checkbox-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    margin-bottom: 1.5rem;

    .checkbox {
      margin-right: 8px;
    }
  }

  .select {
    width: 100%;
  }

  .container-form {
    &.student {
      height: 50vh;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;

      .content-buttons {
        margin-top: auto;
      }
    }
  }
`
