import styled from 'styled-components'

export default styled.div`
  height: 100%;

  .loading-modal {
    .container {
      margin: 4em;
    }
  }
`
