import axios, { AxiosResponse } from 'axios'
import { useContext } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { prefix } from '../config'
import { SessionContext } from '../context'
import { IParamsLogin } from '../models'
import { privatesUrls, publicsUrls } from '../routing'

export function useAuth() {
  const { push } = useHistory()
  const { formatMessage } = useIntl()
  const { setErrorMessage, setLoading, setAlert, setSession, setUsername } = useContext(SessionContext)
  const bearerToken = localStorage.getItem(`${ prefix }:token`)

  const logIn = async (params: IParamsLogin) => {
    setLoading(true)
    await axios.post(
      `${ process.env.REACT_APP_API_URL }api/v1/Account/Login`,
      JSON.stringify(params),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then(async response => {
      localStorage.setItem(`${ prefix }:token`, response.data.token)
      localStorage.setItem(`${ prefix }:refreshToken`, response.data.refreshToken)
      localStorage.setItem(`${ prefix }:username`, params.username)
      localStorage.setItem(`${ prefix }:lastSession`, Date.now().toString())
      setSession({ token: response.data.token, refreshToken: response.data.refreshToken })
      setUsername(params.username)
      push(privatesUrls.gradeInput)
    }).catch(async () => {
      setErrorMessage(true)
      setAlert({
        type: 'error',
        message: formatMessage({ id: 'errorSigninIn' })
      })
    }).finally(() => setLoading(false))
  }

  const logOut = () => {
    localStorage.removeItem(`${ prefix }:token`)
    localStorage.removeItem(`${ prefix }:refreshToken`)
    localStorage.removeItem(`${ prefix }:username`)
    localStorage.removeItem(`${ prefix }:lastSession`)
    setSession({ token: '', refreshToken: '' })
    setUsername('')
    setAlert({
      type: 'error',
      message: formatMessage({ id: 'sessionExpired' })
    })
    push(publicsUrls.login)
  }

  const setApplicationPassword = async (passApp: string) => {
    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/AppPass/UpSet`,
      JSON.stringify({ passApp }),
      {
        headers: {
          accept: '*/*',
          Authorization: 'Bearer ' + bearerToken,
          'Content-Type': 'application/json-patch+json'
        }
      }
    ).then(async () => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'created' })
      })
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Set application password error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.response.data.Message
        })
      }
    })
  }

  const getApplicationPassword = async (): Promise<string | void> => {
    return await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/AppPass/GetPass`, {
      method: 'GET',
      headers: {
        accept: '*/*',
        Authorization: 'Bearer ' + bearerToken,
        'Content-Type': 'application/json-patch+json'
      }
    }).then((response: AxiosResponse<string>) => {
      return response.data ?? ''
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Reference Grades error: ', error)
        }
        setAlert({
          type: 'error',
          message: `${ formatMessage({ id: 'accessCode' }) } error`
        })
      }
    })
  }

  return { logIn, logOut, setApplicationPassword, getApplicationPassword }
}
