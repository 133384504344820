import { Button, Field, IOption } from '@valudio/ui'
import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import Input from '../../../../components/Input'
import { ModalNew as Modal, SearchInput } from '../../../../components'
import { SessionContext } from '../../../../context'
import { IModal, IStudentEditObject } from '../../../../models'
import Styled from './styles'

interface IProps extends IModal {
  classes: IOption[]
  onCreate: (student: IStudentEditObject) => void
}

const ModalNew: React.FC<IProps> = ({
  classes, setTableUpdated = () => undefined, title, isHidden, onClose, onCreate
}) => {
  const { formatMessage } = useIntl()
  const { openModal, setOpenModal, loading } = useContext(SessionContext)
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [identificationNumber, setIdentificationNumber] = useState<string>('')
  const [idClass, setIdClass] = useState<number>()

  const handleClassChange = (option: IOption) => {
    setIdClass(Number(option.id))
  }

  const handleClose = () => {
    handleCleanup()
    if (onClose && typeof onClose === 'function') onClose()
  }

  const handleCleanup = () => {
    setFirstName('')
    setLastName('')
    setIdentificationNumber('')
    setIdClass(undefined)
  }

  const handleSubmit = async () => {
    const newStudent: IStudentEditObject = {
      firstName,
      lastName,
      identificationNumber,
      idClass
    }
    onCreate(newStudent)
    handleCleanup()
  }

  return (
    <Modal
      isHidden={ isHidden }
      title={title}
      setTableUpdated={setTableUpdated}
      onClose={ handleClose }
    >
      <Styled>
        <Input
          className='input'
          label={formatMessage({ id: 'labelFirstName'})}
          onChange={setFirstName}
          initialValue={firstName}
          placeholder={(formatMessage({ id: 'placeholderFirstName'}))}
          type='text'
        />
        <Input
          className='input'
          label={ formatMessage({ id: 'labelLastName'}) }
          onChange={setLastName}
          initialValue={lastName}
          placeholder={(formatMessage({ id: 'placeholderLastName'}))}
          type='text'
        />
        <Input
          className='input'
          label={ formatMessage({ id: 'labelIdentificationNumber'}) }
          onChange={setIdentificationNumber}
          initialValue={identificationNumber}
          placeholder={(formatMessage({ id: 'placeholderIdentificationNumber'}))}
          type='number'
        />
        <Field label={ formatMessage({ id: 'class' }) } className="optional">
          <SearchInput
            className="select"
            placeholder={ formatMessage({ id: 'choosePlaceholder' }) }
            options={ classes }
            isDisabled={ !classes.length }
            onChange={ handleClassChange }
          />
        </Field>
        <div className='content-buttons'>
          <Button type='tertiary' onClick={() => setOpenModal(!openModal)} isDisabled={ loading }>
            {formatMessage({ id: 'cancel' })}
          </Button>

          <Button
            style={{ marginLeft: '0.5rem' }}
            isDisabled={!firstName || !lastName || !identificationNumber || loading}
            onClick={ handleSubmit }
            isLoading={ loading }
          >
            {formatMessage({ id: 'save' })}
          </Button>
        </div>
      </Styled>
    </Modal>
  )
}

export default ModalNew
