import styled from 'styled-components'

export default styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow-y: auto;
  margin-bottom: 20px;

  .table-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    position: sticky;
    top: 0;
    background-color: ${ ({ theme }) => theme.colors.grey.light };
    z-index: 3;

    .column {
      margin-right: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      min-height: 80px;
      border-radius: 5px;
      background-color: ${ ({ theme }) => theme.colors.white };
      margin-bottom: 8px;
      padding: 16px 0 0;
      box-sizing: border-box;

      &.students {
        flex-basis: 300px;
        min-width: 245px;
        background-color: ${ ({ theme }) => theme.colors.grey.light };
        padding: 0;

        input {
          background-color: ${ ({ theme }) => theme.colors.white };
          width: 100%;
          border-radius: 5px;
        }

        .select {
          width: 100%;
          background-color: ${ ({ theme }) => theme.colors.white };
          margin-bottom: 8px;
          border-radius: 5px;
        }
      }

      &.semester {
        flex: 1;
        min-width: 390px;
      }

      &.total {
        width: 100px;
        min-width: 74px;
        margin-right: 0;
        justify-content: flex-start;
      }

      h2 {
        margin: 0 0 8px;
        padding: 0 14px;
        box-sizing: border-box;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        color: ${ ({ theme }) => theme.colors.typography.dark };
      }

      > ul {
        margin: 0;
        padding: 0 3px;
        box-sizing: border-box;
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;


        li {
          flex: 1;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 24px;
          text-align: center;
          color: ${ ({ theme }) => theme.colors.typography.medium };
          padding-bottom: 8px;
          box-sizing: border-box;
          margin: 0 4px;

          &:first-of-type {
            margin-left: 0;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }

  .table-content {
    flex: 1;

    &.loading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`
