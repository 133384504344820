import React from 'react'
import { AutoResizeTextarea } from '../..'
import { ICommentRowItem } from '../../../models'
import StyledDiv from './styles'

interface IProps {
  item: ICommentRowItem
  isEditActive: boolean
  isLoading: boolean
  onChange: (value: string) => void
  isDisabled?: boolean
}

export const CommentRow: React.FC<IProps> = ({ item, isEditActive, isLoading, onChange, isDisabled }) => {
  const comments = isEditActive && !isLoading
    ? item.comments.replaceAll('<br />', '\n')
    : item.comments

  return (
    <StyledDiv>
      <div className="column students">
        <span>{ item.label }</span>
      </div>
      <div className="column comments" >
        {
          isEditActive && !isLoading
            ? <AutoResizeTextarea value={ comments } onBlur={ onChange } isDisabled={ isDisabled } />
            : <p dangerouslySetInnerHTML={{ __html: comments }} />
        }
      </div>
    </StyledDiv>
  )
}
