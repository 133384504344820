import { Input as ValudioInput } from '@valudio/ui'
import React from 'react'
import { InputProps } from '../../models'
import Styled from './styles'

interface IProps extends InputProps<string> {
  label: string
  isDisabled?: boolean
}

const Input: React.FC<IProps> = ({
  isHidden,
  className,
  style,
  label,
  isDisabled,
  onChange,
  initialValue,
  isInvalid,
  placeholder,
  error,
  type
}) => {
  if (isHidden) return null

  const classNames = `${className || ''} ${isDisabled ? 'disabled' : ''}`

  return (
    <Styled className={classNames} style={style}>
      <label>{label}</label>
      <br />
      <ValudioInput
        className={className}
        onChange={onChange}
        initialValue={initialValue}
        placeholder={placeholder}
        isInvalid={isInvalid}
        type={type}
      />
      {isInvalid && <label className='error'>{error}</label>}
    </Styled>
  )
}

export default Input
