import { IBaseProps, IOption } from '@valudio/ui'
import React from 'react'
import { useIntl } from 'react-intl'
import Item from './Item'
import StyledSection from './styles'

interface IProps extends IBaseProps {
  labelProp: string
  options: IOption[]
  selected: IOption[]
  query: string
  onBulkSelect: (filtered?: IOption[]) => void
  onClick: (option: IOption) => void
}

export const Dropdown: React.FC<IProps> = ({
  isHidden, options, labelProp, selected, query, onClick, onBulkSelect
}) => {
  const { formatMessage } = useIntl()
  const bulkLabel = selected.length > 0 ? formatMessage({ id: 'unselectAll' }) : formatMessage({ id: 'selectAll' })

  const isFiltered = (label: string): boolean => {
    return label.toLowerCase().includes(query.toLowerCase())
  }

  const optionItems = options.map((x, i) => (
    <Item
      key={ i }
      label={ x[labelProp] }
      isSelected={ !!selected.find(s => s.value === x.value) }
      onClick={ onClick.bind(undefined, x) }
      isHidden={ !isFiltered(x[labelProp]) }
    />
  ))

  const handleBulkSelect = (event: any) => {
    event.preventDefault()
    const filtered = options.filter(x => isFiltered(x[labelProp]))
    onBulkSelect(filtered)
  }

  if (isHidden) return null
  return (
    <StyledSection>
      <p>
        <button className="bulk-select-button" onClick={ handleBulkSelect }>{ bulkLabel }</button>
      </p>
      <ul className="options">
        { optionItems }
      </ul>
    </StyledSection>
  )
}
