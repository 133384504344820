import React, { createContext, useState } from 'react'

export interface IUiContext {
  isPasswordModalVisible: boolean
  setIsPasswordModalVisible: (isVisible: boolean) => void
  isOtherSettingsVisible: boolean
  setIsOtherSettingsVisible: (isVisible: boolean) => void
}

const UiContext = createContext<IUiContext>({
  isPasswordModalVisible: false,
  setIsPasswordModalVisible: () => undefined,
  isOtherSettingsVisible: false,
  setIsOtherSettingsVisible: () => undefined
})

const UiProvider: React.FC = ({ children }) => {
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false)
  const [isOtherSettingsVisible, setIsOtherSettingsVisible] = useState(false)

  return (
    <UiContext.Provider
      value={{
        isPasswordModalVisible,
        setIsPasswordModalVisible,
        isOtherSettingsVisible,
        setIsOtherSettingsVisible
      }}
    >
      { children }
    </UiContext.Provider>
  )
}

export { UiContext, UiProvider }
