import React, { ChangeEvent, useEffect, useRef } from 'react'
import StyledTextarea from './styles'

interface IProps {
  value: string
  initialRows?: number
  isDisabled?: boolean
  onBlur?: (value: string) => void
  onChange?: (value: string) => void
}

export const AutoResizeTextarea: React.FC<IProps> = ({ initialRows, value, isDisabled, onBlur, onChange }) => {
  const ref = useRef<HTMLTextAreaElement>(null)
  const rows = initialRows ?? 1
  const maxRows = 200

  const handleRowChange = () => {
    const textareaLineHeight = 24

    if (ref && ref.current) {
      const previousRows = ref.current.rows
      ref.current.rows = 1
      const currentRows = ~~(ref.current.scrollHeight / textareaLineHeight)

      if (currentRows === previousRows) {
        ref.current.rows = currentRows
      }

      if (currentRows >= maxRows) {
        ref.current.rows = maxRows
        ref.current.scrollTop = ref.current.scrollHeight
      }

      ref.current.rows = currentRows < maxRows ? currentRows : maxRows
    }
  }

  const handleBlur = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const nextValue = event.currentTarget.value
    if (onBlur && typeof onBlur === 'function') onBlur(nextValue)
  }

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const nextValue = event.currentTarget.value

    handleRowChange()
    if (onChange && typeof onChange === 'function') onChange(nextValue)
  }

  useEffect(() => {
    handleRowChange()
  }, [])

  return (
    <StyledTextarea
      ref={ ref }
      rows={ rows }
      style={{ minHeight: initialRows ? `${ initialRows * 24 }px` : 0 }}
      onBlur={ handleBlur }
      onFocus={ handleRowChange }
      onChange={ handleChange }
      disabled={ isDisabled }
    >
      { value }
    </StyledTextarea>
  )
}
