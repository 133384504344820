import { useCallback, useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import axios, { AxiosResponse } from 'axios'
import { SessionContext } from '../context'
import {
  gradeArrayToGradeTemplate,
  initialDraftReferenceGrade,
  IReferenceGrade,
  IReferenceGradeRow,
  IReferenceGradeRowResponse,
  IReferenceGradeResponse,
  gradeTemplateToGradeArray
} from '../models'
import { prefix } from '../config'
import { useAuth } from '.'

export const useReferenceGrades = () => {
  const { formatMessage } = useIntl()
  const { setAlert, setOpenModalConfirm } = useContext(SessionContext)
  const { logOut } = useAuth()
  const [referenceGrades, setReferenceGrades] = useState<IReferenceGradeRow[]>([])
  const [editReferenceGrade, setEditReferenceGrade] = useState<IReferenceGrade>(initialDraftReferenceGrade)
  const [isFetching, setIsFetching] = useState(false)
  const [isDraftFecthing, setIsDraftFecthing] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const bearerToken = localStorage.getItem(`${ prefix }:token`)
  const bearer = 'Bearer ' + bearerToken

  const fetchReferenceGrades = useCallback(async () => {
    setIsFetching(true)

    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/SectionTemplate/GetAll`, {
      method: 'GET',
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json-patch+json'
      }
    }).then((response: AxiosResponse<IReferenceGradeRowResponse[]>) => {
      const referenceGrades: IReferenceGradeRow[] = response.data.map(rg => ({
        ...rg,
        gradeTemplateItems: gradeArrayToGradeTemplate(rg.sectionTemplateLines)
      }))
      setReferenceGrades(referenceGrades)
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Reference Grades error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ?? formatMessage({ id: 'genericErrorListFetch' })
        })
      }
    }).finally(() => {
      setIsFetching(false)
    })
  }, [bearer, formatMessage, logOut, setAlert])

  const searchReferenceGrades = useCallback(async (query: string) => {
    setIsFetching(true)

    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/SectionTemplate/Filter?search=${ query }`, {
      method: 'GET',
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json-patch+json'
      }
    }).then((response: AxiosResponse<IReferenceGradeRowResponse[]>) => {
      const referenceGrades: IReferenceGradeRow[] = response.data.map(rg => ({
        ...rg,
        gradeTemplateItems: gradeArrayToGradeTemplate(rg.sectionTemplateLines)
      }))
      setReferenceGrades(referenceGrades)
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Reference Grades error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ?? formatMessage({ id: 'genericErrorListFetch' })
        })
      }
    }).finally(() => {
      setIsFetching(false)
    })
  }, [bearer, formatMessage, logOut, setAlert])

  const getReferenceGradeById = async (id: number) => {
    setIsDraftFecthing(true)

    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/SectionTemplate/GetOne/${ id }`, {
      method: 'GET',
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json'
      }
    }).then((response: AxiosResponse<IReferenceGradeResponse>) => {
      const referenceGrade: IReferenceGrade = {
        ...response.data,
        gradeTemplateItems: gradeArrayToGradeTemplate(response.data.sectionTemplateLines)
      }
      setEditReferenceGrade(referenceGrade)
      setIsModalVisible(true)
      setIsDraftFecthing(false)
    }).catch(async error => {
      if (error && error.response && error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Subject fetch error: ', error)
        }
        // Change literal
        setAlert({
          type: 'error',
          message: error.Message ?? formatMessage({ id: 'genericErrorListFetch' })
        })
      }
      setIsModalVisible(false)
      setIsDraftFecthing(false)
    })
  }

  const createReferenceGrade = async (newReferenceGrade: IReferenceGrade) => {
    setIsFetching(true)

    const body = {
      ...newReferenceGrade,
      sectionTemplateLines: gradeTemplateToGradeArray(newReferenceGrade.gradeTemplateItems)
    }

    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/SectionTemplate/Create`,
      JSON.stringify(body),
      {
        headers: {
          accept: '*/*',
          Authorization: 'Bearer ' + bearerToken,
          'Content-Type': 'application/json-patch+json'
        }
      }
    ).then(async () => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'created' })
      })
      setIsModalVisible(false)
      await fetchReferenceGrades()
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Subject fetch error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ??
          `${ formatMessage({ id: 'genericErrorCreate' }) } ${ formatMessage({ id: 'referenceGrade' }) }`
        })
      }
      setIsFetching(false)
    })
  }

  const updateReferenceGrade = async (newReferenceGrade: IReferenceGrade) => {
    setIsFetching(true)

    const body = {
      ...newReferenceGrade,
      sectionTemplateLines: gradeTemplateToGradeArray(newReferenceGrade.gradeTemplateItems)
    }

    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/SectionTemplate/Update`,
      JSON.stringify(body),
      {
        headers: {
          accept: '*/*',
          Authorization: 'Bearer ' + bearerToken,
          'Content-Type': 'application/json-patch+json'
        }
      }
    ).then(async () => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'updated' })
      })
      setIsModalVisible(false)
      await fetchReferenceGrades()
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Reference grade update error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ??
          `${ formatMessage({ id: 'genericErrorUpdate' }) } ${ formatMessage({ id: 'referenceGrade' }) }`
        })
      }
      setIsFetching(false)
    })
  }

  const deleteReferenceGrade = async (id: number) => {
    setIsFetching(true)

    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/SectionTemplate/Delete?id=${ id }`, null, {
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json'
      }
    }).then(async () => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'deleted' })
      })
      setOpenModalConfirm({ openModalConfirm: false, id: 0 })
      await fetchReferenceGrades()
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Reference grade delete error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ??
            `${ formatMessage({ id: 'genericErrorDelete' }) } ${ formatMessage({ id: 'referenceGrade' }) }`
        })
      }
    })
  }

  return {
    referenceGrades,
    editReferenceGrade,
    setEditReferenceGrade,
    isFetching,
    isDraftFecthing,
    setIsDraftFecthing,
    isModalVisible,
    setIsModalVisible,
    fetchReferenceGrades,
    searchReferenceGrades,
    getReferenceGradeById,
    createReferenceGrade,
    updateReferenceGrade,
    deleteReferenceGrade
  }
}
