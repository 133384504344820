import { IClassEdit, IModalTabEdit } from './classes'
import { IAlert, IToast } from './common'
import { IEditId, IModalConfirm, IModalEdit } from './modal'

export interface ISession {
  token: string
  refreshToken: string
}

export interface IUserEdit {
  id: number
  firstName: string
  lastName: string
  userName: string
  email: string
  password?: string
  roles?: any
  identificationNumber?: string
  isAdmin?: boolean
  idClass?: number
}

export interface ISessionContext extends ISession {
  username: string
  errorMessage: boolean
  loading: boolean
  authorized: boolean
  isAdmin: boolean
  isClassLeader: boolean
  openModal: boolean
  openModalEdit: boolean
  userEdit: IUserEdit
  classEdit: IClassEdit
  openModalTabEdit: boolean
  openModalConfirm: boolean
  id: number
  openModalTab: boolean
  openToast: boolean
  titleToast: string
  typeToast: string
  alert: IAlert | null
  isEdit: boolean
  setSession: (session: ISession) => void
  setUsername: (username: string) => void
  setIsAdmin: (admin: boolean) => void
  setIsClassLeader: (classLeader: boolean) => void
  setErrorMessage: (errorMessage: boolean) => void
  setLoading: (loading: boolean) => void
  setAuthorized: (authorized: boolean) => void
  setOpenModal: (openModal: boolean) => void
  setOpenModalEdit: (openModalEdit: IModalEdit) => void
  setOpenModalConfirm: (openModalConfirm: IModalConfirm) => void
  setOpenModalTabEdit: (openModalConfirm: IModalTabEdit) => void
  setOpenModalTab: (openModalTab: boolean) => void
  setAlert: (alert: IAlert | null) => void
  setOpenToast: (openToast: IToast) => void
  setIdEdit: (isEdit: IEditId) => void
}

export const initialUserEdit: IUserEdit = {
  id: 0,
  firstName: '',
  lastName: '',
  userName: '',
  email: '',
  password: '',
  roles: [],
  identificationNumber: ''
}
