import styled from 'styled-components'

export default styled.article`
  display: flex;
  flex-direction: column;
  min-width: 50vw;
  width: 55vw;
  min-width: 700px;
  /* max-width: 1100px; */
  height: 95vh;
  overflow-y: hidden;

  .content-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 1em;
    padding-right: 1em;
  }

  form {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;

    h4 {
      width: 100%;
      padding-left: 1em;
      padding-right: 1em;
      box-sizing: border-box;
    }

    .fields {
      flex: 1;

      .form-row {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: nowrap;

        &:first-of-type {
          margin-bottom: 1.5em;
        }

        &:not(.title-row) {
          padding: 0 36px;
        }

        .field {
          flex: 1;
          margin-left: 1em;
          margin-right: 1em;

          &.checkbox {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-top: 1.5em;

            h4 {
              margin: 0;
            }

            span {
              margin-left: 4px;
            }
          }

          &.date {
            flex: 0 0 auto;
          }
        }

        .label {
          text-align: left;
        }

        input {
          border-radius: 4px;
          max-width: 100%;
        }
      }
    }


    .actions {
      position: sticky;
      bottom: 0;
      width: 100%;
      padding: 1em;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      background-color: ${({ theme }) => theme.colors.grey.light };
      margin-top: 20px;
    }
  }
`
