import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: column;
  min-width: 85vw;
  width: 100%;
  max-width: 1100px;
  height: 85vh;
  overflow-y: hidden;

  .content-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 1em;
    padding-right: 1em;
  }

  form {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: hidden;

    .form-row {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      flex-wrap: nowrap;

      &:first-of-type {
        margin-bottom: 1.5em;
      }

      &.comments {
        margin: 1.5em 0;
      }

      .field {
        flex: 1;
        margin-left: 1em;
        margin-right: 1em;

        h5 {
          margin-top: 0;
        }

        &.hours {
          max-width: 120px;
        }

        &.grade {
          max-width: calc((100% / 6) - 2em);
        }

        &.select {
          /* z-index: 1000; */

          &.year {
            /* width: 80px;
            flex-basis: 80px; */
            flex: 0.4;
          }

          .select-container {
            width: 100%;
            box-sizing: border-box;

            > section {
              z-index: 2000;
            }
          } 
        }

        .label {
          text-align: left;
        }

        input {
          border-radius: 4px;
          max-width: 100%;
        }

        &.checkbox {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-top: 1.5em;

          h4 {
            margin: 0;
          }

          span {
            margin-left: 4px;
          }
        }
      }
    }

    .actions {
      width: 100%;
      padding: 1em;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-top: auto;
      background-color: ${({ theme }) => theme.colors.grey.light };
    }
  }
`
