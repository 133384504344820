import React from 'react'
import { ITabItem } from '../../../../models'
import StyledNav from './styles'

interface IProps {
  tabs: ITabItem[]
  selectedTab: string
  isVisible: boolean
  isLoading?: boolean
  onClick?: (key: string) => void
}

export const ReportTabs: React.FC<IProps> = ({ tabs, selectedTab, isVisible, isLoading, onClick }) => {

  const handleTabClick = (key: string) => {
    if (selectedTab !== key && onClick && typeof onClick === 'function') {
      onClick(key)
    }
  }

  const tabItems = () => {
    if (!isVisible) {
      return null
    }
    return (
      <>
        {
          tabs.map(tab => (
            <div
              key={ tab.key }
              className={ selectedTab === tab.key ? 'active' : '' }
              onClick={ () => handleTabClick(tab.key) }
            >
              <span>{ tab.label }</span>
            </div>
          ))
        }
      </>
    )
  }

  return (
    <StyledNav className={ isLoading ? 'loading' : '' }>
      { tabItems() }
    </StyledNav>
  )
}
