import classNames from 'classnames'
import React from 'react'
import StyledLabel from './styles'

interface IProps {
  value: boolean
  onChange: () => void
  isDisabled?: boolean
}

export const Switch: React.FC<IProps> = ({ value, onChange, isDisabled }) => {
  return (
    <StyledLabel className={ classNames('switch', { disabled: isDisabled }) }>
      <input type="checkbox" checked={ value ?? undefined } onChange={ onChange } />
      <span className="slider round" />
    </StyledLabel>
  )
}
