import styled from 'styled-components'

export default styled.textarea`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  overflow: auto;
	height: auto;
  min-height: 1em;
  line-height: 24px;
  border: 1px solid ${ ({ theme }) => theme.colors.grey.medium };
  border-radius: 3px;
  background-color: ${ ({ theme }) => theme.colors.grey.light };
  outline: none;
  resize: none;
  font-family: ${ ({ theme }) => theme.fontFamily };
  font-size: 16px;
  font-weight: normal;
  padding: 8px;

  &:focus {
    border-color: ${ ({ theme }) => theme.colors.primary.medium };
    background-color: ${ ({ theme }) => theme.colors.primary.light };
  }
`
