import { ISubjectGradeStudent } from '../models'

export const hasGradesNegativeValues = (student: ISubjectGradeStudent): boolean => {
  const { first, second, third, fourth, examFirstSemester, examSecondSemester } = student.grade
  return Number(first.note) < 0
    || Number(first.max) < 0
    || Number(second.note) < 0
    || Number(second.max) < 0
    || Number(third.note) < 0
    || Number(third.max) < 0
    || Number(fourth.note) < 0
    || Number(fourth.max) < 0
    || Number(examFirstSemester.note) < 0
    || Number(examFirstSemester.max) < 0
    || Number(examSecondSemester.note) < 0
    || Number(examSecondSemester.max) < 0
}
