import styled from 'styled-components'

export default styled.article`
  display: flex;
  flex-direction: column;
  min-width: 400px;
  width: 450px;
  max-width: 1100px;
  height: auto;
  overflow-y: hidden;

  > header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 1em;
    padding-right: 1em;
  }

  >  form {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: hidden;

    .form-row {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: nowrap;
      margin-bottom: 1.5em;

      .field {
        flex: 1;
        margin-left: 1em;
        margin-right: 1em;

        h5 {
          margin-top: 0;
        }

        .label {
          text-align: left;
        }

        input {
          border-radius: 4px;
          max-width: 100%;
        }

        .file-input {
          width: 100%;
          max-width: 100%;

          > input {
            flex: 1;
          }
        }
      }
    }

    .form-column {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: 1em;
      padding-right: 1em;
      overflow-y: scroll;
      box-sizing: border-box;

      span {
        font-weight: bold;
      }

      > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        p {
          margin: 0 0 8px;
        }

        ul {
          padding: 0;
          list-style: none;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin: 0;
        }
      }

    }
  }

  > .actions {
    width: 100%;
    padding: 1em;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
`
