import { IOption } from '@valudio/ui'
import axios, { AxiosResponse } from 'axios'
import { useCallback, useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { prefix } from '../config'
import { SessionContext } from '../context'
import { initialSampleTextDraft, ISampleText } from '../models'
import { useAuth } from './useAuth'

export const useSampleTexts = () => {
  const { formatMessage } = useIntl()
  const { setAlert, setOpenModalConfirm } = useContext(SessionContext)
  const { logOut } = useAuth()
  const [sampleTexts, setSampleTexts] = useState<ISampleText[]>([])
  const [sampleTextOptions, setSampleTextOptions] = useState<IOption[]>([])
  const [editSampleText, setEditSampleText] = useState<ISampleText>(initialSampleTextDraft)
  const [isFetching, setIsFetching] = useState(false)
  const [isDraftFecthing, setIsDraftFecthing] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const bearerToken = localStorage.getItem(`${ prefix }:token`)
  const bearer = 'Bearer ' + bearerToken

  const fetchSampleTexts = useCallback(async () => {
    setIsFetching(true)

    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/SampleText/GetAll`, {
      method: 'GET',
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json-patch+json'
      }
    }).then((response: AxiosResponse<ISampleText[]>) => {
      const data: ISampleText[] = response.data
      const options: IOption[] = data.map(sp => ({ id: sp.id.toString(), label: sp.text }))
      setSampleTexts(data)
      setSampleTextOptions(options)
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Reference Grades error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ?? formatMessage({ id: 'genericErrorListFetch' })
        })
      }
    }).finally(() => {
      setIsFetching(false)
    })
  }, [bearer, formatMessage, logOut, setAlert])

  const searchSampleTexts = useCallback(async (query: string) => {
    setIsFetching(true)

    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/SampleText/Filter?search=${ query }`, {
      method: 'GET',
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json-patch+json'
      }
    }).then((response: AxiosResponse<ISampleText[]>) => {
      setSampleTexts(response.data)
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Reference Grades error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ?? formatMessage({ id: 'genericErrorListFetch' })
        })
      }
    }).finally(() => {
      setIsFetching(false)
    })
  }, [bearer, formatMessage, logOut, setAlert])

  const getSampleTextBtId = async (id: number) => {
    setIsDraftFecthing(true)

    await axios.get(`${ process.env.REACT_APP_API_URL }api/v1/SampleText/GetOne/${ id }`, {
      method: 'GET',
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json'
      }
    }).then((response: AxiosResponse<ISampleText>) => {
      const sampleText = { ...editSampleText, ...response.data }
      setEditSampleText(sampleText)
      setIsModalVisible(true)
      setIsDraftFecthing(false)
    }).catch(async error => {
      if (error && error.response && error.response.status === 401) {
        await logOut()
      }
      if (process.env.NODE_ENV === 'development') {
        console.log('Subject fetch error: ', error)
      }
      // Change literal
      setAlert({
        type: 'error',
        message: error.Message ?? formatMessage({ id: 'genericErrorListFetch' })
      })
      setIsModalVisible(false)
      setIsDraftFecthing(false)
    })
  }

  const createSampleText = async (newSampleText: ISampleText) => {
    setIsFetching(true)

    const body = {
      text: newSampleText.text,
      textType: newSampleText.textType
    }

    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/SampleText/Create`,
      JSON.stringify(body),
      {
        headers: {
          accept: '*/*',
          Authorization: 'Bearer ' + bearerToken,
          'Content-Type': 'application/json-patch+json'
        }
      }
    ).then(async () => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'created' })
      })
      setIsModalVisible(false)
      await fetchSampleTexts()
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Sample text fetch error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ??
          `${ formatMessage({ id: 'genericErrorCreate' }) } ${ formatMessage({ id: 'sampleText' }) }`
        })
      }
      setIsFetching(false)
    })
  }

  const updateSampleText = async (updatedSampleText: ISampleText) => {
    setIsFetching(true)

    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/SampleText/Update`,
      JSON.stringify(updatedSampleText),
      {
        headers: {
          accept: '*/*',
          Authorization: 'Bearer ' + bearerToken,
          'Content-Type': 'application/json-patch+json'
        }
      }
    ).then(async () => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'updated' })
      })
      setIsModalVisible(false)
      await fetchSampleTexts()
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Sample text update error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ??
          `${ formatMessage({ id: 'genericErrorUpdate' }) } ${ formatMessage({ id: 'sampleText' }) }`
        })
      }
      setIsFetching(false)
    })
  }

  const deleteSampleText = async (id: number) => {
    setIsFetching(true)

    await axios.post(`${ process.env.REACT_APP_API_URL }api/v1/SampleText/Delete?id=${ id }`, null, {
      headers: {
        accept: '*/*',
        Authorization: bearer,
        'Content-Type': 'application/json'
      }
    }).then(async () => {
      setAlert({
        type: 'success',
        message: formatMessage({ id: 'deleted' })
      })
      setOpenModalConfirm({ openModalConfirm: false, id: 0 })
      await fetchSampleTexts()
    }).catch(async error => {
      if (error.response.status === 401) {
        await logOut()
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('Reference grade delete error: ', error)
        }
        setAlert({
          type: 'error',
          message: error.Message ??
            `${ formatMessage({ id: 'genericErrorDelete' }) } ${ formatMessage({ id: 'sampleText' }) }`
        })
      }
    })
  }

  return {
    sampleTexts,
    sampleTextOptions,
    setSampleTextOptions,
    editSampleText,
    setEditSampleText,
    isFetching,
    isDraftFecthing,
    setIsDraftFecthing,
    isModalVisible,
    setIsModalVisible,
    fetchSampleTexts,
    searchSampleTexts,
    getSampleTextBtId,
    createSampleText,
    updateSampleText,
    deleteSampleText
  }
}
