import { Checkbox, IBaseProps } from '@valudio/ui'
import React from 'react'
import StyledLi from './styles'

interface IProps extends IBaseProps {
  label: string
  onClick: () => void
  isSelected: boolean
}

const Item: React.FC<IProps> = ({ isHidden, label, onClick, isSelected }) => {
  if (isHidden) return null

  return (
    <StyledLi
      className={ `option ${ isSelected ? 'selected' : '' }` }
      onClick={ onClick }
    >
      <Checkbox className="checkbox" value={ isSelected } onChange={ () => null } />
      <label className="label">{ label }</label>
    </StyledLi>
  )
}

export default Item
