import { Button, Field, Icon, Input, Modal } from '@valudio/ui'
import React, { useContext, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, UiContext } from '../../context'
import { useTeachers } from '../../hooks'
import StyledArticle from './styles'

const PasswordModal: React.FC = () => {
  const formRef = useRef<HTMLFormElement>(null)
  const { formatMessage } = useIntl()
  const { username } = useContext(SessionContext)
  const { isPasswordModalVisible, setIsPasswordModalVisible } = useContext(UiContext)
  const { isTeacherFetching, updatePassword } = useTeachers()
  const [password, setPassword] = useState('')

  const handleClose = () => {
    setPassword('')
    if (formRef && formRef.current) formRef.current.reset()
    setIsPasswordModalVisible(false)
  }

  const handleUpdate = async () => {
    await updatePassword(username, password)
    handleClose()
  }

  if (!isPasswordModalVisible) return null
  return (
    <Modal
      isHidden={ !isPasswordModalVisible }
      onOverlayClick={ handleClose }
      style={{ zIndex: 4000 }}
    >
      <StyledArticle>
        <header>
          <h3>{ formatMessage({ id: 'editPassword' }) }</h3>
          <Icon
            className='icon'
            icon='close'
            onClick={ handleClose }
          />
        </header>
        <form ref={ formRef }>
          <div className="form-row">
            <Field label={ formatMessage({ id: 'labelPassword' }) } className="field">
              <Input
                form={ formRef.current ?? undefined }
                onChange={ value => setPassword(value)}
                placeholder={ formatMessage({ id: 'placeholderPassword' }) }
              />
            </Field>
          </div>
        </form>
        <div className="actions">
          <Button
            type="primary"
            onClick={ handleUpdate }
            isLoading={ isTeacherFetching }
            isDisabled={ !password }
          >
            { formatMessage({ id: 'updatePassword' }) }
          </Button>
        </div>
      </StyledArticle>
    </Modal>
  )
}

export default PasswordModal
