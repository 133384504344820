import { IOption } from '@valudio/ui'
import React, { ChangeEvent, useState } from 'react'
import { SmallSpinner } from '../..'
import { Option } from '../Option'
import StyledSection from './styles'

interface IProps {
  placeholder: string
  options: IOption[]
  isHidden: boolean
  onClick: (option: IOption) => void
  onTextChange?: (text: string) => void
  isLoading?: boolean
}

export const Dropdown: React.FC<IProps> = ({ placeholder, options, onClick, isHidden, onTextChange, isLoading }) => {
  const [query, setQuery ] = useState('')

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.currentTarget.value)
    if (onTextChange && typeof onTextChange === 'function') onTextChange(event.currentTarget.value)
  }

  const handleOptionClick = (option: IOption) => {
    onClick(option)
    setQuery('')
  }

  const isFiltered = (item: string) => {
    return item.toLowerCase().includes(query.toLowerCase())
  }

  const optionItems = isLoading
    ? <SmallSpinner style={{ margin: '10px auto' }} />
    : options.map(option => (
      <Option
        key={ `dropdown-option-${ option.id }` }
        label={ option.label }
        onClick={ () => handleOptionClick(option) }
        isHidden={ !isFiltered(option.label) }
      />
    ))

  if (isHidden) return null
  return (
    <StyledSection>
      <input className="input-search" onChange={ handleChange } placeholder={ `${ placeholder }...` } />
      <ul className="options">{ optionItems }</ul>
    </StyledSection>
  )
}
